import gql from 'graphql-tag';
import { analyticArguments, analyticArgumentsQuery } from '../analyticArgument';

export const advanceMetricQuery = `
$postMade: Boolean!,
$neutralTalk: Boolean!,
$engagement: Boolean!,
$followerCount: Boolean!,
$likeCount: Boolean!,
$engagementRatePerPost: Boolean!,
$negativeTalk: Boolean!,
$engagementRate: Boolean!,
$share: Boolean!,
$positiveTalk: Boolean!,
$followerGrowth: Boolean!,
$comment: Boolean!,
$engagementPerPost: Boolean!,
$sentimentScore: Boolean!
$talk: Boolean!,
$talker: Boolean!
$totalReply: Boolean!
$reach: Boolean!
$impression: Boolean!
$view: Boolean!`;

export const advanceMetricArguments = `
postMade: $postMade,
neutralTalk: $neutralTalk,
engagement: $engagement,
followerCount: $followerCount,
likeCount: $likeCount,
engagementRatePerPost: $engagementRatePerPost,
negativeTalk: $negativeTalk,
engagementRate: $engagementRate,
share: $share,
positiveTalk: $positiveTalk,
followerGrowth: $followerGrowth,
comment: $comment,
engagementPerPost: $engagementPerPost,
sentimentScore: $sentimentScore,
talk: $talk,
talker: $talker
totalReply: $totalReply
reach: $reach
impression: $impression
view: $view
`;

export const ADD_COMPARISON = gql`
  mutation comparison_addComparison(
    $projectId: Id!
    $comparisonName: String!
    $comparisonGroups: [ComparisonGroupInput!]!
    $sourceType: SourceType!
  ) {
    comparison_addComparison(
      projectId: $projectId
      comparisonName: $comparisonName
      comparisonGroups: $comparisonGroups
      sourceType: $sourceType
    ) {
      id
      namespace
      displayName
      createdAt
      groups {
        id
        name
        displayName
        createdAt
        objectGroup {
          id
          name
          displayName
          createdAt
          objectList {
            id
            socialMedia
            streamType
            content
            extraContent
            objectName
            isHashtag
            openApiId
            createdAt
            displayPicture
            includes
            excludes
            languages
          }
        }
        objectList {
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
          openApiId
          createdAt
          displayPicture
          includes
          excludes
          languages
        }
        labelList {
          id
          name
          displayName
          colorType
        }
      }
      colors
    }
  }
`;

export const SET_DEFAULT = gql`
  mutation comparison_setDefault($projectId: Id!, $comparisonId: Id!) {
    comparison_setDefault(projectId: $projectId, comparisonId: $comparisonId) {
      id
      namespace
      displayName
      sourceType
      isDefault
      createdAt
      colors
    }
  }
`;

export const EDIT_COMPARISON = gql`
  mutation comparison_editComparison(
    $comparisonId: Id!
    $comparisonName: String!
    $comparisonGroups: [ComparisonGroupInput!]!
  ) {
    comparison_editComparison(
      comparisonId: $comparisonId
      comparisonName: $comparisonName
      comparisonGroups: $comparisonGroups
    ) {
      id
      namespace
      displayName
      createdAt
      colors
    }
  }
`;

export const DELETE_COMPARISON = gql`
  mutation comparison_deleteComparison($comparisonId: Id!) {
    comparison_deleteComparison(comparisonId: $comparisonId) {
      id
      namespace
      displayName
      isDefault
      createdAt
      colors
    }
  }
`;

export const columnArgumentType = `
  $userId: Id!,
  $post: Boolean!,
  $like: Boolean!,
  $comment: Boolean!,
  $share: Boolean!,
  $reach: Boolean!,
  $engagement: Boolean!,
  $engagementRate: Boolean!,
  $followers: Boolean!
`;
export const columnArgumentValue = `
  userId: $userId,
  post: $post,
  like: $like,
  comment: $comment,
  share: $share,
  reach: $reach,
  engagement: $engagement,
  engagementRate: $engagementRate,
  followers: $followers
`;

export const SET_COMPARISON_TABLE_COLUMN = gql`
  mutation comparison_setComparisonTableColumn(${columnArgumentType}) {
    comparison_setComparisonTableColumn(${columnArgumentValue}) {
      userId
      post
      like
      comment
      share
      reach
      engagement
      engagementRate
      followers
    }
  }
`;

export const SET_ADVANCE_METRIC_SELECTION = gql`
  mutation comparison_updateAdvanceMetricSelection(${analyticArgumentsQuery} ${advanceMetricQuery} $comparisonId: Id!) {
    comparison_updateAdvanceMetricSelection(${analyticArguments} ${advanceMetricArguments} comparisonId: $comparisonId) {
      name
      values {
        isActive
        computationName
        value
        isEligible
      }
    }
  }
`;
