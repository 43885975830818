import { ListSubheader, List } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarMenuItem from './item';
import menuItems, { MenuItem } from './items';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/app/hooks';

const MenuWrapper = styled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(1)} ${theme.spacing(1)};
    }

    .MuiListSubheader-root {
      text-transform: uppercase;
      color: #F5F5F5;
      padding: ${theme.spacing(0.8, 3)};
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
    }
`
);

const renderSidebarMenuItems = ({
  items,
  path,
  isSnaDna,
  isPeoplePanel,
  isOfflineMedia,
  isCustomReport,
  isClustering,
  isOnlineMedia,
  isSocialMedia
}: {
  items: MenuItem[];
  path: string;
  isSnaDna: number;
  isPeoplePanel: number;
  isOfflineMedia: number;
  isCustomReport: number;
  isClustering: number;
  isOnlineMedia: number;
  isSocialMedia: number;
}): JSX.Element => {
  let tempItems = items;
  if (isSnaDna === 0) {
    tempItems = tempItems.filter(
      (item) => !(item.name === 'SNA' || item.name === 'DNA')
    );
  }
  if (isPeoplePanel === 0) {
    tempItems = tempItems.filter((item) => !(item.name === 'People Panel'));
  }
  if (isOfflineMedia === 0) {
    tempItems = tempItems.filter((item) => !(item.name === 'Others Media'));
  }
  if (isCustomReport === 0) {
    tempItems = tempItems.filter((item) => !(item.name === 'Report'));
  }
  if (isClustering === 0) {
    tempItems = tempItems.filter((item) => !(item.name === 'Clustering'));
  }
  if (isSocialMedia === 0) {
    tempItems = tempItems.filter((item) => !(item.name === 'Social Media'));
  }
  if (isOnlineMedia === 0) {
    tempItems = tempItems.filter((item) => !(item.name === 'Online Media'));
  }

  return (
    <List>
      {tempItems.reduce(
        (ev, item) =>
          reduceChildRoutes({
            ev,
            item,
            path,
            isSnaDna,
            isPeoplePanel,
            isOfflineMedia,
            isCustomReport,
            isClustering,
            isOnlineMedia,
            isSocialMedia
          }),
        []
      )}
    </List>
  );
};

const reduceChildRoutes = ({
  ev,
  path,
  item,
  isSnaDna,
  isPeoplePanel,
  isOfflineMedia,
  isCustomReport,
  isClustering,
  isOnlineMedia,
  isSocialMedia
}: {
  ev: JSX.Element[];
  path: string;
  item: MenuItem;
  isSnaDna: number;
  isPeoplePanel: number;
  isOfflineMedia: number;
  isCustomReport: number;
  isClustering: number;
  isOnlineMedia: number;
  isSocialMedia: number;
}): Array<JSX.Element> => {
  const key = item.name;

  const exactMatch = item.link
    ? !!matchPath(
        {
          path: item.link,
          end: true
        },
        path
      )
    : false;

  if (item.items) {
    const partialMatch = item.link
      ? !!matchPath(
          {
            path: item.link,
            end: false
          },
          path
        )
      : false;

    ev.push(
      <SidebarMenuItem
        key={key}
        active={partialMatch}
        open={partialMatch}
        name={item.name}
        icon={item.icon}
        link={item.link}
        badge={item.badge}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items,
          isSnaDna: isSnaDna,
          isPeoplePanel: isPeoplePanel,
          isOfflineMedia: isOfflineMedia,
          isCustomReport: isCustomReport,
          isClustering: isClustering,
          isOnlineMedia: isOnlineMedia,
          isSocialMedia: isSocialMedia
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch}
        name={item.name}
        link={item.link}
        badge={item.badge}
        icon={item.icon}
      />
    );
  }

  return ev;
};

function SidebarMenu() {
  const location = useLocation();
  const {
    isSnaDna,
    isPeoplePanel,
    isOfflineMedia,
    isCustomReport,
    isClustering,
    isOnlineMedia,
    isSocialMedia
  } = useAppSelector((state) => state.users.project.flags);

  return (
    <>
      {menuItems.map((section) => (
        <MenuWrapper
          key={section.heading}
          subheader={
            <ListSubheader component="div" disableSticky>
              {section.heading}
            </ListSubheader>
          }
        >
          {renderSidebarMenuItems({
            items: section.items,
            path: location.pathname,
            isSnaDna,
            isPeoplePanel,
            isOfflineMedia,
            isCustomReport,
            isClustering,
            isOnlineMedia,
            isSocialMedia
          })}
        </MenuWrapper>
      ))}
    </>
  );
}

export default SidebarMenu;
