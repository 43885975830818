import { createSlice } from '@reduxjs/toolkit';

interface IAutoRefreshMetric {
  selectedAutoRefreshInterval: number;
}

const initialState: IAutoRefreshMetric = {
  selectedAutoRefreshInterval: 0
};

export const autoRefreshMetric = createSlice({
  name: 'autoRefreshMetric',
  initialState,
  reducers: {
    setSelectedAutoRefreshInterval: (
      state: IAutoRefreshMetric,
      action: { payload: number }
    ) => {
      state.selectedAutoRefreshInterval = action.payload;
    }
  }
});

export const { setSelectedAutoRefreshInterval } = autoRefreshMetric.actions;

export default autoRefreshMetric.reducer;
