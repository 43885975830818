import gql from 'graphql-tag';
import { analyticArgumentsQuery, analyticArguments } from '../analyticArgument';

export const payloadAutoLabelQuery = `
  $projectId: Id!,
  $objectList: [Id!]!,
  $name: String!,
  $description: String,
  $start: Timestamp!,
  $labelList: [Id!]!,
  $keywords: [String!],
  $postOwnerships: [PostOwnership!]!
`;

export const payloadAutoLabel = `
  projectId: $projectId,
  objectList: $objectList,
  name: $name,
  description: $description,
  start: $start,
  labelList: $labelList,
  keywords: $keywords,
  postOwnerships: $postOwnerships
`;

export const result = `
  originalId
  socialMedia
  displayPicture
  link
  content
  fromId
  fromName
  timestamp
  share
  comment
  like
  audience
  engagementRate
  sentiment
  objectModel{
    id
    socialMedia
    streamType
    content
    extraContent
    objectName
    isHashtag
    createdAt
    openApiId
  }
  postOwnership
  label{
    id
    name
    displayName
    colorType
    isActive
    createdAt
  }
  isEdited
  streamVisibility`;

export const MANAGE_LABELS_ON_STREAM = gql`
  mutation labellingSystem_manageLabelsOnStreams(
    $projectId: Id!
    $objectAndStream: [ObjectAndStreamListInput!]!
    $attachLabels: [Id!]!
    $removeLabels: [Id!]!
  ) {
    labellingSystem_manageLabelsOnStreams(
      projectId: $projectId
      objectAndStream: $objectAndStream
      attachLabels: $attachLabels
      removeLabels: $removeLabels
    ) {
      originalId
      socialMedia
      displayPicture
      link
      content
      fromId
      fromName
      timestamp
      share
      comment
      like
      audience
      engagementRate
      entity {
        id
        name
      }
      sentiment
      objectModel {
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
      }
      postOwnership
      label {
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
    }
  }
`;

export const CREATE_LABEL = gql`
  mutation labellingSystem_createLabel(
    $projectId: Id!
    $name: String!
    $requestColor: String!
  ) {
    labellingSystem_createLabel(
      projectId: $projectId
      name: $name
      requestColor: $requestColor
    ) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const EDIT_LABEL_SOCIAL_MEDIA = gql`
  mutation labellingSystem_updateLabel(
    $projectId: Id!
    $name: String!
    $requestColor: String!
    $labelId: Id!
  ) {
    labellingSystem_updateLabel(
      projectId: $projectId
      name: $name
      requestColor: $requestColor
      labelId: $labelId
    ) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const MANAGE_LABELS_ON_STREAM_BY_FILTER = gql`
  mutation labellingSystem_manageLabelsOnStreamsByFilter(${analyticArgumentsQuery}, 
    $attachLabels: [Id!]!,
    $removeLabels: [Id!]!, $retweetFromOriginalIdList: [String!]) {
    labellingSystem_manageLabelsOnStreamsByFilter(${analyticArguments}, 
      attachLabels: $attachLabels,
      removeLabels: $removeLabels,
      retweetFromOriginalIdList: $retweetFromOriginalIdList) {
      id
      status
    }
  }
`;

export const DELETE_LABEL = gql`
  mutation labellingSystem_deleteLabel($labelId: Id!) {
    labellingSystem_deleteLabel(labelId: $labelId) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const CREATE_AUTO_LABEL = gql`
  mutation autoLabel_createAutoLabel(${payloadAutoLabelQuery}) {
    autoLabel_createAutoLabel(${payloadAutoLabel}) {
      id
      projectId
      objectList{
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
        displayPicture
      }
      name
      description
      start
      labelList{
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
      keywords
      postOwnerships
      isActive
      createdAt
    }
  }
`;

export const EDIT_AUTO_LABEL = gql`
  mutation autoLabel_editAutoLabel($autoLabelId: Id!, ${payloadAutoLabelQuery}) {
    autoLabel_editAutoLabel(autoLabelId: $autoLabelId, ${payloadAutoLabel}) {
      id
      projectId
      objectList{
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
        displayPicture
      }
      name
      description
      start
      labelList{
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
      keywords
      postOwnerships
      isActive
      createdAt
    }
  }
`;

export const DELETE_AUTO_LABEL = gql`
  mutation autoLabel_deleteAutoLabel($autoLabelId: Id!) {
    autoLabel_deleteAutoLabel(autoLabelId: $autoLabelId) {
      id
      projectId
      objectList {
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
        displayPicture
      }
      name
      description
      start
      labelList {
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
      keywords
      postOwnerships
      isActive
      createdAt
    }
  }
`;

export const APPLY_LABEL_TO_ALL_STREAM_ON_RETWEET = gql`
mutation labellingSystem_applyLabelToAllStreamBasedOnRetweet(
  $projectId: Id!
  $objectAndStream: [ObjectAndStreamListInput!]!
  $attachLabels: [Id!]!
  $removeLabels: [Id!]!
) {
  labellingSystem_applyLabelToAllStreamBasedOnRetweet(
    projectId: $projectId
    objectAndStream: $objectAndStream
    attachLabels: $attachLabels
    removeLabels: $removeLabels
  ) {
    ${result}
  }
}
`;

export const ADD_LABEL_ARTICLE = gql`
  mutation labellingArticle_addLabelArticle(
    $projectId: Id!
    $name: String!
    $requestColor: String!
  ) {
    labellingArticle_addLabelArticle(
      projectId: $projectId
      name: $name
      requestColor: $requestColor
    ) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
      __typename
    }
  }
`;
export const EDIT_LABEL_ARTICLE = gql`
  mutation labellingArticle_updateLabelArticle(
    $projectId: Id!
    $name: String!
    $requestColor: String!
    $labelId: Id!
  ) {
    labellingArticle_updateLabelArticle(
      projectId: $projectId
      name: $name
      requestColor: $requestColor
      labelId: $labelId
    ) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const DELETE_LABEL_ARTICLE = gql`
  mutation labellingArticle_deleteLabelArticle($labelId: Id!) {
    labellingArticle_deleteLabelArticle(labelId: $labelId) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const ADD_LABEL_PEOPLE = gql`
  mutation peoplePanel_createLabel(
    $projectId: Id!
    $displayName: String!
    $color: String!
  ) {
    peoplePanel_createLabel(
      projectId: $projectId
      displayName: $displayName
      color: $color
    ) {
      id
      name
      displayName
      color
      isActive
      createdAt
      isProjectDefault
      __typename
    }
  }
`;

export const EDIT_LABEL_PEOPLE = gql`
  mutation peoplePanel_updateLabel(
    $projectId: Id!
    $name: String!
    $color: String!
    $labelId: Id!
  ) {
    peoplePanel_updateLabel(
      projectId: $projectId
      name: $name
      color: $color
      labelId: $labelId
    ) {
      id
      name
      displayName
      color
      isActive
      createdAt
    }
  }
`;

export const DELETE_LABEL_PEOPLE = gql`
  mutation peoplePanel_deleteLabel($labelId: Id!) {
    peoplePanel_deleteLabel(labelId: $labelId) {
      id
      name
      displayName
      color
      isActive
      createdAt
    }
  }
`;

export const ADD_AUTO_LABEL_ONM = gql`
  mutation autoLabelArticle_createAutoLabel(
    $projectId: Id!
    $clippingList: [Id!]!
    $name: String!
    $description: String
    $start: Timestamp!
    $labelList: [Id!]!
    $phrasesList: [[String!]!]!
  ) {
    autoLabelArticle_createAutoLabel(
      projectId: $projectId
      clippingList: $clippingList
      name: $name
      description: $description
      start: $start
      labelList: $labelList
      phrasesList: $phrasesList
    ) {
      clippingList {
        id
        name
        createdAt
        phrases
        startDate
      }
      createdAt
      description
      id
      isActive
      labelList {
        id
        name
        displayName
        colorType
        isActive
        createdAt
        clippingId
      }
      name
      phrasesList
      projectId
      start
    }
  }
`;

export const EDIT_AUTO_LABEL_ONM = gql`
  mutation autoLabelArticle_editAutoLabel(
    $projectId: Id!
    $clippingList: [Id!]!
    $name: String!
    $description: String
    $start: Timestamp!
    $labelList: [Id!]!
    $phrasesList: [[String!]!]!
    $autoLabelArticleId: Id!
  ) {
    autoLabelArticle_editAutoLabel(
      projectId: $projectId
      clippingList: $clippingList
      name: $name
      description: $description
      start: $start
      labelList: $labelList
      phrasesList: $phrasesList
      autoLabelArticleId: $autoLabelArticleId
    ) {
      clippingList {
        id
        name
        createdAt
        phrases
        startDate
      }
      createdAt
      description
      id
      isActive
      labelList {
        id
        name
        displayName
        colorType
        isActive
        createdAt
        clippingId
      }
      name
      phrasesList
      projectId
      start
    }
  }
`;

export const DELETE_AUTO_LABEL_ONM = gql`
  mutation autoLabelArticle_deleteAutoLabel($autoLabelArticleId: Id!) {
    autoLabelArticle_deleteAutoLabel(autoLabelArticleId: $autoLabelArticleId) {
      clippingList {
        id
        name
      }
      createdAt
      description
      id
      isActive
      labelList {
        id
        name
      }
      name
      phrasesList
      projectId
      start
    }
  }
`;
