import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Radio, useTheme } from '@mui/material';
import moment from 'moment';
import { useAppDispatch } from 'src/app/hooks';
import { ICampaign } from 'src/models/campaign';
import { reducerUpdateSelectedCampaign } from 'src/features/sosmedCampaign';

interface Props {
  listCampaign: Array<ICampaign>;
  loading?: boolean;
  campaignSelected: ICampaign;
  onClick: () => void;
}

const TableGroupCampaign: React.FC<Props> = ({
  listCampaign,
  loading = false,
  campaignSelected,
  onClick
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleClick = (campaign: ICampaign) => {
    onClick();
    dispatch(reducerUpdateSelectedCampaign(campaign));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>No</TableCell>
            <TableCell>Group</TableCell>
            <TableCell align="center">Added</TableCell>
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listCampaign.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              hover
              onClick={() => handleClick(row)}
            >
              <TableCell component="th" scope="row">
                <Radio
                  checked={row?.id === campaignSelected?.id}
                  value={row.id}
                  name="group-object"
                  size="small"
                  sx={{ color: theme.palette.primary.main }}
                />
              </TableCell>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row.campaignName}</TableCell>
              <TableCell align="center">
                {moment(row.createdAt).format('D/M/YY')}
              </TableCell>
              <TableCell align="center">{row.campaignProgress}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableGroupCampaign;
