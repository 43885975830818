import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { TSentiment } from 'src/models/general';
import { IClipping } from 'src/models/onlineMedia';

interface IUpdateSentimentOnmDetail {
  clipping: IClipping;
  end: string;
  id: string;
  processedBatches: number;
  processedData: number;
  projectId: string;
  sentimentUpdate: TSentiment;
  start: string;
  status: string;
  timezoneOffset: number;
  totalBatches: number;
  totalData: number;
}

interface IProps {
  detail: IUpdateSentimentOnmDetail;
}
const InformationDetailUpdateSentimentOnm = ({ detail }: IProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">Update Sentiment ONM</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 8 }}>
        <Typography>Clipping: {detail.clipping.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{`Date Range: ${moment(
          detail.start
        ).format('DD MMM YYYY')} - ${moment(detail.end).format(
          'DD MMM YYYY'
        )}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Processed data: {detail.processedData}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 8 }}>
        <Typography variant="h5">Change to:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{`Sentiment Update: ${detail.sentimentUpdate}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default InformationDetailUpdateSentimentOnm;
