import { Box, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/app/hooks';
import BottomHeader from './Bottom';
import ObjectPreview from 'src/components/ObjectPreview';
import GroupObject from 'src/components/GroupObject';
import ModalManageObjectByGroup from 'src/components/ModalManageObjectByGroup';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

const HeaderWrapperSec = styled(Box)(
  ({ theme }) => `
    background: #fbfbfb;
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    justify-content: space-between;
    width: 100%;
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function HeaderPeoplePanel() {
  const objectListByObjectType = useAppSelector(
    (state) => state.objectManagement.objectListByObjectType
  );

  return (
    <>
      <HeaderWrapper display="flex" alignItems="center">
        <Grid container>
          <Grid item lg={12}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h1"
                sx={{
                  mr: 1
                }}
              >
                People Panel
              </Typography>
            </Stack>
          </Grid>
          <Grid item lg={12}>
            <Box mt={2}>
              <GroupObject />
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              mt={1.5}
              mb={3}
            >
              <ModalManageObjectByGroup title="Social Profile" />
              <ObjectPreview objectList={objectListByObjectType} />
            </Stack>
          </Grid>
        </Grid>
      </HeaderWrapper>
      <HeaderWrapperSec display="flex" alignItems="center">
        <Grid container>
          <Grid item lg={12}>
            <BottomHeader />
          </Grid>
        </Grid>
      </HeaderWrapperSec>
    </>
  );
}

export default HeaderPeoplePanel;
