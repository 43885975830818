import moment from 'moment';
import { useState } from 'react';
import { IDateRange, IGranularity } from 'src/models/general';

export const useGranularity = () => {
  const [granular, setGlanular] = useState<IGranularity>({
    unit: 'day',
    value: 1
  });

  const getGranularityUnit = ({ start, end }: IDateRange) => {
    let granularityUnit: 'hour' | 'day' | 'week' | 'month' = 'day';
    if (moment(end).diff(start, 'days') < 1) granularityUnit = 'hour';
    if (moment(end).diff(start, 'months') >= 6) granularityUnit = 'month';
    if (granular.unit !== granularityUnit)
      setGlanular((prev) => ({ ...prev, unit: granularityUnit }));
    return { ...granular, unit: granularityUnit };
  };

  // get granularity based on timeframe
  const getGranularityForChronological = ({
    start,
    end
  }: IDateRange): IGranularity => {
    const differentDays = moment(end).diff(start, 'day');
    const differentHours = moment(end).diff(start, 'hour');
    // hours
    if (differentDays <= 1 || differentHours <= 24) {
      const hourly: IGranularity = { unit: 'hour', value: 1 };
      // setGlanular(hourly);
      return hourly;
    }
    // days
    if (differentDays > 1 && differentDays < 14) {
      const daily: IGranularity = { unit: 'day', value: 1 };
      // setGlanular(daily);
      return daily;
    }
    // weeks
    if (differentDays >= 14 && differentDays < 60) {
      const weekly: IGranularity = { unit: 'day', value: 7 };
      // setGlanular(weekly);
      return weekly;
    }
    const monthly: IGranularity = { unit: 'month', value: 1 };
    // setGlanular(monthly);
    return monthly;
  };

  return {
    granular,
    setGlanular,
    getGranularityUnit,
    getGranularityForChronological
  };
};
