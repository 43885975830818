import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApolloQueryResult } from '@apollo/react-hooks';
import { IComparisonEntity, INamespace } from 'src/models/comparison';
import {
  IGranularity,
  IRawPostmadeOutputWithPagination
} from 'src/models/general';

export interface IComparison {
  comparisonId: string;
  comparisonList: ApolloQueryResult<Array<INamespace>>;
  comparisonEntity: ApolloQueryResult<IComparisonEntity>;
  brandPositioning: ApolloQueryResult<any>;
  shareOfVoice: ApolloQueryResult<any>;
  sentimentAnalysis: ApolloQueryResult<any>;
  dayToDayEngagement: ApolloQueryResult<any[]>;
  dayToDayFollowersGrowth: ApolloQueryResult<any>;
  dayToDayTalk: ApolloQueryResult<any>;
  peakTime: ApolloQueryResult<any>;
  listOfPostMade: ApolloQueryResult<IRawPostmadeOutputWithPagination>;
  advanceMetric: ApolloQueryResult<any>;
  engagementGranularity: IGranularity;
  followersGranularity: IGranularity;
  talkGranularity: IGranularity;
}

export interface IHeadCellAdvanceMetrics {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  size: number;
  active: boolean;
}

const initDataTable: IRawPostmadeOutputWithPagination = {
  result: [],
  limit: 100,
  page: 1,
  totalPages: 1,
  totalRows: 0
};

const initGranularity: IGranularity = { unit: 'day', value: 1 };

export const initHeadCells: Array<IHeadCellAdvanceMetrics> = [
  {
    id: 'postMade',
    numeric: false,
    disablePadding: true,
    label: 'Post Made',
    size: 10,
    active: false
  },
  {
    id: 'likeCount',
    numeric: false,
    disablePadding: true,
    label: 'Like',
    size: 10,
    active: false
  },
  {
    id: 'comment',
    numeric: true,
    disablePadding: true,
    label: 'Comment',
    size: 10,
    active: false
  },
  {
    id: 'share',
    numeric: true,
    disablePadding: true,
    label: 'Share',
    size: 10,
    active: false
  },
  {
    id: 'talk',
    numeric: true,
    disablePadding: true,
    label: 'Talk',
    size: 10,
    active: false
  },
  {
    id: 'talker',
    numeric: true,
    disablePadding: true,
    label: 'Talker',
    size: 10,
    active: false
  },
  {
    id: 'view',
    numeric: true,
    disablePadding: true,
    label: 'View',
    size: 10,
    active: false
  },
  {
    id: 'engagement',
    numeric: true,
    disablePadding: true,
    label: 'Engagement',
    size: 10,
    active: false
  },
  {
    id: 'engagementPerPost',
    numeric: true,
    disablePadding: true,
    label: 'Engagement / Post',
    size: 10,
    active: false
  },
  {
    id: 'engagementRate',
    numeric: false,
    disablePadding: true,
    label: 'Engagement Rate',
    size: 10,
    active: false
  },
  {
    id: 'engagementRatePerPost',
    numeric: false,
    disablePadding: true,
    label: 'Engagement Rate / Post',
    size: 10,
    active: false
  },
  {
    id: 'followerCount',
    numeric: true,
    disablePadding: true,
    label: 'Follower Count',
    size: 10,
    active: false
  },
  {
    id: 'followerGrowth',
    numeric: true,
    disablePadding: true,
    label: 'Follower Growth',
    size: 10,
    active: false
  },
  {
    id: 'positiveTalk',
    numeric: true,
    disablePadding: true,
    label: 'Positive',
    size: 10,
    active: false
  },
  {
    id: 'neutralTalk',
    numeric: true,
    disablePadding: true,
    label: 'Neutral',
    size: 10,
    active: false
  },
  {
    id: 'negativeTalk',
    numeric: true,
    disablePadding: true,
    label: 'Negative',
    size: 10,
    active: false
  },
  {
    id: 'sentimentScore',
    numeric: true,
    disablePadding: true,
    label: 'Sentiment Score',
    size: 10,
    active: false
  },
  {
    id: 'totalReply',
    numeric: true,
    disablePadding: true,
    label: 'Total Reply',
    size: 10,
    active: false
  },
  {
    id: 'reach',
    numeric: true,
    disablePadding: true,
    label: 'Reach',
    size: 10,
    active: false
  },
  {
    id: 'impression',
    numeric: true,
    disablePadding: true,
    label: 'Impression',
    size: 10,
    active: false
  }
];

const initialState: IComparison = {
  comparisonId: '',
  comparisonList: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  comparisonEntity: {
    errors: undefined,
    loading: true,
    networkStatus: 1,
    partial: false,
    data: undefined
  },
  brandPositioning: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  shareOfVoice: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  advanceMetric: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  dayToDayEngagement: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  dayToDayFollowersGrowth: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  dayToDayTalk: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  listOfPostMade: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initDataTable
  },
  peakTime: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  sentimentAnalysis: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  engagementGranularity: initGranularity,
  followersGranularity: initGranularity,
  talkGranularity: initGranularity
};

export const sosmedComparison = createSlice({
  name: 'sosmedComparison',
  initialState,
  reducers: {
    reducerUpdateComparisonList: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<Array<INamespace>>>
    ) => {
      state.comparisonList = action.payload;
    },
    reducerUpdateComparisonEntity: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<any>>
    ) => {
      state.comparisonEntity = action.payload;
    },
    reducerUpdateAllLoadingComparison: (
      state: IComparison,
      action: PayloadAction<boolean>
    ) => {
      state.brandPositioning.loading = action.payload;
      state.shareOfVoice.loading = action.payload;
      state.advanceMetric.loading = action.payload;
      state.dayToDayFollowersGrowth.loading = action.payload;
      state.dayToDayTalk.loading = action.payload;
      state.listOfPostMade.loading = action.payload;
      state.peakTime.loading = action.payload;
      state.sentimentAnalysis.loading = action.payload;
    },
    reducerUpdateBrandPosition: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.brandPositioning = action.payload;
    },
    reducerUpdateShareOfVoice: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.shareOfVoice = action.payload;
    },
    reducerUpdateAdvanceMetric: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.advanceMetric = action.payload;
    },
    reducerUpdateDayToDayEngagement: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.dayToDayEngagement = action.payload;
    },
    reducerUpdateDayToDayFollowersGrowth: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.dayToDayFollowersGrowth = action.payload;
    },
    reducerUpdateDayToDayTalk: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.dayToDayTalk = action.payload;
    },
    reducerUpdateListOfPostMade: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<IRawPostmadeOutputWithPagination>>
    ) => {
      state.listOfPostMade = action.payload;
    },
    reducerUpdateLoadingListOfPostMade: (
      state: IComparison,
      action: PayloadAction<boolean>
    ) => {
      state.listOfPostMade.loading = action.payload;
    },
    reducerUpdatePeakTime: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.peakTime = action.payload;
    },
    reducerUpdateSentimentAnalysis: (
      state: IComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.sentimentAnalysis = action.payload;
    },
    reducerUpdateEngagementGranularity: (
      state: IComparison,
      action: PayloadAction<IGranularity>
    ) => {
      state.engagementGranularity = action.payload;
    },
    reducerUpdateFollowersGranularity: (
      state: IComparison,
      action: PayloadAction<IGranularity>
    ) => {
      state.followersGranularity = action.payload;
    },
    reducerUpdateTalkGranularity: (
      state: IComparison,
      action: PayloadAction<IGranularity>
    ) => {
      state.talkGranularity = action.payload;
    }
  }
});

export const {
  reducerUpdateComparisonList,
  reducerUpdateAllLoadingComparison,
  reducerUpdateBrandPosition,
  reducerUpdateShareOfVoice,
  reducerUpdateAdvanceMetric,
  reducerUpdateDayToDayEngagement,
  reducerUpdateDayToDayFollowersGrowth,
  reducerUpdateDayToDayTalk,
  reducerUpdateListOfPostMade,
  reducerUpdateLoadingListOfPostMade,
  reducerUpdatePeakTime,
  reducerUpdateSentimentAnalysis,
  reducerUpdateComparisonEntity,
  reducerUpdateEngagementGranularity,
  reducerUpdateFollowersGranularity,
  reducerUpdateTalkGranularity
} = sosmedComparison.actions;

export default sosmedComparison.reducer;
