import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useAppSelector } from 'src/app/hooks';
import TableFinishHashtag from './TableFinishHashtag';
import CloseIcon from '@mui/icons-material/Close';

const ModalFinishCampaign = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const theme = useTheme();

  const campaignSelected = useAppSelector(
    (state) => state.storeSocialMediaCampaign.campaignSelected
  );

  const filterOngoing = useMemo(() => {
    return {
      ...campaignSelected,
      campaignObjectDetails: campaignSelected?.campaignObjectDetails?.filter(
        (obj) => obj.campaignProgress !== 'finished'
      )
    };
  }, [campaignSelected]);

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleClickOpen}>
        {' '}
        Finish Campaign
      </Button>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'md'}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(3, 3, 0),
            p: theme.spacing(2, 3, 0, 3)
          }}
        >
          <Typography variant="h3">Finish Campaign</Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            m: theme.spacing(1, 3)
          }}
        >
          <TableFinishHashtag data={filterOngoing.campaignObjectDetails} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalFinishCampaign;
