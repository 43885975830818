import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApolloQueryResult } from '@apollo/react-hooks';
import {
  IOnmComparisonEntity,
  INamespace,
  INamespaceOnm
} from 'src/models/comparison';

export interface IOnmComparison {
  comparisonId: string;
  comparisonList: ApolloQueryResult<Array<INamespaceOnm>>;
  comparisonEntity: ApolloQueryResult<IOnmComparisonEntity>;
  brandPositioning: ApolloQueryResult<any>;
  shareOfVoice: ApolloQueryResult<any>;
  sentimentAnalysis: ApolloQueryResult<any>;
  dayToDayMedia: ApolloQueryResult<any>;
  dayToDayArticle: ApolloQueryResult<any>;
  advanceMetric: ApolloQueryResult<any>;
}

export interface IHeadCellAdvanceMetrics {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  size: number;
  active: boolean;
}

export const initHeadCells: Array<IHeadCellAdvanceMetrics> = [
  {
    id: 'totalArticle',
    numeric: false,
    disablePadding: true,
    label: 'Total Article',
    size: 10,
    active: false
  },
  {
    id: 'totalMedia',
    numeric: true,
    disablePadding: true,
    label: 'Total Media',
    size: 10,
    active: false
  },
  {
    id: 'prValue',
    numeric: true,
    disablePadding: true,
    label: 'PR Value',
    size: 10,
    active: false
  },
  {
    id: 'sentimentPositive',
    numeric: true,
    disablePadding: true,
    label: 'Positive',
    size: 10,
    active: false
  },
  {
    id: 'sentimentNeutral',
    numeric: true,
    disablePadding: true,
    label: 'Neutral',
    size: 10,
    active: false
  },
  {
    id: 'sentimentNegative',
    numeric: true,
    disablePadding: true,
    label: 'Negative',
    size: 10,
    active: false
  }
];

const initialState: IOnmComparison = {
  comparisonId: '',
  comparisonList: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  comparisonEntity: {
    errors: undefined,
    loading: true,
    networkStatus: 1,
    partial: false,
    data: undefined
  },
  brandPositioning: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  shareOfVoice: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  advanceMetric: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  dayToDayMedia: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  dayToDayArticle: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  sentimentAnalysis: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  }
};

export const onmComparison = createSlice({
  name: 'onmComparison',
  initialState,
  reducers: {
    reducerUpdateOnmComparisonList: (
      state: IOnmComparison,
      action: PayloadAction<ApolloQueryResult<Array<INamespaceOnm>>>
    ) => {
      state.comparisonList = action.payload;
    },
    reducerUpdateOnmComparisonEntity: (
      state: IOnmComparison,
      action: PayloadAction<ApolloQueryResult<IOnmComparisonEntity>>
    ) => {
      state.comparisonEntity = action.payload;
    },
    reducerUpdateAllLoadingComparison: (
      state: IOnmComparison,
      action: PayloadAction<boolean>
    ) => {
      state.brandPositioning.loading = action.payload;
      state.shareOfVoice.loading = action.payload;
      state.advanceMetric.loading = action.payload;
      state.dayToDayMedia.loading = action.payload;
      state.dayToDayArticle.loading = action.payload;
      state.sentimentAnalysis.loading = action.payload;
    },
    reducerUpdateBrandPosition: (
      state: IOnmComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.brandPositioning = action.payload;
    },
    reducerUpdateShareOfVoice: (
      state: IOnmComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.shareOfVoice = action.payload;
    },
    reducerUpdateAdvanceMetric: (
      state: IOnmComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.advanceMetric = action.payload;
    },
    reducerUpdateDayToDayMedia: (
      state: IOnmComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.dayToDayMedia = action.payload;
    },
    reducerUpdateDayToDayArticle: (
      state: IOnmComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.dayToDayArticle = action.payload;
    },
    reducerUpdateSentimentAnalysis: (
      state: IOnmComparison,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.sentimentAnalysis = action.payload;
    }
  }
});

export const {
  reducerUpdateOnmComparisonList,
  reducerUpdateOnmComparisonEntity,
  reducerUpdateAllLoadingComparison,
  reducerUpdateBrandPosition,
  reducerUpdateShareOfVoice,
  reducerUpdateAdvanceMetric,
  reducerUpdateDayToDayMedia,
  reducerUpdateDayToDayArticle,
  reducerUpdateSentimentAnalysis
} = onmComparison.actions;

export default onmComparison.reducer;
