import { FC } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useTheme
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoNoLimit from 'src/assets/image/nolimit-logo-flat.svg';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/app/hooks';
import separateNumberFormat from 'src/utils/separateNumberFormat';
import useRegister from 'src/services/useRegister';
import { getLogoByHostname } from 'src/utils/getLogoByHostname';

interface FormCheckout {
  paymentGroupCode: string;
  paymentGroupCodeChannelId: string;
}

interface Props {
  registerId: string;
}

function validationSchema() {
  return Yup.object({
    paymentGroupCode: Yup.string(),
    paymentGroupCodeChannelId: Yup.string()
  });
}

const AccordionCustom = styled(Accordion)(
  ({ theme }) => `
    .MuiAccordionSummary-root {
      border: 1px solid ${theme.palette.divider};
      height: ${theme.spacing(7)};
      background-color: ${theme.palette.common.white};
      -webkit-transition: background-color 0.4s ease;
      -moz-transition: background-color 0.4s ease;
      -o-transition: background-color 0.4s ease;
      transition: background-color 0.4s ease;
    }

    .Mui-expanded {
      background-color: ${theme.palette.primary.main};
      -webkit-transition: background-color 0.4s ease;
      -moz-transition: background-color 0.4s ease;
      -o-transition: background-color 0.4s ease;
      transition: background-color 0.4s ease;
      border-radius: 8px 8px 0px 0px ;
      min-height: unset;
      color: ${theme.palette.common.white}
      
    }

    .MuiAccordionDetails-root {
      border-radius: 0px 0px 8px 8px;
      padding: ${theme.spacing(2, 3)};
      background: ${theme.palette.grey[50]};
      border: 1px solid ${theme.palette.divider};
    }
`
);

const FormPayment: FC<Props> = ({ registerId }) => {
  const theme = useTheme();
  const { postUserPlanCheckout, responseUserPlanCheckout } = useRegister();
  const { logo, width } = getLogoByHostname(true);
  const promoCode = useAppSelector(
    (state) => state.register.planSimulation.data.promoCode
  );
  console.log(promoCode);
  const { handleSubmit, values, setFieldValue } = useFormik<FormCheckout>({
    initialValues: {
      paymentGroupCode: '',
      paymentGroupCodeChannelId: ''
    },
    validationSchema: validationSchema(),
    onSubmit: (payload) => {
      postUserPlanCheckout({
        variables: {
          registerId,
          promoCode,
          ...payload
        }
      }).then(({ data }) => {
        window.location.href = data.user_planCheckout.url;
      });
    }
  });

  const planSimulation = useAppSelector(
    (state) => state.register.planSimulation
  );
  const xenditPaymentChannel = useAppSelector(
    (state) => state.register.xenditPaymentChannel
  );

  return (
    <Box
      sx={{
        width: '536px'
      }}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <img src={logo} alt={'company Logo'} width={width} />
          </Grid>
          <Grid item lg={12}>
            <Divider />
          </Grid>
          <Grid item lg={12}>
            <Typography variant="h1" textAlign="center">
              {planSimulation.data.merchantName === 'xendit'
                ? 'Choose Payment'
                : 'Checkout'}
            </Typography>
          </Grid>
          <Grid item lg={12}>
            {planSimulation.data.merchantName === 'xendit'
              ? xenditPaymentChannel.data.map((payment) => (
                  <Box
                    key={payment.groupCode}
                    sx={{
                      mb: theme.spacing(2)
                    }}
                  >
                    <AccordionCustom>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        id="virtual-account"
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 600,
                            color: 'inherit'
                          }}
                        >
                          {payment.groupName}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {payment.channels.map((channel, idx) => (
                          <FormControl component="fieldset">
                            <RadioGroup
                              name={payment.groupName}
                              onClick={() => {
                                setFieldValue(
                                  'paymentGroupCode',
                                  payment.groupCode
                                );
                                setFieldValue(
                                  'paymentGroupCodeChannelId',
                                  channel.id
                                );
                              }}
                              value={values.paymentGroupCodeChannelId}
                            >
                              <FormControlLabel
                                value={channel.id}
                                control={<Radio />}
                                label={
                                  <Box
                                    sx={{
                                      width: theme.spacing(57),
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginLeft: theme.spacing(1)
                                    }}
                                  >
                                    <Typography variant="h6">
                                      {channel.name}
                                    </Typography>
                                    <img
                                      src={channel.logo}
                                      alt={'logo'}
                                      style={{ width: theme.spacing(7) }}
                                    />
                                  </Box>
                                }
                              />
                              {payment.channels.length > idx + 1 && (
                                <Divider sx={{ my: theme.spacing(1.5) }} />
                              )}
                            </RadioGroup>
                          </FormControl>
                        ))}
                      </AccordionDetails>
                    </AccordionCustom>
                  </Box>
                ))
              : null}
          </Grid>
          <Grid item lg={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                my: 1
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 'bold',
                  color: '#545454'
                }}
              >
                Grand Total
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: 20,
                    lineHeight: '24px',
                    fontWeight: 800,
                    color: '#009FE3'
                  }}
                >
                  {`${
                    planSimulation.data.symbolBySelectedCountry
                  } ${separateNumberFormat(
                    planSimulation.data.totalBySelectedCountry
                  )}`}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontSize: 14,
                    lineHeight: '24px',
                    fontWeight: 800,
                    color: '#545454',
                    ml: theme.spacing(0.5)
                  }}
                >
                  {planSimulation.data.period < 12
                    ? `/${
                        planSimulation.data.period === 1
                          ? ''
                          : planSimulation.data.period
                      } Month`
                    : `/ Year`}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
              disabled={responseUserPlanCheckout.loading}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default FormPayment;
