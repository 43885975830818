import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Radio, Stack, useTheme } from '@mui/material';
import moment from 'moment';
import { useAppDispatch } from 'src/app/hooks';
import { reducerUpdateObjectManagementDashboardSelectedById } from 'src/features/customDashboard';
import Empty from 'src/components/Empty';

interface Props {
  dashboardObjects: any;
  loading?: boolean;
  dashboardSelectedById: string | undefined;
  onClick: () => void;
}

const TableDashboardObject: React.FC<Props> = ({
  dashboardObjects,
  loading = false,
  dashboardSelectedById,
  onClick
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleClick = (id: string) => {
    onClick();
    dispatch(reducerUpdateObjectManagementDashboardSelectedById(id));
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: theme.spacing(30) }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>No</TableCell>
            <TableCell>Dashboard</TableCell>
            <TableCell align="center">Added</TableCell>
            <TableCell align="center">Object Count</TableCell>
          </TableRow>
        </TableHead>
        {dashboardObjects.length > 0 ? (
          <TableBody>
            {dashboardObjects.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
                onClick={() => handleClick(row.id)}
              >
                <TableCell component="th" scope="row">
                  <Radio
                    checked={row.id === dashboardSelectedById}
                    value={row.id}
                    name="dashboard-object"
                    size="small"
                    sx={{ color: theme.palette.primary.main }}
                  />
                </TableCell>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{row.displayName}</TableCell>
                <TableCell align="center">
                  {row.createdAt ? moment(row.createdAt).format('D/M/YY') : '-'}
                </TableCell>
                <TableCell align="center">{row.objectList.length}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableRow hover={false}>
            <TableCell align="center" colSpan={11}>
              <Stack
                sx={{
                  height: 'calc(80vh - 400px)',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Empty message="There's no data yet" />
              </Stack>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </TableContainer>
  );
};

export default TableDashboardObject;
