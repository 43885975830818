import { Typography } from '@mui/material';
import React from 'react';
import useStyles from './styles';

interface Props {
  progress?: number;
}

const ScreenLoader: React.FC<Props> = ({ progress }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div>
        <svg
          width="300px"
          height="200px"
          viewBox="0 0 187.3 93.7"
          preserveAspectRatio="xMidYMid meet"
          style={{
            left: '50%',
            top: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%) matrix(1, 0, 0, 1, 0, 0)'
          }}
        >
          <path
            id="outline"
            className={classes.outlineLoader}
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
          />
          <path
            id="outline-bg"
            opacity="0.2"
            className={classes.trackLoader}
            fill="none"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="M93.9,46.4c9.3,9.5,13.8,17.9,23.5,17.9s17.5-7.8,17.5-17.5s-7.8-17.6-17.5-17.5c-9.7,0.1-13.3,7.2-22.1,17.1c-8.9,8.8-15.7,17.9-25.4,17.9s-17.5-7.8-17.5-17.5s7.8-17.5,17.5-17.5S86.2,38.6,93.9,46.4z"
          />
        </svg>
      </div>
      <div className={classes.textLoading}>
        <Typography variant="h3" style={{ marginRight: 8, color: '#FFFFFF' }}>
          Loading
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ marginRight: 8, color: '#FFFFFF' }}
        >
          please wait for a moment
        </Typography>
      </div>
    </div>
  );
};

export default ScreenLoader;
