import { FC, ReactNode } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  content: ReactNode;
  color?: string;
}

const Information: FC<Props> = ({ content, color = '#051C26' }) => {
  return (
    <Tooltip title={content} placement="top-start">
      <InfoOutlinedIcon sx={{ fontSize: 14, color: color }} />
    </Tooltip>
  );
};

export default Information;
