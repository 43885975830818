import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useMemo, useState } from 'react';
import TableDashboardObject from './Table';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import useCustomDashboard from 'src/services/useCustomDashboard';
import {
  reducerUpdateDashboardList,
  reducerUpdateObjectManagementDashboardSelectedById
} from 'src/features/customDashboard';
import { IDashboardObject } from 'src/models/customDashboard';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import ModalManageObjectByGroup from '../ModalManageObjectByGroup';
import { IGranularity } from 'src/models/general';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ModalCustomDownload from './components/ModalCustomDownload';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { useAlert } from 'src/hooks/useAlert';

const CustomDashboardObject = () => {
  const theme = useTheme();
  const { handleClickAlert } = useAlert();
  const dispatch = useAppDispatch();
  const { dateRangeFormatted, objectIdList } = useAppSelector(
    (state) => state.analyticArguments
  );
  const { dashboardList, dashboardSelectedById } = useAppSelector(
    (state) => state.storeCustomDashboard
  );
  const projectId = useAppSelector((state) => state.users.project.id);
  const advanceSearchSocialMedia = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );
  const fillAdvanceSearchSocialMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchSocialMedia);
  }, [advanceSearchSocialMedia]);
  const {
    objectManagementAddDashboard,
    createDownloadJob,
    resCreateDownloadJob
  } = useCustomDashboard();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState<string>('');
  const [editingDashboard, setEditingDashboard] = useState<boolean>(false);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const [openAddDashboard, setOpenAddDashboard] = useState(false);
  const handleClickAddDashboard = () => {
    setOpenAddDashboard(true);
  };
  const handleCloseAddDashboard = () => {
    setOpenAddDashboard(false);
  };

  const handleCreateDashboard = (e) => {
    e.preventDefault();
    objectManagementAddDashboard({
      variables: {
        projectId,
        displayName: name
      }
    }).then((response) => {
      const dashboard: IDashboardObject =
        response.data?.customDashboard_addCustomDashboard;
      dispatch(
        reducerUpdateDashboardList({
          ...dashboardList,
          data: [
            ...dashboardList.data,
            { ...dashboard, customDashboardWidgetMetadataList: [] }
          ]
        })
      );
      dispatch(
        reducerUpdateObjectManagementDashboardSelectedById(dashboard.id)
      );
      setOpenAddDashboard(false);
      setEditingDashboard(true);
      handleClose();
    });
  };

  const granularity: IGranularity = {
    unit: 'day',
    value: 1
  };

  const finalPayload = {
    ...fillAdvanceSearchSocialMedia,
    projectId,
    startDate: dateRangeFormatted.start,
    endDate: dateRangeFormatted.end,
    objectIds: objectIdList,
    granularity: granularity,
    timezone: 7,
    customDashboardId: dashboardSelectedById
  };

  // const [getDownloadPdf, resDownloadPdf] = useLazyQuery(
  //   CUSTOM_DASHBOARD_DOWNLOAD_PDF,
  //   {
  //     fetchPolicy: 'network-only',
  //     onCompleted: (response) => {
  //       if (!response) return;
  //       handleClickAlert({
  //         horizontal: 'center',
  //         vertical: 'top',
  //         severity: 'success',
  //         message:
  //           'It may take some time for the report to be processed. The report will automatically be downloaded afterwards.'
  //       });
  //       setTimeout(async () => {
  //         await saveAs(response.customDashboard_downloadPdf.url, 'report.pdf');
  //       }, 5000);
  //     }
  //   }
  // );

  const handleDownloadDefault = async (isWhiteLabel: boolean) => {
    handleCloseDownload();
    handleCloseCustomDownload();
    try {
      await createDownloadJob({
        variables: {
          ...finalPayload,
          start: finalPayload.startDate,
          end: finalPayload.endDate,
          objectList: finalPayload.objectIds,
          isWhiteLabel
        }
      });
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        severity: 'success',
        message:
          'It may take some time for the report to be processed. The report can be downloaded from the Activity List on the top right corner.'
      });
    } catch (e) {
      console.log(e);
    }

    // getDownloadPdf({
    //   variables: { ...finalPayload, isWhiteLabel }
    // });
  };

  const dashboardActive = useMemo(() => {
    const dashboard: IDashboardObject = dashboardList.data.find(
      (dashboard) => dashboard.id === dashboardSelectedById
    );
    return dashboard;
  }, [dashboardSelectedById, dashboardList.data]);

  const [anchorElDownload, setAnchorElDownload] =
    useState<HTMLButtonElement | null>(null);

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };

  const openDownload = Boolean(anchorElDownload);
  const id = openDownload ? 'simple-popover' : undefined;

  const [openCustomDownload, setOpenCustomDownload] = useState<boolean>(false);
  const handleOpenCustomDownload = () => {
    setOpenCustomDownload(true);
  };
  const handleCloseCustomDownload = () => {
    setOpenCustomDownload(false);
  };

  const isKompasWhiteLabel = window.location.hostname.includes('kompas');

  return (
    <Stack
      direction="row"
      alignItems={'center'}
      justifyContent={'space-between'}
    >
      <Stack direction="row" spacing={2}>
        <FormControl size="small" sx={{ width: '509px' }}>
          <Select
            name="group"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={dashboardSelectedById}
            defaultValue={dashboardSelectedById}
          >
            <MenuItem
              value={'_default'}
              key={'_default'}
              sx={{ display: 'none' }}
            />
            {dashboardList.data.map((dashboard) => (
              <MenuItem
                key={dashboard.id}
                value={dashboard.id}
                sx={{ display: 'none' }}
              >
                {dashboard.displayName}
              </MenuItem>
            ))}

            <Box sx={{ p: theme.spacing(2) }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                marginBottom={1}
              >
                <Typography variant="h4">Custom Dashboard</Typography>
              </Stack>
              <Input
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                fullWidth
                placeholder="Search Source"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                }}
              />
              <Box sx={{ mt: theme.spacing(1) }}>
                <TableDashboardObject
                  dashboardObjects={dashboardList.data}
                  loading={dashboardList.loading}
                  dashboardSelectedById={dashboardSelectedById}
                  onClick={handleClose}
                />
              </Box>
            </Box>
          </Select>
        </FormControl>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          endIcon={<AddIcon />}
          sx={{
            borderRadius: '6px',
            minWidth: theme.spacing(6),
            height: 36
          }}
          onClick={(event) => {
            handleClickAddDashboard();
            event.stopPropagation();
            event.preventDefault();
          }}
        >
          Create New
        </Button>
      </Stack>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        startIcon={<DownloadIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          minWidth: theme.spacing(6),
          height: 36,
          backgroundColor: '#FBFBFB',
          borderRadius: theme.spacing(1)
        }}
        onClick={handleClickDownload}
        disabled={
          resCreateDownloadJob.loading ||
          dashboardSelectedById === '_default' ||
          !dashboardActive ||
          dashboardActive.customDashboardWidgetMetadataList.length < 1
        }
      >
        Download Report
      </Button>
      <Dialog
        onClose={handleCloseAddDashboard}
        open={openAddDashboard}
        fullWidth
        maxWidth={'sm'}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseAddDashboard}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(3, 3, 1),
            textAlign: 'center'
          }}
        >
          <Typography variant="h3">Create new dashboard</Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            m: theme.spacing(1, 3)
          }}
        >
          <form onSubmit={handleCreateDashboard}>
            <FormControl fullWidth size="medium">
              <FormLabel>Dashboard name</FormLabel>
              <OutlinedInput
                name="objectName"
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <Box display="flex" justifyContent="center" mt={theme.spacing(4)}>
              <Button
                variant="contained"
                type="submit"
                size="large"
                sx={{
                  background: theme.palette.primary.dark
                }}
              >
                Create New
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <ModalManageObjectByGroup
        title="Object"
        isHidden={true}
        onCloseEdit={() => setEditingDashboard(false)}
        isEditing={editingDashboard}
        isDashboard={true}
      />
      <Popover
        id={id}
        open={openDownload}
        anchorEl={anchorElDownload}
        onClose={handleCloseDownload}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          {!isKompasWhiteLabel ? (
            <ListItemButton onClick={() => handleDownloadDefault(false)}>
              <ListItemText primary="Download (PDF)" />
            </ListItemButton>
          ) : null}
          <ListItemButton onClick={handleOpenCustomDownload}>
            <ListItemText primary="White-label Report" />
          </ListItemButton>
        </List>
      </Popover>
      <ModalCustomDownload
        open={openCustomDownload}
        onClose={handleCloseCustomDownload}
        onDownload={() => handleDownloadDefault(true)}
      />
    </Stack>
  );
};

export default CustomDashboardObject;
