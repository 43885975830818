import {
  FetchResult,
  useApolloClient,
  useLazyQuery,
  useMutation
} from '@apollo/react-hooks';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  reducerUpdateArticleLabel,
  reducerUpdateAutoLabelOfm,
  reducerUpdateAutoLabelOnm,
  reducerUpdateAutoLabelSosmed,
  reducerUpdatelabelListSosmed,
  reducerUpdatePeopleLabel
} from 'src/features/label';
import { ILabel, ITableWithPagination } from 'src/models/general';
import {
  IAutoLabel,
  IAutoLabelOnm,
  ILabelArticle,
  ILabelPeople
} from 'src/models/label';
import {
  ADD_LABEL_ARTICLE,
  ADD_LABEL_PEOPLE,
  CREATE_AUTO_LABEL,
  CREATE_LABEL,
  DELETE_LABEL,
  DELETE_LABEL_ARTICLE,
  DELETE_LABEL_PEOPLE,
  EDIT_LABEL_ARTICLE,
  EDIT_LABEL_PEOPLE,
  EDIT_LABEL_SOCIAL_MEDIA,
  EDIT_AUTO_LABEL,
  DELETE_AUTO_LABEL,
  DELETE_AUTO_LABEL_ONM,
  ADD_AUTO_LABEL_ONM,
  EDIT_AUTO_LABEL_ONM
} from './graphql/labels/mutation';
import {
  GET_AUTO_LABEL_LIST,
  GET_AUTO_LABEL_ONM,
  GET_LABEL_ARTICLE_V2,
  GET_LABEL_FROM_PROJECT,
  GET_LABEL_PEOPLE
} from './graphql/labels/query';
import {
  GET_AUTO_LABEL_ARTICLE,
  GET_LABEL,
  GET_LABEL_BY_ID
} from './graphql/offlineMedia/query';
import { TMediaType } from 'src/models/offlineMedia';
import {
  ADD_AUTO_LABEL_ARTICLE,
  ADD_LABEL_ARTICLE as ADD_LABEL_OFM,
  DELETE_AUTO_LABEL_ARTICLE,
  DELETE_LABEL_ARTICLE as DELETE_LABEL_OFM,
  EDIT_AUTO_LABEL_ARTICLE,
  EDIT_LABEL_ARTICLE as EDIT_LABEL_OFM
} from './graphql/offlineMedia/mutation';
interface IGetLabelSocialMediaResponse {
  labellingSystem_getLabelFromProjects: Array<ILabel>;
}

interface IGetAutoLabelSocialMediaResponse {
  autoLabel_getAutoLabelList: Array<IAutoLabel>;
}

interface IGetLabelArticleResponse {
  labellingArticle_getLabelArticleV2: Array<ILabelArticle>;
}

interface IGetLabelPeople {
  peoplePanel_getLabelList: Array<ILabelPeople>;
}

interface IGetAutoLabelOnmPayload {
  projectId: string;
  limit: number;
  page: number;
  keyword: string;
}

interface IGetAutoLabelOnmResponse {
  autoLabelArticle_getAutoLabel: ITableWithPagination<IAutoLabelOnm>;
}

export interface IAddAutoLabelOnmPayload {
  projectId: string;
  clippingList: Array<string>;
  name: string;
  description?: string;
  start: Date;
  labelList: Array<string>;
  phrasesList: Array<string[]>;
}

export interface IEditAutoLabelOnmPayload extends IAddAutoLabelOnmPayload {
  autoLabelArticleId: string;
}

interface IAddAutoLabelOnmResponse {
  autoLabelArticle_editAutoLabel: IAutoLabelOnm;
}

export interface IGetOfmLabelResponse {
  article_getLabelArticle: ITableWithPagination<ILabel>;
}

export interface IGetOfmLabelPayload {
  projectId: string;
  page: number;
  limit: number;
  name: string;
  categoryArticle: TMediaType;
}

export interface IAddOfmLabelResponse {
  article_addLabelArticle: ILabel;
}

export interface IAddOfmLabelPayload {
  projectId: string;
  name: string;
  requestColor: string;
  categoryArticle: TMediaType;
}

export interface IDeleteOfmLabelResponse {
  article_deleteLabelArticle: ILabel;
}

export interface IDeleteOfmLabelPayload {
  labelId: string;
}

export interface IEditOfmLabelResponse {
  article_editLabelArticle: ILabel;
}

export interface IEditOfmLabelPayload {
  labelId: string;
  name: string;
  requestColor: string;
}

export interface IGetAutoLabelOfmResponse {
  article_getAutoLabel: ITableWithPagination<IAutoLabelOnm>;
}

export interface IGetAutoLabelOfmPayload {
  projectId: string;
  limit: number;
  page: number;
  keyword: string;
}

export interface IDeleteAutoLabelOfmPayload {
  autoLabelArticleId: string;
}

export interface IDeleteAutoLabelOfmResponse {
  article_deleteAutoLabel: IAutoLabelOnm;
}

export interface IAddAutoLabelOfmPayload extends IAddAutoLabelOnmPayload {
  categoryArticle: TMediaType;
}

export interface IAddAutoLabelOfmResponse {
  article_createAutoLabel: IAutoLabelOnm;
}

export interface IGetAutoLabelSosmedByIdPayload {
  projectId: string;
  autoLabelId: string;
}

export interface IGetAutoLabelSosmedByIdResponse {
  autoLabel_getAutoLabelById: IAutoLabel;
}

const useLabels = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const autoLabelSosmedState = useAppSelector(
    (state) => state.storeLabel.autoLabelSosmed
  );

  const fetchLabelSocialMedia = async function (projectId: string) {
    return await client
      .query<IGetLabelSocialMediaResponse>({
        query: GET_LABEL_FROM_PROJECT,
        variables: {
          projectId
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.labellingSystem_getLabelFromProjects
        };
      });
  };

  const getLabelSocialMedia = async function (projectId: string) {
    const response = await fetchLabelSocialMedia(projectId);
    dispatch(reducerUpdatelabelListSosmed(response));
  };

  const fetchAutoLabelSocialMedia = async function (projectId: string) {
    return await client
      .query<IGetAutoLabelSocialMediaResponse>({
        query: GET_AUTO_LABEL_LIST,
        variables: {
          projectId
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.autoLabel_getAutoLabelList
        };
      });
  };

  const getAutoLabelSocialMedia = async function (projectId: string) {
    dispatch(
      reducerUpdateAutoLabelSosmed({ ...autoLabelSosmedState, loading: true })
    );
    const response = await fetchAutoLabelSocialMedia(projectId);
    dispatch(reducerUpdateAutoLabelSosmed(response));
  };

  const fetchLabelArticle = async function (projectId: string) {
    return await client
      .query<IGetLabelArticleResponse>({
        query: GET_LABEL_ARTICLE_V2,
        variables: {
          projectId
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.labellingArticle_getLabelArticleV2
        };
      });
  };

  const getLabelArticle = async function (projectId: string) {
    const response = await fetchLabelArticle(projectId);
    dispatch(reducerUpdateArticleLabel(response));
  };

  const fetchLabelPeople = async function (projectId: string) {
    return await client
      .query<IGetLabelPeople>({
        query: GET_LABEL_PEOPLE,
        variables: {
          projectId
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.peoplePanel_getLabelList
        };
      });
  };

  const getLabelPeople = async function (projectId: string) {
    const response = await fetchLabelPeople(projectId);
    dispatch(reducerUpdatePeopleLabel(response));
  };

  const fetchAutoLabelOnm = async function (payload: IGetAutoLabelOnmPayload) {
    return await client
      .query<IGetAutoLabelOnmResponse>({
        query: GET_AUTO_LABEL_ONM,
        variables: payload
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.autoLabelArticle_getAutoLabel
        };
      });
  };

  const getAutoLabelOnm = async function (payload: IGetAutoLabelOnmPayload) {
    const response = await fetchAutoLabelOnm(payload);
    dispatch(reducerUpdateAutoLabelOnm(response));
  };

  const [getOfflineMediaLabel, resGetOfflineMediaLabel] = useLazyQuery<
    IGetOfmLabelResponse,
    IGetOfmLabelPayload
  >(GET_LABEL, {
    fetchPolicy: 'network-only'
  });

  const [getOfflineMediaLabelById, resGetOfflineMediaLabelById] = useLazyQuery(
    GET_LABEL_BY_ID,
    {
      fetchPolicy: 'network-only'
    }
  );

  const [getAutoLabelArticle, resGetAutoLabelArticle] = useLazyQuery<
    IGetAutoLabelOfmResponse,
    IGetAutoLabelOfmPayload
  >(GET_AUTO_LABEL_ARTICLE, {
    fetchPolicy: 'network-only',
    onCompleted: (data) =>
      dispatch(reducerUpdateAutoLabelOfm(data.article_getAutoLabel))
  });

  const [addStreamLabel, resAddStreamLabel] = useMutation(CREATE_LABEL);
  const [editStreamLabel, resEditStreamLabel] = useMutation(
    EDIT_LABEL_SOCIAL_MEDIA
  );
  const [deleteLabel, resDeleteLabel] = useMutation(DELETE_LABEL);

  const [addArticleLabel, resAddArticleLabel] = useMutation(ADD_LABEL_ARTICLE);
  const [editArticleLabel, resEditArticleLabel] =
    useMutation(EDIT_LABEL_ARTICLE);
  const [deleteArticleLabel, resDeleteArticleLabel] =
    useMutation(DELETE_LABEL_ARTICLE);

  const [addPeopleLabel, resAddPeopleLabel] = useMutation(ADD_LABEL_PEOPLE);
  const [editPeopleLabel, resEditPeopleLabel] = useMutation(EDIT_LABEL_PEOPLE);
  const [deletePeopleLabel, resDeletePeopleLabel] =
    useMutation(DELETE_LABEL_PEOPLE);
  const [addAutoLabel, resAddAutoLabel] = useMutation(CREATE_AUTO_LABEL);
  const [editAutoLabel, resEditAutoLabel] = useMutation(EDIT_AUTO_LABEL);
  const [deleteAutoLabel, resDeleteAutoLabel] = useMutation(DELETE_AUTO_LABEL);
  const [deleteAutoLabelOnm, resDeleteAutoLabelOnm] = useMutation(
    DELETE_AUTO_LABEL_ONM
  );
  const [addAutoLabelOnm, resAddAutoLabelOnm] = useMutation<
    IAddAutoLabelOnmResponse,
    IAddAutoLabelOnmPayload
  >(ADD_AUTO_LABEL_ONM);
  const [editAutoLabelOnm, resEditAutoLabelOnm] =
    useMutation<IEditAutoLabelOnmPayload>(EDIT_AUTO_LABEL_ONM);

  const [addLabelOfm, resAddLabelOfm] = useMutation<
    IAddOfmLabelResponse,
    IAddOfmLabelPayload
  >(ADD_LABEL_OFM);

  const [deleteLabelOfm, resDeleteLabelOfm] = useMutation<
    IDeleteOfmLabelResponse,
    IDeleteOfmLabelPayload
  >(DELETE_LABEL_OFM);

  const [editLabelOfm, resEditLabelOfm] = useMutation<
    IEditOfmLabelResponse,
    IEditOfmLabelPayload
  >(EDIT_LABEL_OFM);

  const [addAutoLabelArticle, resAddAutoLabelArticle] = useMutation<
    IAddAutoLabelOfmResponse,
    IAddAutoLabelOfmPayload
  >(ADD_AUTO_LABEL_ARTICLE);

  const [editAutoLabelArticle, resEditAutoLabelArticle] = useMutation(
    EDIT_AUTO_LABEL_ARTICLE
  );
  const [deleteAutoLabelArticle, resDeleteAutoLabelArticle] = useMutation<
    IDeleteAutoLabelOfmResponse,
    IDeleteAutoLabelOfmPayload
  >(DELETE_AUTO_LABEL_ARTICLE);

  return {
    getLabelSocialMedia,
    getLabelArticle,
    addStreamLabel,
    resAddStreamLabel,
    deleteLabel,
    resDeleteLabel,
    editStreamLabel,
    resEditStreamLabel,
    addArticleLabel,
    resAddArticleLabel,
    editArticleLabel,
    resEditArticleLabel,
    deleteArticleLabel,
    resDeleteArticleLabel,
    getLabelPeople,
    addPeopleLabel,
    resAddPeopleLabel,
    editPeopleLabel,
    resEditPeopleLabel,
    deletePeopleLabel,
    resDeletePeopleLabel,
    addAutoLabel,
    resAddAutoLabel,
    getAutoLabelSocialMedia,
    editAutoLabel,
    resEditAutoLabel,
    deleteAutoLabel,
    resDeleteAutoLabel,
    getAutoLabelOnm,
    deleteAutoLabelOnm,
    resDeleteAutoLabelOnm,
    addAutoLabelOnm,
    resAddAutoLabelOnm,
    editAutoLabelOnm,
    resEditAutoLabelOnm,
    getOfflineMediaLabel,
    resGetOfflineMediaLabel,
    getOfflineMediaLabelById,
    resGetOfflineMediaLabelById,
    addLabelOfm,
    resAddLabelOfm,
    deleteLabelOfm,
    resDeleteLabelOfm,
    editLabelOfm,
    resEditLabelOfm,
    addAutoLabelArticle,
    resAddAutoLabelArticle,
    editAutoLabelArticle,
    resEditAutoLabelArticle,
    deleteAutoLabelArticle,
    resDeleteAutoLabelArticle,
    getAutoLabelArticle,
    resGetAutoLabelArticle
  };
};

export default useLabels;
