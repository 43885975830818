import { Box, Fab, Grid, Stack, Typography, capitalize } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import TooltipCustomize from 'src/components/TooltipCustomize';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  onClick: () => void;
  title?: string;
  showBackButton?: boolean;
}

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

const textTooltip = (title) => {
  switch (title.toLowerCase()) {
    case 'overview':
      return 'Overview metrics of object performance';
    case 'content':
      return 'Discover engagement performance of social profiles';
    case 'community':
      return 'Discover followers analysis and its growth of social profiles';
    case 'campaign':
      return 'Discover Hashtags performance';
    case 'conversation':
      return 'Discover all mentions and sentiment analysis of social profiles, keywords and hashtags';
    case 'comparison':
      return 'Comparison analysis between each object, group of object or label';
    default:
      return '';
  }
};

const MinimalistHeader: FC<Props> = ({
  title,
  onClick,
  showBackButton = true
}) => {
  const theme = useTheme();

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
            {showBackButton && (
              <Fab
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.grey[200]}`
                }}
                size="small"
                onClick={onClick}
              >
                <ArrowBackIcon />
              </Fab>
            )}
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h1"
                sx={{
                  mr: 1
                }}
              >
                {capitalize(title)}
              </Typography>
              <TooltipCustomize
                title={textTooltip(title)}
                placement="top-start"
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{ transform: 'translateY(-4px)' }}
                />
              </TooltipCustomize>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
};

export default MinimalistHeader;
