import gql from 'graphql-tag';

export const POST_USER_SIGN_IN = gql`
  mutation user_logInForOpenBeta(
    $email: String!
    $password: String!
    $companyName: String!
  ) {
    user_logInForOpenBeta(
      email: $email
      password: $password
      companyName: $companyName
    ) {
      companyId
      userId
      email
      username
      company
      projectList {
        id
        name
        displayName
      }
    }
  }
`;

export const INVITE_USER = gql`
  mutation user_inviteUser($email: String!, $projectId: Id!, $role: Role!) {
    user_inviteUser(email: $email, projectId: $projectId, role: $role) {
      id
      email
      role
    }
  }
`;

export const EDIT_MEMBER = gql`
  mutation user_editMember(
    $projectUserRelationId: Int!
    $projectId: Id!
    $role: Role!
  ) {
    user_editMember(
      projectUserRelationId: $projectUserRelationId
      projectId: $projectId
      role: $role
    ) {
      id
      email
      fullName
      role
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation user_deleteMember($projectUserRelationId: Int!, $projectId: Id!) {
    user_deleteMember(
      projectUserRelationId: $projectUserRelationId
      projectId: $projectId
    ) {
      id
      email
      fullName
      role
    }
  }
`;

export const CANCEL_INVITATION = gql`
  mutation user_cancelInvitation($invitedUserId: Id!) {
    user_cancelInvitation(invitedUserId: $invitedUserId) {
      createdAt
      id
      email
      expiredAt
      role
    }
  }
`;

export const LOG_PROJECT_ACTIVE = gql`
  mutation loggingDashboard_userLogging($projectId: Id!, $userId: Id!) {
    loggingDashboard_userLogging(projectId: $projectId, userId: $userId) {
      username
      email
    }
  }
`;

export const AUTO_SIGN_IN = gql`
  mutation user_loginUsingCredentialKey($key: String!) {
    user_loginUsingCredentialKey(key: $key) {
      companyId
      userId
      email
      username
      company
      emailNeedVerification
      projectList {
        id
        name
        displayName
        objectLimitation
        logo
        objectNeedSet
        role
        isLtd
        planType
        status
        flags {
          isOnlineMedia
          isOfflineMedia
          isSocialMedia
          isCustomDownloadCsv
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const CHECK_EMAIL_REGISTERED = gql`
  mutation user_checkEmailRegistered($key: Id!, $projectId: Id!) {
    user_checkEmailRegistered(key: $key, projectId: $projectId) {
      url
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation user_changePassword($oldPassword: String!, $newPassword: String!) {
    user_changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      username
      email
      isEmailVerified
    }
  }
`;

export const CONFIRM_REGISTER_INVITATION = gql`
  mutation user_confirmUserInvitation(
    $key: Id!
    $fullName: String!
    $password: String!
  ) {
    user_confirmUserInvitation(
      key: $key
      fullName: $fullName
      password: $password
    ) {
      email
      role
      id
      fullName
      __typename
    }
  }
`;

export const USER_CREATE_TOKEN = gql`
  mutation user_createToken($projectId: Id!) {
    user_createToken(projectId: $projectId) {
      id
      hashKey
    }
  }
`;

// for forgot password
export const IDENTIFY_EMAIL = gql`
  mutation user_sendEmailForgotPassword($email: String!) {
    user_sendEmailForgotPassword(email: $email) {
      username
      email
      isEmailVerified
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation user_forgotPassword($key: Id!, $newPassword: String!) {
    user_forgotPassword(key: $key, newPassword: $newPassword) {
      username
      email
      isEmailVerified
    }
  }
`;
