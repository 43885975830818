import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  reducerUpdateAnalyticArgumentDateRange,
  reducerUpdateAnalyticArgumentDateRangeOptions
} from 'src/features/analyticArguments';
import ModalCustomTimeFrame from 'src/components/ModalCustomTimeFrame';
import { IDateRange, IOptionDateRange } from 'src/models/general';
import { reducerUpdateStatisticGranularity } from 'src/features/onlineMediaStream';
import { getGranularityForChronological } from 'src/utils/getGranularityForChronological';

let now = new Date();
let start = moment(
  new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
);
let end = moment(start).add(1, 'days').subtract(1, 'seconds');
let ranges: Array<IOptionDateRange> = [
  {
    id: 'today-only',
    label: 'Today Only',
    value: 0,
    start: moment(start).toDate(),
    end: moment(end).toDate()
  },
  {
    id: 'one-week',
    label: '1 Week',
    value: 1,
    start: moment(start).subtract(7, 'days').toDate(),
    end: moment(end).toDate()
  },
  {
    id: 'two-week',
    label: '2 Week',
    value: 2,
    start: moment(start).subtract(14, 'days').toDate(),
    end: moment(end).toDate()
  },
  {
    id: 'one-month',
    label: '1 Month',
    value: 3,
    start: moment(start).subtract(1, 'months').toDate(),
    end: moment(end).toDate()
  },
  {
    id: 'custom',
    label: 'Custom',
    value: 4
  }
];

const SelectDateRange = () => {
  const dateRangeOptionActive = useAppSelector(
    (state) => state.analyticArguments.dateRangeOptionActive.value
  );
  const [selectedRange, setSelectedRange] = useState<string | number>(
    dateRangeOptionActive
  );
  const dispatch = useAppDispatch();

  // timeframe option
  const handleChangeTimeframeOption = (
    event: SelectChangeEvent<number>,
    child: React.ReactNode
  ) => {
    const value = event.target.value;
    setSelectedRange(value);
    if (value === 4) {
      return;
    }

    const payloadDateRange = {
      start: ranges[value].start,
      end: ranges[value].end
    };
    const newGranularity = getGranularityForChronological(payloadDateRange);

    dispatch(reducerUpdateAnalyticArgumentDateRangeOptions(ranges[value]));
    dispatch(reducerUpdateAnalyticArgumentDateRange(payloadDateRange));
    dispatch(reducerUpdateStatisticGranularity(newGranularity));
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCustomTimeframe = (dateRange: IDateRange) => {
    dispatch(
      reducerUpdateAnalyticArgumentDateRangeOptions({
        ...ranges[selectedRange],
        ...dateRange
      })
    );
    const newGranularity = getGranularityForChronological(dateRange);

    dispatch(reducerUpdateAnalyticArgumentDateRange(dateRange));
    dispatch(reducerUpdateStatisticGranularity(newGranularity));
    setOpen(false);
  };
  return (
    <>
      <Select
        id="dateRange"
        name="dateRange"
        value={selectedRange}
        onChange={handleChangeTimeframeOption}
      >
        {ranges.map(({ id, value, label }) => (
          <MenuItem
            key={id}
            id={id}
            value={value}
            onClick={() => {
              if (value !== 4) return;
              handleClickOpen();
            }}
          >
            {label}
          </MenuItem>
        ))}
      </Select>
      <ModalCustomTimeFrame
        open={open}
        onClose={handleClose}
        onSet={handleChangeCustomTimeframe}
      />
    </>
  );
};

export default SelectDateRange;
