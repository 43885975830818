import React from 'react';
import useStyles from './styles';
import clsx from 'clsx';
import { Tooltip, Typography } from '@mui/material';

interface Props {
  children?: React.ReactNode;
  text: string;
  color?: string;
  padding?: string;
}
// a
const TagLabel: React.FC<Props> = ({
  text,
  color = '#B2B2B2',
  padding = ''
}) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={<span className={classes.textHover}>{text}</span>}
      arrow
      placement="top"
      disableHoverListener={text.length < 9}
    >
      <div
        style={{
          backgroundColor: color === '#fff' || color === '' ? '#B2B2B2' : color,
          padding: padding
        }}
        className={clsx({
          [classes.root]: true
        })}
      >
        <span className={classes.dot} />
        <Typography className={classes.text} color="inherit">
          {text}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default TagLabel;
