import {
  Grid,
  Stack,
  Fab,
  Typography,
  Button,
  Popover,
  List,
  ListItemButton,
  ListItemText,
  Box,
  useTheme,
  FormControl
} from '@mui/material';
import { CUSTOM_REPORT } from 'src/route';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SelectDateRange from 'src/components/SelectDateRange';
import { useAppSelector } from 'src/app/hooks';
import { useMutation, useQuery } from '@apollo/client';
import { GET_REPORT_BY_ID } from 'src/services/graphql/customReport/query';
import { DOWNLOAD_CUSTOM_REPORT } from 'src/services/graphql/customReport/mutation';
import { useAlert } from 'src/hooks/useAlert';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

const HeaderCustomReportById = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleClickAlert } = useAlert();
  const projectId = useAppSelector((store) => store.users.project.id);
  const customReportId = window.location.pathname.split('/')[3];
  const customReportById = useQuery(GET_REPORT_BY_ID, {
    variables: { customReportId }
  });
  const { start, end } = useAppSelector(
    (store) => store.analyticArguments.dateRangeFormatted
  );

  const [anchorElDownload, setAnchorElDownload] =
    useState<HTMLButtonElement | null>(null);

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };

  const [downloadCustomReport, resDownloadCustomReport] = useMutation(
    DOWNLOAD_CUSTOM_REPORT
  );

  const handleDownload = () => {
    downloadCustomReport({
      variables: {
        projectId,
        customReportId,
        startDate: start,
        endDate: end,
        timezone: 7
      },
      onCompleted: () =>
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          severity: 'success',
          message:
            'It may take some time for the report to be processed.\n The report can be downloaded from the Activity List on the top right corner.'
        }),
      onError(error) {
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          severity: 'error',
          message: 'Failed to download report, please try again.'
        });
        console.log(error.message);
      }
    });
    handleCloseDownload();
  };

  const openDownload = Boolean(anchorElDownload);
  const idPopup = openDownload ? 'simple-popover' : undefined;
  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{ paddingBottom: theme.spacing(2) }}
    >
      <Grid container>
        <Grid item lg={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Stack direction="row" alignItems="center">
              <Fab
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.grey[200]}`,
                  mr: theme.spacing(2)
                }}
                size="small"
                onClick={() => navigate(-1)}
              >
                <ArrowBackIcon />
              </Fab>
              <Typography variant="h1">Custom Report</Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                width: '30%',
                border: 'solid 1px #D9D9D9',
                backgroundColor: '#FBFBFB',
                borderRadius: '8px',
                paddingBlock: '0.7em',
                paddingInline: '0.7em',
                color: '#051C26',
                fontWeight: '600',
                fontSize: '14px'
              }}
            >
              {
                customReportById?.data?.customReportV2_getCustomReportById
                  .displayName
              }
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              spacing={theme.spacing(2)}
            >
              <FormControl size="small" sx={{ width: 135 }}>
                <SelectDateRange />
              </FormControl>
              <Box>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  endIcon={<KeyboardArrowDownIcon />}
                  sx={{
                    minWidth: theme.spacing(6),
                    height: 36,
                    backgroundColor: '#FBFBFB',
                    borderRadius: theme.spacing(1)
                  }}
                  onClick={handleClickDownload}
                >
                  Download Report
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Popover
        id={idPopup}
        open={openDownload}
        anchorEl={anchorElDownload}
        onClose={handleCloseDownload}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          <ListItemButton onClick={handleDownload}>
            <ListItemText primary="Download PPTX" />
          </ListItemButton>
        </List>
      </Popover>
    </HeaderWrapper>
  );
};

export default HeaderCustomReportById;
