/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Paper,
  Typography,
  FormControl,
  FormLabel,
  OutlinedInput
} from '@mui/material';
import { Box } from '@mui/system';
import { useTheme, styled } from '@mui/material/styles';
import { useAppSelector } from 'src/app/hooks';
import separateNumberFormat from 'src/utils/separateNumberFormat';
import useBillingSystem from 'src/services/useBillingSystem';
import useRegister from 'src/services/useRegister';
import useQueryUrl from 'src/hooks/useQuery';

const LiteTypography = styled(Typography)(
  ({ theme }) => `
    background: ${theme.colors.gradients.blue1};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `
);

const OutlinedInputCustom = styled(OutlinedInput)(
  ({ theme }) => `
    border-radius: 6px 0px 0px 6px;
  `
);

const ButtonCustom = styled(Button)(
  ({ theme }) => `
    border-radius: 0px 6px 6px 0px;
    padding: ${theme.spacing(0, 4)}
  `
);

const PricingCalculator: React.FC = () => {
  const theme = useTheme();
  const query = useQueryUrl();
  const registerId = query.get('id')
  const { getUserPlanSimulation, getUserPlanSimulationById } = useRegister();
  const { planSimulation, countryId, selectedCountryId } = useAppSelector(
    (state) => state.register
  );
  const [promoCodeValue, setPromoCodeValue] = useState('')

  const currentRate = useAppSelector(
    (state) => state.storeBilling.currentRate.data
  );
  const { quotaBasic, quotaAdvance, period, packagePlan } = planSimulation.data;

  const { getCurrentRate } = useBillingSystem();

  useEffect(() => {
    getCurrentRate(countryId);
  }, [planSimulation.data]);

  useEffect(() => {
    getUserPlanSimulationById(registerId)
  }, [registerId])

  const handleInputPromo = () => {
    getUserPlanSimulation({
      basicObject: quotaBasic,
      advanceObject: quotaAdvance,
      countryId: countryId,
      onlineMediaObject: 0,
      packagePlanId: packagePlan.id,
      period: period,
      promoCode: promoCodeValue
    });
  };

  return (
    <Paper
      sx={{
        width: theme.spacing(49),
        transform: 'translateY(60px)',
        padding: 4,
        borderRadius: '12px'
      }}
    >
      <Box
        sx={{
          mb: 1
        }}
      >
        <LiteTypography variant="h1">Lite</LiteTypography>
      </Box>
      <Box
        sx={{
          mb: 3
        }}
      >
        <Typography variant="subtitle2">{`${planSimulation.data.quotaBasic} Social Profile`}</Typography>
        <Typography variant="subtitle2">{`${planSimulation.data.quotaAdvance} Hashtag / Keyword`}</Typography>
      </Box>
      <Box>
        <FormControl fullWidth size="medium">
          <FormLabel>Promo Code</FormLabel>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <OutlinedInputCustom
              name="promo-code"
              placeholder="Please enter promo code"
              value={promoCodeValue}
              onChange={(e) => setPromoCodeValue(e.target.value)}
            />
            <ButtonCustom variant="contained" onClick={() => handleInputPromo()}>Use</ButtonCustom>
          </Box>
        </FormControl>
      </Box>
      <Divider
        sx={{
          m: theme.spacing(2, 0, 3, 0),
          position: 'absolute',
          left: 0,
          right: 0
        }}
      />
      <Box
        sx={{
          mt: theme.spacing(5)
        }}
      >
        <Typography variant="subtitle2">Total Billing</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: 22,
              lineHeight: '24px',
              fontWeight: 800,
              color: '#009FE3'
            }}
          >
            {`${
              planSimulation.data.symbolBySelectedCountry
                ? planSimulation.data.symbolBySelectedCountry
                : ''
            } ${separateNumberFormat(
              planSimulation.data.totalBySelectedCountry
            )}`}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: 16,
              lineHeight: '27px',
              fontWeight: 600,
              color: theme.palette.grey[800],
              transform: 'translateY(2px)',
              ml: theme.spacing(0.5)
            }}
          >
            {planSimulation.data.period < 12
              ? `/ ${
                  planSimulation.data.period === 1
                    ? ''
                    : planSimulation.data.period
                } Month`
              : `/ Year`}
          </Typography>
        </Box>
      </Box>
      {planSimulation.data.currency !== 'idr' && (
        <>
          <Box
            sx={{
              mt: theme.spacing(3)
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 600,
                color: theme.palette.grey[800]
              }}
            >
              Current rate:{' '}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 600,
                color: theme.palette.grey[800]
              }}
            >
              {`${planSimulation.data.symbolBySelectedCountry}${currentRate} = 1 Dollars`}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: theme.spacing(3)
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 400,
                color: theme.palette.grey[800]
              }}
            >
              USD exchange rate is in constant fluctuation,
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 400,
                color: theme.palette.grey[800]
              }}
            >
              Prices may vary depending on latest rate
            </Typography>
          </Box>
        </>
      )}
    </Paper>
  );
};

export default PricingCalculator;
