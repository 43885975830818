import { filterOptionLocation } from './filterOptionLocation';

const changeObjectToQuery = function (object: Object) {
  let query = '';
  Object.keys(object).forEach((key, index) => {
    const suffix = Object.keys(object).length - 1 > index ? `; ` : ``;
    if (typeof object[key] === 'string') {
      if (object[key].length <= 0) return object[key];
      if (key === 'fromUsername') {
        query = query.concat(`${key}: @${object[key]}${suffix}`);
        return;
      }
      if (key === 'replyToOriginalId') {
        query = query.concat(`${key}: ${object[key]}${suffix}`);
        return;
      }
      return;
    }
    if (typeof object[key] === 'boolean') {
      if (object[key] === false) return;
      query = query.concat(`${key}: ${object[key]}${suffix}`);
    }
    if (object[key].length > 0) {
      if (key === 'locations' || key === 'locationList') {
        query = query.concat(
          `${key}:${object[key].map(
            (obj) =>
              ` ${filterOptionLocation(obj)
                .map((opt: any) => opt.displayName)
                .join(', ')}`
          )}${suffix}`
        );
      } else if (key === 'ageList') {
        query = query.concat(
          `${key}:${object[key].map((obj) => ` ${obj.label}`)}${suffix}`
        );
      } else {
        query = query.concat(
          `${key}:${object[key].map((obj) => ` ${obj}`)}${suffix}`
        );
      }
    }
  });
  return query;
};

export default changeObjectToQuery;
