import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import TagLabel from 'src/components/TagLabel';
import { TSentiment } from 'src/models/general';
import {
  ILabelArticle,
  TSearchType,
  TValidation
} from 'src/models/onlineMedia';

export interface IUpdateLabelOnmDetail {
  attachLabels: string[];
  attachLabelsObject: ILabelArticle[];
  clipping: { id: string; name: string };
  end: string;
  excludeKeywords?: string[];
  id: string;
  includeKeywords?: string[];
  keywordInclude?: string[];
  labelList?: string[];
  labels?: string[];
  labelsObject: ILabelArticle[];
  languageList?: string[];
  mediaList: string[];
  operator: string;
  processedBatches: number;
  processedData: number;
  projectId: string;
  removeLabels: string[];
  removeLabelsObject?: ILabelArticle[];
  searchType?: TSearchType;
  sentiment?: TSentiment[];
  sentiments: TSentiment[];
  start: string;
  status: string;
  timezoneOffset: number;
  totalBatches: number;
  totalData: number;
  validationList?: TValidation[];
}

interface IProps {
  detail: IUpdateLabelOnmDetail;
}

const InformationDetailUpdateLabelOnm = ({ detail }: IProps) => {
  const {
    start,
    end,
    clipping,
    attachLabelsObject,
    removeLabelsObject,
    sentiments,
    keywordInclude,
    includeKeywords,
    operator,
    labelsObject
  } = detail;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">Update Label ONM:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Clipping: {clipping.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{`Date Range: ${moment(start).format(
          'DD MMM YYYY'
        )} - ${moment(end).format('DD MMM YYYY')}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Labels: </Typography>
        {labelsObject.map((label) => (
          <TagLabel
            text={label.displayName}
            key={label.id}
            color={label.colorType}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Keywords: {includeKeywords ? includeKeywords.join(', ') : ''}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Sentiments: {sentiments.join(', ')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">Operator: {operator}</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 8 }}>
        <Typography variant="h5">Change to:</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{`Attach Labels : `}</Typography>
        {attachLabelsObject.map((label) => (
          <TagLabel
            text={label.displayName}
            key={label.id}
            color={label.colorType}
          />
        ))}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{`Remove Labels : `}</Typography>
        {removeLabelsObject.map((label) => (
          <TagLabel
            text={label.displayName}
            key={label.id}
            color={label.colorType}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default InformationDetailUpdateLabelOnm;
