/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from "react";
import _ from "lodash";

export const useDebounce = (obj: any = null, wait: number = 500) => {
  const [state, setState] = useState(obj);
  const debounce = useCallback(
    _.debounce((_prop: string) => {
      setState(_prop);
    }, wait),
    []
  );

  const setDebouncedState = (_val: any) => {
    debounce(_val);
  };

  return [state, setDebouncedState];
};
