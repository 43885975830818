import { Box, styled, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC } from 'react';

interface Props {
  children: any;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  style?: any;
}

const CircleButton = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 3px 8px 3px;
    background: #E0F6FF;
    border: 1px solid rgba(5, 28, 38, 0.08);
    box-sizing: border-box;
    border-radius: ${theme.spacing(5)};
    cursor: pointer;
    height: ${theme.spacing(4)};
    width: ${theme.spacing(4)};
    &:hover {
      background: #E0F6FF
    }
  `
);

const ButtonCircle: FC<Props> = ({ children, sx, onClick, style }) => {
  return (
    <CircleButton sx={sx} onClick={onClick} style={style}>
      {children}
    </CircleButton>
  );
};

export default ButtonCircle;
