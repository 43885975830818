import gql from "graphql-tag";

export const CAMPAIGN_ADD_CAMPAIGN = gql`
mutation campaign_addCampaignV2(
	$projectId: Id!, 
	$campaignName: String!,
	$objectIds:[Id!]!) {
  campaign_addCampaignV2(
		projectId: $projectId, 
		campaignName: $campaignName,
		objectIds:$objectIds) {
    id
    projectId
	campaignName
    dateStart
    dateEnd
	campaignProgress
	createdAt
	campaignObjectDetails{
		objectModel{
			id
			socialMedia
			streamType
			content
			extraContent
			isHashtag
			objectName
			createdAt
		}
		campaignProgress
		createdAt
		dateStart
		dateEnd
		talk
		talker
		reach
		impression
	}
  
			
  }
}
`;

export const CAMPAIGN_EDIT_CAMPAIGN = gql`
mutation campaign_editCampaignV2(
	$campaignId: Id!,
	$projectId: Id!, 
	$campaignName: String!,
	$objectIds:[Id!]!) {
  campaign_editCampaignV2(
		campaignId: $campaignId, 
		projectId: $projectId, 
		campaignName: $campaignName,
		objectIds:$objectIds) {
    id
    projectId
		campaignProgress
		createdAt
		campaignObjectDetails{
			objectModel{
				id
				socialMedia
				streamType
				content
				extraContent
				isHashtag
				objectName
				createdAt
			}
			campaignProgress
			createdAt
			dateStart
			dateEnd
			talk
			talker
			reach
			impression
		}
    
    __typename
			
  }
}
`;

export const CAMPAIGN_FINISH_CAMPAIGN = gql`
mutation campaign_finishCampaignV2(
	$campaignId: Id!,
	$projectId: Id!, 
	$objectIds:[Id!]!) {
  campaign_finishCampaignV2(
		campaignId: $campaignId, 
		projectId: $projectId, 
		objectIds:$objectIds) {
    id
    projectId
		campaignProgress
		createdAt
		campaignObjectDetails{
			objectModel{
				id
				socialMedia
				streamType
				content
				extraContent
				isHashtag
				objectName
				createdAt
			}
			campaignProgress
			createdAt
			dateStart
			dateEnd
			talk
			talker
			reach
			impression
		}
    
    __typename
			
  }
}

`;

