import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
  IAdvanceSearchPeoplePayload,
  IPeopleStreamOutputWithPagination,
  TInterest
} from 'src/models/people';
import {
  CREATE_INTEREST,
  GET_IGNORED_PEOPLE_LIST,
  GET_INTEREST_LIST,
  GET_PEOPLE,
  GET_PEOPLE_LOCATION_LIST,
  IGNORE_PEOPLE_BY_SELECTION,
  UPDATE_AGE_RANGE_BY_FILTER,
  UPDATE_AGE_RANGE_BY_SELECTED,
  UPDATE_GENDER_BY_FILTER,
  UPDATE_GENDER_BY_SELECTED,
  UPDATE_PEOPLE_INTERESTS,
  UPDATE_PEOPLE_INTEREST_BY_FILTER,
  UPDATE_PEOPLE_LABELS,
  UPDATE_PEOPLE_LABELS_BY_FILTER,
  UPDATE_PEOPLE_LOCATION,
  UPDATE_PEOPLE_LOCATION_BY_FILTER
} from './graphql/people/query';
import uuidNameSpace from 'uuid/v5';
import getNS from 'uuid/v4';
import { useRef } from 'react';
import { useAppDispatch } from 'src/app/hooks';
import {
  reducerUpdateInterestList,
  reducerUpdatePeople,
  reducerUpdateLocationList,
  reducerInitIgnoredPeopleList,
  reducerUpdatePeopleLoading
} from 'src/features/people';

interface IGetLocationPayload {
  phrase: string;
  count: number;
}

interface IGetPeopleResponse {
  peoplePanel_getEngagedUserWithPagination: IPeopleStreamOutputWithPagination;
}

interface IGetInterestResponse {
  peoplePanel_getInterestList: Array<TInterest>;
}

const usePeople = () => {
  const client = useApolloClient();
  const RequestNameSpace = getNS();
  const dispatch = useAppDispatch();

  const peoplePromise: any = useRef();
  const interestPromise: any = useRef();
  const locationPromise: any = useRef();
  const ignoredPeoplePromise: any = useRef();

  const fetchPeople = (parameter: IAdvanceSearchPeoplePayload) => {
    const response = client
      .query<IGetPeopleResponse>({
        query: GET_PEOPLE,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_CONVERSATION_CONVERSATION_GROUP_TOTAL',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.peoplePanel_getEngagedUserWithPagination
        };
      });
    return response;
  };

  const getPeople = (parameter: IAdvanceSearchPeoplePayload) => {
    dispatch(reducerUpdatePeopleLoading(true))
    const response = fetchPeople(parameter);
    peoplePromise.current = response;
    if (response !== peoplePromise.current) return;
    response.then((response) => dispatch(reducerUpdatePeople(response)));
  };

  const fetchInterestList = (projectId: string) => {
    const response = client
      .query<IGetInterestResponse>({
        query: GET_INTEREST_LIST,
        variables: { projectId },
        context: {
          requestTrackerId: uuidNameSpace('GET_INTEREST_LIST', RequestNameSpace)
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.peoplePanel_getInterestList
        };
      });
    return response;
  };

  const getInterestList = (projectId: string) => {
    const response = fetchInterestList(projectId);
    interestPromise.current = response;
    if (response !== interestPromise.current) return;
    response.then((response) => dispatch(reducerUpdateInterestList(response)));
  };

  const fetchLocationList = (parameter: IGetLocationPayload) => {
    const response = client
      .query({
        query: GET_PEOPLE_LOCATION_LIST,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_PEOPLE_LOCATION_LIST',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.peoplePanel_getLocationList
        };
      });
    return response;
  };

  const getLocationList = (parameter: IGetLocationPayload) => {
    const response = fetchLocationList(parameter);
    locationPromise.current = response;
    if (response !== locationPromise.current) return;
    response.then((response) => dispatch(reducerUpdateLocationList(response)));
  };

  const fetchIgnoredPeopleList = (parameter: IAdvanceSearchPeoplePayload) => {
    const response = client
      .query({
        query: GET_IGNORED_PEOPLE_LIST,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_IGNORED_PEOPLE_LIST',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.peoplePanel_getIgnoreEngagedUserWithPagination
        };
      });
    return response;
  };

  const getIgnoredPeopleList = (parameter: IAdvanceSearchPeoplePayload) => {
    const response = fetchIgnoredPeopleList(parameter);
    ignoredPeoplePromise.current = response;
    if (response !== ignoredPeoplePromise.current) return;
    response.then((response) =>
      dispatch(reducerInitIgnoredPeopleList(response))
    );
  };

  const [updateGender, resUpdateGender] = useMutation(
    UPDATE_GENDER_BY_SELECTED
  );

  const [updateAgeRange, resUpdateAgeRange] = useMutation(
    UPDATE_AGE_RANGE_BY_SELECTED
  );

  const [createInterest, resCreateInterest] = useMutation(CREATE_INTEREST);

  const [updatePeopleLabels, resUpdatePeopleLabels] =
    useMutation(UPDATE_PEOPLE_LABELS);

  const [updatePeopleInterests, resUpdatePeopleInterests] = useMutation(
    UPDATE_PEOPLE_INTERESTS
  );

  const [updatePeopleLocation, resUpdatePeopleLocation] = useMutation(
    UPDATE_PEOPLE_LOCATION
  );

  const [ignorePeopleBySelected, resIgnorePeopleBySelected] = useMutation(
    IGNORE_PEOPLE_BY_SELECTION
  );

  const [restorePeopleBySelected, resRestorePeopleBySelected] = useMutation(
    IGNORE_PEOPLE_BY_SELECTION
  );

  const [updateGenderAll, resUpdateGenderAll] = useMutation(UPDATE_GENDER_BY_FILTER);
  
  const [updateAgeRangeAll, resUpdateAgeRangeAll] = useMutation(UPDATE_AGE_RANGE_BY_FILTER);

  const [updatePeopleInterestAll, resUpdatePeopleInterestAll] = useMutation(UPDATE_PEOPLE_INTEREST_BY_FILTER);

  const [updatePeopleLabelsAll, resUpdatePeopleLabelsAll] = useMutation(UPDATE_PEOPLE_LABELS_BY_FILTER);

  const [updatePeopleLocationAll, resUpdatePeopleLocationAll] = useMutation(UPDATE_PEOPLE_LOCATION_BY_FILTER);

  return {
    getPeople,
    getInterestList,
    getLocationList,
    getIgnoredPeopleList,
    updateGender,
    resUpdateGender,
    updateAgeRange,
    resUpdateAgeRange,
    createInterest,
    resCreateInterest,
    updatePeopleLabels,
    resUpdatePeopleLabels,
    updatePeopleInterests,
    resUpdatePeopleInterests,
    updatePeopleLocation,
    resUpdatePeopleLocation,
    ignorePeopleBySelected,
    resIgnorePeopleBySelected,
    restorePeopleBySelected,
    resRestorePeopleBySelected,
    updateGenderAll,
    resUpdateGenderAll,
    updateAgeRangeAll,
    resUpdateAgeRangeAll,
    updatePeopleInterestAll,
    resUpdatePeopleInterestAll,
    updatePeopleLabelsAll,
    resUpdatePeopleLabelsAll,
    updatePeopleLocationAll,
    resUpdatePeopleLocationAll
  };
};

export default usePeople;
