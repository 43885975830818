import { Alert, CssBaseline, Snackbar, styled } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { useAlert } from 'src/hooks/useAlert';
import { useAppSelector } from 'src/app/hooks';
import { Outlet } from 'react-router-dom';

interface Props {
  title?: string;
  subTitle?: string;
}

const Container = styled(Box)(
  ({ theme }) => `
      height: 100vh;
      width: 100vw;
      padding: ${theme.spacing(0, 23, 0, 23)}
  `
);

const BasicLayout: FC<Props> = ({ children }) => {
  const stateAlert = useAppSelector((state) => state.storeAlert.alert);
  const { handleCloseAlert } = useAlert();
  return (
    <>
      <CssBaseline />
      <Container>{children || <Outlet />}</Container>
      <Snackbar
        anchorOrigin={{
          vertical: stateAlert.vertical,
          horizontal: stateAlert.horizontal
        }}
        open={stateAlert.open}
        onClose={handleCloseAlert}
        autoHideDuration={6000}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={stateAlert.severity}
          sx={{ width: '100%' }}
        >
          {stateAlert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BasicLayout;
