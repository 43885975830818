import { useMutation } from '@apollo/client';
import {
  DOWNLOAD_CSV,
  DOWNLOAD_CSV_JOB_FOR_MEDIA_ONLINE
} from './graphql/download/mutation';

const useDownload = () => {
  const [downloadCsv, resDownloadCsv] = useMutation(DOWNLOAD_CSV);
  const [downloadCsvMediaOnline, resDownloadCsvMediaOnline] = useMutation(
    DOWNLOAD_CSV_JOB_FOR_MEDIA_ONLINE
  );
  return { downloadCsv, resDownloadCsv, downloadCsvMediaOnline, resDownloadCsvMediaOnline };
};

export default useDownload;
