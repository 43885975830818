import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  border: {
    width: '25vw'
  }
}));

export default useStyles;
