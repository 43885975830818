import { TStreamType } from 'src/models/general';

export const getSymbolByStreamType = (streamType: TStreamType) => {
  switch (streamType) {
    case 'account':
      return '@';
    case 'keyword':
      return '~';
    default:
      return '';
  }
};
