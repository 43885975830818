/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  Stack,
  TextField,
  IconButton,
  Button,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SelectDateRange from 'src/components/SelectDateRange';
import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  initialState,
  reducerUpdateAdvanceClear,
  reducerUpdateAdvanceSearchIncludeKeywords
} from 'src/features/advanceSearchSocialMedia';
import AdvanceSearchSocialMedia from 'src/components/AdvanceSearchSocialMedia';
import ClearIcon from '@mui/icons-material/Clear';
import changeObjectToQuery from 'src/utils/changeObjectToQuery';
import FilterListIcon from '@mui/icons-material/FilterList';
import TooltipCustomize from 'src/components/TooltipCustomize';
import RefreshIcon from '@mui/icons-material/Refresh';
import useStreams from 'src/services/useStreams';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { IGranularity, ITableAtribute } from 'src/models/general';
import {
  reducerUpdateIsSelectedAllPrimary,
  reducerUpdateStreamSosmedSelected
} from 'src/features/sosmedStream';
import useObjectManagement from 'src/services/useObjectManagement';
import { concatUniqueObjectsById } from 'src/utils/concatUniqueObjectsById';
import { reducerUpdateObjectListByObjectType } from 'src/features/objectManagement';
import { reducerUpdateAnalyticArgumentObjectIdList } from 'src/features/analyticArguments';
import { useAlert } from 'src/hooks/useAlert';

const CustomBox = styled(Box)(
  ({ theme }) => `
    // width: calc(100vw - ${theme.sidebar.width} - ${theme.spacing(10)});
    margin: ${theme.spacing(2, 0)};
    // background: #fbfbfb;
    // position: absolute;
`
);

const FilterBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
);

function BottomHeader() {
  const dispatch = useAppDispatch();
  const advanceSearch = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );
  const limit = useAppSelector(
    (state) => state.storeStreamPanel.streamPanel.data.limit
  );
  const {
    streamsCommentingToPost,
    selectedOriginalToPost,
    searchOriginalToPost,
    ...rest
  } = advanceSearch;

  const query = changeObjectToQuery(rest);
  const [localSearch, setLocalSearch] = useState<string>(query);

  const handleClear = () => {
    setLocalSearch('');
    dispatch(reducerUpdateAdvanceClear(initialState));
  };

  const handleSearch = () => {
    dispatch(reducerUpdateAdvanceSearchIncludeKeywords([localSearch]));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') handleSearch();
  };

  useEffect(() => {
    setLocalSearch(query);
  }, [advanceSearch]);

  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const projectId = useAppSelector((state) => state.users.project.id);

  const advanceSearchSocialMedia = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );

  const fillAdvanceSearchSocialMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchSocialMedia);
  }, [advanceSearchSocialMedia]);

  const { dateRangeFormatted, objectIdList } = useAppSelector(
    (state) => state.analyticArguments
  );

  const objectList = useAppSelector(
    (state) => state.objectManagement.objectList.data
  );

  const { groupSelectedById: selectedGroupId, groupObject: prevGroupObject } =
    useAppSelector((state) => state.objectManagement);

  const objectListByObjectTypeActive = useMemo(() => {
    const dataList = objectList.filter((object) =>
      objectIdList.find((id) => id === object.id)
    );
    return dataList.map((object) => {
      return object.id;
    });
  }, [objectIdList]);

  const granularity: IGranularity = {
    unit: 'day',
    value: 1
  };

  const initialTableAttribute: ITableAtribute = {
    columnName: 'dateColumn',
    limit: limit,
    page: 1,
    sortingMethod: 'desc'
  };

  const finalPayload = {
    ...fillAdvanceSearchSocialMedia,
    projectId,
    ...dateRangeFormatted,
    objectList: objectListByObjectTypeActive,
    granularity,
    timezone: 7,
    ...initialTableAttribute
  };

  const {
    getStreamOutputWithPagination,
    getIgnoredStreamOutputWithPagination
  } = useStreams();

  const { getObjectList, getGroupObject } = useObjectManagement();
  const { handleClickAlert } = useAlert();

  const handleRefresh = () => {
    dispatch(reducerUpdateStreamSosmedSelected([]));
    dispatch(reducerUpdateIsSelectedAllPrimary(false));
    Promise.all([
      getObjectList(projectId),
      getGroupObject({ projectId: projectId, templateName: 'content' })
    ])
      .then((responses) => {
        // if a group is selected
        if (selectedGroupId !== '_default') {
          const newGroupObjectList = responses[1].data;
          const prevSelectedGroup = prevGroupObject.data.find(
            (group) => group.id === selectedGroupId
          );
          const prevSelectedGroupIds = prevSelectedGroup.objectList.map(
            (group) => group.id
          );
          const newSelectedGroup = newGroupObjectList.find(
            (group) => group.id === selectedGroupId
          );

          // semua object yang ada di active object + object baru di grup
          const finalActiveObjects = newSelectedGroup.objectList.filter(
            (object) =>
              objectIdList.includes(object.id) ||
              !prevSelectedGroupIds.includes(object.id)
          );
          dispatch(reducerUpdateObjectListByObjectType(finalActiveObjects));
          dispatch(
            reducerUpdateAnalyticArgumentObjectIdList(
              finalActiveObjects.map((object) => object.id)
            )
          );
          // if no group selected
        } else {
          const newObjectList = responses[0].data;
          // get only new objects
          const newObjects = newObjectList.filter((object) => {
            const prevObjectListIds = objectList.map((object) => object.id);
            return !prevObjectListIds.includes(object.id);
          });

          const selectedObjects = newObjectList.filter((object) =>
            objectIdList.includes(object.id)
          );
          const finalActiveObjects = concatUniqueObjectsById(
            selectedObjects,
            newObjects
          );

          dispatch(reducerUpdateObjectListByObjectType(finalActiveObjects));
          dispatch(
            reducerUpdateAnalyticArgumentObjectIdList(
              finalActiveObjects.map((object) => object.id)
            )
          );
        }
      })
      .catch((error) =>
        handleClickAlert({
          vertical: 'top',
          horizontal: 'center',
          message: "Couldn't fetch data, please try again."
        })
      );
    // getStreamOutputWithPagination(finalPayload);
    // getIgnoredStreamOutputWithPagination(finalPayload);
  };

  return (
    <CustomBox>
      <FilterBox>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            size="small"
            sx={{ width: 509 }}
            InputProps={{
              startAdornment: (
                <IconButton size="small" onClick={handleSearch}>
                  <SearchTwoToneIcon />
                </IconButton>
              ),
              endAdornment: localSearch ? (
                <IconButton size="small" onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              ) : null
            }}
            placeholder="Search stream"
            onChange={(e) => setLocalSearch(e.target.value)}
            value={localSearch}
            onKeyDown={handleKeyDown}
          />
          <TooltipCustomize title="Advance search" placement="top-start">
            <Button
              variant="contained"
              sx={{
                borderRadius: '6px',
                height: theme.spacing(5),
                minWidth: theme.spacing(6),
                background: theme.palette.primary.dark
              }}
              onClick={() => handleClickOpen()}
            >
              <FilterListIcon htmlColor={theme.colors.primary.lighter} />
            </Button>
          </TooltipCustomize>
          {open && (
            <AdvanceSearchSocialMedia open={open} onClose={handleClose} />
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl size="small" sx={{ width: 135 }}>
            <SelectDateRange />
          </FormControl>
          <TooltipCustomize title="Refresh all metric" placement="top-start">
            <Button
              variant="contained"
              onClick={handleRefresh}
              sx={{
                borderRadius: '6px',
                height: theme.spacing(5),
                minWidth: theme.spacing(6),
                background: theme.palette.primary.main
              }}
            >
              <RefreshIcon />
            </Button>
          </TooltipCustomize>
        </Stack>
      </FilterBox>
    </CustomBox>
  );
}

export default BottomHeader;
