import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
  GET_PLAN_SIMULATION_BY_ID,
  GET_USER_COUNTRIES,
  GET_USER_GET_PACKAGE_PLAN,
  GET_USER_PLAN_SIMULATION,
  GET_XENDIT_PAYMENT_CHANNEL
} from './graphql/register/query';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  reducerUserPackagePlan,
  reducerUserPlanSimulation,
  reducerCountries,
  reducerXenditPaymentChannel
} from 'src/features/register';
import {
  PayloadPlanSimulation,
  PaymentGroup,
  PlanSimulation,
  UserPackagePlan
} from 'src/models/register';
import {
  POST_COMPLETE_REGISTER_APPSUMO,
  POST_USER_PLAN_CHECKOUT,
  POST_USER_REGISTER
} from './graphql/register/mutation';
import { useNavigate } from 'react-router';
import { REGISTER_CHECKOUT, USERS_SIGN_IN } from 'src/route';
import { useAlert } from 'src/hooks/useAlert';
import { BILLING_INVOICE_CHECKOUT } from './graphql/billing/mutation';
import { ICountry } from 'src/models/onboarding';

const useRegister = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleClickAlert } = useAlert();
  const registerPackagePlan = useAppSelector(state => state.register.planSimulation.data.packagePlan)

  interface IGetCountriesResponse {
    billing_getCountry: Array<ICountry>;
  }

  interface IGetPackagePlanResponse {
    user_getPackagePlan: Array<UserPackagePlan>;
  }

  interface IGetUserPlanSimulationResponse {
    user_getPlanSimulation: PlanSimulation;
  }

  interface IGetPlanSimulationByIdResponse {
    user_getPlanSimulationByRegisterId: PlanSimulation;
  }

  interface IGetXenditChannelResponse {
    user_getXenditPaymentChannel: {
      paymentGroup: Array<PaymentGroup>;
    };
  }

  const fetchCountries = async function () {
    return await client
      .query<IGetCountriesResponse>({
        query: GET_USER_COUNTRIES
      })
      .then((response) => {
        return { ...response, data: response.data.billing_getCountry };
      });
  };

  const getCountries = async function () {
    const response = await fetchCountries();
    dispatch(reducerCountries(response));
  };

  const fetchUserPackagePlan = async function () {
    return await client
      .query<IGetPackagePlanResponse>({
        query: GET_USER_GET_PACKAGE_PLAN
      })
      .then((response) => {
        return { ...response, data: response.data.user_getPackagePlan };
      });
  };

  const getUserPackagePlan = async function () {
    const response = await fetchUserPackagePlan();
    dispatch(reducerUserPackagePlan(response));
  };

  const fetchUserPlanSimulation = async function (
    payload: PayloadPlanSimulation
  ) {
    return await client
      .query<IGetUserPlanSimulationResponse>({
        query: GET_USER_PLAN_SIMULATION,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.user_getPlanSimulation };
      });
  };

  const getUserPlanSimulation = async function (
    payload: PayloadPlanSimulation
  ) {
    const response = await fetchUserPlanSimulation(payload);
    // don't replace package plan if using promo code
    if (response.data.isUsedPromo){
      dispatch(reducerUserPlanSimulation({...response, data: {...response.data, packagePlan: registerPackagePlan}}))
    }else {
      dispatch(reducerUserPlanSimulation(response));
    }
    if (response.data.promoErrorMessage) {
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        message: response.data.promoErrorMessage,
        severity: response.data.isPromoValid ? 'info' : 'error'
      });
    }
  };

  const fetchUserPlanSimulationById = async function (registerId: string) {
    return await client
      .query<IGetPlanSimulationByIdResponse>({
        query: GET_PLAN_SIMULATION_BY_ID,
        variables: { registerId }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.user_getPlanSimulationByRegisterId
        };
      });
  };

  const getUserPlanSimulationById = async function (registerId: string) {
    const response = await fetchUserPlanSimulationById(registerId);
    dispatch(reducerUserPlanSimulation(response));
  };

  const fetchXenditPaymentChannel = async function () {
    return await client
      .query<IGetXenditChannelResponse>({
        query: GET_XENDIT_PAYMENT_CHANNEL
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.user_getXenditPaymentChannel.paymentGroup
        };
      });
  };

  const getXenditPaymentChannel = async function () {
    const response = await fetchXenditPaymentChannel();
    dispatch(reducerXenditPaymentChannel(response));
  };

  const [postUserRegister, responseUserRegister] = useMutation(
    POST_USER_REGISTER,
    {
      onCompleted: (response) => {
        navigate(
          `${REGISTER_CHECKOUT}?id=${response.user_registerUserV2.registerId}`
        );
      },
      onError: (error) => {
        if (!error) return;
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          message: 'Your email has been registered',
          severity: 'error'
        });
      }
    }
  );
  const [postUserPlanCheckout, responseUserPlanCheckout] = useMutation(
    POST_USER_PLAN_CHECKOUT
  );
  const [postBillingInvoiceCheckout, responseBillingInvoiceCheckout] =
    useMutation(BILLING_INVOICE_CHECKOUT);

  const [postCompleteRegisterAppSumo, responseCompleteRegisterAppSumo] =
    useMutation(POST_COMPLETE_REGISTER_APPSUMO, {
      onCompleted: (response) => {
        navigate(`${USERS_SIGN_IN}`);
      },
      onError: (error) => {
        if (!error) return;
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          message: 'Your email has been registered',
          severity: 'error'
        });
      }
    });

  return {
    getUserPackagePlan,
    getUserPlanSimulation,
    getCountries,
    postUserRegister,
    responseUserRegister,
    getUserPlanSimulationById,
    getXenditPaymentChannel,
    postUserPlanCheckout,
    responseUserPlanCheckout,
    postCompleteRegisterAppSumo,
    responseCompleteRegisterAppSumo,
    postBillingInvoiceCheckout,
    responseBillingInvoiceCheckout
  };
};

export default useRegister;
