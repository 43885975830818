import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { Alert, Snackbar } from '@mui/material';
import { useAlert } from 'src/hooks/useAlert';
import { useAppSelector } from 'src/app/hooks';

interface BaseLayoutProps {
  children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const { handleCloseAlert } = useAlert();
  const stateAlert = useAppSelector((state) => state.storeAlert.alert);
  return (
    <>
      {children || <Outlet />}
      <Snackbar
        anchorOrigin={{
          vertical: stateAlert.vertical,
          horizontal: stateAlert.horizontal
        }}
        open={stateAlert.open}
        onClose={handleCloseAlert}
        autoHideDuration={6000}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={stateAlert.severity}
          sx={{ width: '100%' }}
        >
          {stateAlert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
