import React, { useState } from 'react';
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Avatar,
  TableContainer,
  styled,
  Box,
  useTheme,
  Checkbox,
  Stack,
  Typography,
  Divider,
  Button
} from '@mui/material';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { ICampaignObjectDetail } from 'src/models/campaign';
import moment from 'moment';
import useCampagin from 'src/services/useCampaign';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { reducerUpdateSelectedCampaign } from 'src/features/sosmedCampaign';
import GetSocialMediaIcon from '../GetSocialMediaIcon';

interface Props {
  data: Array<ICampaignObjectDetail>;
  loading?: boolean;
  height?: number;
}

const TableCellHeader = styled(TableCell)(
  ({ theme }) => `
      z-index: 3;
      background: ${theme.palette.primary.main};
      color: ${theme.palette.common.white};
  `
);

const TableFinishHashtag: React.FC<Props> = ({
  data,
  loading = false,
  height
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { updateFinishCampagin, resUpdateFinishCampagin } = useCampagin();
  const [selectedObject, setSelectedObject] = useState<
    Array<ICampaignObjectDetail>
  >([]);

  const isSelected = (id: string) =>
    selectedObject.findIndex((obj) => obj.objectModel.id === id) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedObject(data);
      return;
    }
    setSelectedObject([]);
  };

  const handleSelect = function (object: ICampaignObjectDetail) {
    const selectedIndex = selectedObject.findIndex(
      (obj) => obj.objectModel.id === object.objectModel.id
    );
    let newSelected: ICampaignObjectDetail[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedObject, object);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedObject.slice(1));
    } else if (selectedIndex === selectedObject.length - 1) {
      newSelected = newSelected.concat(selectedObject.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedObject.slice(0, selectedIndex),
        selectedObject.slice(selectedIndex + 1)
      );
    }
    setSelectedObject(newSelected);
  };

  const projectId = useAppSelector((state) => state.users.project.id);
  const campaignId = useAppSelector(
    (state) => state.storeSocialMediaCampaign.campaignSelected.id
  );

  const handleFinish = () => {
    updateFinishCampagin({
      variables: {
        projectId,
        objectIds: selectedObject.map((object) => object.objectModel.id),
        campaignId
      }
    }).then((response) => {
      dispatch(
        reducerUpdateSelectedCampaign(response.data.campaign_finishCampaignV2)
      );
    });
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCellHeader
                padding="checkbox"
                sx={{ width: '4%' }}
                align="center"
              >
                <Checkbox
                  indeterminate={
                    selectedObject.length > 0 &&
                    selectedObject.length < data.length
                  }
                  checked={selectedObject.length === data.length}
                  size="small"
                  icon={
                    <FilterNoneIcon
                      sx={{
                        color: theme.palette.common.white,
                        width: theme.spacing(2.5),
                        height: theme.spacing(2.5)
                      }}
                    />
                  }
                  checkedIcon={
                    <LibraryAddCheckIcon
                      sx={{
                        color: theme.palette.common.white,
                        width: theme.spacing(2.5),
                        height: theme.spacing(2.5)
                      }}
                    />
                  }
                  onChange={handleSelectAllClick}
                />
              </TableCellHeader>
              <TableCellHeader align="center" size="small">
                No.
              </TableCellHeader>
              <TableCellHeader align="left" size="small">
                Hashtag
              </TableCellHeader>
              <TableCellHeader align="center" size="small">
                Usage
              </TableCellHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <TableRow key={moment(row.createdAt).format('llll')}>
                  <TableCell align="center" size="small">
                    <Checkbox
                      color="primary"
                      checked={isSelected(row.objectModel.id)}
                      value={row.objectModel.id}
                      //   className={classes.z0}
                      onChange={() => handleSelect(row)}
                    />
                  </TableCell>
                  <TableCell align="center" size="small">
                    {index + 1}
                  </TableCell>
                  <TableCell align="left" size="small">
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Box>
                        <GetSocialMediaIcon socialMedia={row.objectModel.socialMedia} color='inherit' fontSize='small'/>
                      </Box>
                      <Typography variant="h4">
                        {row.objectModel.objectName}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="center" size="small">
                    Usage
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow hover={false}>
                <TableCell
                  align="center"
                  colSpan={4}
                  sx={height && { height: theme.spacing(height) }}
                >
                  {loading ? (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <CircularProgress disableShrink size={64} />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Avatar>N/A</Avatar>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider sx={{ my: theme.spacing(2) }} />
      <Box justifyContent="flex-end" display="flex">
        <Button
          variant="contained"
          color="primary"
          sx={{ backgroundColor: theme.palette.primary.dark }}
          size="large"
          onClick={handleFinish}
          disabled={resUpdateFinishCampagin.loading}
        >
          Finish now
        </Button>
      </Box>
    </Box>
  );
};

export default TableFinishHashtag;
