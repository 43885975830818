import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const len = 242;
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main
  },
  outlineLoader: {
    strokeDasharray: `${len * 0.01}, ${len}`,
    strokeDashoffset: 0,
    animation: `$anim 1.6s linear infinite`,
    stroke: "#ededed"
    //stroke: theme.palette.primary.main,
  },
  trackLoader: {
    //stroke: theme.palette.primary.main,
    stroke: "#ededed"
  },
  "@keyframes anim": {
    "12.5%": {
      strokeDasharray: `${len * 0.14}, ${len}`,
      strokeDashoffset: -len * 0.11,
    },
    "43.75%": {
      strokeDasharray: `${len * 0.35}, ${len}`,
      strokeDashoffset: `${-len * 0.35}`,
    },
    "100%": {
      strokeDasharray: `${len * 0.01}, ${len}`,
      strokeDashoffset: `${-len * 0.99}`,
    },
  },
  textLoading: {
    marginTop: theme.spacing(16),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

  }
}));

export default useStyles;
