import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IDataWidgetCustomDasboard,
  IWidgetMetaData,
  IDashboardObject
} from 'src/models/customDashboard';

interface IState {
  widgetMetaDataList: ApolloQueryResult<Array<IWidgetMetaData>>;
  widgetDataCustomDashboard: ApolloQueryResult<IDataWidgetCustomDasboard>;
  dashboardList: ApolloQueryResult<Array<IDashboardObject>>;
  dashboardSelectedById: string;
}

const initialState: IState = {
  widgetMetaDataList: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  dashboardList: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  widgetDataCustomDashboard: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  dashboardSelectedById: '_default'
};

export const customDashboard = createSlice({
  name: 'custom-dasboard',
  initialState,
  reducers: {
    reducerUpdateWidgetMetaDataList: (
      state: IState,
      action: PayloadAction<ApolloQueryResult<Array<IWidgetMetaData>>>
    ) => {
      state.widgetMetaDataList = action.payload;
    },
    reducerUpdateDashboardList: (
      state: IState,
      action: PayloadAction<ApolloQueryResult<Array<IDashboardObject>>>
    ) => {
      state.dashboardList = action.payload;
    },
    reducerUpdateDashboardListAfterDelete: (
      state: IState,
      action: PayloadAction<Array<IDashboardObject>>
    ) => {
      state.dashboardList.data = action.payload;
    },
    reducerUpdateObjectManagementDashboardSelectedById: (
      state: IState,
      action: PayloadAction<string | undefined>
    ) => {
      state.dashboardSelectedById = action.payload;
    }
  }
});

export const {
  reducerUpdateWidgetMetaDataList,
  reducerUpdateDashboardList,
  reducerUpdateObjectManagementDashboardSelectedById,
  reducerUpdateDashboardListAfterDelete
} = customDashboard.actions;

export default customDashboard.reducer;
