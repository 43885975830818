import { useApolloClient, useMutation } from '@apollo/client';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { GET_GROUP_PORTAL } from './graphql/groupPortal/query';
import {
  ADD_GROUP_PORTAL,
  DELETE_GROUP_PORTAL,
  EDIT_GROUP_PORTAL
} from './graphql/groupPortal/mutation';
import { IGroupPortal } from 'src/models/groupPortal';
import { updateGroupPortalList } from 'src/features/groupPortal';

interface IAddGroupPortalPayload {
  name: string;
  projectId: string;
  portals: string[];
}

interface IAddGroupPortalResponse {
  groupPortal_addGroupPortal: IGroupPortal;
}

interface IDeleteGroupPortalPayload {
  groupPortalId: string;
}

interface IDeleteGroupPortalResponse {
  groupPortal_deleteGroupPortal: IGroupPortal;
}

interface IEditGroupPortalPayload {
  groupPortalId: string;
  name: string;
  portals: string[];
  projectId: string;
}

interface IEditGroupPortalResponse {
  groupPortal_updateGroupPortal: IGroupPortal;
}

const useGroupPortal = () => {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const groupPortalList = useAppSelector(
    (state) => state.groupPortal.groupPortalList
  );

  const fetchGroupPortal = async (projectId: string) => {
    return await client
      .query({
        query: GET_GROUP_PORTAL,
        variables: {
          projectId
        }
      })
      .then((response) => {
        return { ...response, data: response.data.groupPortal_getGroupPortal };
      });
  };

  const getGroupPortal = async (projectId: string) => {
    dispatch(updateGroupPortalList({ ...groupPortalList, loading: true }));
    const response = await fetchGroupPortal(projectId);
    dispatch(updateGroupPortalList(response));
  };

  const [addGroupPortal, resAddGroupPortal] = useMutation<
    IAddGroupPortalResponse,
    IAddGroupPortalPayload
  >(ADD_GROUP_PORTAL);

  const [deleteGroupPortal, resDeleteGroupPortal] = useMutation<
    IDeleteGroupPortalResponse,
    IDeleteGroupPortalPayload
  >(DELETE_GROUP_PORTAL);

  const [editGroupPortal, resEditGroupPortal] = useMutation<
    IEditGroupPortalResponse,
    IEditGroupPortalPayload
  >(EDIT_GROUP_PORTAL);

  return {
    getGroupPortal,
    addGroupPortal,
    resAddGroupPortal,
    deleteGroupPortal,
    resDeleteGroupPortal,
    editGroupPortal,
    resEditGroupPortal
  };
};

export default useGroupPortal;
