/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { FC, useMemo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppSelector } from 'src/app/hooks';
import ChipSocialMediaObjectSelector from 'src/components/ChipSocialMediaObjectSelector';
import Empty from 'src/components/Empty';
import { IObject } from 'src/models/objectManagement';
import SearchIcon from '@mui/icons-material/Search';
import { useFiltersByName } from 'src/hooks/useFiltersByName';
import { useUnlimitedScroll } from 'src/hooks/useUnlimitedScroll';
import { useSelectItemDefaultSelected } from 'src/hooks/useSelectItemDefaultSelected';
import ModalManageObject from 'src/components/ModalManageObject';
import ModalAddKeywordAndHashtag from 'src/components/ModalAddKeywordAndHashtag';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

interface Props {
  objectList: Array<IObject>;
  onParentVisible: () => void;
  onSelect: (items: Array<string>) => void;
  objectListIdActive: Array<string>;
}

interface Item {
  name: string;
}

const SectionKeywordHashtag: FC<Props> = ({
  objectList,
  onParentVisible,
  onSelect,
  objectListIdActive
}) => {
  const theme = useTheme();

  const addName = useMemo((): Array<IObject & Item> => {
    return objectList.map((object) => ({
      ...object,
      name: object.objectName
    }));
  }, [objectList]);

  const { filteredList, handleFilter } = useFiltersByName<IObject>(addName);
  const { groupSelectedById } = useAppSelector(
    (state) => state.objectManagement
  );

  const { fetchMoreData, infiniteScroll } =
    useUnlimitedScroll<IObject>(filteredList);

  const { selectedItemByIds, handleClick, isSelected, handleSelectAll } =
    useSelectItemDefaultSelected<IObject>(filteredList, objectListIdActive);

  const { isCheckedAll, isIndeterminate } = useMemo(() => {
    const checkedItems = filteredList.filter(
      (el) => selectedItemByIds.indexOf(el.id) !== -1
    );
    const isCheckedAll = filteredList.length === checkedItems.length;
    const isIndeterminate =
      checkedItems.length > 0 && checkedItems.length < filteredList.length;

    return { isCheckedAll, isIndeterminate };
  }, [filteredList, selectedItemByIds]);

  useEffect(() => {
    onSelect(selectedItemByIds);
  }, [selectedItemByIds]);
  return (
    <>
      <DialogTitle
        sx={{
          m: theme.spacing(3, 3, 1),
          p: theme.spacing(2, 3, 0, 3)
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">Keyword & Hashtag</Typography>
          {groupSelectedById === '_default' ? (
            <ModalAddKeywordAndHashtag
              title="Hashtag & Keyword"
              onHide={onParentVisible}
              objectList={objectList}
            />
          ) : null}
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          m: theme.spacing(1, 3)
        }}
      >
        <Stack direction="row" spacing={2} sx={{ mb: theme.spacing(1) }}>
          <Box sx={{ minWidth: theme.spacing(15) }}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<FilterNoneIcon />}
                  checkedIcon={<LibraryAddCheckIcon />}
                  indeterminateIcon={<IndeterminateCheckBoxIcon />}
                  indeterminate={isIndeterminate}
                />
              }
              label="Select All"
              onChange={handleSelectAll}
              checked={isCheckedAll}
            />
          </Box>
          <TextField
            fullWidth
            id="standard-basic"
            placeholder="Search Profile"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            sx={{
              mb: theme.spacing(3)
            }}
            onChange={(e) => handleFilter(e.target.value)}
          />
        </Stack>
        <InfiniteScroll
          dataLength={infiniteScroll.list.length}
          next={fetchMoreData}
          hasMore={infiniteScroll.hasMore}
          loader={<div />}
          height={104}
          endMessage={<div />}
        >
          {infiniteScroll.list.length > 0 ? (
            infiniteScroll.list.map(
              ({
                id,
                content,
                objectName,
                socialMedia,
                isHashtag,
                streamType
              }) => {
                const isItemSelected = !isSelected(id);
                return (
                  <ChipSocialMediaObjectSelector
                    key={`${id}${content}`}
                    id={content}
                    name={objectName}
                    socialMedia={socialMedia}
                    streamType={isHashtag ? 'hashtag' : streamType}
                    sx={{ m: '0 8px 8px 0' }}
                    onClick={(event) => handleClick(event, id)}
                    checked={isItemSelected}
                  />
                );
              }
            )
          ) : (
            <Empty />
          )}
        </InfiniteScroll>
      </DialogContent>
    </>
  );
};

export default SectionKeywordHashtag;
