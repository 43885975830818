/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CloseIcon from '@mui/icons-material/Close';
import { useUnlimitedScroll } from 'src/hooks/useUnlimitedScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IGender } from 'src/models/people';
import { genderItem } from 'src/content/streamPanel/peoplePanel/components/SelectGender';

interface ModalSelectGenderProps {
  onClose: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  open: boolean;
  prevSelectedGender?: Array<IGender>;
  onSubmit: (selectedGender: Array<IGender>) => void;
}

const TableCellHeader = styled(TableCell)(
  ({ theme }) => `
        z-index: 3;
        background: ${theme.palette.primary.main};
        color: ${theme.palette.common.white};
    `
);

const ModalSelectGender: React.FC<ModalSelectGenderProps> = ({
  open,
  onClose,
  onSubmit,
  prevSelectedGender = []
}) => {
  const [selectedGendersLocal, setSelectedGendersLocal] =
    useState<Array<IGender>>(prevSelectedGender);
  const theme = useTheme();

  const isSelected = (id: string) =>
    selectedGendersLocal.findIndex((obj) => obj.id === id) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedGendersLocal(genderItem);
      return;
    }
    setSelectedGendersLocal([]);
  };

  const handleClickLabel = function (gender: IGender) {
    const selectedIndex = selectedGendersLocal.findIndex(
      (obj) => obj.id === gender.id
    );
    let newSelected: IGender[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedGendersLocal, gender);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedGendersLocal.slice(1));
    } else if (selectedIndex === selectedGendersLocal.length - 1) {
      newSelected = newSelected.concat(selectedGendersLocal.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedGendersLocal.slice(0, selectedIndex),
        selectedGendersLocal.slice(selectedIndex + 1)
      );
    }
    setSelectedGendersLocal(newSelected);
  };

  const { fetchMoreData, infiniteScroll } =
    useUnlimitedScroll<IGender>(genderItem);

  useEffect(() => {
    if (!prevSelectedGender) return;
    setSelectedGendersLocal(prevSelectedGender);
  }, [prevSelectedGender]);

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={'md'}>
      <DialogTitle
        sx={{
          m: theme.spacing(3, 3, 1)
        }}
      >
        <IconButton
          aria-label="close"
          onClick={(e) => onClose(e)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          Select Gender
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          m: theme.spacing(1, 3)
        }}
      >
        <InfiniteScroll
          dataLength={infiniteScroll.list.length}
          next={fetchMoreData}
          hasMore={infiniteScroll.hasMore}
          loader={<div />}
          height={300}
          endMessage={<div />}
        >
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCellHeader padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedGendersLocal.length > 0 &&
                        selectedGendersLocal.length < genderItem.length
                      }
                      size="small"
                      sx={{ ml: theme.spacing(0.2) }}
                      icon={
                        <FilterNoneIcon
                          sx={{
                            color: theme.palette.common.white,
                            width: theme.spacing(2.3),
                            height: theme.spacing(2.3)
                          }}
                        />
                      }
                      checkedIcon={
                        <LibraryAddCheckIcon
                          sx={{
                            color: theme.palette.common.white,
                            width: theme.spacing(2.3),
                            height: theme.spacing(2.3)
                          }}
                        />
                      }
                      indeterminateIcon={
                        <IndeterminateCheckBoxIcon
                          sx={{
                            color: theme.palette.common.white,
                            width: theme.spacing(2.2),
                            height: theme.spacing(2.2)
                          }}
                        />
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCellHeader>
                  <TableCellHeader align="left" size="small">
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: 13, color: theme.palette.common.white }}
                    >
                      Gender list
                    </Typography>
                  </TableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {infiniteScroll.list.length > 0 ? (
                  infiniteScroll.list.map((gender) => {
                    const isChecked = isSelected(gender.id);
                    return (
                      <TableRow key={gender.id}>
                        <TableCell align="center" size="small">
                          <FormControlLabel
                            sx={{ marginLeft: 0 }}
                            control={
                              <Checkbox
                                size="small"
                                checked={isChecked}
                                onChange={(e) => {
                                  handleClickLabel(gender);
                                }}
                                name="labels"
                                color="primary"
                                value={gender.id}
                              />
                            }
                            label=""
                          />
                        </TableCell>
                        <TableCell align="left" size="small">
                          {gender.label}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow hover={false}>
                    <TableCell align="center" colSpan={2}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Avatar>N/A</Avatar>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            size="large"
            onClick={() => onSubmit(selectedGendersLocal)}
            sx={{ width: theme.spacing(20), mt: theme.spacing(2) }}
          >
            Done
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSelectGender;
