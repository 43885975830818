import { Box, Stack, styled, Typography } from '@mui/material';
import React from 'react';

interface ColorLabelPickerProps {
  color: string;
  isActive: boolean;
  label: string;
  onClick: (color: string) => void;
}

interface IContainer {
  isActive: boolean;
}

interface ICircle {
  colorCircle: string;
}

const Container = styled(Box)<IContainer>(
  ({ theme, isActive }) => `
      cursor: pointer;
      height: ${theme.spacing(4)};
      width: 100%;
      background: #E7F3F7;
      border: ${
        !isActive ? '1px solid rgba(0, 159, 227, 0.2)' : '2px solid #009FE3'
      };
      box-sizing: border-box;
      border-radius: ${theme.spacing(1)};
      padding: ${theme.spacing(1)};
    `
);

const CircleColor = styled(Box)<ICircle>(
  ({ theme, colorCircle }) => `
    height: ${theme.spacing(2)};
    width: ${theme.spacing(2)};
    background-color: ${colorCircle};
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #064E66;
    box-sizing: border-box;
    `
);

const ColorLabelPicker: React.FC<ColorLabelPickerProps> = ({
  color,
  isActive,
  label,
  onClick
}) => {
  return (
    <Container isActive={isActive} onClick={() => onClick(color)}>
      <Stack direction="row" spacing={1} alignItems="center">
        <CircleColor colorCircle={color} />
        <Typography
          variant="subtitle2"
          sx={{ fontSize: 13, fontWeight: isActive ? 600 : 'normal' }}
        >
          {label}
        </Typography>
      </Stack>
    </Container>
  );
};

export default ColorLabelPicker;
