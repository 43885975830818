import { alpha, createTheme, lighten, darken } from '@mui/material';
import '@mui/lab/themeAugmentation';

const theme = createTheme();
export const defaultPrimary = {
  lighter: lighten('#E7F3F7', 0.85),
  light: '#E7F3F7',
  main: '#064E66',
  dark: '#051C26'
};

export const pdipPrimary = {
  lighter: '#F3F3F3',
  light: '#F0F5F9',
  main: '#A70000',
  dark: '#010101'
};

export const kemhanPrimary = {
  lighter: '#FBFBFB',
  light: '#F5F5F5',
  main: '#C2A252',
  dark: '#594024'
};

export const baisPrimary = {
  lighter: '#FFFFFF',
  light: '#F0F5F9',
  main: '#064E66',
  dark: '#010101'
};

export const kadencePrimary = {
  lighter: '#FFFFFF',
  light: '#F0F5F9',
  main: '#70A143',
  dark: '#F26A2C'
};

export const kompasPrimary = {
  lighter: '#FFFFFF',
  light: '#F0F5F9',
  main: '#005288',
  dark: '#EF7721'
};

export const skemaPrimary = {
  lighter: '#FFFFFF',
  light: '#F0F5F9',
  main: '#07265D',
  dark: '#1999DC'
};

export const gcommPrimary = {
  lighter: '#FFFFFF',
  light: '#F0F5F9',
  main: '#275EAC',
  dark: '#231F20'
};

export const plnPrimary = {
  lighter: '#FFFFFF',
  light: '#F0F5F9',
  dark: '#00A2B9',
  main: '#035B71'
};

export const tniAuPrimary = {
  lighter: '#FFFFFF',
  light: '#F0F5F9',
  main: '#012D50',
  dark: '#FBB414'
};

export const diyPrimary = {
  lighter: '#FFFFFF',
  light: '#F0F5F9',
  main: '#C0392B',
  dark: '#222222'
};

export const bbdoPrimary = {
  lighter: '#FFFFFF',
  light: '#F0F5F9',
  main: '#222222',
  dark: '#E94325'
};

const getPrimaryColors = () => {
  if (window.location.hostname.includes('pdip')) return pdipPrimary;
  if (window.location.hostname.includes('kemhan')) return kemhanPrimary;
  if (window.location.hostname.includes('legasysolution')) return baisPrimary;
  if (window.location.hostname.includes('kadence')) return kadencePrimary;
  if (window.location.hostname.includes('skema')) return skemaPrimary;
  if (window.location.hostname.includes('gcomm')) return gcommPrimary;
  if (window.location.hostname.includes('ps-techno')) return plnPrimary;
  if (window.location.hostname.includes('tni-au')) return tniAuPrimary;
  if (window.location.hostname.includes('elingjogja')) return diyPrimary;
  if (window.location.hostname.includes('bbdo')) return bbdoPrimary;
  return defaultPrimary;
};

export const themeColors = {
  primary: '#5569ff',
  secondary: '#6E759F',
  success: '#44D600',
  warning: '#E35200',
  error: '#FF1943',
  info: '#33C2FF',
  black: '#051C26',
  white: '#ffffff',
  primaryAlt: '#000C57',
  darkerGray: '#4B4B4B',
  darkTeal: '#0B85AE',
  gray: '#9D9D9C',
  blue: '#009FE3',
  tiktok: '#010101',
  linkedin: '#0A66C2'
};

export const colors = {
  gradients: {
    blue1: 'linear-gradient(92.9deg, #47A3E6 -4.64%, #54CEE9 101.13%)',
    blue2: 'linear-gradient(92.9deg, #47A3E6 -4.64%, #043747 101.13%)',
    blue3: 'linear-gradient(127.55deg, #141E30 3.73%, #243B55 92.26%)',
    orange1: 'linear-gradient(135deg, #FCCF31 0%, #F55555 100%)',
    orange2: 'linear-gradient(135deg, #FFD3A5 0%, #FD6585 100%)',
    purple1: 'linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)',
    pink1: 'linear-gradient(135deg, #F6CEEC 0%, #D939CD 100%)',
    pink2: 'linear-gradient(135deg, #F761A1 0%, #8C1BAB 100%)',
    green1: 'linear-gradient(135deg, #FFF720 0%, #3CD500 100%)',
    black1: 'linear-gradient(100.66deg, #434343 6.56%, #000000 93.57%)',
    background:
      'linear-gradient(103.76deg, rgba(221, 245, 255, 0.04) -12.1%, rgba(193, 254, 250, 0.04) 14.9%, rgba(193, 236, 254, 0.04) 74.97%, rgba(193, 236, 254, 0.04) 108.76%)'
  },
  shadows: {
    success:
      '0px 1px 4px rgba(68, 214, 0, 0.25), 0px 3px 12px 2px rgba(68, 214, 0, 0.35)',
    error:
      '0px 1px 4px rgba(255, 25, 67, 0.25), 0px 3px 12px 2px rgba(255, 25, 67, 0.35)',
    info: '0px 1px 4px rgba(51, 194, 255, 0.25), 0px 3px 12px 2px rgba(51, 194, 255, 0.35)',
    primary:
      '0px 1px 4px rgba(85, 105, 255, 0.25), 0px 3px 12px 2px rgba(85, 105, 255, 0.35)',
    warning:
      '0px 1px 4px rgba(255, 163, 25, 0.25), 0px 3px 12px 2px rgba(255, 163, 25, 0.35)',
    card: '0px 2px 3px rgb(159 162 191 / 18%), 0px 1px 1px rgb(159 162 191 / 32%)',
    cardSm:
      '0px 2px 3px rgba(159, 162, 191, 0.18), 0px 1px 1px rgba(159, 162, 191, 0.32)',
    cardLg:
      '0 5rem 14rem 0 rgb(255 255 255 / 30%), 0 0.8rem 2.3rem rgb(0 0 0 / 60%), 0 0.2rem 0.3rem rgb(0 0 0 / 45%)',
    cardNtmy: '0px 2px 90px 12px rgba(5, 29, 61, 0.08)'
  },
  layout: {
    general: {
      bodyBg: '#ffffff'
    },
    sidebar: {
      background: themeColors.black,
      textColor: themeColors.secondary,
      dividerBg: '#f2f5f9',
      menuItemColor: '#242E6F',
      menuItemColorActive: themeColors.primary,
      menuItemBg: 'transparent',
      menuItemBgActive: '#f2f5f9',
      menuItemIconColor: lighten(themeColors.secondary, 0.3),
      menuItemIconColorActive: themeColors.primary,
      menuItemHeadingColor: darken(themeColors.secondary, 0.3)
    }
  },
  alpha: {
    white: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white
    },
    trueWhite: {
      5: alpha(themeColors.white, 0.02),
      10: alpha(themeColors.white, 0.1),
      30: alpha(themeColors.white, 0.3),
      50: alpha(themeColors.white, 0.5),
      70: alpha(themeColors.white, 0.7),
      100: themeColors.white
    },
    black: {
      5: alpha(themeColors.black, 0.02),
      10: alpha(themeColors.black, 0.1),
      30: alpha(themeColors.black, 0.3),
      50: alpha(themeColors.black, 0.5),
      70: alpha(themeColors.black, 0.7),
      100: themeColors.black
    }
  },
  secondary: {
    lighter: lighten(themeColors.secondary, 0.85),
    light: lighten(themeColors.secondary, 0.25),
    main: themeColors.secondary,
    dark: darken(themeColors.secondary, 0.2)
  },
  primary: getPrimaryColors(),
  success: {
    lighter: lighten(themeColors.success, 0.85),
    light: lighten(themeColors.success, 0.3),
    main: themeColors.success,
    dark: darken(themeColors.success, 0.2)
  },
  warning: {
    lighter: lighten(themeColors.warning, 0.85),
    light: lighten(themeColors.warning, 0.3),
    main: themeColors.warning,
    dark: darken(themeColors.warning, 0.2)
  },
  error: {
    lighter: lighten(themeColors.error, 0.85),
    light: lighten(themeColors.error, 0.3),
    main: themeColors.error,
    dark: darken(themeColors.error, 0.2)
  },
  info: {
    lighter: lighten(themeColors.info, 0.85),
    light: lighten(themeColors.info, 0.3),
    main: themeColors.info,
    dark: darken(themeColors.info, 0.2)
  }
};

const defaultWhiteLabel = {
  sidebar: {
    background: defaultPrimary.dark,
    textColor: themeColors.secondary,
    dividerBg: '#4B4B4B',
    menuItemColor: '#242E6F',
    menuItemColorActive: themeColors.blue,
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background:
      'linear-gradient(rgba(221, 245, 255, 0.16), rgba(193, 254, 250, 0.16), rgba(193, 236, 254, 0.16));',
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient:
    'linear-gradient(90deg, rgba(221, 245, 255, 0.3) 0%, rgba(193, 254, 250, 0.3) 50.65%, rgba(193, 236, 254, 0.3) 100%)'
};

const pdipWhiteLabel = {
  sidebar: {
    background: pdipPrimary.dark,
    textColor: themeColors.secondary,
    dividerBg: '#f2f5f9',
    menuItemColor: '#242E6F',
    menuItemColorActive: '#ffff',
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background: colors.primary.light,
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient: '#ffff'
};

const kemhanWhiteLabel = {
  sidebar: {
    background: kemhanPrimary.dark,
    textColor: themeColors.secondary,
    dividerBg: '#f2f5f9',
    menuItemColor: '#242E6F',
    menuItemColorActive: kemhanPrimary.main,
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background: colors.primary.light,
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), linear-gradient(90deg, #F7F3ED -131.56%, #F0E6DB 1.38%, #D1B594 130.94%)'
};

const baisWhiteLabel = {
  sidebar: {
    background: pdipPrimary.dark,
    textColor: themeColors.secondary,
    dividerBg: '#f2f5f9',
    menuItemColor: '#242E6F',
    menuItemColorActive: themeColors.blue,
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background: colors.primary.light,
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient:
    'linear-gradient(90deg, rgba(221, 245, 255, 0.3) 0%, rgba(193, 254, 250, 0.3) 50.65%, rgba(193, 236, 254, 0.3) 100%)'
};

const kadenceWhiteLabel = {
  sidebar: {
    background: defaultPrimary.dark,
    textColor: themeColors.secondary,
    dividerBg: '#f2f5f9',
    menuItemColor: '#242E6F',
    menuItemColorActive: colors.primary.main,
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background:
      'linear-gradient(90deg, #F2F8ED 0%, #CBE1B7 50.65%, #98C36F 100%)',
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient: '#F2F8ED'
};

const kompasWhiteLabel = {
  sidebar: {
    background: '#000F1A',
    textColor: themeColors.secondary,
    dividerBg: '#f2f5f9',
    menuItemColor: '#242E6F',
    menuItemColorActive: '#EF7721',
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background:
      'linear-gradient(90deg, #E5F5FF 0%, #CCEBFF 50.65%, #99D6FF 100%)',
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient: '#E5F5FF'
};

const skemaWhiteLabel = {
  sidebar: {
    background: '#000F1A',
    textColor: themeColors.secondary,
    dividerBg: '#f2f5f9',
    menuItemColor: '#242E6F',
    menuItemColorActive: colors.primary.dark,
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background:
      'linear-gradient(90deg, #E5F5FF 0%, #0398AE 50.65%, #07265D 100%)',
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient: '#E5F5FF'
};

const gcommWhiteLabel = {
  sidebar: {
    background: gcommPrimary.dark,
    textColor: themeColors.secondary,
    dividerBg: '#f2f5f9',
    menuItemColor: '#242E6F',
    menuItemColorActive: '#0D81C8',
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background:
      'linear-gradient(90deg, rgba(229, 245, 255, 0.80) -20.26%, rgba(13, 129, 200, 0.80) 100%);',
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient: '#E5F5FF'
};

const plnWhiteLabel = {
  sidebar: {
    background: '#000F1A',
    textColor: themeColors.secondary,
    dividerBg: '#f2f5f9',
    menuItemColor: '#242E6F',
    menuItemColorActive: plnPrimary.dark,
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background:
      'linear-gradient(90deg, rgba(0, 162, 185, 0.32) 5.77%, rgba(0, 162, 185, 0.535471) 49.06%, rgba(0, 162, 185, 0.8) 93.13%, rgba(3, 91, 113, 0.8) 149.38%)',
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient: 'rgba(0, 162, 185, 0.2)'
};

const tniAuWhiteLabel = {
  sidebar: {
    background: tniAuPrimary.main,
    textColor: themeColors.secondary,
    dividerBg: '#f2f5f9',
    menuItemColor: '#242E6F',
    menuItemColorActive: '#FBB414',
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background:
      'linear-gradient(90deg, #E5F5FF 0%, #CCEBFF 50.65%, #99D6FF 100%)',
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient: '#E5F5FF'
};

const diyWhiteLabel = {
  sidebar: {
    background: diyPrimary.dark,
    textColor: themeColors.secondary,
    dividerBg: '#4B4B4B',
    menuItemColor: '#242E6F',
    menuItemColorActive: diyPrimary.main,
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background:
      'linear-gradient(rgba(221, 245, 255, 0.16), rgba(193, 254, 250, 0.16), rgba(193, 236, 254, 0.16));',
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient:
    'linear-gradient(90deg, rgba(221, 245, 255, 0.3) 0%, rgba(193, 254, 250, 0.3) 50.65%, rgba(193, 236, 254, 0.3) 100%)'
};

const bbdoWhiteLabel = {
  sidebar: {
    background: bbdoPrimary.main,
    textColor: themeColors.secondary,
    dividerBg: '#4B4B4B',
    menuItemColor: '#242E6F',
    menuItemColorActive: bbdoPrimary.dark,
    menuItemBg: 'transparent',
    menuItemBgActive: '#f2f5f9',
    menuItemIconColor: lighten(themeColors.secondary, 0.3),
    menuItemIconColorActive: themeColors.primary,
    menuItemHeadingColor: darken(themeColors.secondary, 0.3)
  },
  header: {
    height: '88px',
    background:
      'linear-gradient(90deg, rgba(255, 255, 255, 0.2), rgba(233, 67, 37, 0.2), rgba(233, 67, 37, 0.2));',
    boxShadow: colors.shadows.cardSm,
    textColor: colors.secondary.main
  },
  cardGradient: 'rgba(234, 234, 234, 1)'
};

const getCurrentWhiteLabel = () => {
  if (window.location.hostname.includes('pdip')) return pdipWhiteLabel;
  if (window.location.hostname.includes('kemhan')) return kemhanWhiteLabel;
  if (window.location.hostname.includes('legasysolution'))
    return baisWhiteLabel;
  if (window.location.hostname.includes('kadence')) return kadenceWhiteLabel;
  if (window.location.hostname.includes('kompas')) return kompasWhiteLabel;
  if (window.location.hostname.includes('skema')) return skemaWhiteLabel;
  if (window.location.hostname.includes('gcomm')) return gcommWhiteLabel;
  if (window.location.hostname.includes('ps-techno')) return plnWhiteLabel;
  if (window.location.hostname.includes('tni-au')) return tniAuWhiteLabel;
  if (window.location.hostname.includes('elingjogja')) return diyWhiteLabel;
  if (window.location.hostname.includes('bbdo')) return bbdoWhiteLabel;
  return defaultWhiteLabel;
};

const currentWhiteLabel = getCurrentWhiteLabel();

export const PureLightTheme = createTheme({
  // direction: i18n.dir(),
  transitions: {
    // So `transition: none;` gets applied everywhere
    create: () => 'none'
  },
  colors: {
    gradients: {
      blue1: colors.gradients.blue1,
      blue2: colors.gradients.blue2,
      blue3: colors.gradients.blue3,
      orange1: colors.gradients.orange1,
      orange2: colors.gradients.orange2,
      purple1: colors.gradients.purple1,
      pink1: colors.gradients.pink1,
      pink2: colors.gradients.pink2,
      green1: colors.gradients.green1,
      black1: colors.gradients.black1,
      background: colors.gradients.background
    },
    shadows: {
      success: colors.shadows.success,
      error: colors.shadows.error,
      primary: colors.shadows.primary,
      warning: colors.shadows.warning
    },
    alpha: {
      white: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white
      },
      trueWhite: {
        5: alpha(themeColors.white, 0.02),
        10: alpha(themeColors.white, 0.1),
        30: alpha(themeColors.white, 0.3),
        50: alpha(themeColors.white, 0.5),
        70: alpha(themeColors.white, 0.7),
        100: themeColors.white
      },
      black: {
        5: alpha(themeColors.black, 0.02),
        10: alpha(themeColors.black, 0.1),
        30: alpha(themeColors.black, 0.3),
        50: alpha(themeColors.black, 0.5),
        70: alpha(themeColors.black, 0.7),
        100: themeColors.black
      }
    },
    secondary: {
      lighter: alpha(themeColors.secondary, 0.1),
      light: lighten(themeColors.secondary, 0.3),
      main: themeColors.secondary,
      dark: darken(themeColors.secondary, 0.2)
    },
    primary: colors.primary,
    success: {
      lighter: alpha(themeColors.success, 0.1),
      light: lighten(themeColors.success, 0.3),
      main: themeColors.success,
      dark: darken(themeColors.success, 0.2)
    },
    warning: {
      lighter: alpha(themeColors.warning, 0.1),
      light: lighten(themeColors.warning, 0.3),
      main: themeColors.warning,
      dark: darken(themeColors.warning, 0.2)
    },
    error: {
      lighter: alpha(themeColors.error, 0.1),
      light: lighten(themeColors.error, 0.3),
      main: themeColors.error,
      dark: darken(themeColors.error, 0.2)
    },
    info: {
      lighter: alpha(themeColors.info, 0.1),
      light: lighten(themeColors.info, 0.3),
      main: themeColors.info,
      dark: darken(themeColors.info, 0.2)
    }
  },

  general: {
    reactFrameworkColor: '#00D8FF',
    borderRadiusSm: '4px',
    borderRadius: '6px',
    borderRadiusLg: '10px',
    borderRadiusXl: '18px'
  },
  sidebar: {
    ...currentWhiteLabel.sidebar,
    boxShadow:
      '2px 0 3px rgba(159, 162, 191, 0.18), 1px 0 1px rgba(159, 162, 191, 0.32)',
    width: '248px'
  },
  header: currentWhiteLabel.header,
  cardGradient: currentWhiteLabel.cardGradient,
  spacing: 8,
  palette: {
    common: {
      black: themeColors.black,
      white: colors.alpha.white[100]
    },
    mode: 'light',
    primary: {
      light: colors.primary.light,
      main: colors.primary.main,
      dark: colors.primary.dark
    },
    secondary: {
      light: colors.secondary.light,
      main: colors.secondary.main,
      dark: colors.secondary.dark
    },
    error: {
      light: colors.error.light,
      main: colors.error.main,
      dark: colors.error.dark,
      contrastText: colors.alpha.white[100]
    },
    success: {
      light: colors.success.light,
      main: colors.success.main,
      dark: colors.success.dark,
      contrastText: colors.alpha.white[100]
    },
    info: {
      light: colors.info.light,
      main: colors.info.main,
      dark: colors.info.dark,
      contrastText: colors.alpha.white[100]
    },
    warning: {
      light: colors.warning.light,
      main: colors.warning.main,
      dark: colors.warning.dark,
      contrastText: colors.alpha.white[100]
    },
    grey: {
      50: '#FBFBFB',
      100: '#F3F5F6',
      200: '#E8EAED',
      300: '#DCE0E5',
      400: '#bdbdbd',
      500: '#9D9D9C',
      600: '#757575',
      700: '#616161',
      800: themeColors.darkerGray,
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    },
    text: {
      primary: colors.alpha.black[100],
      secondary: colors.alpha.black[70],
      disabled: colors.alpha.black[50]
    },
    background: {
      paper: colors.alpha.white[100],
      default: colors.layout.general.bodyBg
    },
    action: {
      active: colors.alpha.black[100],
      hover: colors.primary.lighter,
      hoverOpacity: 0.1,
      selected: colors.alpha.black[10],
      selectedOpacity: 0.1,
      disabled: colors.alpha.black[50],
      disabledBackground: colors.alpha.black[5],
      disabledOpacity: 0.38,
      focus: colors.alpha.black[10],
      focusOpacity: 0.05,
      activatedOpacity: 0.12
    },
    tonalOffset: 0.5
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1840
    }
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(darken(themeColors.primaryAlt, 0.4), 0.2),
          backdropFilter: 'blur(2px)',

          '&.MuiBackdrop-invisible': {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(2px)'
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          marginLeft: 8,
          marginRight: 8,
          fontWeight: 'bold'
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          width: '100%'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        'html, body, #root': {
          width: '100%',
          height: '100%'
        },
        '#nprogress .bar': {
          background: colors.primary.main
        },
        '#nprogress .spinner-icon': {
          borderTopColor: colors.primary.main,
          borderLeftColor: colors.primary.main
        },
        '#nprogress .peg': {
          boxShadow:
            '0 0 10px ' +
            colors.primary.main +
            ', 0 0 5px' +
            colors.primary.main
        },
        ':root': {
          '--swiper-theme-color': colors.primary.main
        },
        code: {
          background: colors.info.lighter,
          color: colors.info.dark,
          borderRadius: 4,
          padding: 4
        },
        '@keyframes ripple': {
          '0%': {
            transform: 'scale(.8)',
            opacity: 1
          },
          '100%': {
            transform: 'scale(2.8)',
            opacity: 0
          }
        },
        '@keyframes float': {
          '0%': {
            transform: 'translate(0%, 0%)'
          },
          '100%': {
            transform: 'translate(3%, 3%)'
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        iconOutlined: {
          color: colors.alpha.black[50]
        },
        icon: {
          top: 'calc(50% - 14px)'
        },
        select: {
          padding: '12.21px 16px',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '13px',
          lineHeight: '120%',
          letterSpacing: '0.02em',
          minHeight: 'unset',
          textAlign: 'left'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          '& .MuiInputAdornment-positionEnd.MuiInputAdornment-outlined': {
            paddingRight: 6
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.alpha.black[50]
          },
          '&.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primary.main
          },
          overflow: 'hidden'
        },
        input: {
          padding: '8px 14px 9px',
          maxHeight: 40,
          background: '#FFFFFF'
        }
      }
    },
    MuiListSubheader: {
      styleOverrides: {
        colorPrimary: {
          fontWeight: 'bold',
          lineHeight: '40px',
          fontSize: 13,
          background: colors.alpha.black[5],
          color: colors.alpha.black[70]
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          marginTop: -5,
          marginBottom: -5
        },
        title: {
          fontSize: 15
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          borderRadius: '50px',
          padding: 'unset'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        label: {
          paddingLeft: 0,
          margin: '0 0 0 8px'
        },
        colorSecondary: {
          background: colors.alpha.black[5],
          color: colors.alpha.black[100],

          '&:hover': {
            background: colors.alpha.black[10]
          }
        },
        deleteIcon: {
          color: colors.error.light,

          '&:hover': {
            color: colors.error.main
          }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',

          '&.Mui-expanded': {
            margin: 0
          },
          '&::before': {
            display: 'none'
          }
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 'bold'
        },
        colorDefault: {
          background: colors.alpha.black[30],
          color: colors.alpha.white[100]
        }
      }
    },
    MuiAvatarGroup: {
      styleOverrides: {
        root: {
          alignItems: 'center'
        },
        avatar: {
          background: colors.alpha.black[10],
          fontSize: 13,
          color: colors.alpha.black[70],
          fontWeight: 'bold',

          '&:first-of-type': {
            border: 0,
            background: 'transparent'
          }
        }
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        alignItemsFlexStart: {
          marginTop: 0
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          fontSize: 13,
          fontWeight: 'bold',
          transition: 'all .2s'
        },
        textPrimary: {
          '&.Mui-selected': {
            boxShadow: colors.shadows.primary
          },
          '&.MuiButtonBase-root:hover': {
            background: colors.alpha.black[5]
          },
          '&.Mui-selected.MuiButtonBase-root:hover': {
            background: colors.primary.main
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          fontWeight: 500,
          textTransform: 'none',
          paddingLeft: 16,
          paddingRight: 16,
          '.MuiSvgIcon-root': {
            transition: 'all .2s'
          },
          borderRadius: '100px'
        },
        endIcon: {
          marginRight: -8
        },
        containedSecondary: {
          backgroundColor: colors.secondary.main,
          color: colors.alpha.white[100],
          border: '1px solid ' + colors.alpha.black[30]
        },
        outlinedSecondary: {
          backgroundColor: colors.alpha.white[100],

          '&:hover, &.MuiSelected': {
            backgroundColor: colors.alpha.black[5],
            color: colors.alpha.black[100]
          }
        },
        outlined: {
          color: themeColors.black,
          border: `1px solid ${themeColors.black}`,
          fontWeight: 'bold'
        },
        sizeLarge: {
          height: 52
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          borderRadius: 6
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          minWidth: 360
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontWeight: 'bold',
          padding: '32px 8px 8px 8px'
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: 13,
          fontWeight: 'normal',
          color: '#9D9D9C',
          padding: '20px!important'
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          padding: '0 8px 24px 8px'
        }
      }
    },

    MuiToggleButton: {
      defaultProps: {
        disableRipple: true
      },
      styleOverrides: {
        root: {
          color: colors.primary.main,
          background: colors.alpha.white[100],
          transition: 'all .2s',

          '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            color: colors.alpha.white[100],
            background: colors.primary.main
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: '50%',

          '& .MuiTouchRipple-root': {
            borderRadius: 6
          }
        },
        sizeSmall: {
          padding: 4
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          margin: 0
        }
      }
    },

    MuiDivider: {
      styleOverrides: {
        root: {
          background: colors.alpha.black[10],
          border: 0,
          height: 1
        },
        vertical: {
          height: 'auto',
          width: 1,

          '&.MuiDivider-flexItem.MuiDivider-fullWidth': {
            height: 'auto'
          },
          '&.MuiDivider-absolute.MuiDivider-fullWidth': {
            height: '100%'
          }
        },
        withChildren: {
          '&:before, &:after': {
            border: 0
          }
        },
        wrapper: {
          background: colors.alpha.white[100],
          fontWeight: 'bold',
          height: 24,
          lineHeight: '24px',
          marginTop: -12,
          color: 'inherit',
          textTransform: 'uppercase'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0,
          border: '1px solid rgba(75, 75, 75, 0.08)'
        },
        elevation0: {
          boxShadow: 'none'
        },
        elevation: {
          boxShadow: colors.shadows.card
        },
        elevation2: {
          boxShadow: colors.shadows.cardSm
        },
        elevation24: {
          boxShadow: colors.shadows.cardLg
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          height: 4
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '& .MuiSlider-valueLabelCircle, .MuiSlider-valueLabelLabel': {
            transform: 'none'
          },
          '& .MuiSlider-valueLabel': {
            borderRadius: 6,
            background: colors.alpha.black[100],
            color: colors.alpha.white[100]
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,

          '& .MuiListItem-button': {
            transition: 'all .2s',

            '& > .MuiSvgIcon-root': {
              minWidth: 34
            },

            '& .MuiTouchRipple-root': {
              opacity: 0.2
            }
          },
          '& .MuiListItem-root.MuiButtonBase-root.Mui-selected': {
            backgroundColor: colors.alpha.black[10]
          }
        },
        padding: {
          padding: '12px',

          '& .MuiListItem-button': {
            borderRadius: 6,
            margin: '1px 0'
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
        indicator: {
          height: 3
          // border: '1px solid #009FE3'
        },
        scrollableX: {
          overflow: 'visible !important'
        }
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 38,
          minHeight: 38,
          borderRadius: 6,
          transition: 'color .2s',
          textTransform: 'capitalize',
          color: '#4B4B4B',

          '&.MuiButtonBase-root': {
            minWidth: 'auto',
            padding: '8px 8px 0',
            marginRight: 4
          },
          '&.Mui-selected, &.Mui-selected:hover': {
            color: '#051C26',
            zIndex: 5
          },
          '&:hover': {
            color: colors.alpha.black[100]
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          padding: '16px'
        },
        list: {
          padding: 0,

          '& .MuiMenuItem-root.MuiButtonBase-root': {
            fontSize: 14,
            marginTop: 1,
            marginBottom: 1,
            transition: 'all .2s',
            color: colors.alpha.black[70],

            '& .MuiTouchRipple-root': {
              opacity: 0.2
            },

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.alpha.black[100],
              background: lighten(colors.primary.lighter, 0.5)
            }
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root': {
            color: colors.secondary.main,

            '&:hover, &:active, &.active, &.Mui-selected': {
              color: colors.alpha.black[100],
              background: lighten(colors.primary.lighter, 0.5)
            }
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          margin: 1
        },
        root: {
          '.MuiAutocomplete-inputRoot.MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
            {
              right: 14
            }
        },
        clearIndicator: {
          background: colors.error.lighter,
          color: colors.error.main,
          marginRight: 8,

          '&:hover': {
            background: colors.error.lighter,
            color: colors.error.dark
          }
        },
        popupIndicator: {
          color: colors.alpha.black[50],

          '&:hover': {
            background: colors.primary.lighter,
            color: colors.primary.main
          }
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          '& .MuiIconButton-root': {
            padding: 8
          }
        },
        select: {
          '&:focus': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: '0 !important',
          padding: '0 !important'
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          background: colors.primary.main
        },
        root: {
          transition: 'background-color .2s',

          '&.MuiTableRow-hover:hover': {
            backgroundColor: lighten(colors.alpha.black[5], 0.1),
            cursor: 'pointer'
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomColor: colors.alpha.black[10],
          fontSize: 14,
          padding: '8px',
          //wordBreak: 'break-all',
          backgroundColor: 'transparent'
        },
        head: {
          fontSize: 13,
          color: themeColors.white
        },
        stickyHeader: {
          background: colors.primary.main
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          lineHeight: 1.5,
          fontSize: 14
        },
        standardInfo: {
          color: colors.info.main
        },
        action: {
          color: colors.alpha.black[70]
        }
      }
    },
    MuiTimelineDot: {
      styleOverrides: {
        root: {
          margin: 0,
          zIndex: 5,
          position: 'absolute',
          top: '50%',
          marginTop: -6,
          left: -6
        },
        outlined: {
          backgroundColor: colors.alpha.white[100],
          boxShadow: '0 0 0 6px ' + colors.alpha.white[100]
        },
        outlinedPrimary: {
          backgroundColor: colors.alpha.white[100],
          boxShadow: '0 0 0 6px ' + colors.alpha.white[100]
        }
      }
    },
    MuiTimelineConnector: {
      styleOverrides: {
        root: {
          position: 'absolute',
          height: '100%',
          top: 0,
          borderRadius: 50,
          backgroundColor: colors.alpha.black[10]
        }
      }
    },
    MuiTimelineItem: {
      styleOverrides: {
        root: {
          minHeight: 0,
          padding: '8px 0',

          '&:before': {
            display: 'none'
          }
        },
        missingOppositeContent: {
          '&:before': {
            display: 'none'
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: alpha(colors.alpha.black['100'], 0.95),
          padding: '8px 16px',
          fontSize: 13
        },
        arrow: {
          color: alpha(colors.alpha.black['100'], 0.95)
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          height: 33,
          overflow: 'visible',

          '& .MuiButtonBase-root': {
            position: 'absolute',
            padding: 6,
            transition:
              'left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          },
          '& .MuiIconButton-root': {
            borderRadius: 100
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            opacity: 0.3
          }
        },
        thumb: {
          backgroundColor: colors.alpha.white[100],
          border: '1px solid ' + colors.alpha.black[30],
          boxShadow:
            '0px 9px 14px ' +
            colors.alpha.black[10] +
            ', 0px 2px 2px ' +
            colors.alpha.black[10]
        },
        track: {
          backgroundColor: colors.alpha.black[5],
          border: '1px solid ' + colors.alpha.black[10],
          boxShadow: 'inset 0px 1px 1px ' + colors.alpha.black[10],
          opacity: 1
        },
        colorPrimary: {
          '& .MuiSwitch-thumb': {
            backgroundColor: colors.alpha.white[100]
          },

          '&.Mui-checked .MuiSwitch-thumb': {
            backgroundColor: colors.primary.main
          }
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          paddingTop: 20,
          paddingBottom: 20,
          background: colors.alpha.black[5]
        }
      }
    },
    MuiFab: {
      styleOverrides: {
        root: {
          minHeight: '32px',
          width: '32px',
          height: '32px',
          background: '#FBFBFB',
          border: '1px solid #F5F5F5'
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.MuiStepIcon-completed': {
            color: colors.success.main
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'div',
          h4: 'div',
          h5: 'div',
          h6: 'div',
          subtitle1: 'div',
          subtitle2: 'div',
          body1: 'div',
          body2: 'div'
        }
      },
      styleOverrides: {
        root: {
          //wordBreak: 'break-all'
        },
        gutterBottom: {
          marginBottom: 4
        },
        paragraph: {
          fontSize: 17,
          lineHeight: 1.7
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: '130%',
          fontStyle: 'normal',
          fontWeight: 600,
          color: themeColors.black,
          marginBottom: 8
        },
        asterisk: {
          color: colors.error.main
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: themeColors.darkTeal,
          fontSize: 14,
          lineHeight: '130%',
          fontStyle: 'normal',
          fontWeight: 600
        }
      }
    }
  },
  shape: {
    borderRadius: 6
  },
  typography: {
    fontFamily: [
      'Inter',
      'Red Hat Display',
      '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"'
    ].join(','),
    fontSize: 14,
    htmlFontSize: 15,
    h1: {
      fontWeight: 700,
      fontSize: 42,
      lineHeight: '62px',
      fontFamily: 'Red Hat Display',
      fontStyle: 'normal',
      color: themeColors.black,
      [theme.breakpoints.down('sm')]: {
        fontSize: 28
      }
    },
    h2: {
      fontWeight: 700,
      fontSize: 28,
      lineHeight: '37px',
      fontFamily: 'Red Hat Display',
      fontStyle: 'normal',
      color: themeColors.black
    },
    h3: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: '130%',
      fontFamily: 'Red Hat Display',
      fontStyle: 'normal',
      color: themeColors.black
    },
    h4: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: '22px',
      fontFamily: 'Red Hat Display',
      fontStyle: 'normal',
      letterSpacing: '-2%',
      color: themeColors.black
    },
    h5: {
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '110%',
      fontFamily: 'Red Hat Display',
      fontStyle: 'normal',
      color: themeColors.black
    },
    h6: {
      fontSize: 15
    },
    body1: {
      fontSize: 16,
      lineHeight: '130%',
      fontStyle: 'normal',
      letterSpacing: '-1%',
      color: themeColors.black
    },
    body2: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '130%',
      fontStyle: 'normal',
      color: themeColors.black
    },
    button: {
      fontSize: 14,
      fontWeight: 700
    },
    caption: {
      fontSize: 13,
      lineHeight: '130%',
      color: themeColors.gray,
      fontWeight: 400
    },
    subtitle1: {
      fontSize: 16,
      lineHeight: '19px',
      fontStyle: 'normal',
      letterSpacing: '-2%',
      color: themeColors.black
    },
    subtitle2: {
      fontSize: 13,
      lineHeight: '16.9px',
      fontStyle: 'normal',
      color: themeColors.black
    },
    overline: {
      fontSize: 13,
      fontWeight: 700,
      textTransform: 'uppercase'
    },
    fontWeightBold: 600,
    fontWeightRegular: 400,
    fontWeightLight: 300
  },
  shadows: [
    'none',
    colors.shadows.cardNtmy,
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none'
  ]
});
