import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IPeople,
  IPeopleStreamOutputWithPagination,
  TInterest,
  TPeopleLocation
} from 'src/models/people';

interface IPeopleSlice {
  people: ApolloQueryResult<IPeopleStreamOutputWithPagination>;
  locationList: ApolloQueryResult<Array<TPeopleLocation>>;
  interestList: ApolloQueryResult<Array<TInterest>>;
  selectedPeople: Array<IPeople>;
  ignoredPeople: ApolloQueryResult<IPeopleStreamOutputWithPagination>;
  isSelectedAllPrimary: boolean;
}

const initialState: IPeopleSlice = {
  people: {
    data: {
      result: [],
      limit: 20,
      page: 1,
      totalRows: 0,
      totalPages: 0
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  locationList: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  interestList: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  selectedPeople: [],
  ignoredPeople: {
    data: {
      result: [],
      limit: 20,
      page: 1,
      totalRows: 0,
      totalPages: 0
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  isSelectedAllPrimary: false
};

export const peopleSlice = createSlice({
  name: 'peoplePanel',
  initialState: initialState,
  reducers: {
    reducerUpdatePeople: (
      state: IPeopleSlice,
      action: PayloadAction<
        ApolloQueryResult<IPeopleStreamOutputWithPagination>
      >
    ) => {
      state.people = action.payload;
    },
    reducerUpdatePeopleLoading: (state: IPeopleSlice, action: PayloadAction<boolean>) => {
      state.people.loading = action.payload
    },
    reducerUpdateInterestList: (
      state: IPeopleSlice,
      action: PayloadAction<ApolloQueryResult<Array<TInterest>>>
    ) => {
      state.interestList = action.payload;
    },
    reducerUpdateSelectedPeople: (
      state: IPeopleSlice,
      action: PayloadAction<Array<IPeople>>
    ) => {
      state.selectedPeople = action.payload;
    },
    reducerUpdatePeopleData: (
      state: IPeopleSlice,
      action: PayloadAction<Array<IPeople>>
    ) => {
      const updateTable = (
        prevTable: Array<IPeople>,
        payload: Array<IPeople>
      ) => {
        const tempPeople = [...prevTable];
        payload.forEach((person: IPeople) => {
          const elementIndex = prevTable.findIndex(
            (element) =>
              `${element.originalId}${element.socialMedia}` ===
              `${person.originalId}${person.socialMedia}`
          );

          tempPeople[elementIndex] = {
            ...tempPeople[elementIndex],
            gender: person.gender,
            ageRange: person.ageRange,
            labelList: person.labelList,
            interestList: person.interestList,
            location: person.location
          };
        });
        return tempPeople;
      };
      state.people.data.result = updateTable(
        state.people.data.result,
        action.payload
      );
    },
    reducerUpdateLocationList: (
      state: IPeopleSlice,
      action: PayloadAction<ApolloQueryResult<Array<TPeopleLocation>>>
    ) => {
      state.locationList = action.payload;
    },
    reducerUpdateIgnoredPeopleDataAfterIgnore: (
      state: IPeopleSlice,
      action: PayloadAction<ApolloQueryResult<IPeople[]>>
    ) => {
      const updateTableIgnoreStream = (
        ignoredStreamOutputWithPagination,
        streamsUpdated
      ) => {
        return {
          ...ignoredStreamOutputWithPagination,
          totalRows:
            ignoredStreamOutputWithPagination.totalRows + streamsUpdated.length,
          result: [
            ...ignoredStreamOutputWithPagination.result,
            ...streamsUpdated
          ]
        };
      };
      state.ignoredPeople.data = updateTableIgnoreStream(
        state.ignoredPeople.data,
        action.payload
      );
    },
    reducerUpdatePeopleDataAfterIgnore: (
      state: IPeopleSlice,
      action: PayloadAction<IPeople[]>
    ) => {
      const updatePeopleData = (
        prevData: IPeopleStreamOutputWithPagination,
        ignoredData: IPeople[]
      ) => {
        const filteredResult = prevData.result.filter((currData) => {
          const isIgnored = ignoredData.find(
            (ignoredItem) =>
              ignoredItem.originalId === currData.originalId &&
              ignoredItem.socialMedia === currData.socialMedia
          );
          return !isIgnored;
        });
        return {
          ...prevData,
          totalRows: prevData.totalRows - ignoredData.length,
          result: filteredResult
        };
      };
      state.people.data = updatePeopleData(state.people.data, action.payload);
    },
    reducerInitIgnoredPeopleList: (
      state: IPeopleSlice,
      action: PayloadAction<
        ApolloQueryResult<IPeopleStreamOutputWithPagination>
      >
    ) => {
      state.ignoredPeople = action.payload;
    },
    reducerUpdateIgnoredPeopleAfterRestore: (
      state: IPeopleSlice,
      action: PayloadAction<IPeople[]>
    ) => {
      const updatePeopleData = (
        prevData: IPeopleStreamOutputWithPagination,
        restoredData: IPeople[]
      ) => {
        const filteredResult = prevData.result.filter((currData) => {
          const isIgnored = restoredData.find(
            (restoredItem) =>
              restoredItem.originalId === currData.originalId &&
              restoredItem.socialMedia === currData.socialMedia
          );
          return !isIgnored;
        });
        console.log(filteredResult);
        return {
          ...prevData,
          totalRows: prevData.totalRows - restoredData.length,
          result: filteredResult
        };
      };
      state.ignoredPeople.data = updatePeopleData(
        state.ignoredPeople.data,
        action.payload
      );
    },
    reducerUpdatePeopleDataAfterRestore: (
      state: IPeopleSlice,
      action: PayloadAction<IPeople[]>
    ) => {
      const updatePeopleTable = (currentPeopleData, restoredData) => {
        return {
          ...currentPeopleData,
          totalRows: currentPeopleData.totalRows + restoredData.length,
          result: [...currentPeopleData.result, ...restoredData]
        };
      };
      state.people.data = updatePeopleTable(state.people.data, action.payload);
    },
    reducerUpdateSelectedAllPrimary: (state: IPeopleSlice, action: PayloadAction<boolean>) => {
      state.isSelectedAllPrimary = action.payload
    }
  }
});

export const {
  reducerUpdatePeople,
  reducerUpdateInterestList,
  reducerUpdateSelectedPeople,
  reducerUpdatePeopleData,
  reducerUpdateLocationList,
  reducerUpdateIgnoredPeopleDataAfterIgnore,
  reducerUpdatePeopleDataAfterIgnore,
  reducerInitIgnoredPeopleList,
  reducerUpdateIgnoredPeopleAfterRestore,
  reducerUpdatePeopleDataAfterRestore,
  reducerUpdateSelectedAllPrimary,
  reducerUpdatePeopleLoading
} = peopleSlice.actions;

export default peopleSlice.reducer;
