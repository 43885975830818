import { useState, useEffect } from 'react';

interface Item {
  name: string;
}

// export const useFiltersByName = function <T>(list: Array<T & Item>) {
//   const [filteredList, setFilteredList] = useState<Array<T>>(list);
//   const [findByKeyword, setFindByKeyword] = useState<string>('');
//
//   useEffect(() => {
//     setFilteredList(list);
//   }, [list]);
//
//   const handleFilter = function (value: string) {
//     setFindByKeyword(value);
//     let updatedList = list;
//     updatedList = updatedList.filter(function (item: T & Item) {
//       return item.name.toLowerCase().search(value.toLowerCase()) !== -1;
//     });
//     setFilteredList(updatedList);
//   };
//   return { filteredList, handleFilter, findByKeyword };
// };

export const useFiltersByName = function <T>(list: Array<T & Item>) {
  const [filteredList, setFilteredList] = useState<Array<T>>(list);
  const [findByKeyword, handleFilter] = useState<string>('');
  const listData = list || [];

  useEffect(() => {
    setFilteredList(list);
  }, [list]);

  useEffect(() => {
    const updatedList = listData.filter(function (item: T & Item) {
      return item.name.toLowerCase().includes(findByKeyword.toLowerCase());
    });
    setFilteredList(updatedList);
  }, [findByKeyword]);

  return { filteredList, handleFilter, findByKeyword };
};
