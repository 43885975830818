import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICampaign, ICampaignTopInfluencer, ICampaignTopTalker, IGroupTotalNumericalCampaign } from 'src/models/campaign';
import { IDataWorldCloud, INumericOutputWithGrowth, IPeakTime, IRawStreamOutputWithUnlimitedScroll } from 'src/models/general';

interface IDataAudienceEngagement {
  min: number;
  max: number;
  data: Array<any>;
}

interface IStream {
  listCampaign: ApolloQueryResult<Array<ICampaign>>;
  campaignSelected: ICampaign;
  listCampaignHistory: ApolloQueryResult<Array<ICampaign>>;
  campaignById: ApolloQueryResult<ICampaign>;
  dataGroupNumericalCampaign: ApolloQueryResult<IGroupTotalNumericalCampaign>;
  dataGroupChronologicalCampaign: ApolloQueryResult<any>;
  dataSocialMediaSummary: ApolloQueryResult<Array<any>>;
  dataAudienceEngagement: ApolloQueryResult<IDataAudienceEngagement>;
  dataWordCloudTalk: ApolloQueryResult<IDataWorldCloud>;
  dataTopInfluencer: ApolloQueryResult<Array<ICampaignTopInfluencer>>;
  dataTopTalker: ApolloQueryResult<Array<ICampaignTopTalker>>;
  dataStreamPreview: ApolloQueryResult<IRawStreamOutputWithUnlimitedScroll>;
}

const initNumericalOutput: INumericOutputWithGrowth = {
  contentType: "",
  growth: null,
  pastValue: null,
  recentValue: null,
};

const initWordCloud: IDataWorldCloud = {
  data: [],
  min: 0,
  max: 1,
};

const initPeakTime: IPeakTime = {
  max: 0,
  min: 0,
  data: [
    {
      name: "monday",
      data: [0],
    },
  ],
};

const initStreamOutput: IRawStreamOutputWithUnlimitedScroll = {
  pagination: {
    hasNext: false,
    next: "",
  },
  result: [],
  total: 0,
  totalBySentiment: {
    negative: 0,
    neutral: 0,
    positive: 0,
  },
};

const initialState: IStream = {
  listCampaign: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: [],
  },
  campaignSelected: {
    id: '_default',
    campaignName: 'All Hashtag',
    campaignObjectDetails: [],
    campaignProgress: 'ongoing',
    createdAt: new Date(),
    dateEnd: new Date(),
    dateStart: new Date(),
    projectId: ''
  },
  listCampaignHistory: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: [],
  },
  campaignById: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: undefined
  },
  dataGroupNumericalCampaign: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: {
      talker: initNumericalOutput,
      talk: initNumericalOutput,
      reach: initNumericalOutput,
      impression: initNumericalOutput,
    }
  },
  dataGroupChronologicalCampaign: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  dataTopTalker: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: [],
  },
  dataTopInfluencer: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: [],
  },
  dataSocialMediaSummary: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: [],
  },
  dataAudienceEngagement: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initPeakTime,
  },
  dataWordCloudTalk: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initWordCloud,
  },
  dataStreamPreview: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initStreamOutput,
  },
}

export const sosmedCampaign = createSlice({
  name: 'sosmedCampaign',
  initialState,
  reducers: {
    reducerUpdateListCampaign: (state: IStream, action: PayloadAction<ApolloQueryResult<Array<ICampaign>>>)=> {
      state.listCampaign = action.payload;
    },
    reducerUpdateSelectedCampaign: (state: IStream, action: PayloadAction<ICampaign>)=> {
      state.campaignSelected = action.payload;
    },
    reducerUpdateListCampaignHistory: (state: IStream, action: PayloadAction<ApolloQueryResult<Array<ICampaign>>>)=> {
      state.listCampaignHistory = action.payload;
    },
    reducerUpdateCampaignById: (state: IStream, action: PayloadAction<ApolloQueryResult<ICampaign>>)=> {
      state.campaignById = action.payload;
    },
    reducerUpdateGroupTotalNumericalCampaign: (state: IStream, action: PayloadAction<ApolloQueryResult<IGroupTotalNumericalCampaign>>)=> {
      state.dataGroupNumericalCampaign = action.payload;
    },
    reducerUpdateGroupChronologicalCampaign: (state: IStream, action: PayloadAction<ApolloQueryResult<any>>)=> {
      state.dataGroupChronologicalCampaign = action.payload;
    },
    reducerUpdateTopTalkerCampaign: (state: IStream, action: PayloadAction<ApolloQueryResult<Array<ICampaignTopTalker>>>)=> {
      state.dataTopTalker = action.payload;
    },
    reducerUpdateTopInfluencerCampaign: (state: IStream, action: PayloadAction<ApolloQueryResult<Array<ICampaignTopInfluencer>>>)=> {
      state.dataTopInfluencer = action.payload;
    },
    reducerUpdateSocialMediaSummaryCampaign: (state: IStream, action: PayloadAction<ApolloQueryResult<Array<any>>>)=> {
      state.dataSocialMediaSummary = action.payload;
    },
    reducerUpdateAudienceEngagementCampaign: (state: IStream, action: PayloadAction<ApolloQueryResult<IDataAudienceEngagement>>)=> {
      state.dataAudienceEngagement = action.payload;
    },
    reducerUpdateWordCloudTalkCampaign: (state: IStream, action: PayloadAction<ApolloQueryResult<IDataWorldCloud>>)=> {
      state.dataWordCloudTalk = action.payload;
    },
    reducerUpdateStreamPreviewCampaign: (state: IStream, action: PayloadAction<ApolloQueryResult<IRawStreamOutputWithUnlimitedScroll>>)=> {
      state.dataStreamPreview = action.payload;
    },
    reducerUpdateStreamPreviewCampaignLoading: (state: IStream, action: PayloadAction<boolean>)=> {
      state.dataStreamPreview.loading = action.payload;
    },
    reducerUpdateAllLoadingCampaign: (state: IStream, action: PayloadAction<boolean>)=> {
      state.dataAudienceEngagement.loading = action.payload;
      state.dataGroupChronologicalCampaign.loading = action.payload;
      state.dataGroupNumericalCampaign.loading = action.payload;
      state.dataSocialMediaSummary.loading = action.payload;
      state.dataTopInfluencer.loading = action.payload;
      state.dataTopTalker.loading = action.payload;
      state.dataWordCloudTalk.loading = action.payload;
    },
    reducerUpdateAllLoadingCampaignPerDay: (state: IStream, action: PayloadAction<boolean>)=> {
      state.dataAudienceEngagement.loading = action.payload;
      state.dataSocialMediaSummary.loading = action.payload;
      state.dataTopInfluencer.loading = action.payload;
      state.dataTopTalker.loading = action.payload;
      state.dataWordCloudTalk.loading = action.payload;
    }
  },
})

export const {
  reducerUpdateListCampaign, 
  reducerUpdateSelectedCampaign,
  reducerUpdateListCampaignHistory, 
  reducerUpdateCampaignById,
  reducerUpdateGroupTotalNumericalCampaign,
  reducerUpdateGroupChronologicalCampaign,
  reducerUpdateTopTalkerCampaign,
  reducerUpdateTopInfluencerCampaign,
  reducerUpdateSocialMediaSummaryCampaign,
  reducerUpdateAudienceEngagementCampaign,
  reducerUpdateWordCloudTalkCampaign,
  reducerUpdateStreamPreviewCampaign,
  reducerUpdateAllLoadingCampaign,
  reducerUpdateStreamPreviewCampaignLoading,
  reducerUpdateAllLoadingCampaignPerDay


} = sosmedCampaign.actions;

export default sosmedCampaign.reducer;