import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import TableGroupCampaign from './Table';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import useCampagin from 'src/services/useCampaign';
import ModalAddHashtagToCampaign from '../ModalAddHashtagToCampaign';
import { reducerUpdateListCampaign } from 'src/features/sosmedCampaign';
import CloseIcon from '@mui/icons-material/Close';

const GroupCampaign = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { listCampaign, campaignSelected } = useAppSelector(
    (state) => state.storeSocialMediaCampaign
  );
  const objectList = useAppSelector(
    (state) => state.objectManagement.objectList
  );
  const projectId = useAppSelector((state) => state.users.project.id);

  const { addCampagin, resAddCampagin } = useCampagin();
  const [open, setOpen] = React.useState(false);
  const [campaignName, setCampaignName] = React.useState<string>('');

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const [openAddGroup, setOpenAddGroup] = React.useState(false);
  const handleClickOpenAddGroup = () => {
    setOpenAddGroup(true);
  };
  const handleCloseAddGroup = () => {
    setOpenAddGroup(false);
  };

  const [openAddHashtag, setOpenAddHashtag] = React.useState(false);

  const handleCloseAddHashtag = () => setOpenAddHashtag(false);

  const handleOpenAddHashtag = () => setOpenAddHashtag(true);

  const handleCreateNew = () => {
    handleCloseAddGroup();
    handleOpenAddHashtag();
  };

  const handleCreateGroup = (selectedKeywordIds: Array<string>) => {
    addCampagin({
      variables: {
        projectId,
        objectIds: selectedKeywordIds,
        campaignName
      }
    }).then((response) => {
      if (!response.data) return;
      dispatch(
        reducerUpdateListCampaign({
          ...listCampaign,
          data: [...listCampaign.data, response.data.campaign_addCampaignV2]
        })
      );
      handleCloseAddHashtag();
    });
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <FormControl size="small" fullWidth>
        <Select
          name="group"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={campaignSelected?.id ? campaignSelected?.id : ''}
        >
          <MenuItem
            value={'_default'}
            key={'_default'}
            sx={{ display: 'none' }}
          >
            All Hashtag
          </MenuItem>
          {listCampaign.data.map((group) => (
            <MenuItem key={group.id} value={group.id} sx={{ display: 'none' }}>
              {group.campaignName}
            </MenuItem>
          ))}

          <Box sx={{ p: theme.spacing(2) }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Campaign</Typography>
              <Button
                size="small"
                variant="outlined"
                onClick={(event) => {
                  handleClickOpenAddGroup();
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                Create New +
              </Button>
            </Stack>
            <Input
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: '#009FE3' }} />
                </InputAdornment>
              }
              fullWidth
              placeholder="Search group"
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            />
            <Box sx={{ mt: theme.spacing(1) }}>
              <TableGroupCampaign
                listCampaign={[
                  {
                    id: '_default',
                    campaignName: 'All Hashtag',
                    campaignObjectDetails: [],
                    campaignProgress: 'ongoing',
                    createdAt: new Date(),
                    dateEnd: new Date(),
                    dateStart: new Date(),
                    projectId: projectId
                  },
                  ...listCampaign.data
                ]}
                campaignSelected={campaignSelected}
                onClick={handleClose}
              />
            </Box>
          </Box>
        </Select>
      </FormControl>
      <Dialog
        onClose={handleCloseAddGroup}
        open={openAddGroup}
        fullWidth
        maxWidth={'sm'}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseAddGroup}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(3, 3, 1),
            textAlign: 'center'
          }}
        >
          <Typography
            variant="h2"
            sx={{ color: '#0B85AE', fontWeight: 'bold' }}
          >
            Create New Campaign
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            m: theme.spacing(1, 3)
          }}
        >
          <FormControl fullWidth size="medium">
            <FormLabel>Campaign Name</FormLabel>
            <OutlinedInput
              name="objectName"
              type="search"
              onChange={(e) => setCampaignName(e.target.value)}
            />
          </FormControl>
          <Box display="flex" justifyContent="center" mt={theme.spacing(4)}>
            <Button
              variant="contained"
              size="large"
              sx={{
                background: theme.palette.primary.dark
              }}
              onClick={handleCreateNew}
            >
              Create New
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <ModalAddHashtagToCampaign
        open={openAddHashtag}
        onClose={handleCloseAddHashtag}
        objectList={objectList.data}
        onDone={handleCreateGroup}
        loading={resAddCampagin.loading}
      />
    </Box>
  );
};

export default GroupCampaign;
