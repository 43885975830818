import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import { IClipping } from 'src/models/onlineMedia';

type TVisibility = 'show' | 'hidden';

interface IUpdateVisibilityOnm {
  clipping: IClipping;
  end: string;
  id: string;
  processedBatches: number;
  processedData: number;
  projectId: string;
  start: string;
  status: string;
  timezoneOffset: number;
  totalBatches: number;
  totalData: number;
  visibility: TVisibility;
}

interface IProps {
  detail: IUpdateVisibilityOnm;
}

const InformationDetailIgnoreOnm = ({ detail }: IProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">Ignore ONM</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 8 }}>
        <Typography>Clipping: {detail.clipping.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">{`Date Range: ${moment(
          detail.start
        ).format('DD MMM YYYY')} - ${moment(detail.end).format(
          'DD MMM YYYY'
        )}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Processed data: {detail.processedData}
        </Typography>
        <Grid item xs={12} style={{ marginTop: 8 }}>
          <Typography variant="h5">Change to:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">{`Visibility Update: ${detail.visibility}`}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InformationDetailIgnoreOnm;
