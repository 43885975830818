import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConversationNumericOutputWithGrowth } from 'src/models/conversation';
import {
  IDataWorldCloud,
  INumericOutputWithGrowth,
  IPeakTime,
  IRawPostmadeOutputWithPagination,
  IValuePieChart
} from 'src/models/general';

const initDataTable: IRawPostmadeOutputWithPagination = {
  result: [],
  limit: 100,
  page: 1,
  totalPages: 1,
  totalRows: 0
};

const initWordCloud: IDataWorldCloud = {
  data: [],
  min: 0,
  max: 1
};

const initPeakTime: IPeakTime = {
  max: 0,
  min: 0,
  data: [
    {
      name: 'monday',
      data: [0]
    }
  ]
};

const initNumericalOutput: INumericOutputWithGrowth = {
  contentType: '',
  growth: null,
  pastValue: null,
  recentValue: null
};

const initNumericals: IConversationNumericOutputWithGrowth = {
  talk: initNumericalOutput,
  talker: initNumericalOutput,
  positiveTalk: initNumericalOutput,
  negativeTalk: initNumericalOutput
};

export interface IConversation {
  numericals: ApolloQueryResult<IConversationNumericOutputWithGrowth>;
  dayToDayConversation: ApolloQueryResult<Array<any>>;
  conversationByMedia: ApolloQueryResult<any>;
  sentimentAnalysis: ApolloQueryResult<Array<IValuePieChart>>;
  talkerBySentiment: ApolloQueryResult<Array<IValuePieChart>>;
  talkerByInterest: ApolloQueryResult<Array<IValuePieChart>>;
  talkerByLocations: ApolloQueryResult<Array<IValuePieChart>>;
  talkerByGender: ApolloQueryResult<Array<IValuePieChart>>;
  listOfTalk: ApolloQueryResult<IRawPostmadeOutputWithPagination>;
  topTalker: ApolloQueryResult<Array<any>>;
  topInfluencer: ApolloQueryResult<Array<any>>;
  topEngagement: ApolloQueryResult<Array<any>>;
  peakTime: ApolloQueryResult<IPeakTime>;
  wordClouds: ApolloQueryResult<IDataWorldCloud>;
}

const initialState: IConversation = {
  numericals: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initNumericals
  },
  dayToDayConversation: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  conversationByMedia: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  sentimentAnalysis: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  talkerBySentiment: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  talkerByInterest: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  talkerByLocations: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  talkerByGender: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  listOfTalk: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initDataTable
  },
  topTalker: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  topInfluencer: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  topEngagement: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  peakTime: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initPeakTime
  },
  wordClouds: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initWordCloud
  }
};

export const sosmedConversation = createSlice({
  name: 'sosmedConversation',
  initialState,
  reducers: {
    reducerUpdateConversationNumericals: (
      state: IConversation,
      action: PayloadAction<
        ApolloQueryResult<IConversationNumericOutputWithGrowth>
      >
    ) => {
      state.numericals = action.payload;
    },
    reducerUpdateConversationDayToDayConversation: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.dayToDayConversation = action.payload;
    },
    reducerUpdateConversationDayToDayLoading: (
      state: IConversation,
      action: PayloadAction<boolean>
    ) => {
      state.dayToDayConversation.loading = action.payload;
    },
    reducerUpdateConversationByMedia: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<any>>
    ) => {
      state.conversationByMedia = action.payload;
    },
    reducerUpdateConversationSentimentAnalysis: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<Array<IValuePieChart>>>
    ) => {
      state.sentimentAnalysis = action.payload;
    },
    reducerUpdateConversationTalkerBySentiment: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<Array<IValuePieChart>>>
    ) => {
      state.talkerBySentiment = action.payload;
    },
    reducerUpdateConversationTalkerByInterest: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<Array<IValuePieChart>>>
    ) => {
      state.talkerByInterest = action.payload;
    },
    reducerUpdateConversationTalkerByLocations: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<Array<IValuePieChart>>>
    ) => {
      state.talkerByLocations = action.payload;
    },
    reducerUpdateConversationTalkerByGender: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<Array<IValuePieChart>>>
    ) => {
      state.talkerByGender = action.payload;
    },
    reducerUpdateConversationListOfTalk: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<IRawPostmadeOutputWithPagination>>
    ) => {
      state.listOfTalk = action.payload;
    },
    reducerUpdateConversationListOfTalkLoading: (
      state: IConversation,
      action: PayloadAction<boolean>
    ) => {
      state.listOfTalk.loading = action.payload;
    },
    reducerUpdateConversationTopTalker: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.topTalker = action.payload;
    },
    reducerUpdateConversationTopInfluencer: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.topInfluencer = action.payload;
    },
    reducerUpdateConversationTopEngagement: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.topEngagement = action.payload;
    },
    reducerUpdateConversationPeakTime: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<IPeakTime>>
    ) => {
      state.peakTime = action.payload;
    },
    reducerUpdateConversationWordClouds: (
      state: IConversation,
      action: PayloadAction<ApolloQueryResult<IDataWorldCloud>>
    ) => {
      state.wordClouds = action.payload;
    },
    reducerUpdateConversationAllMetricLoading: (
      state: IConversation,
      action: PayloadAction<boolean>
    ) => {
      state.conversationByMedia.loading = action.payload;
      state.dayToDayConversation.loading = action.payload;
      state.listOfTalk.loading = action.payload;
      state.numericals.loading = action.payload;
      state.peakTime.loading = action.payload;
      state.sentimentAnalysis.loading = action.payload;
      state.talkerByGender.loading = action.payload;
      state.talkerByInterest.loading = action.payload;
      state.talkerByLocations.loading = action.payload;
      state.talkerBySentiment.loading = action.payload;
      state.topInfluencer.loading = action.payload;
      state.topTalker.loading = action.payload;
      state.wordClouds.loading = action.payload;
      state.topEngagement.loading = action.payload;
    },
    reducerUpdateConversationAllMetricLoadingPerDay: (
      state: IConversation,
      action: PayloadAction<boolean>
    ) => {
      state.conversationByMedia.loading = action.payload;
      state.listOfTalk.loading = action.payload;
      state.peakTime.loading = action.payload;
      state.sentimentAnalysis.loading = action.payload;
      state.talkerByGender.loading = action.payload;
      state.talkerByInterest.loading = action.payload;
      state.talkerByLocations.loading = action.payload;
      state.talkerBySentiment.loading = action.payload;
      state.topInfluencer.loading = action.payload;
      state.topTalker.loading = action.payload;
      state.wordClouds.loading = action.payload;
      state.topEngagement.loading = action.payload;
    }
  }
});

export const {
  reducerUpdateConversationAllMetricLoading,
  reducerUpdateConversationByMedia,
  reducerUpdateConversationDayToDayConversation,
  reducerUpdateConversationListOfTalk,
  reducerUpdateConversationNumericals,
  reducerUpdateConversationPeakTime,
  reducerUpdateConversationSentimentAnalysis,
  reducerUpdateConversationTalkerByGender,
  reducerUpdateConversationTalkerByInterest,
  reducerUpdateConversationTopEngagement,
  reducerUpdateConversationTalkerByLocations,
  reducerUpdateConversationTalkerBySentiment,
  reducerUpdateConversationTopInfluencer,
  reducerUpdateConversationTopTalker,
  reducerUpdateConversationWordClouds,
  reducerUpdateConversationListOfTalkLoading,
  reducerUpdateConversationAllMetricLoadingPerDay,
  reducerUpdateConversationDayToDayLoading
} = sosmedConversation.actions;

export default sosmedConversation.reducer;
