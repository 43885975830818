import { Box, Fab, Grid, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import BottomHeader from './Bottom';
import { useNavigate } from 'react-router';
import { OFFLINE_MEDIA_COMPARISON } from 'src/route';
import { FC } from 'react';
import useQueryUrl from 'src/hooks/useQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TooltipCustomize from 'src/components/TooltipCustomize';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  onClick: () => void;
}

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

const HeaderWrapperSec = styled(Box)(
  ({ theme }) => `
    background: #fbfbfb;
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    justify-content: space-between;
    width: 100%;
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

const HeaderOfflineMediaComparison: FC<Props> = ({ onClick }) => {
  const query = useQueryUrl();
  const title = query.get('title');
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      <HeaderWrapper display="flex" alignItems="center">
        <Grid container>
          <Grid item lg={12}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Fab
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.grey[200]}`
                }}
                size="small"
                onClick={() => navigate(OFFLINE_MEDIA_COMPARISON)}
              >
                <ArrowBackIcon />
              </Fab>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="h1"
                  sx={{
                    mr: 1
                  }}
                >
                  {title}
                </Typography>
                <TooltipCustomize
                  title={'Comparison analysis between each clipping'}
                  placement="top-start"
                >
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{ transform: 'translateY(-4px)' }}
                  />
                </TooltipCustomize>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </HeaderWrapper>
      <HeaderWrapperSec display="flex" alignItems="center">
        <Grid container>
          <Grid item lg={12}>
            <BottomHeader />
          </Grid>
        </Grid>
      </HeaderWrapperSec>
    </>
  );
};

export default HeaderOfflineMediaComparison;
