import { TSocialMedia } from 'src/models/general';

export const getSocialMediaColor = (socialMedia: TSocialMedia) => {
  switch (socialMedia) {
    case 'twitter':
      return '#1DA1F2';
    case 'instagram':
      return '#833AB4';
    case 'youtube':
      return '#CD201F';
    case 'facebook':
      return '#1877F2';
    case 'tiktok':
      return '#010101';
    default:
      return '#1DA1F2';
  }
};
