import { Scrollbars } from 'react-custom-scrollbars-2';
import {
  Avatar,
  Box,
  Button,
  Link,
  Tooltip,
  useTheme,
  CSSObject
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import SidebarMenu from './SidebarMenu';
import { useAppSelector, useAppDispatch } from 'src/app/hooks';
import { toogleSidebar } from 'src/features/sidebarToggleSlice';
import LogoNoLimitWhite from 'src/components/LogoNoLimitWhite';
import { USERS_SIGN_IN, SETTINGS } from 'src/route';
import { useLocation, useNavigate } from 'react-router';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import MiniSidebarMenu from './MiniSidebarMenu';
import { usePostHog } from 'posthog-js/react';

interface ToogleSidebar {
  open: boolean;
}

const openedMixin = (theme): CSSObject => ({
  width: '248px',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  border: 'none'
});

const closedMixin = (theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `74px`,
  border: 'none'
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: '248px',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

const SidebarWrapper = styled(Box)<ToogleSidebar>(
  ({ theme, open }) => `
    min-width: ${open ? theme.sidebar.width : 0};
    width: inherit;
    color: ${theme.sidebar.textColor};
    background: ${theme.sidebar.background};
    box-shadow: ${theme.sidebar.boxShadow};
    height: 100%;
    position: relative;
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        position: fixed;
        z-index: 10;
        position: relative;
    }
    &:hover {
      & #custom-icon {
        opacity: 1
      }
    }
`
);

const TopSection = styled(Box)<ToogleSidebar>(
  ({ theme, open }) => `
    display: flex;
    height: 88px;
    align-items: center;
    justify-content: ${open ? 'space-between' : 'center'};
    margin: 0 ${open ? `${theme.spacing(3)} ${theme.spacing(3)}` : 0};
`
);

const CustomAvatar = styled(Avatar)(
  ({ theme }) => `
    cursor: pointer;
`
);

const SidebarContainer = styled(Box)<ToogleSidebar>(
  ({ theme, open }) => `
    width: ${open ? '288px' : '74px'};
    transition: width 0.1s linear;
`
);

function Sidebar() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const posthog = usePostHog();
  const sidebarToggle = useAppSelector((state) => state.sidebarToggle.toggle);
  const toggleSidebar = () => {
    dispatch(toogleSidebar(!sidebarToggle));
  };
  const handleSignOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('project');
    // posthog.reset();
    window.location.href = USERS_SIGN_IN;
  };

  const linkToHome = () => {
    navigate('/');
  };

  const { pathname } = useLocation();
  const mainLevelMenu = pathname.split('/')[1];
  const isMatchCurrentPath = (url: string) => mainLevelMenu === url;

  return (
    <>
      <SidebarContainer open={sidebarToggle}>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={toggleSidebar}
          variant="permanent"
          elevation={9}
          sx={{
            maxWidth: theme.sidebar.width
          }}
        >
          <SidebarWrapper open={sidebarToggle}>
            <Scrollbars autoHide>
              <TopSection open={sidebarToggle}>
                {sidebarToggle && (
                  <Link
                    onClick={linkToHome}
                    underline="none"
                    sx={{ cursor: 'pointer' }}
                  >
                    <LogoNoLimitWhite />
                  </Link>
                )}
                <Tooltip
                  title={sidebarToggle ? 'Close sidebar' : 'Open sidebar'}
                >
                  <CustomAvatar
                    variant="rounded"
                    id="custom-icon"
                    onClick={toggleSidebar}
                    sx={{ backgroundColor: theme.sidebar.background }}
                  >
                    <DoubleArrowIcon
                      sx={
                        sidebarToggle && {
                          transform: 'scaleX(-1)'
                        }
                      }
                      fontSize="small"
                    />
                  </CustomAvatar>
                </Tooltip>
              </TopSection>
              {sidebarToggle ? <SidebarMenu /> : <MiniSidebarMenu />}
              {!sidebarToggle && (
                <Button
                  onClick={(e) => navigate(SETTINGS)}
                  sx={{
                    display: 'flex',
                    minWidth: '0',
                    width: '70%',
                    marginInline: 'auto',
                    borderRadius: '0.5em',
                    ...(isMatchCurrentPath(SETTINGS.slice(1)) && {
                      background: '#064E66'
                    })
                  }}
                >
                  <SettingsIcon sx={{ color: 'white' }} />
                </Button>
              )}
              <Button
                sx={
                  sidebarToggle
                    ? {
                        ml: 2,
                        color: theme.sidebar.menuItemBgActive
                      }
                    : {
                        width: '100%',
                        color: theme.sidebar.menuItemBgActive
                      }
                }
                color="inherit"
                onClick={handleSignOut}
              >
                {sidebarToggle ? 'Sign Out' : <LogoutIcon />}
              </Button>
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </SidebarContainer>
      {/* <HoverSidebar
        onClick={(e) => handleClick(e.currentTarget)}
        onMouseEnter={(e) => {
          handleClick(e.currentTarget);
        }}
      />
      <Popper
        anchorEl={anchorEl}
        open={open}
        placement="right"
        style={{ zIndex: 10, left: 0, top: theme.spacing(8) }}
        onMouseLeave={(e) => {
          handleClose();
        }}
      >
        <SidebarWrapperHover>
          <TopSection>
            <Link
              onClick={linkToHome}
              underline="none"
              sx={{ cursor: 'pointer' }}
            >
              <LogoNoLimitWhite />
            </Link>
          </TopSection>
          <SidebarMenu />
          <Button
            sx={{
              ml: 2
            }}
            color="inherit"
            onClick={handleSignOut}
          >
            Sign Out
          </Button>
        </SidebarWrapperHover>
      </Popper> */}
      {/* <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={dispatchToggleSidebar}
          variant="temporary"
          elevation={9}
          sx={{
            maxWidth: '248px'
          }}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
                <LogoNoLimitWhite />
              </TopSection>
              <SidebarMenu />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden> */}
    </>
  );
}

export default Sidebar;
