import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  reducerUpdateAllLoadingCampaign,
  reducerUpdateAllLoadingCampaignPerDay,
  reducerUpdateAudienceEngagementCampaign,
  reducerUpdateCampaignById,
  reducerUpdateGroupChronologicalCampaign,
  reducerUpdateGroupTotalNumericalCampaign,
  reducerUpdateListCampaign,
  reducerUpdateListCampaignHistory,
  reducerUpdateSelectedCampaign,
  reducerUpdateSocialMediaSummaryCampaign,
  reducerUpdateStreamPreviewCampaign,
  reducerUpdateStreamPreviewCampaignLoading,
  reducerUpdateTopInfluencerCampaign,
  reducerUpdateTopTalkerCampaign,
  reducerUpdateWordCloudTalkCampaign
} from 'src/features/sosmedCampaign';
import {
  CAMPAIGN_ADD_CAMPAIGN,
  CAMPAIGN_EDIT_CAMPAIGN,
  CAMPAIGN_FINISH_CAMPAIGN
} from './graphql/campaign/mutation';
import {
  CAMPAIGN_GET_AUDIENCE_ENGAGEMENT,
  CAMPAIGN_GET_CAMPAIGNS,
  CAMPAIGN_GET_CAMPAIGNS_HISTORY,
  CAMPAIGN_GET_CAMPAIGN_BY_ID,
  CAMPAIGN_GET_GROUP_CHRONOLOGICAL_CAMPAIGN,
  CAMPAIGN_GET_SOCIAL_MEDIA_SUMMARY,
  CAMPAIGN_GET_STREAM_PREVIEW,
  CAMPAIGN_GET_TOP_INFLUENCER,
  CAMPAIGN_GET_TOP_TALKER,
  CAMPAIGN_GET_TOTAL_GROUPED_CAMPAIGN,
  CAMPAIGN_GET_WORD_CLOUD_TALK
} from './graphql/campaign/query';
import getNS from 'uuid/v4';
import uuidNameSpace from 'uuid/v5';
import { buildDataLineGroup } from 'src/utils/buildDataLineGroup';
import {
  ICampaign,
  ICampaignTopInfluencer,
  ICampaignTopTalker,
  IGroupTotalNumericalCampaign,
  ISocialMediaSummary
} from 'src/models/campaign';
import sortingDayPeakTime from 'src/utils/sortingDayPeakTime';
import { useRef } from 'react';
import {
  IAdvanceSearchSocialMedia,
  IAnalyticTotalGrowth,
  IChronologicalOutput,
  IRawStreamOutputWithUnlimitedScroll,
  IValuesPeakTime,
  IWordCloud
} from 'src/models/general';
const RequestNameSpace = getNS();

interface IGetListCampaignResponse {
  campaign_getCampaigns: Array<ICampaign>;
}

interface IGetCampaignByIdResponse {
  campaign_getCampaign: ICampaign;
}

interface IGetCampaignHistoriesResponse {
  campaign_getCampaignsHistory: Array<ICampaign>;
}

interface IGetNumericalResponse {
  campaign_getTotalGrouped: Array<IAnalyticTotalGrowth>;
}

interface IGetChronologicalResponse {
  campaign_getChronologicalGrouped: Array<IChronologicalOutput>;
}

interface IGetWordCloudResponse {
  campaign_getWordCloudTalk: Array<IWordCloud>;
}

interface IGetStreamPreviewResponse {
  campaign_getStreamPreview: IRawStreamOutputWithUnlimitedScroll;
}

interface IGetTopTalkerResponse {
  campaign_getTopTalker: Array<ICampaignTopTalker>;
}

interface IGetTopInfluencerResponse {
  campaign_getTopInfluencer: Array<ICampaignTopInfluencer>;
}

interface IGetSocialMediaSummaryResponse {
  campaign_getSocialMediaSummary: Array<ISocialMediaSummary>;
}

// interface IGetPeakTimesResponse {
//   campaign_getAudienceEngagement: IPeakTime;
// }

const useCampagin = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const lastPromiseNumericals: any = useRef();
  const lastPromiseGroupChronological: any = useRef();
  const lastPromiseTopTalker: any = useRef();
  const lastPromiseTopInfluencer: any = useRef();
  const lastPromiseSocialMediaSummary: any = useRef();
  const lastPromiseAudienceEngagement: any = useRef();
  const lastPromiseWordCloudTalk: any = useRef();
  const lastPromiseStreamPreview: any = useRef();

  const { campaignSelected, dataGroupNumericalCampaign } = useAppSelector(
    (state) => state.storeSocialMediaCampaign
  );
  const fetchListCampaign = async function (projectId: string) {
    return await client
      .query<IGetListCampaignResponse>({
        query: CAMPAIGN_GET_CAMPAIGNS,
        variables: { projectId }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data?.campaign_getCampaigns ?? []
        };
      });
  };

  const getListCampaign = async function (projectId: string) {
    const response = await fetchListCampaign(projectId);
    if (!campaignSelected)
      dispatch(reducerUpdateSelectedCampaign(response.data[0]));
    dispatch(reducerUpdateListCampaign(response));
  };

  const fetchCampaignById = async function (campaignId: string) {
    return await client
      .query<IGetCampaignByIdResponse>({
        query: CAMPAIGN_GET_CAMPAIGN_BY_ID,
        variables: {
          campaignId
        }
      })
      .then((response) => {
        return { ...response, data: response.data.campaign_getCampaign };
      });
  };

  const getCampaignById = async function (campaignId: string) {
    const response = await fetchCampaignById(campaignId);
    dispatch(reducerUpdateCampaignById(response));
  };

  const fetchCampaignHistories = async function (projectId: string) {
    return await client
      .query<IGetCampaignHistoriesResponse>({
        query: CAMPAIGN_GET_CAMPAIGNS_HISTORY,
        variables: { projectId }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.campaign_getCampaignsHistory
        };
      });
  };

  const getCampaignHistories = async function (projectId: string) {
    const response = await fetchCampaignHistories(projectId);
    dispatch(reducerUpdateListCampaignHistory(response));
  };

  //Dashboard
  const fetchNumericals = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetNumericalResponse>({
      query: CAMPAIGN_GET_TOTAL_GROUPED_CAMPAIGN,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'CAMPAIGN_GET_TOTAL_GROUPED_CAMPAIGN',
          RequestNameSpace
        )
      }
    });
    const dataGroupNumerical = response.data.campaign_getTotalGrouped;
    let mappingNumerical: IGroupTotalNumericalCampaign =
      dataGroupNumericalCampaign.data;
    dataGroupNumerical.forEach((numerical) => {
      mappingNumerical = {
        ...mappingNumerical,
        [numerical.contentType]: numerical
      };
    });
    return {
      ...response,
      data: mappingNumerical
    };
  };

  const fetchChronological = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetChronologicalResponse>({
      query: CAMPAIGN_GET_GROUP_CHRONOLOGICAL_CAMPAIGN,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'CAMPAIGN_GET_GROUP_CHRONOLOGICAL_CAMPAIGN',
          RequestNameSpace
        )
      }
    });
    const generateDataLine = buildDataLineGroup(
      response.data.campaign_getChronologicalGrouped
    );

    return {
      ...response,
      data: generateDataLine
    };
  };

  const fetchWordCloud = async function (parameter: IAdvanceSearchSocialMedia) {
    const response = await client.query<IGetWordCloudResponse>({
      query: CAMPAIGN_GET_WORD_CLOUD_TALK,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'CAMPAIGN_GET_WORD_CLOUD_TALK',
          RequestNameSpace
        )
      }
    });
    const wordClouds = response.data.campaign_getWordCloudTalk.map((word) => ({
      text: word.name,
      value: word.value
    }));
    const data =
      wordClouds.length > 0 ? wordClouds.map((word) => word.value) : [];
    const max = data.length > 0 ? Math.max(...data) : 0;
    const min = data.length > 0 ? Math.min(...data) : 1;
    return { ...response, data: { data: wordClouds, max, min } };
  };

  const fetchStreamPreview = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetStreamPreviewResponse>({
      query: CAMPAIGN_GET_STREAM_PREVIEW,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'CAMPAIGN_GET_STREAM_PREVIEW',
          RequestNameSpace
        )
      }
    });
    return { ...response, data: response.data.campaign_getStreamPreview };
  };

  const fetchTopTalker = async function (parameter: IAdvanceSearchSocialMedia) {
    const response = await client.query<IGetTopTalkerResponse>({
      query: CAMPAIGN_GET_TOP_TALKER,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'CAMPAIGN_GET_TOP_TALKER',
          RequestNameSpace
        )
      }
    });
    return { ...response, data: response.data.campaign_getTopTalker };
  };

  const fetchTopInfluencer = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetTopInfluencerResponse>({
      query: CAMPAIGN_GET_TOP_INFLUENCER,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'CAMPAIGN_GET_TOP_INFLUENCER',
          RequestNameSpace
        )
      }
    });
    return { ...response, data: response.data.campaign_getTopInfluencer };
  };

  const fetchSocialMediaSummary = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetSocialMediaSummaryResponse>({
      query: CAMPAIGN_GET_SOCIAL_MEDIA_SUMMARY,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'CAMPAIGN_GET_SOCIAL_MEDIA_SUMMARY',
          RequestNameSpace
        )
      }
    });
    return { ...response, data: response.data.campaign_getSocialMediaSummary };
  };

  const fetchPeakTimes = async function (parameter: IAdvanceSearchSocialMedia) {
    const response = await client.query({
      query: CAMPAIGN_GET_AUDIENCE_ENGAGEMENT,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'CAMPAIGN_GET_AUDIENCE_ENGAGEMENT',
          RequestNameSpace
        )
      }
    });
    const { max, min, peakTimeValue } =
      response.data.campaign_getAudienceEngagement;
    const data: IValuesPeakTime[] = peakTimeValue
      ? sortingDayPeakTime(peakTimeValue)
      : [];
    return { ...response, data: { data, max, min } };
  };

  const getGroupNumericals = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const resNumericals = fetchNumericals(parameter);
    lastPromiseNumericals.current = resNumericals;
    if (resNumericals === lastPromiseNumericals.current) {
      resNumericals.then((response) => {
        dispatch(reducerUpdateGroupTotalNumericalCampaign(response));
      });
    }
  };

  const getGroupChronological = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = fetchChronological(parameter);
    lastPromiseGroupChronological.current = response;
    if (response === lastPromiseGroupChronological.current) {
      response.then((response) => {
        dispatch(reducerUpdateGroupChronologicalCampaign(response));
      });
    }
  };

  const getTopTalker = async function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchTopTalker(parameter);
    lastPromiseTopTalker.current = response;
    if (response === lastPromiseTopTalker.current) {
      response.then((response) => {
        dispatch(reducerUpdateTopTalkerCampaign(response));
      });
    }
  };

  const getTopInfluencer = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = fetchTopInfluencer(parameter);
    lastPromiseTopInfluencer.current = response;
    if (response === lastPromiseTopInfluencer.current) {
      response.then((response) => {
        dispatch(reducerUpdateTopInfluencerCampaign(response));
      });
    }
  };

  const getSocialMediaSummary = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = fetchSocialMediaSummary(parameter);
    lastPromiseSocialMediaSummary.current = response;
    if (response === lastPromiseSocialMediaSummary.current) {
      response.then((response) => {
        dispatch(reducerUpdateSocialMediaSummaryCampaign(response));
      });
    }
  };

  const getPeakTime = async function (parameter: IAdvanceSearchSocialMedia) {
    const response = fetchPeakTimes(parameter);
    lastPromiseAudienceEngagement.current = response;
    if (response === lastPromiseAudienceEngagement.current) {
      response.then((response) => {
        dispatch(reducerUpdateAudienceEngagementCampaign(response));
      });
    }
  };

  const getTalkWordCloud = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = fetchWordCloud(parameter);
    lastPromiseWordCloudTalk.current = response;
    if (response === lastPromiseWordCloudTalk.current) {
      response.then((response) => {
        dispatch(reducerUpdateWordCloudTalkCampaign(response));
      });
    }
  };

  const getStreamPreview = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateStreamPreviewCampaignLoading(true));
    const response = fetchStreamPreview(parameter);
    lastPromiseStreamPreview.current = response;
    if (response === lastPromiseStreamPreview.current) {
      response.then((response) => {
        dispatch(reducerUpdateStreamPreviewCampaign(response));
      });
    }
  };

  const getAllMetricCampaign = (parameter: IAdvanceSearchSocialMedia) => {
    dispatch(reducerUpdateAllLoadingCampaign(true));
    getGroupNumericals(parameter);
    getGroupChronological(parameter);
    getTopTalker(parameter);
    getTopInfluencer(parameter);
    getSocialMediaSummary(parameter);
    getPeakTime(parameter);
    getTalkWordCloud(parameter);
  };

  const getAllMetricCampaignPerDay = (parameter: IAdvanceSearchSocialMedia) => {
    dispatch(reducerUpdateAllLoadingCampaignPerDay(true));
    getTopTalker(parameter);
    getTopInfluencer(parameter);
    getSocialMediaSummary(parameter);
    getPeakTime(parameter);
    getTalkWordCloud(parameter);
  };

  const [addCampagin, resAddCampagin] = useMutation(CAMPAIGN_ADD_CAMPAIGN);
  const [editCampagin, resEditCampagin] = useMutation(CAMPAIGN_EDIT_CAMPAIGN);
  const [updateFinishCampagin, resUpdateFinishCampagin] = useMutation(
    CAMPAIGN_FINISH_CAMPAIGN
  );

  return {
    getAllMetricCampaign,
    getListCampaign,
    getCampaignById,
    getCampaignHistories,
    getStreamPreview,
    addCampagin,
    resAddCampagin,
    editCampagin,
    resEditCampagin,
    updateFinishCampagin,
    resUpdateFinishCampagin,
    getAllMetricCampaignPerDay
  };
};

export default useCampagin;
