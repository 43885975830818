import moment from "moment";

const  defaultDateRange = function () {
  //new Date(year, month, day, hours, minutes, seconds, milliseconds)
  let now = new Date();
  let start = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate()-7, 0, 0, 0, 0)
  );
  let end = moment(start).add(8, "days").subtract(1, "seconds");
  return {
    startDate: start,
    endDate: end,
  };
};

export default defaultDateRange;
