/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

export interface InfiniteScroll<T> {
  list: Array<T>;
  hasMore: boolean;
  page: number;
}

export const useUnlimitedScroll = function <T>(dataList: Array<T>) {
  const [infiniteScroll, setInfiniteScroll] = useState<InfiniteScroll<T>>({
    list: dataList,
    hasMore: true,
    page: 1,
  });

  const fetchMoreData = () => {
    if (infiniteScroll.list.length >= dataList.length) {
      setInfiniteScroll((prev) => ({
        ...prev,
        hasMore: false,
      }));
      return;
    }
    setTimeout(() => {
      setInfiniteScroll((prev) => ({
        ...prev,
        page: prev.page + 1,
        list: prev.list.concat(dataList.slice(prev.page * 24, (prev.page + 1) * 24)),
      }));
    }, 300);
  };

  const reset = () => {
    setInfiniteScroll({
      list: dataList.slice(0, 24),
      hasMore: true,
      page: 1,
    });
  };

  useEffect(() => {
    reset();
  }, [dataList]);

  return { infiniteScroll, setInfiniteScroll, fetchMoreData, reset };
};
