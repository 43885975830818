import {
  Box,
  FormControl,
  Stack,
  Button,
  Select,
  MenuItem,
  useTheme,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SelectDateRange from 'src/components/SelectDateRange';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/app/hooks';
import ModalAddAutoClipping from 'src/components/ModalAddAutoClipping';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useQueryUrl from 'src/hooks/useQuery';
import { useNavigate } from 'react-router';
import { STREAM_PANEL_OFFLINE_MEDIA } from 'src/route';
import { useDispatch } from 'react-redux';
import {
  reducerUpdateOfflineMediaIncludeKeywords,
  reducerUpdateLanguageSelected
} from 'src/features/offlineMediaStream';
import TableSelectGroupPortal from 'src/components/TableSelectGroupPortal';
import { useFiltersByName } from 'src/hooks/useFiltersByName';
import { IGroupPortal } from 'src/models/groupPortal';
import useGroupPortal from 'src/services/useGroupPortal';

const CustomBox = styled(Box)(
  ({ theme }) => `
    // width: calc(100vw - ${theme.sidebar.width} - ${theme.spacing(10)});
    margin: ${theme.spacing(2, 0)};
    // position: absolute;
`
);

const FilterBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
);

function BottomHeader() {
  const query = useQueryUrl();
  const id = query.get('id');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { languageSelected, languages } = useAppSelector(
    (state) => state.storeOfflineMediaStream
  );
  const projectId = useAppSelector((state) => state.users.project.id);
  const { getGroupPortal } = useGroupPortal();

  const [openSelectGroup, setOpenSelectGroup] = useState(false);

  const { groupPortalList, selectedGroupPortalId } = useAppSelector(
    (state) => state.groupPortal
  );
  const { filteredList, handleFilter } = useFiltersByName<IGroupPortal>(
    groupPortalList.data
  );

  const handleChangeLanguage = function (event) {
    const language = event.target.value;
    dispatch(reducerUpdateLanguageSelected(language));
  };

  useEffect(() => {
    getGroupPortal(projectId);
  }, [projectId]);

  return (
    <CustomBox>
      <FilterBox>
        <Stack direction="row" alignItems="center" spacing={2}>
          <FormControl size="small" sx={{ width: 409 }}>
            <Select
              name="group"
              open={openSelectGroup}
              onClose={() => setOpenSelectGroup(false)}
              onOpen={() => setOpenSelectGroup(true)}
              value={selectedGroupPortalId}
              defaultValue={selectedGroupPortalId}
            >
              <MenuItem
                value={'_default'}
                key={'_default'}
                sx={{ display: 'none' }}
              >
                Group
              </MenuItem>
              {groupPortalList.data.map((group) => (
                <MenuItem
                  key={group.id}
                  value={group.id}
                  sx={{ display: 'none' }}
                >
                  {group.displayName}
                </MenuItem>
              ))}

              <Box sx={{ p: theme.spacing(2) }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h4">Group</Typography>
                  {/* <Button
                    size="small"
                    variant="outlined"
                    onClick={(event) => {
                      handleClickOpenAddGroup();
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    New group +
                  </Button> */}
                </Stack>
                {/* <Input
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  fullWidth
                  placeholder="Search Source"
                  onChange={(event) => {
                    handleFilter(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                /> */}
                <Box sx={{ mt: theme.spacing(1) }}>
                  <TableSelectGroupPortal
                    groupPortals={[
                      {
                        id: '_default',
                        name: 'All',
                        displayName: 'All',
                        portals: [],
                        isActive: false,
                        createdAt: '-'
                      },
                      ...filteredList
                    ]}
                    selectedGroupById={selectedGroupPortalId}
                    loading={groupPortalList.loading}
                    onClick={() => setOpenSelectGroup(false)}
                  />
                </Box>
              </Box>
            </Select>
          </FormControl>
          <ModalAddAutoClipping clippingType="offlineMedia" />
        </Stack>
        <Stack spacing={2} direction="row">
          <FormControl size="small" sx={{ width: 135 }}>
            <Select
              id="language"
              name="language"
              value={languageSelected}
              onChange={handleChangeLanguage}
            >
              <MenuItem
                key={'all-language'}
                id={'all-language'}
                value={'all-language'}
              >
                All Language
              </MenuItem>
              {languages.map(({ name, language }) => (
                <MenuItem key={language} id={language} value={language}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ width: 135 }}>
            <SelectDateRange />
          </FormControl>
        </Stack>
      </FilterBox>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mt: id ? 2 : 1 }}
      >
        {id && (
          <Button
            size="small"
            variant="text"
            color="primary"
            startIcon={<ArrowBackIcon />}
            sx={{
              borderRadius: 1
            }}
            onClick={() => navigate(STREAM_PANEL_OFFLINE_MEDIA)}
          >
            Media Profile Preview
          </Button>
        )}
      </Stack>
    </CustomBox>
  );
}

export default BottomHeader;
