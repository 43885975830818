import { useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, IconButton, useTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { IObject } from 'src/models/objectManagement';
import { reducerUpdateAnalyticArgumentObjectIdList } from 'src/features/analyticArguments';
import SectionSocialProfile from './components/SectionSocialProfile';
import SectionKeywordHashtag from './components/SectionKeywordHashtag';
import useObjectManagement from 'src/services/useObjectManagement';
import SectionHashtag from './components/SectionHashtag';
import {
  reducerUpdateGroupObject,
  reducerUpdateObjectListByObjectType
} from 'src/features/objectManagement';
import useCampagin from 'src/services/useCampaign';
import { reducerUpdateSelectedCampaign } from 'src/features/sosmedCampaign';
import { reducerUpdateDashboardList } from 'src/features/customDashboard';
import CloseIcon from '@mui/icons-material/Close';
import useCustomDashboard from 'src/services/useCustomDashboard';
import { useAlert } from 'src/hooks/useAlert';

interface Props {
  isSocialProfile?: boolean;
  isHastag?: boolean;
  isEditing?: boolean;
  isDashboard?: boolean;
  initialValues?: any;
  onCloseEdit?: () => void;
  textButton?: string;
}

const ModalManageObject: React.FC<Props> = ({
  isSocialProfile = true,
  isHastag = false,
  isDashboard = false,
  isEditing = false,
  initialValues = {
    id: null,
    objectList: null
  },
  onCloseEdit,
  textButton = 'Add to group +'
}) => {
  const dispatch = useAppDispatch();
  const { handleClickAlert } = useAlert();
  const { editCampagin, resEditCampagin } = useCampagin();
  const { objectManagementEditGroup, resObjectManagementEditGroup } =
    useObjectManagement();
  const { objectManagementEditDashboard } = useCustomDashboard();
  const projectId = useAppSelector((state) => state.users.project.id);
  const { groupSelectedById, groupObject } = useAppSelector(
    (state) => state.objectManagement
  );
  const campaignSelected = useAppSelector(
    (state) => state.storeSocialMediaCampaign.campaignSelected
  );
  const { dashboardList, dashboardSelectedById } = useAppSelector(
    (state) => state.storeCustomDashboard
  );
  const { id } = initialValues;
  const [open, setOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const objectList = useAppSelector(
    (state) => state.objectManagement.objectList.data
  );

  const objectListSocialProfile = useMemo((): Array<IObject> => {
    return objectList.filter((object) => object.streamType === 'account');
  }, [objectList]);

  const objectListKeywordAndHashtag = useMemo((): Array<IObject> => {
    return objectList.filter((object) => object.streamType === 'keyword');
  }, [objectList]);

  const objectListHashtag = useMemo((): Array<IObject> => {
    return objectList.filter((object) => object.isHashtag);
  }, [objectList]);

  const [selectedSocialProfileIds, setSelectedSocialProfileIds] = useState<
    Array<string>
  >(objectListSocialProfile.map((object) => object.id));

  const [selectedKeywordIds, setSelectedKeywordIds] = useState<Array<string>>(
    objectListKeywordAndHashtag.map((object) => object.id)
  );

  const [selectedHashtagIds, setSelectedHashtagIds] = useState<Array<string>>(
    objectListHashtag.map((object) => object.id)
  );

  const groupObjectById = groupObject.data.find(
    (group) => group.id === (isEditing ? id : groupSelectedById)
  );

  const handleClickDone = () => {
    if (isHastag) {
      if (!campaignSelected.id) {
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          severity: 'error',
          message: 'You have to create campaign first'
        });
        return;
      }
      editCampagin({
        variables: {
          projectId,
          objectIds: selectedHashtagIds,
          campaignName: campaignSelected.campaignName,
          campaignId: campaignSelected.id
        }
      }).then((response) => {
        dispatch(
          reducerUpdateSelectedCampaign(response.data.campaign_editCampaignV2)
        );
        handleClose();
      });
      return;
    } else if (isDashboard) {
      const tempDashboard = dashboardList.data.filter(
        (dashboard) => dashboard.id === dashboardSelectedById
      )[0];
      const payload = {
        displayName: tempDashboard.displayName,
        objectIds: isSocialProfile
          ? selectedSocialProfileIds
          : selectedKeywordIds,
        projectId,
        customDashboardId: dashboardSelectedById
      };
      objectManagementEditDashboard({
        variables: payload
      }).then((response) => {
        const { id, objectList } =
          response.data?.customDashboard_editCustomDashboard;
        const objectIdList = objectList.map((object: IObject) => object.id);
        const updateDashboard = dashboardList.data.map((dashboard) => {
          if (dashboard.id === id) {
            return { ...dashboard, objectList };
          }
          return dashboard;
        });
        dispatch(
          reducerUpdateDashboardList({
            ...dashboardList,
            data: updateDashboard
          })
        );
        dispatch(reducerUpdateObjectListByObjectType(objectList));
        dispatch(reducerUpdateAnalyticArgumentObjectIdList(objectIdList));
        handleClose();
      });
      return;
    }
    objectManagementEditGroup({
      variables: {
        projectId,
        objectList: isSocialProfile
          ? selectedSocialProfileIds
          : selectedKeywordIds,
        objectGroupId: isEditing ? id : groupSelectedById,
        groupName: groupObjectById.displayName,
        templateName: 'content'
      }
    }).then((response) => {
      const groupId = response.data?.objectManagement_editObjectGroup?.id;
      const objectList =
        response.data?.objectManagement_editObjectGroup?.objectList;
      const objectIdList = objectList.map((object: IObject) => object.id);
      const updateGroup = groupObject.data.map((group) => {
        if (group.id === groupId) {
          return { ...group, objectList };
        }
        return group;
      });
      dispatch(reducerUpdateGroupObject({ ...groupObject, data: updateGroup }));
      dispatch(reducerUpdateObjectListByObjectType(objectList));
      dispatch(reducerUpdateAnalyticArgumentObjectIdList(objectIdList));
      isEditing ? onCloseEdit() : handleClose();
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        sx={{
          color: theme.palette.primary.dark,
          border: `1px solid ${theme.palette.primary.dark}`
        }}
      >
        {textButton}
      </Button>
      <Dialog
        onClose={isEditing ? onCloseEdit : handleClose}
        open={isEditing ? isEditing : open}
        fullWidth
        maxWidth={'md'}
        sx={{ opacity: hide ? 0 : 1 }}
      >
        <IconButton
          aria-label="close"
          onClick={isEditing ? onCloseEdit : handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        {isHastag ? (
          <SectionHashtag
            objectList={objectListHashtag}
            onParentVisible={() => setHide((prev) => !prev)}
            onSelect={(hashtagIds) => setSelectedHashtagIds(hashtagIds)}
          />
        ) : isSocialProfile ? (
          <SectionSocialProfile
            objectList={objectListSocialProfile}
            onParentVisible={() => setHide((prev) => !prev)}
            onSelect={(socialProfileIds) =>
              setSelectedSocialProfileIds(socialProfileIds)
            }
          />
        ) : (
          <SectionKeywordHashtag
            objectList={objectListKeywordAndHashtag}
            onParentVisible={() => setHide((prev) => !prev)}
            onSelect={(keywordAndHashtagIds) =>
              setSelectedKeywordIds(keywordAndHashtagIds)
            }
          />
        )}
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pb: theme.spacing(5)
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: theme.palette.primary.dark,
              width: theme.spacing(20)
            }}
            size="large"
            onClick={handleClickDone}
            disabled={
              resObjectManagementEditGroup.loading || resEditCampagin.loading
                ? true
                : false
            }
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalManageObject;
