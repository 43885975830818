import gql from 'graphql-tag';
import { analyticArguments, analyticArgumentsQuery } from '../analyticArgument';

export const GET_OVERVIEW_TOTAL_REACH = gql`
  query overview_getReach(${analyticArgumentsQuery}) {
    overview_getReach(${analyticArguments}) {
      contentType
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_OVERVIEW_TOTAL_TALK = gql`
  query overview_getTalk(${analyticArgumentsQuery}) {
    overview_getTalk(${analyticArguments}) {
      contentType
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_OVERVIEW_TOTAL_ENGAGEMENT = gql`
  query overview_getEngagement(${analyticArgumentsQuery}) {
    overview_getEngagement(${analyticArguments}) {
      contentType
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_OVERVIEW_TOTAL_FOLLOWERS = gql`
  query overview_getTotalFollower(${analyticArgumentsQuery}) {
    overview_getTotalFollower(${analyticArguments}) {
      contentType
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_OVERVIEW_CHRONOLOGICAL_ENGAGEMENT = gql`
  query overview_getChronologicalEngagement(${analyticArgumentsQuery}) {
    overview_getChronologicalEngagement(${analyticArguments}) {
      name
      values{
        timestamp
        value
      }
    }
  }
`;
export const GET_OVERVIEW_CHRONOLOGICAL_AUDIENCE = gql`
  query overview_getChronologicalAudience(${analyticArgumentsQuery}) {
    overview_getChronologicalAudience(${analyticArguments}) {
      name
      values{
        timestamp
        value
      }
    }
  }
`;
export const GET_OVERVIEW_CHRONOLOGICAL_TALK_OVERVIEW = gql`
  query overview_getChronologicalTalkOverview(${analyticArgumentsQuery}) {
    overview_getChronologicalTalkOverview(${analyticArguments}) {
      name
      values{
        timestamp
        value
      }
    }
  }
`;
export const GET_OVERVIEW_CHRONOLOGICAL_REACH = gql`
  query overview_getChronologicalReach(${analyticArgumentsQuery}) {
    overview_getChronologicalReach(${analyticArguments}) {
      name
      values{
        timestamp
        value
      }
    }
  }
`;

export const GET_OVERVIEW_STREAM_PREVIEW = gql`
query overview_getStreamPreview(${analyticArgumentsQuery}) {
    overview_getStreamPreview(${analyticArguments}) {
      result{
        originalId
        socialMedia
        displayPicture
        link
        content
        fromId
        fromName
        timestamp
        like
        share
        comment
        audience
        engagementRate
        sentiment
        objectModel{
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
          createdAt
          openApiId
        }
        postOwnership
      }
      pagination{
        next 
        hasNext
      }
  }
}`;
