import { FC, ReactNode, useMemo, useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  IconButton,
  ListItemButton,
  Box,
  Stack,
  Typography,
  useTheme
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { reducerUpdateAnalyticArgumentObjectIdList } from 'src/features/analyticArguments';
import {
  reducerUpdateObjectListByObjectType,
  reducerUpdateSelectedObjectType
} from 'src/features/objectManagement';
import { reducerUpdateSelectedCampaign } from 'src/features/sosmedCampaign';
import { ICampaign } from 'src/models/campaign';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
}

const Dot = styled(Box)(
  ({ theme }) => `
    height: 8px;
    width: 8px;
    background-color: ${theme.sidebar.menuItemColorActive};
    border-radius: 50%;
    display: inline-block;
    margin-right: 4px;
    transform: translateY(-2px);
  `
);

const MainMenu = styled(Typography)(
  ({ theme }) => `
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: #F5F5F5;
  `
);

const ListItemCustom = styled(ListItem)(
  ({ theme }) => `
    cursor: pointer;
    .MuiListItemButton-root {
      padding-top: 0px;
      padding-bottom: 0px;
      -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      padding-left: 0px;
      padding-right: 0px;
      &:hover {
        background-color: unset;
      }
    }
    
      .MuiListItemIcon-root {
        font-family: Red Hat Display;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #F5F5F5;
        min-width: ${theme.spacing(5)};
      }
      
      
    }
`
);

// .MuiTypography-root {
//   font-family: Red Hat Display;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 130%;
//   letter-spacing: -0.02em;
//   color: #F5F5F5;
// }
// .MuiListItemSecondaryAction-root {
//   font-family: Red Hat Display;
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 130%;
//   letter-spacing: -0.02em;
//   .MuiButtonBase-root {
//     color: #F5F5F5;
//   }

const CollapseCustom = styled(Collapse)(
  ({ theme }) => `
    .MuiCollapse-wrapper {
      .MuiCollapse-wrapperInner {
        .MuiList-root {
          padding: 0px;
          .MuiListItem-root {
            padding-top: ${theme.spacing(0.6)};
          }
          .MuiListItemButton-root {
            .MuiListItemText-root {
              padding-bottom: ${theme.spacing(1.2)};
              border-bottom: 1px solid ${theme.sidebar.dividerBg};
            }
          }
        }
      }
    }
  `
);

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector((state) => state.users.project.id);
  const { objectList, groupSelectedById, groupObject } = useAppSelector(
    (state) => state.objectManagement
  );

  const activeGroupObject = useMemo(() => {
    // if no group selected return undefined
    if (groupSelectedById === '_default') return undefined;
    // return current selected group object
    return groupObject.data.find((group) => group.id === groupSelectedById);
  }, [groupSelectedById, groupObject]);

  const activeGroupObjectIdList = useMemo(() => {
    if (!activeGroupObject) return undefined;
    return activeGroupObject.objectList.map((object) => object.id);
  }, [activeGroupObject]);

  // all object id from object list in current project
  const allObjectIds = useMemo(() => {
    return objectList.data.map((object) => object.id);
  }, [objectList]);

  // all hashtag object
  const objectListHashtag = useMemo(() => {
    return objectList.data.filter(
      (object) => object.streamType === 'keyword' && object.isHashtag
    );
  }, [objectList]);

  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };

  // reset active object id when page changes
  const onClickMenuChild = (menuName: string) => {
    if (menuName === 'Dashboard') return;
    // for campaign page
    if (menuName === 'Campaign') {
      const allCampaign: ICampaign = {
        id: '_default',
        campaignName: 'All Hashtag',
        campaignObjectDetails: [],
        campaignProgress: 'ongoing',
        createdAt: new Date(),
        dateEnd: new Date(),
        dateStart: new Date(),
        projectId: projectId
      };
      dispatch(reducerUpdateSelectedCampaign(allCampaign));
      const objectIdListHashtag = objectListHashtag.map((object) => object.id);
      dispatch(reducerUpdateObjectListByObjectType(objectListHashtag));
      dispatch(reducerUpdateAnalyticArgumentObjectIdList(objectIdListHashtag));
      return;
    }
    dispatch(reducerUpdateSelectedObjectType('all'));
    dispatch(
      reducerUpdateObjectListByObjectType(
        !activeGroupObject ? objectList.data : activeGroupObject.objectList
      )
    );
    dispatch(
      reducerUpdateAnalyticArgumentObjectIdList(
        activeGroupObjectIdList ?? allObjectIds
      )
    );
  };

  if (children) {
    return (
      <>
        <ListItemCustom
          key={name}
          {...rest}
          onClick={toggleMenu}
          secondaryAction={
            <IconButton edge="end" aria-label="delete">
              {menuToggle ? (
                <ArrowDropUpIcon sx={{ color: '#FBFBFB' }} />
              ) : (
                <ArrowDropDownIcon sx={{ color: '#FBFBFB' }} />
              )}
            </IconButton>
          }
        >
          <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
          <ListItemText primary={<MainMenu>{name}</MainMenu>} />
        </ListItemCustom>
        <CollapseCustom in={menuToggle}>{children}</CollapseCustom>
      </>
    );
  }

  return (
    <ListItemCustom key={name} {...rest}>
      <ListItemButton
        onClick={() => onClickMenuChild(name)}
        component={RouterLink}
        to={link}
        activeClassName="Mui-active"
      >
        <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
        <ListItemText
          primary={
            active ? (
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Dot />
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme.sidebar.menuItemColorActive,
                    fontWeight: 600
                  }}
                >
                  {name}
                </Typography>
              </Stack>
            ) : (
              <Typography
                variant="subtitle2"
                sx={{
                  color: '#FBFBFB',
                  fontWeight: 600
                }}
              >
                {name}
              </Typography>
            )
          }
          primaryTypographyProps={active ? { style: { color: '#009FE3' } } : {}}
        />
      </ListItemButton>
    </ListItemCustom>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false
};

export default SidebarMenuItem;
