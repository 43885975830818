/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  Stack,
  TextField,
  IconButton,
  Button,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SelectDateRange from 'src/components/SelectDateRange';
import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import ClearIcon from '@mui/icons-material/Clear';
import changeObjectToQuery from 'src/utils/changeObjectToQuery';
import FilterListIcon from '@mui/icons-material/FilterList';
import TooltipCustomize from 'src/components/TooltipCustomize';
import RefreshIcon from '@mui/icons-material/Refresh';
import { IGranularity, ITableAtribute } from 'src/models/general';
import AdvanceSearchPeoplePanel from 'src/components/AdvanceSearchPeoplePanel';
import { reducerUpdateSelectedAllPrimary, reducerUpdateSelectedPeople } from 'src/features/people';
import usePeople from 'src/services/usePeople';
import { fillPeopleAdvanceSearchParams } from 'src/utils/fillPeopleAdvanceSearchParams';
import { initialState, reducerUpdateIncludeUsername, reducerUpdatePeopleAdvanceSearch } from 'src/features/advanceSearchPeoplePanel';

const CustomBox = styled(Box)(
  ({ theme }) => `
    // width: calc(100vw - ${theme.sidebar.width} - ${theme.spacing(10)});
    margin: ${theme.spacing(2, 0)};
    // background: #fbfbfb;
    // position: absolute;
`
);

const FilterBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
);

function BottomHeader() {
  const dispatch = useAppDispatch();
  const peopleAdvanceSearch = useAppSelector(
    (state) => state.storePeopleAdvanceSearch
  );
  const limit = useAppSelector(
    (state) => state.storeStreamPanel.streamPanel.data.limit
  );

  const {
    labelList,
    interestList,
    genderList,
    ...rest
  } = peopleAdvanceSearch;

  const query = changeObjectToQuery(rest);
  const [localSearch, setLocalSearch] = useState<string>(query);

  const handleClear = () => {
    setLocalSearch('');
    dispatch(reducerUpdatePeopleAdvanceSearch(initialState));
  };

  const handleSearch = () => {
    dispatch(reducerUpdateIncludeUsername([localSearch]));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') handleSearch();
  };

  useEffect(() => {
    setLocalSearch(query);
  }, [peopleAdvanceSearch]);

  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const projectId = useAppSelector((state) => state.users.project.id);

  const filterAdvanceSearchPeople = useMemo(() => {
    return fillPeopleAdvanceSearchParams(peopleAdvanceSearch);
  }, [peopleAdvanceSearch]);

  const { dateRangeFormatted, objectIdList } = useAppSelector(
    (state) => state.analyticArguments
  );

  const objectList = useAppSelector(
    (state) => state.objectManagement.objectList.data
  );

  const objectListByObjectTypeActive = useMemo(() => {
    const dataList = objectList.filter((object) =>
      objectIdList.find((id) => id === object.id)
    );
    return dataList.map((object) => {
      return object.id;
    });
  }, [objectIdList]);

  const granularity: IGranularity = {
    unit: 'day',
    value: 1
  };

  const initialTableAttribute: ITableAtribute = {
    columnName: 'dateColumn',
    limit: limit,
    page: 1,
    sortingMethod: 'desc'
  };

  const finalPayload = {
    ...filterAdvanceSearchPeople,
    projectId,
    ...dateRangeFormatted,
    objectList: objectListByObjectTypeActive,
    granularity,
    timezone: 7,
    ...initialTableAttribute,
    sizeToFetch: 20
  };

  const {
    getPeople,
    getIgnoredPeopleList
  } = usePeople();

  const handleRefresh = () => {
    // empty selected people
    dispatch(reducerUpdateSelectedPeople([]))
    // set selected all primary to false
    dispatch(reducerUpdateSelectedAllPrimary(true))
    // get people
    getPeople(finalPayload)
    getIgnoredPeopleList(finalPayload)
  };

  return (
    <CustomBox>
      <FilterBox>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            size="small"
            sx={{ width: 509 }}
            InputProps={{
              startAdornment: (
                <IconButton size="small" onClick={handleSearch}>
                  <SearchTwoToneIcon />
                </IconButton>
              ),
              endAdornment: localSearch ? (
                <IconButton size="small" onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              ) : null
            }}
            placeholder="Search stream"
            onChange={(e) => setLocalSearch(e.target.value)}
            value={localSearch}
            onKeyDown={handleKeyDown}
          />
          <TooltipCustomize title="Advance search" placement="top-start">
            <Button
              variant="contained"
              sx={{
                borderRadius: '6px',
                height: theme.spacing(5),
                minWidth: theme.spacing(6),
                background: theme.palette.primary.dark
              }}
              onClick={() => handleClickOpen()}
            >
              <FilterListIcon />
            </Button>
          </TooltipCustomize>
          {open && (
            <AdvanceSearchPeoplePanel open={open} onClose={handleClose} />
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          <FormControl size="small" sx={{ width: 135 }}>
            <SelectDateRange />
          </FormControl>
          <TooltipCustomize title="Refresh all metric" placement="top-start">
            <Button
              variant="contained"
              onClick={handleRefresh}
              sx={{
                borderRadius: '6px',
                height: theme.spacing(5),
                minWidth: theme.spacing(6),
                background: theme.palette.primary.main
              }}
            >
              <RefreshIcon />
            </Button>
          </TooltipCustomize>
        </Stack>
      </FilterBox>
    </CustomBox>
  );
}

export default BottomHeader;
