import { useAppDispatch } from 'src/app/hooks';
import {
  ITopNotification,
  reducerUpdateOpenTopNotification,
  reducerUpdateTopNotification
} from 'src/features/alert';

export const useTopNotification = () => {
  const dispatch = useAppDispatch();
  const handleShowTopNotification = (newState: ITopNotification) => {
    dispatch(reducerUpdateTopNotification({ open: true, ...newState }));
  };
  const handleCloseNotification = () => {
    dispatch(reducerUpdateOpenTopNotification(false));
  };
  return { handleShowTopNotification, handleCloseNotification };
};
