/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from 'react';
import {
  Button,
  Divider,
  Fab,
  Grid,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material';
import { Box } from '@mui/system';
import { useTheme, styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useLocation } from 'react-router';
import { useCounter } from 'src/hooks/useCounter';
import useRegister from 'src/services/useRegister';
import { useAppSelector } from 'src/app/hooks';
import separateNumberFormat from 'src/utils/separateNumberFormat';

interface Props {
  periodes: Array<any>;
  setPeriodes: any;
}

const LiteTypography = styled(Typography)(
  ({ theme }) => `
    background: ${theme.colors.gradients.blue1};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    `
);

const ButtonPeriod = styled(Button)(
  ({ theme }) => `
       padding: ${theme.spacing(1)};
       font-size: 12px;
       background: ${theme.colors.gradients.blue1};
       &:hover {
        background: ${theme.colors.gradients.blue1};
       };
    `
);

const ButtonIcon = styled(Fab)(
  ({ theme }) => `
    background: ${theme.colors.gradients.blue1};
    color: ${theme.palette.common.white};
    &:hover {
        background: ${theme.colors.gradients.blue2};
    }
      `
);

const PricingCalculator: React.FC<Props> = ({ periodes, setPeriodes }) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const planParam = params.get('plan');
  const planCurrency = params.get('currency');
  const socialProfile = useCounter(0);
  const keywordOrHashtag = useCounter(0);
  const theme = useTheme();

  const { getUserPackagePlan, getUserPlanSimulation } = useRegister();

  const packagePlan = useAppSelector((state) => state.register.userPackagePlan);
  const planSimulation = useAppSelector(
    (state) => state.register.planSimulation
  );
  const selectedCountryId = useAppSelector(
    (state) => state.register.selectedCountryId
  );
  const initCountryId =
    planCurrency === 'idr'
      ? '9cd88e16-510e-49f0-9ce8-f4d27f0b85bb'
      : '162021c9-becc-11eb-a7ce-1e87ea3f1b00';

  const handleChangePeriod = (id: string) => {
    setPeriodes((prevPeriodes) =>
      prevPeriodes.map((period) => {
        if (period.id === id) return { ...period, isActive: true };
        return { ...period, isActive: false };
      })
    );
  };

  useEffect(() => {
    getUserPackagePlan();
  }, []);

  useEffect(() => {
    const socialProfileByPackage = packagePlan.data.find(
      (plan) => plan.name === planParam
    );

    if (!socialProfileByPackage) return;

    const packagePlanId = socialProfileByPackage.id;

    const basicObjectQuota = socialProfileByPackage.basicObjectQuota;

    const advanceObjectQuota = socialProfileByPackage.advanceObjectQuota;

    socialProfile.setInitCount(basicObjectQuota);

    keywordOrHashtag.setInitCount(advanceObjectQuota);

    if (basicObjectQuota < 1 && advanceObjectQuota < 1) return;
    getUserPlanSimulation({
      basicObject: basicObjectQuota,
      advanceObject: advanceObjectQuota,
      countryId:
        selectedCountryId.length < 1 ? initCountryId : selectedCountryId,
      onlineMediaObject: 0,
      packagePlanId,
      period: 1
    });
  }, [packagePlan, selectedCountryId]);

  useEffect(() => {
    const socialProfileByPackage = packagePlan.data.find(
      (plan) => plan.name === planParam
    );
    if (!socialProfileByPackage) return;
    const packagePlanId = socialProfileByPackage.id;
    const valuePeriod = periodes.find((period) => period.isActive).value;
    getUserPlanSimulation({
      basicObject: socialProfile.count,
      advanceObject: keywordOrHashtag.count,
      countryId:
        selectedCountryId.length < 1 ? initCountryId : selectedCountryId,
      onlineMediaObject: 0,
      packagePlanId,
      period: valuePeriod
    });
  }, [periodes]);

  useEffect(() => {
    const socialProfileByPackage = packagePlan.data.find(
      (plan) => plan.name === 'custom'
    );
    if (!socialProfileByPackage) return;
    const packagePlanId = socialProfileByPackage.id;
    const valuePeriod = periodes.find((period) => period.isActive).value;
    getUserPlanSimulation({
      basicObject: socialProfile.count,
      advanceObject: keywordOrHashtag.count,
      countryId:
        selectedCountryId.length < 1 ? initCountryId : selectedCountryId,
      onlineMediaObject: 0,
      packagePlanId,
      period: valuePeriod
    });
  }, [socialProfile.count, keywordOrHashtag.count]);

  const periodLabel = useMemo(
    () => periodes.find((period) => period.isActive).period,
    [periodes]
  );

  return (
    <Paper
      sx={{
        width: theme.spacing(49),
        transform: 'translateY(60px)',
        padding: 4,
        [theme.breakpoints.down('sm')]: {
          pt: 0,
          mb: '56px'
        },
        borderRadius: '12px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <LiteTypography variant="h1">
          {planParam.charAt(0).toUpperCase() + planParam.slice(1)}
        </LiteTypography>
        {/* <Button>Change</Button> */}
      </Box>
      <Box
        sx={{
          height: theme.spacing(5),
          width: '100%',
          background: '#F5F5F5',
          borderRadius: '100px',
          mt: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Grid container>
          {periodes.map(({ id, label, isActive }) => {
            if (isActive)
              return (
                <Grid item lg={4} key={id}>
                  <ButtonPeriod variant="contained" fullWidth>
                    {label}
                  </ButtonPeriod>
                </Grid>
              );
            return (
              <Grid item lg={4} key={id}>
                <Button
                  variant="text"
                  fullWidth
                  onClick={() => handleChangePeriod(id)}
                >
                  {label}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box
        sx={{
          my: 3
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 'bold', marginBottom: 1 }}
        >
          Starting from
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 800, mr: theme.spacing(0.5) }}
          >{`${
            planSimulation.data.symbolBySelectedCountry
          } ${separateNumberFormat(
            planSimulation.data.totalBySelectedCountry
          )}`}</Typography>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 'bold', transform: 'translateY(2px)' }}
          >{` / ${periodLabel}`}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          Social Profile
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: theme.spacing(16)
          }}
        >
          <ButtonIcon
            aria-label="minus"
            size="small"
            onClick={() => socialProfile.decrement()}
            disabled={socialProfile.count < 1}
          >
            <RemoveIcon
              sx={{
                background: theme.palette.common.white,
                borderRadius: '50%',
                color: '#47A3E6',
                fontSize: theme.spacing(4)
              }}
            />
          </ButtonIcon>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {socialProfile.count}
          </Typography>
          <ButtonIcon
            aria-label="plus"
            size="small"
            onClick={() => socialProfile.increment()}
          >
            <AddIcon
              sx={{
                background: theme.palette.common.white,
                borderRadius: '50%',
                color: '#47A3E6',
                fontSize: theme.spacing(4)
              }}
            />
          </ButtonIcon>
        </Box>
      </Box>
      <Divider
        sx={{
          my: theme.spacing(2)
        }}
      />
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
          Keyword & Hashtag
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: theme.spacing(16)
          }}
        >
          <ButtonIcon
            aria-label="minus"
            size="small"
            onClick={() => keywordOrHashtag.decrement()}
            disabled={keywordOrHashtag.count < 1}
          >
            <RemoveIcon
              sx={{
                background: theme.palette.common.white,
                borderRadius: '50%',
                color: '#47A3E6',
                fontSize: theme.spacing(4)
              }}
            />
          </ButtonIcon>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            {keywordOrHashtag.count}
          </Typography>
          <ButtonIcon
            aria-label="plus"
            size="small"
            onClick={() => keywordOrHashtag.increment()}
          >
            <AddIcon
              sx={{
                background: theme.palette.common.white,
                borderRadius: '50%',
                color: '#47A3E6',
                fontSize: theme.spacing(4)
              }}
            />
          </ButtonIcon>
        </Box>
      </Box>
      <Divider
        sx={{
          mt: theme.spacing(4),
          position: 'absolute',
          left: 0,
          right: 0
        }}
      />
      <Box
        sx={{
          mt: theme.spacing(7)
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <Typography variant="h2">{`${
            planSimulation.data.symbolBySelectedCountry
          } ${separateNumberFormat(
            planSimulation.data.totalBySelectedCountry
          )}`}</Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 'bold',
              ml: theme.spacing(0.5),
              transform: 'translateY(4px)'
            }}
          >{` / ${periodLabel}`}</Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default PricingCalculator;
