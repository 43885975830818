import { Box, Divider, styled, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useAppSelector } from 'src/app/hooks';

interface DateRangeLabelProps {
  label: string;
}

const FooterBox = styled(Box)(
  ({ theme }) => `
    width: 100%;
    margin-top: ${theme.spacing(2)};
    display: flex;
    align-items: center;
  `
);

const DateRangeLabel: React.FC<DateRangeLabelProps> = ({ label }) => {
  const theme = useTheme();
  const dateRangeOptionActive = useAppSelector(
    (state) => state.analyticArguments.dateRangeOptionActive
  );

  const dateRangeLabel = () => {
    if (dateRangeOptionActive.id !== 'custom') {
      return dateRangeOptionActive.label;
    }
    return `${moment(dateRangeOptionActive.start).format('L HH:mm')} - ${moment(
      dateRangeOptionActive.end
    ).format('L HH:mm')}`;
  };
  return (
    <FooterBox>
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 600,
          flexGrow: 'inherit',
          textTransform: 'lowercase'
        }}
      >
        {label}
        <span
          style={{
            color: theme.palette.primary.main,
            marginLeft: theme.spacing(1)
          }}
        >
          {dateRangeLabel()}
        </span>
      </Typography>
      <Divider
        sx={{ gridArea: 'divider', flexGrow: 1, ml: theme.spacing(1) }}
      />
    </FooterBox>
  );
};

export default DateRangeLabel;
