import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAnalyticArguments,
  IDateRange,
  IOptionDateRange
} from 'src/models/general';
import moment from 'moment';
import defaultDateRange from 'src/utils/defaultDateRange';

let now = new Date();
let start = moment(
  new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
);
let end = moment(start).add(1, 'days').subtract(1, 'seconds');

const initialState: IAnalyticArguments = {
  dateRange: {
    start: moment(defaultDateRange().startDate).toDate(),
    end: moment(defaultDateRange().endDate).toDate()
  },
  dateRangeFormatted: {
    start: moment(defaultDateRange().startDate).format('YYYY-MM-DD HH:mm:ss'),
    end: moment(defaultDateRange().endDate).format('YYYY-MM-DD HH:mm:ss')
  },
  objectIdList: [],
  dateRangeOptionActive: {
    id: 'one-week',
    label: '1 Week',
    value: 1,
    start: moment(start).subtract(7, 'days').toDate(),
    end: moment(end).toDate()
  },
  maxCountNode: 50
};

export const analyticArguments = createSlice({
  name: 'analyticArguments',
  initialState,
  reducers: {
    reducerUpdateAnalyticArgumentDateRange: (
      state: IAnalyticArguments,
      action: PayloadAction<IDateRange>
    ) => {
      state.dateRange = action.payload;
      state.dateRangeFormatted = {
        start: moment(action.payload.start).format('YYYY-MM-DD HH:mm:ss'),
        end: moment(action.payload.end).format('YYYY-MM-DD HH:mm:ss')
      };
    },
    reducerUpdateAnalyticArgumentObjectIdList: (
      state: IAnalyticArguments,
      action: PayloadAction<Array<string>>
    ) => {
      state.objectIdList = action.payload;
    },

    reducerUpdateAnalyticArgumentDateRangeOptions: (
      state: IAnalyticArguments,
      action: PayloadAction<IOptionDateRange>
    ) => {
      state.dateRangeOptionActive = action.payload;
    },
    reducerUpdateMaxCountNode: (
      state: IAnalyticArguments,
      action: PayloadAction<string | number>
    ) => {
      state.maxCountNode = action.payload;
    }
  }
});

export const {
  reducerUpdateAnalyticArgumentDateRange,
  reducerUpdateAnalyticArgumentObjectIdList,
  reducerUpdateAnalyticArgumentDateRangeOptions,
  reducerUpdateMaxCountNode
} = analyticArguments.actions;

export default analyticArguments.reducer;
