import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICountry, IIndustry, IPreparation } from 'src/models/onboarding';

interface OnBoarding {
  industries: ApolloQueryResult<Array<IIndustry>>;
  preparation: ApolloQueryResult<IPreparation>;
  countryByProject: ApolloQueryResult<ICountry>;
}

const initialState: OnBoarding = {
  industries: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  preparation: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  countryByProject: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  }
};

export const onboarding = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    reducerUpdateIndustries: (
      state: OnBoarding,
      action: PayloadAction<ApolloQueryResult<Array<IIndustry>>>
    ) => {
      state.industries = action.payload;
    },
    reducerPreparationProgress: (
      state: OnBoarding,
      action: PayloadAction<ApolloQueryResult<IPreparation>>
    ) => {
      state.preparation = action.payload;
    },
    reducerUpdateCountryByProject: (
      state: OnBoarding,
      action: PayloadAction<ApolloQueryResult<ICountry>>
    ) => {
      state.countryByProject = action.payload;
    },
  }
});

export const { reducerUpdateIndustries, reducerPreparationProgress, reducerUpdateCountryByProject } =
  onboarding.actions;

export default onboarding.reducer;
