import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAuthenticatedUserWithPublicCredential,
  IProject,
  IMemberWithPagination,
  IRole
} from 'src/models/users';

interface Users {
  isAuthenticated: boolean;
  userProfile: ApolloQueryResult<
    IAuthenticatedUserWithPublicCredential | undefined
  >;
  project: IProject | undefined;
  listMember: ApolloQueryResult<IMemberWithPagination>;
  pendingInvitation: ApolloQueryResult<IMemberWithPagination>;
  role: ApolloQueryResult<Array<IRole>>;
}
const token = window.localStorage.getItem('token');

const initialState: Users = {
  isAuthenticated: token ? true : false,
  project: undefined,
  userProfile: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  listMember: {
    data: {
      result: [],
      limit: 20,
      page: 1,
      totalRows: 0,
      totalPages: 0
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  pendingInvitation: {
    data: {
      result: [],
      limit: 20,
      page: 1,
      totalRows: 0,
      totalPages: 0
    },
    loading: false,
    errors: [],
    networkStatus: 1
  },
  role: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1
  }
};

export const users = createSlice({
  name: 'users',
  initialState,
  reducers: {
    reducerSignIn: (state: Users, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    reducerSetProject: (state: Users, action: PayloadAction<IProject>) => {
      state.project = action.payload;
    },
    reducerUpdateUsedSocalProfile: (
      state: Users,
      action: PayloadAction<number>
    ) => {
      state.project.onBoarding.basicObjectQuotaUsed = action.payload;
    },
    reducerUpdateUsedKeywordAndHashtag: (
      state: Users,
      action: PayloadAction<number>
    ) => {
      state.project.onBoarding.advanceObjectQuotaUsed = action.payload;
    },
    reducerUserProfile: (
      state: Users,
      action: PayloadAction<
        ApolloQueryResult<IAuthenticatedUserWithPublicCredential | undefined>
      >
    ) => {
      state.userProfile = action.payload;
    },
    reducerListMember: (state: Users, action: PayloadAction<any>) => {
      state.listMember = action.payload;
    },
    reducerPendingInvitation: (state: Users, action: PayloadAction<any>) => {
      state.pendingInvitation = action.payload;
    },
    reducerListRole: (
      state: Users,
      action: PayloadAction<ApolloQueryResult<Array<IRole>>>
    ) => {
      state.role = action.payload;
    }
  }
});

export const {
  reducerSignIn,
  reducerUserProfile,
  reducerUpdateUsedSocalProfile,
  reducerUpdateUsedKeywordAndHashtag,
  reducerSetProject,
  reducerListMember,
  reducerPendingInvitation,
  reducerListRole
} = users.actions;

export default users.reducer;
