import gql from 'graphql-tag';
import { analyticArguments, analyticArgumentsQuery } from '../analyticArgument';

export const GET_CONVERSATION_CONVERSATION_GROUP_TOTAL = gql`
  query conversation_getTotalGrouped(${analyticArgumentsQuery}) {
    conversation_getTotalGrouped(${analyticArguments}) {
      recentValue
      growth
      pastValue
      contentType
    }
  }`;

export const GET_CONVERSATION_GROUP_CHRONOLOGICAL_CONVERSATION = gql`
  query conversation_getChronologicalGrouped(${analyticArgumentsQuery}) {
    conversation_getChronologicalGrouped(${analyticArguments}) {
      contentType
      values {
        timestamp
        value
      }
    }
  }`;

export const GET_CONVERSATION_TALK = gql`
  query conversation_getTalk(${analyticArgumentsQuery}) {
    conversation_getTalk(${analyticArguments}) {
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_CONVERSATION_TALKER = gql`
  query conversation_getTalker(${analyticArgumentsQuery}) {
    conversation_getTalker(${analyticArguments}) {
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_CONVERSATION_POSIIVE_TALK = gql`
  query conversation_getTalkPositive(${analyticArgumentsQuery}) {
    conversation_getTalkPositive(${analyticArguments}) {
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_CONVERSATION_NEGATIVE_TALK = gql`
  query conversation_getTalkNegative(${analyticArgumentsQuery}) {
    conversation_getTalkNegative(${analyticArguments}) {
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_CONVERSATION_CHRONOLOGICAL_TALK = gql`
  query conversation_getChronologicalTalk(${analyticArgumentsQuery}) {
    conversation_getChronologicalTalk(${analyticArguments}) {
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_CONVERSATION_CHRONOLOGICAL_TALKER = gql`
  query conversation_getChronologicalTalker(${analyticArgumentsQuery}) {
    conversation_getChronologicalTalker(${analyticArguments}) {
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_CONVERSATION_TOP_TALK = gql`
query conversation_getTopTalk(${analyticArgumentsQuery}) {
    conversation_getTopTalk(${analyticArguments}) {
        originalId
        socialMedia
        displayPicture
        fromId
        fromName
        content
        link
        timestamp
        shareCount
        likeCount
        commentCount
        reach
        impression
        engagementRate
        benchmark {
          benchmark
        }
        viewCount
  }
}`;
export const GET_CONVERSATION_TOP_ENGAGEMENT = gql`
query conversation_getTopEngagement(${analyticArgumentsQuery}) {
  conversation_getTopEngagement(${analyticArguments}) {
      comment
      engagement
      followers
      fromName
      like
      share
      socialMedia
      talk
      value
      view
      following
  }
}`;

export const GET_CONVERSATION_TOP_TALK_WITH_PAGINATION = gql`
  query conversation_getTopTalkWithPaginationV2(${analyticArgumentsQuery},$columnName: SortingByColumn, $sortingMethod: SortingType,$page: Int!, $limit: Int!) {
    conversation_getTopTalkWithPaginationV2(${analyticArguments}, columnName: $columnName, sortingMethod: $sortingMethod, page: $page, limit: $limit) {
      totalRows
      totalPages
      page
      limit
      result{
        originalId
        socialMedia
        displayPicture
        fromId
        fromName
        content
        link
        timestamp
        shareCount
        likeCount
        commentCount
        reach
        impression
        engagementRate
        engagement
        benchmark {
          benchmark
        }
        viewCount
      }
    }
  }
`;

export const GET_CONVERSATION_TOP_INFLUENCER = gql`
query conversation_getTopInfluencer(${analyticArgumentsQuery}) {
    conversation_getTopInfluencer(${analyticArguments}) {
      comment
      engagement
      followers
      fromName
      like
      share
      socialMedia
      talk
      value
      view
      following
  }
}`;

export const GET_CONVERSATION_TOP_TALKER = gql`
query conversation_getTopTalker(${analyticArgumentsQuery}) {
    conversation_getTopTalker(${analyticArguments}) {
      comment
      engagement
      followers
      fromName
      like
      share
      socialMedia
      talk
      value
      view
      following
  }
}`;

export const GET_CONVERSATION_WORD_CLOUD_TALK = gql`
  query conversation_getWordCloudTalk(${analyticArgumentsQuery}) {
    conversation_getWordCloudTalk(${analyticArguments}) {
      name
      value
    }
  }
`;

export const GET_CONVERSATION_SENTIMENT_ANALYSIS = gql`
  query conversation_getSentimentAnalysis(${analyticArgumentsQuery}) {
    conversation_getSentimentAnalysis(${analyticArguments}) {
      name
      value
    }
  }
`;

export const GET_CONVERSATION_TALKER_BY_SENTIMENT = gql`
  query conversation_getTalkerBySentiment(${analyticArgumentsQuery}) {
    conversation_getTalkerBySentiment(${analyticArguments}) {
      name
      value
    }
  }
`;

export const GET_CONVERSATION_TALKER_BY_INTEREST = gql`
  query conversation_getInterest(${analyticArgumentsQuery}) {
    conversation_getInterest(${analyticArguments}) {
      name
      value
    }
  }
`;

export const GET_CONVERSATION_TALKER_BY_LOCATIONS = gql`
  query conversation_getLocation(${analyticArgumentsQuery}) {
    conversation_getLocation(${analyticArguments}) {
      name
      value
    }
  }
`;

export const GET_CONVERSATION_FOLLOWERS_BY_GENDER = gql`
  query conversation_getGender(${analyticArgumentsQuery}) {
    conversation_getGender(${analyticArguments}) {
      name
      value
    }
  }
`;

export const GET_CONVERSATION_AUDIENCE_ENGAGEMENT = gql`
query conversation_getAudienceEngagement(${analyticArgumentsQuery}) {
    conversation_getAudienceEngagement(${analyticArguments}) {
    max
    min
    peakTimeValue {
      name
      data
    }
  }
}
`;

export const GET_CONVERSATION_TALK_BY_MEDIA = gql`
  query conversation_getTalkByMedia(${analyticArgumentsQuery}) {
    conversation_getTalkByMedia(${analyticArguments}) {
      socialMedia
      talk
      talker
    }
  }
`;

export const GET_CONVERSATION_STREAM_PREVIEW = gql`
query content_getStreamPreview(${analyticArgumentsQuery}) {
    content_getStreamPreview(${analyticArguments}) {
      result{
        originalId
        socialMedia
        displayPicture
        link
        content
        fromId
        fromName
        timestamp
        like
        share
        comment
        audience
        engagementRate
        sentiment
        objectModel{
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
          createdAt
          openApiId
        }
        postOwnership
      }
      pagination{
        next 
        hasNext
      }
      total
      totalBySentiment {
        positive
        negative
        neutral
      }
  }
}
`;
