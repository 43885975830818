import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useAppDispatch } from 'src/app/hooks';
import { reducerUpdateWidgetMetaDataList } from 'src/features/customDashboard';
import {
  CUSTOM_DASHBOARD_DOWNLOAD_PDF,
  GET_CUSTOM_DASHBOARD_WIDGET_DATA,
  GET_CUSTOM_DASHBOARD_WIDGET_METADATA
} from './graphql/customDashboard/query';
import { reducerUpdateDashboardList } from 'src/features/customDashboard';
import { GET_CUSTOM_DASHBOARD } from './graphql/customDashboard/query';
import {
  ADD_CUSTOM_DASHBOARD,
  CUSTOM_DASHBOARD_CREATE_JOB_DOWNLOAD,
  CUSTOM_DASHBOARD_EDIT_WHITE_LABEL,
  CUSTOM_DASHBOARD_MANAGE_WIDGET,
  DELETE_CUSTOM_DASHBOARD,
  EDIT_CUSTOM_DASHBOARD
} from './graphql/customDashboard/mutation';
import {
  IDashboardObject,
  IDashboardWidgetDataPayload,
  IDataWidgetCustomDasboard,
  IWidgetMetaData
} from 'src/models/customDashboard';

interface IGetCustomDashboardResponse {
  customDashboard_getCustomDashboard: Array<IDashboardObject>;
}

interface IGetWidgetMetadataResponse {
  customDashboard_getCustomDashboardWidgetMetadata: Array<IWidgetMetaData>;
}

const useCustomDashboard = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const fetchCustomDashboardList = async function (projectId: string) {
    return await client
      .query<IGetCustomDashboardResponse>({
        query: GET_CUSTOM_DASHBOARD,
        variables: {
          projectId
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.customDashboard_getCustomDashboard
        };
      });
  };

  const getCustomDashboardList = async function (projectId: string) {
    const response = await fetchCustomDashboardList(projectId);
    dispatch(reducerUpdateDashboardList(response));
  };
  const [objectManagementAddDashboard, resObjectManagementAddDashboard] =
    useMutation(ADD_CUSTOM_DASHBOARD);
  const [objectManagementEditDashboard, resObjectManagementEditDashboard] =
    useMutation(EDIT_CUSTOM_DASHBOARD);

  const fetchCustomDashboardWidgetMetadata = async function () {
    const response = await client.query<IGetWidgetMetadataResponse>({
      query: GET_CUSTOM_DASHBOARD_WIDGET_METADATA,
      fetchPolicy: 'network-only'
    });
    return {
      ...response,
      data: response.data.customDashboard_getCustomDashboardWidgetMetadata
    };
  };

  const getCustomDashboardWidgetMetadata = async function () {
    const response = await fetchCustomDashboardWidgetMetadata();
    dispatch(reducerUpdateWidgetMetaDataList(response));
  };

  const fetchCustomDashboardWidgetData = async function (
    payload: IDashboardWidgetDataPayload
  ) {
    const response = await client.query<IDataWidgetCustomDasboard>({
      query: GET_CUSTOM_DASHBOARD_WIDGET_DATA,
      variables: payload,
      fetchPolicy: 'network-only'
    });
    return {
      ...response,
      data: response.data
    };
  };

  const getCustomDashboardWidgetData = async function (
    payload: IDashboardWidgetDataPayload
  ) {
    const response = await fetchCustomDashboardWidgetData(payload);
    return response;
  };

  const fetchCustomDashboardDownloadPdf = async function (
    payload: IDashboardWidgetDataPayload
  ) {
    const response = await client.query<{ url: string }>({
      query: CUSTOM_DASHBOARD_DOWNLOAD_PDF,
      variables: payload,
      fetchPolicy: 'network-only'
    });
    return {
      ...response,
      data: response.data
    };
  };

  const getCustomDashboardDownloadPdf = async function (
    payload: IDashboardWidgetDataPayload
  ) {
    try {
      const response = await fetchCustomDashboardDownloadPdf(payload);
      window.open(response.data.url, '_blank');
    } catch (err) {
      console.log(err);
    }
  };

  const [manageWidgetCustomDashboard, resManageWidgetCustomDashboard] =
    useMutation(CUSTOM_DASHBOARD_MANAGE_WIDGET);

  const [editCustomDahsboard, resEditCustomDashboard] = useMutation(
    CUSTOM_DASHBOARD_EDIT_WHITE_LABEL
  );

  const [deleteCustomDashboard, resDeleteCustomDashboard] = useMutation(
    DELETE_CUSTOM_DASHBOARD
  );

  const [createDownloadJob, resCreateDownloadJob] = useMutation(
    CUSTOM_DASHBOARD_CREATE_JOB_DOWNLOAD
  );

  return {
    getCustomDashboardWidgetMetadata,
    getCustomDashboardList,
    objectManagementAddDashboard,
    resObjectManagementAddDashboard,
    objectManagementEditDashboard,
    resObjectManagementEditDashboard,
    manageWidgetCustomDashboard,
    resManageWidgetCustomDashboard,
    getCustomDashboardWidgetData,
    getCustomDashboardDownloadPdf,
    editCustomDahsboard,
    resEditCustomDashboard,
    deleteCustomDashboard,
    resDeleteCustomDashboard,
    createDownloadJob,
    resCreateDownloadJob
  };
};

export default useCustomDashboard;
