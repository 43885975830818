import { FC, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {
  TextField,
  IconButton,
  Box,
  Typography,
  useTheme,
  Stack,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';
import ModalAddSocialProfile from 'src/components/ModalAddSocialProfile';
import { IObject, TObjectFilter } from 'src/models/objectManagement';
import ModalAddKeywordAndHashtag from 'src/components/ModalAddKeywordAndHashtag';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { reducerUpdateObjectType } from 'src/features/objectManagement';
interface Props {
  objectCount: any;
  onSearch: (keyword: string) => void;
  objectList: Array<IObject>;
}

export interface ObjectTypeOption {
  id: string;
  label: string;
  value: string;
}

export const objectTypeOptions: ObjectTypeOption[] = [
  {
    id: 'all',
    label: 'All',
    value: 'all'
  },
  {
    id: 'object',
    label: 'Object',
    value: 'object'
  },
  {
    id: 'entity',
    label: 'Entity based',
    value: 'entity'
  }
];

const HeaderObjects: FC<Props> = ({ objectCount, onSearch, objectList }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { account, keyword, accountQuota, keywordQuota } = objectCount;
  const objectType = useAppSelector(
    (state) => state.objectManagement.objectType
  );
  const title = pathname.split('/')[2];

  const objectListSocialProfile = useMemo((): Array<IObject> => {
    return objectList.filter((object) => object.streamType === 'account');
  }, [objectList]);

  const objectListKeywordAndHashtag = useMemo((): Array<IObject> => {
    return objectList.filter((object) => object.streamType === 'keyword');
  }, [objectList]);

  const handleSearch = function (e: any) {
    const keyword = e.target.value;
    onSearch(keyword);
  };

  const handleChangeObjectType = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    const selectedValue = event.target.value;
    dispatch(reducerUpdateObjectType(selectedValue as TObjectFilter));
  };

  return (
    <>
      <Stack direction={'row'} justifyContent="space-between">
        <Stack direction="row" gap={2}>
          <TextField
            size="small"
            sx={{ flexGrow: 1, minWidth: 404 }}
            InputProps={{
              startAdornment: (
                <IconButton size="small">
                  <SearchTwoToneIcon />
                </IconButton>
              )
            }}
            placeholder={`Search ${title}`}
            onChange={handleSearch}
          />
          <Select
            id="objectType"
            name="objectType"
            value={objectType}
            onChange={handleChangeObjectType}
            sx={{ minWidth: '140px' }}
          >
            <MenuItem key="placeholder" id="placeholder" value="" disabled>
              Object Type
            </MenuItem>
            {objectTypeOptions.map(({ id, value, label }) => (
              <MenuItem key={id} id={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <Stack direction="row" spacing={theme.spacing(2)}>
          <ModalAddSocialProfile
            title="Social Profile"
            objectList={objectListSocialProfile}
          />
          <ModalAddKeywordAndHashtag
            title="Hashtag & Keyword"
            objectList={objectListKeywordAndHashtag}
            buttonLabel="Keyword & Hashtag"
          />
        </Stack>
      </Stack>
      <Box sx={{ display: 'flex', marginTop: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: 600, fontSize: 14 }}>
          Account{' '}
          <Typography
            component="span"
            variant="h4"
            color={theme.palette.primary.main}
            sx={{ fontWeight: 600, fontSize: 14 }}
          >
            {account}/{accountQuota}
          </Typography>
        </Typography>
        <Typography
          variant="h4"
          sx={{ marginLeft: 2, fontWeight: 600, fontSize: 14 }}
        >
          Keyword & Hashtag{' '}
          <Typography
            component="span"
            variant="h4"
            color={theme.palette.primary.main}
            sx={{ fontWeight: 600, fontSize: 14 }}
          >
            {keyword}/{keywordQuota}
          </Typography>
        </Typography>
      </Box>
    </>
  );
};

export default HeaderObjects;
