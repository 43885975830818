import { ApolloQueryResult } from '@apollo/client';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IGroupPortal } from 'src/models/groupPortal';

interface IGroupPortalSlice {
  groupPortalList: ApolloQueryResult<IGroupPortal[]>;
  selectedGroupPortalId: string;
}

const initState: IGroupPortalSlice = {
  groupPortalList: {
    data: [],
    loading: false,
    networkStatus: 1,
    partial: false
  },
  selectedGroupPortalId: '_default'
};

export const groupPortalSlice = createSlice({
  name: 'groupPortal',
  initialState: initState,
  reducers: {
    updateGroupPortalList: (
      state: IGroupPortalSlice,
      action: PayloadAction<ApolloQueryResult<IGroupPortal[]>>
    ) => {
      state.groupPortalList = action.payload;
    },
    updateSelectedGroupPortalId: (
      state: IGroupPortalSlice,
      action: PayloadAction<string>
    ) => {
      state.selectedGroupPortalId = action.payload;
    }
  }
});

export const { updateGroupPortalList, updateSelectedGroupPortalId } =
  groupPortalSlice.actions;
export default groupPortalSlice.reducer;
