import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import sidebarToggleRducer from 'src/features/sidebarToggleSlice';
import registerReducer from 'src/features/register';
import usersReducer from 'src/features/users';
import onboardingReducer from 'src/features/onboarding';
import objectManagementReducer from 'src/features/objectManagement';
import advanceSearchSocialMedia from 'src/features/advanceSearchSocialMedia';
import sosmedOverview from 'src/features/sosmedOverview';
import sosmedStream from 'src/features/sosmedStream';
import sosmedConversation from 'src/features/sosmedConversation';
import sosmedCommunity from 'src/features/sosmedCommunity';
import sosmedContent from 'src/features/sosmedContent';
import analyticArguments from 'src/features/analyticArguments';
import advanceSearchOnlineMedia from 'src/features/advanceSearchOnlineMedia';
import onlineMediaStream from 'src/features/onlineMediaStream';
import offlineMediaStream from 'src/features/offlineMediaStream';
import sosmedCampaign from 'src/features/sosmedCampaign';
import customDashboard from 'src/features/customDashboard';
import sosmedComparison from 'src/features/sosmedComparison';
import onmComparison from 'src/features/onmComparison';
import label from 'src/features/label';
import alert from 'src/features/alert';
import download from 'src/features/download';
import billing from 'src/features/billing';
import networkAnalysist from 'src/features/networkAnalysist';
import people from 'src/features/people';
import advanceSearchPeoplePanel from 'src/features/advanceSearchPeoplePanel';
import advanceSearchOfflineMedia from 'src/features/advanceSearchOfflineMedia';
import customReport from 'src/features/customReport';
import autoRefreshMetricReducer from 'src/features/autoRefreshMetric';
import groupPortal from 'src/features/groupPortal';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  reducer: {
    sidebarToggle: sidebarToggleRducer,
    analyticArguments: analyticArguments,
    register: registerReducer,
    users: usersReducer,
    onBoarding: onboardingReducer,
    objectManagement: objectManagementReducer,
    storeAdvanceSearchSocialMedia: advanceSearchSocialMedia,
    storeOverview: sosmedOverview,
    storeStreamPanel: sosmedStream,
    storeConversation: sosmedConversation,
    storeCommunity: sosmedCommunity,
    storeContent: sosmedContent,
    storeAdvanceSearchOnlineMedia: advanceSearchOnlineMedia,
    storeOnlineMediaStream: onlineMediaStream,
    storeOfflineMediaStream: offlineMediaStream,
    storeSocialMediaCampaign: sosmedCampaign,
    storeCustomDashboard: customDashboard,
    storeLabel: label,
    storeAlert: alert,
    storeComparison: sosmedComparison,
    onmComparison: onmComparison,
    downloadState: download,
    storeBilling: billing,
    storeNetworkAnalysist: networkAnalysist,
    storePeople: people,
    storePeopleAdvanceSearch: advanceSearchPeoplePanel,
    advanceSearchOfflineMedia: advanceSearchOfflineMedia,
    storeCustomReport: customReport,
    autoRefreshMetric: autoRefreshMetricReducer,
    groupPortal: groupPortal
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
