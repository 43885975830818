import {
  MenuItem,
  Select,
  SelectChangeEvent,
  alpha,
  useTheme
} from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { setSelectedAutoRefreshInterval } from 'src/features/autoRefreshMetric';
import { useAlert } from 'src/hooks/useAlert';

const SelectAutoRefreshInterval = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { handleClickAlert } = useAlert();
  const { selectedAutoRefreshInterval } = useAppSelector(
    (store) => store.autoRefreshMetric
  );
  const { dateRangeOptionActive } = useAppSelector(
    (store) => store.analyticArguments
  );

  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setSelectedAutoRefreshInterval(Number(event.target.value)));
    if (Number(event.target.value) === 0) return;
    handleClickAlert({
      horizontal: 'center',
      vertical: 'top',
      message:
        'When auto refresh is activated, manage object or group is disabled.',
      severity: 'warning'
    });
  };

  useEffect(() => {
    if (dateRangeOptionActive?.id === 'today-only') return;
    dispatch(setSelectedAutoRefreshInterval(0));
  }, [dateRangeOptionActive]);

  return (
    <Select
      disabled={dateRangeOptionActive?.id === 'today-only' ? false : true}
      id="autoRefreshInterval"
      name="autoRefreshInterval"
      value={selectedAutoRefreshInterval.toString()}
      onChange={handleChange}
      sx={{
        '& .MuiSelect-select': {
          color: '#FFFFFF',
          backgroundColor: theme.palette.primary.main
        },
        '& .MuiSelect-select.Mui-disabled': {
          backgroundColor: alpha('#d3d3d3', 0.3)
        },
        '& .MuiSelect-icon': {
          color: '#FFFFFF'
        },
        '& .MuiSelect-icon.Mui-disabled': {
          color: '#d3d3d3'
        }
      }}
    >
      <MenuItem value={0}>Off</MenuItem>
      <MenuItem value={10 * 1000}>10 s</MenuItem>
      <MenuItem value={1 * 60 * 1000}>1 min</MenuItem>
      <MenuItem value={3 * 60 * 1000}>3 min</MenuItem>
      <MenuItem value={5 * 60 * 1000}>5 min</MenuItem>
      <MenuItem value={10 * 60 * 1000}>10 min</MenuItem>
      <MenuItem value={15 * 60 * 1000}>15 min</MenuItem>
    </Select>
  );
};

export default SelectAutoRefreshInterval;
