import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IStreamOutputWithPagination,
  RawStreamOutputEntity
} from 'src/models/stream';

interface IStream {
  streamPanel: ApolloQueryResult<IStreamOutputWithPagination>;
  ignoredStreamPanel: ApolloQueryResult<IStreamOutputWithPagination>;
  streamSelected: Array<RawStreamOutputEntity>;
  isSelectedAllPrimary: boolean;
}

const initialState: IStream = {
  streamPanel: {
    data: {
      result: [],
      limit: 20,
      page: 1,
      totalRows: 0,
      totalPages: 0
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  ignoredStreamPanel: {
    data: {
      result: [],
      limit: 20,
      page: 1,
      totalRows: 0,
      totalPages: 0
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  streamSelected: [],
  isSelectedAllPrimary: false
};

export const sosmedStream = createSlice({
  name: 'sosmedStreamPanel',
  initialState,
  reducers: {
    reducerUpdateStreamPanel: (
      state: IStream,
      action: PayloadAction<ApolloQueryResult<IStreamOutputWithPagination>>
    ) => {
      state.streamPanel = action.payload;
    },
    reducerUpdateStreamPanelByIgnored: (
      state: IStream,
      action: PayloadAction<IStreamOutputWithPagination>
    ) => {
        const updateTableStream = (prevTable, streamsUpdated) => {
        return {
          ...prevTable,
          totalRows:
          prevTable.totalRows -
          streamsUpdated.length,
          result: prevTable.result.filter((currData) => {
            const isIgnored =
            streamsUpdated.find(
                (engaged) => engaged.originalId === currData.originalId
              );
            return !isIgnored;
          })
        };
      };
      state.streamPanel.data = updateTableStream(state.streamPanel.data, action.payload);
    },
    reducerUpdateStreamPanelByChange: (
      state: IStream,
      action: PayloadAction<Array<RawStreamOutputEntity>>
    ) => {
      state.streamPanel.data.result = action.payload;
    },
    reducerUpdatePartOfStreamPanel: (
      state: IStream,
      action: PayloadAction<Array<RawStreamOutputEntity>>
    ) => {
      const updateTable = (prevTable, streamsUpdated) => {
        const tempStream = [...prevTable];
        streamsUpdated.forEach((stream) => {
          const elementIndex = prevTable.findIndex(
            (element) =>
              `${element.originalId}${element.objectModel.id}` ===
              `${stream.originalId}${stream.objectModel.id}`
          );

          tempStream[elementIndex] = {
            ...tempStream[elementIndex],
            sentiment: stream.sentiment,
            label: stream.label,
            isEdited: true
          };
        });
        return tempStream;
      };
      state.streamPanel.data.result = updateTable(state.streamPanel.data.result, action.payload);
    },
    reducerUpdateIgnoredStreamPanel: (
      state: IStream,
      action: PayloadAction<ApolloQueryResult<IStreamOutputWithPagination>>
    ) => {
      state.ignoredStreamPanel = action.payload;
    },
    reducerUpdateIgnoreStreamPanelByIgnored: (
      state: IStream,
      action: PayloadAction<IStreamOutputWithPagination>
    ) => {
      const updateTableIgnoreStream = (ignoredStreamOutputWithPagination, streamsUpdated) => {
        return {
          ...ignoredStreamOutputWithPagination,
          totalRows:
            ignoredStreamOutputWithPagination.totalRows +
            streamsUpdated.length,
          result: [
            ...ignoredStreamOutputWithPagination.result,
            ...streamsUpdated
          ]
        };
      };
      state.ignoredStreamPanel.data = updateTableIgnoreStream(state.ignoredStreamPanel.data, action.payload);
    },
    reducerUpdateAllMetricLoading: (
      state: IStream,
      action: PayloadAction<boolean>
    ) => {
      state.streamPanel.loading = action.payload;
    },
    reducerUpdateDataStreamPanel: (
      state: IStream,
      action: PayloadAction<Array<RawStreamOutputEntity>>
    ) => {
      const updateTable = (prevTable:Array<RawStreamOutputEntity>, payload:Array<RawStreamOutputEntity>) => {
        const tempStream = [...prevTable];
        payload.forEach((stream:RawStreamOutputEntity) => {
          const elementIndex = prevTable.findIndex(
            (element) =>
              `${element.originalId}${element.objectModel.id}` ===
              `${stream.originalId}${stream.objectModel.id}`
          );

          tempStream[elementIndex] = {
            ...tempStream[elementIndex],
            sentiment: stream.sentiment,
            label: stream.label,
            isEdited: true
          };
        });
        return tempStream;
      };
      state.streamPanel.data.result = updateTable(state.streamPanel.data.result, action.payload);
    },
    reducerUpdateLabelStreamPanel: (
      state: IStream,
      action: PayloadAction<Array<RawStreamOutputEntity>>
    ) => {
      const updateTable = (prevTable:Array<RawStreamOutputEntity>, payload:Array<RawStreamOutputEntity>) => {
        const tempStream = [...prevTable];
        payload.forEach((stream:RawStreamOutputEntity) => {
          const elementIndex = prevTable.findIndex(
            (element) =>
              `${element.originalId}${element.objectModel.id}` ===
              `${stream.originalId}${stream.objectModel.id}`
          );

          tempStream[elementIndex] = {
            ...tempStream[elementIndex],
            label: stream.label,
          };
        });
        return tempStream;
      };
      state.streamPanel.data.result = updateTable(state.streamPanel.data.result, action.payload);
    },
    reducerUpdateStreamSosmedSelected: (
      state: IStream,
      action: PayloadAction<Array<RawStreamOutputEntity>>
    ) => {
      state.streamSelected = action.payload;
    },
    reducerFinishStreamSosmedSelected: (state: IStream, action: PayloadAction<Array<RawStreamOutputEntity>>)=> {
      const update = state.streamSelected.filter((streamPrev) =>
        !action.payload.find(
          (streamUpdate) => streamUpdate.originalId === streamPrev.originalId
        )
      );
      state.streamSelected = update;
    },
    reducerUpdateIsSelectedAllPrimary: (
      state: IStream,
      action: PayloadAction<boolean>
    ) => {
      state.isSelectedAllPrimary = action.payload;
    }
  }
});

export const {
  reducerUpdateStreamPanel,
  reducerUpdateStreamPanelByIgnored,
  reducerUpdateIgnoreStreamPanelByIgnored,
  reducerUpdateAllMetricLoading,
  reducerUpdateStreamSosmedSelected,
  reducerUpdateDataStreamPanel,
  reducerUpdateIsSelectedAllPrimary,
  reducerUpdateStreamPanelByChange,
  reducerUpdateIgnoredStreamPanel,
  reducerUpdatePartOfStreamPanel,
  reducerFinishStreamSosmedSelected,
  reducerUpdateLabelStreamPanel
} = sosmedStream.actions;

export default sosmedStream.reducer;
