/* eslint-disable react-hooks/exhaustive-deps */
import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { useAppDispatch, useAppSelector } from './app/hooks';
import Loading from './content/pages/Status/Loading';
import { LOG_PROJECT_ACTIVE } from './services/graphql/users/mutation';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { reducerSignIn } from './features/users';

const App = () => {
  const isAuthenticated = useAppSelector(
    (state) => state.users.isAuthenticated
  );
  const project = useAppSelector((state) => state.users.project);
  const isOnboarding = useAppSelector(
    (state) => state.users.project?.onBoarding?.isOnBoarding
  );
  const isNetworkAnalysis = useAppSelector(
    (state) => state.users.project?.flags?.isSnaDna
  );
  const isCustomReport = useAppSelector(
    (store) => store.users.project?.flags?.isCustomReport
  );
  const isClustering = useAppSelector(
    (store) => store.users.project?.flags?.isClustering
  );

  const content = useRoutes(
    routes(
      isAuthenticated,
      isOnboarding,
      Boolean(isNetworkAnalysis),
      Boolean(isCustomReport),
      Boolean(isClustering)
    )
  );

  const user = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();

  const [logProjectActive] = useMutation(LOG_PROJECT_ACTIVE, {
    onCompleted: ({ session }) => {
      if (!session?.token) return;
      const isAuth = session.token ? true : false;
      window.localStorage.setItem('token', session.token);
      dispatch(reducerSignIn(isAuth));
    }
  });

  useEffect(() => {
    if (user?.project?.id && user?.userProfile?.data?.userId) {
      logProjectActive({
        variables: {
          projectId: user?.project?.id,
          userId: user?.userProfile?.data?.userId
        }
      });
    }
  }, [user?.project?.id, user?.userProfile?.data?.userId]);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {isAuthenticated && !project ? <Loading /> : content}
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
