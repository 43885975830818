import {
  Box,
  Button,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/app/hooks';
import { useTheme } from '@mui/material/styles';
import BottomHeader from './Bottom';
import { useLocation, useNavigate } from 'react-router';
import ObjectPreview from 'src/components/ObjectPreview';
import ModalManageObjectByGroup from 'src/components/ModalManageObjectByGroup';
import { useEffect, useState } from 'react';
import useQueryUrl from 'src/hooks/useQuery';
import GroupCampaign from 'src/components/GroupCampaign';
import EditIcon from '@mui/icons-material/Edit';
import TooltipCustomize from 'src/components/TooltipCustomize';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);
const HeaderWrapperSec = styled(Box)(
  ({ theme }) => `
    background: #fbfbfb;
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    justify-content: space-between;
    width: 100%;
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function HeaderSocialMediaHashtag() {
  const { pathname } = useLocation();
  const title = pathname.split('/')[2];
  const query = useQueryUrl();
  const tabQuery = query.get('tab');
  const navigate = useNavigate();
  const [tab, setTab] = useState<string>(tabQuery);
  const setTitle = () => {
    switch (title) {
      case 'social_media':
        return 'Social Media Stream';
      default:
        return title.charAt(0).toUpperCase() + title.slice(1);
    }
  };
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const campaignSelected = useAppSelector(
    (state) => state.storeSocialMediaCampaign.campaignSelected
  );

  const objectListByObjectType = useAppSelector(
    (state) => state.objectManagement.objectListByObjectType
  );

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
    navigate(`${pathname}?tab=${newValue}`);
  };
  useEffect(() => {
    setTab(tabQuery);
  }, [tabQuery]);

  const handlePreference = () => {
    if (!campaignSelected) return;

    navigate(`${pathname}?preferences=${campaignSelected.id}`);
  };

  return (
    <>
      <HeaderWrapper display="flex" alignItems="center">
        <Grid container>
          <Grid item sm={12}>
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h1"
                sx={{
                  mr: 1
                }}
              >
                {setTitle()}
              </Typography>
              <TooltipCustomize
                title={'Discover Hashtags performance'}
                placement="top-start"
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{ transform: 'translateY(-4px)' }}
                />
              </TooltipCustomize>
            </Stack>
          </Grid>
          <Grid item sm={12}>
            <Stack
              direction={isLargeScreen ? 'row' : 'column'}
              justifyContent="space-between"
              alignItems={isLargeScreen ? 'center' : 'unset'}
              gap={isLargeScreen ? 2 : 1}
              mt={2}
            >
              <Stack
                spacing={2}
                direction="row"
                width={isLargeScreen ? '75%' : '100%'}
                maxWidth={isLargeScreen ? '600px' : 'unset'}
              >
                <GroupCampaign />
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '6px',
                    height: theme.spacing(5),
                    minWidth: theme.spacing(6)
                  }}
                  onClick={() => {
                    handlePreference();
                  }}
                  color="primary"
                  endIcon={<EditIcon />}
                  disabled={
                    !campaignSelected || campaignSelected.id === '_default'
                  }
                >
                  Preferences
                </Button>
              </Stack>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: '6px',
                    height: theme.spacing(5),
                    minWidth: theme.spacing(6),
                    background: theme.palette.common.white
                  }}
                  onClick={() => {
                    navigate(`${pathname}?history=1`);
                  }}
                >
                  Campaign History
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item sm={12}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              mt={1.5}
              mb={2.1}
            >
              <ModalManageObjectByGroup title="Social Profile" isCampaign />
              <ObjectPreview objectList={objectListByObjectType} />
            </Stack>
          </Grid>
          <Grid item sm={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={tab !== 'stream_preview' ? 'widget' : tab}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Widget" value="widget" />
                <Tab label="Stream Preview" value="stream_preview" />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
      </HeaderWrapper>
      <HeaderWrapperSec display="flex" alignItems="center">
        <Grid container>
          <Grid item sm={12}>
            <BottomHeader key={title} />
          </Grid>
        </Grid>
      </HeaderWrapperSec>
    </>
  );
}

export default HeaderSocialMediaHashtag;
