import {
  Box,
  Button,
  Dialog,
  Fab,
  FormControl,
  FormLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useCustomDashboard from 'src/services/useCustomDashboard';
import { useAppSelector } from 'src/app/hooks';
import { useAlert } from 'src/hooks/useAlert';

interface ModalCustomDownloadProps {
  open: boolean;
  onClose: () => void;
  onDownload: () => void;
}

interface ICustomDownload {
  titleReport: string;
  colorTitleReport: string;
  coverImageReport: string;
}

const ColorPicker = styled(Box)<{ backgroundColor: string; isActive: boolean }>(
  ({ theme, backgroundColor, isActive }) => `
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  background: ${backgroundColor};
  border-radius: ${theme.spacing(0.5)};
  cursor: pointer;
  border: ${isActive ? `2px solid ${theme.palette.primary.light}` : 'none'}
  `
);

const ContainerUpload = styled(Box)(
  ({ theme }) => `
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: ${theme.spacing(1)};
  display: flex;
  justify-content: space-between;
  min-height: ${theme.spacing(5)};
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  `
);

const TitlePreview = styled(Typography)(
  ({ theme }) => `
  font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 9px;
/* or 90% */

text-transform: uppercase;

/* Other/Darkest BG */

color: #051C26;
  `
);

const Skeleton = styled(Box)(
  ({ theme }) => `
  width: 38px;
height: 4px;

/* Gray Medium */

background: #D9D9D9;
border-radius: 2px;
  `
);

const colorList: Array<string> = [
  '#54B9D1',
  '#61B258',
  '#C23F38',
  '#FACB00',
  '#0B85AE',
  '#051C26'
];

const ModalCustomDownload: React.FC<ModalCustomDownloadProps> = ({
  open,
  onClose,
  onDownload
}) => {
  const theme = useTheme();
  const { coverImageReport, colorTitleReport, titleReport, id } =
    useAppSelector((state) => state.users.project);
  const { editCustomDahsboard, resEditCustomDashboard } = useCustomDashboard();
  const { handleClickAlert } = useAlert();
  function validationSchema() {
    return Yup.object({
      titleReport: Yup.string().required(),
      colorTitleReport: Yup.string(),
      coverImageReport: Yup.string()
    });
  }

  const { handleSubmit, setFieldValue, values, handleChange } =
    useFormik<ICustomDownload>({
      initialValues: {
        titleReport,
        colorTitleReport: colorTitleReport ? colorTitleReport : '#051C26',
        coverImageReport
      },
      validationSchema: validationSchema(),
      onSubmit: (value) => {
        editCustomDahsboard({
          variables: {
            ...value,
            projectId: id
          }
        }).then((res) => {
          onDownload();
        });
      }
    });

  const handleUpload = async (e) => {
    const inputFiles = e.target.files[0];
    const formData = new FormData();
    formData.append('file', inputFiles);
    const requestOptions = {
      method: 'POST',
      body: formData
    };

    try {
      await fetch(
        'https://v5.external.dashboard.nolimit.id/upload-file/upload-image',
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data?.error) {
            handleClickAlert({
              horizontal: 'center',
              vertical: 'top',
              severity: 'error',
              message: data?.error
            });
            return;
          }
          setFieldValue('coverImageReport', data?.path);
        });
    } catch (error) {
      console.log(error);
      handleClickAlert({
        horizontal: 'center',
        vertical: 'top',
        severity: 'error',
        message: error.error
      });
    }
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth={'md'} fullWidth={true}>
      <Fab
        onClick={onClose}
        size="small"
        sx={{
          background: '#F5F5F5',
          position: 'absolute',
          right: theme.spacing(2),
          top: theme.spacing(2)
        }}
      >
        <CloseIcon
          sx={{
            fontSize: '18px',
            color: '#051C26'
          }}
        />
      </Fab>
      <Box sx={{ width: '100%', padding: theme.spacing(5, 4) }}>
        <Box sx={{ mb: theme.spacing(4) }}>
          <Typography variant="h4">Edit White Label Report </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item lg={9}>
              <Stack direction="column" spacing={2}>
                <FormControl fullWidth size="medium">
                  <FormLabel>Report Title</FormLabel>
                  <OutlinedInput
                    name="titleReport"
                    inputProps={{
                      maxlength: '30'
                    }}
                    onChange={handleChange}
                    value={values.titleReport}
                    endAdornment={
                      <InputAdornment position="end">{`${values.titleReport.length}/30`}</InputAdornment>
                    }
                  />
                </FormControl>
                <FormControl fullWidth size="medium">
                  <FormLabel>Pick title color</FormLabel>
                  <Stack direction="row" spacing={1}>
                    {colorList.map((color) => (
                      <ColorPicker
                        backgroundColor={color}
                        onClick={() => setFieldValue('colorTitleReport', color)}
                        isActive={values.colorTitleReport === color}
                      />
                    ))}
                  </Stack>
                </FormControl>
                <FormControl fullWidth size="medium">
                  <FormLabel>Upload your logo</FormLabel>
                  <label
                    htmlFor="btn-upload"
                    style={{
                      cursor: 'pointer',
                      marginBottom: theme.spacing(0.5)
                    }}
                  >
                    <input
                      id="btn-upload"
                      name="btn-upload"
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/*"
                      onChange={handleUpload}
                    />
                    <ContainerUpload>
                      <Typography variant="caption">{''}</Typography>
                      <Button
                        variant="contained"
                        component="span"
                        color="primary"
                        sx={{ height: '34px', borderRadius: theme.spacing(1) }}
                      >
                        Choose Image
                      </Button>
                    </ContainerUpload>
                  </label>
                  <Typography variant="caption">
                    Image size: no more than 300kB in jpeg or png.{' '}
                  </Typography>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item lg={3}>
              <Box
                sx={{
                  borderRadius: theme.spacing(1),
                  background: 'rgba(245, 247, 250, 0.5)',
                  padding: theme.spacing(3),
                  width: '100%',
                  height: '282px'
                }}
              >
                <Box
                  sx={{
                    borderRadius: '1px',
                    background: '#FFFFFF',
                    padding: theme.spacing(2, 1),
                    width: '100%',
                    height: '100%'
                  }}
                >
                  <img
                    src={values.coverImageReport}
                    alt={values.coverImageReport}
                    style={{ height: theme.spacing(3), position: 'absolute' }}
                  />

                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{ mt: theme.spacing(10) }}
                  >
                    <TitlePreview
                      sx={{
                        mb: theme.spacing(1),
                        color: values.colorTitleReport
                      }}
                    >
                      {values?.titleReport ? values?.titleReport : 'REPORT'}
                    </TitlePreview>
                    <Skeleton />
                    <Skeleton sx={{ width: theme.spacing(8) }} />
                  </Stack>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12} display="flex" justifyContent="center">
              <Stack direction="row" spacing={2} sx={{ mt: theme.spacing(8) }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  sx={{ width: theme.spacing(15) }}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  sx={{
                    width: theme.spacing(15),
                    background: theme.palette.primary.dark
                  }}
                  disabled={resEditCustomDashboard.loading}
                >
                  Done
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Dialog>
  );
};

export default ModalCustomDownload;
