import {
  Box,
  FormControl,
  Stack,
  TextField,
  IconButton,
  Button,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SelectDateRange from 'src/components/SelectDateRange';
import { useLocation } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  initialState,
  reducerUpdateAdvanceClear,
  reducerUpdateAdvanceSearchIncludeKeywords
} from 'src/features/advanceSearchSocialMedia';
import AdvanceSearchSocialMedia from 'src/components/AdvanceSearchSocialMedia';
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeLabel from '../../components/DateRangeLabel';
import FilterListIcon from '@mui/icons-material/FilterList';
import TooltipCustomize from 'src/components/TooltipCustomize';
import RefreshIcon from '@mui/icons-material/Refresh';
import useCampaign from 'src/services/useCampaign';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { IGranularity } from 'src/models/general';
import changeObjectToQuery from 'src/utils/changeObjectToQuery';
import useQueryUrl from 'src/hooks/useQuery';
import SelectAutoRefreshInterval from 'src/components/SelectAutoRefreshInterval';

const CustomBox = styled(Box)(
  ({ theme }) => `
    // width: calc(100vw - ${theme.sidebar.width} - ${theme.spacing(10)});
    margin: ${theme.spacing(2, 0)};
    // position: absolute;
`
);

const FilterBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
);

function BottomHeader() {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const urlquery = useQueryUrl();
  const title = pathname.split('/')[2];
  const tabQuery = urlquery.get('tab');
  const sentimentQuery = urlquery.get('sentiment');

  const advanceSearch = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );

  const isStreamPreviewSentiment =
    tabQuery === 'stream_preview' && Boolean(sentimentQuery);

  const {
    streamsCommentingToPost,
    selectedOriginalToPost,
    searchOriginalToPost,
    ...rest
  } = advanceSearch;

  const query = changeObjectToQuery({
    ...rest,
    sentiment: isStreamPreviewSentiment ? [sentimentQuery] : rest.sentiment
  });

  const [localSearch, setLocalSearch] = useState<string>(query);

  const handleClear = () => {
    setLocalSearch('');
    dispatch(reducerUpdateAdvanceClear(initialState));
  };

  const handleSearch = () => {
    dispatch(reducerUpdateAdvanceSearchIncludeKeywords([localSearch]));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') handleSearch();
  };

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { getAllMetricCampaign } = useCampaign();

  const projectId = useAppSelector((state) => state.users.project.id);

  const advanceSearchSocialMedia = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );

  const { dateRangeFormatted, objectIdList } = useAppSelector(
    (state) => state.analyticArguments
  );

  const objectList = useAppSelector(
    (state) => state.objectManagement.objectList.data
  );

  const fillAdvanceSearchSocialMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchSocialMedia);
  }, [advanceSearchSocialMedia]);

  const objectListByObjectTypeActive = useMemo(() => {
    const dataList = objectList.filter((object) =>
      objectIdList.find((id) => id === object.id)
    );
    return dataList.map((object) => {
      return object.id;
    });
  }, [objectIdList]);

  const granularity: IGranularity = {
    unit: 'day',
    value: 1
  };

  const finalPayload = {
    ...fillAdvanceSearchSocialMedia,
    projectId,
    ...dateRangeFormatted,
    objectList: objectListByObjectTypeActive,
    granularity,
    timezone: 7
  };

  const handleRefresh = () => {
    getAllMetricCampaign(finalPayload);
  };

  // update search value to have "includeKeywords: ..."
  useEffect(() => {
    setLocalSearch(query);
  }, [advanceSearch, isStreamPreviewSentiment]);

  // auto refresh metric
  const { selectedAutoRefreshInterval } = useAppSelector(
    (store) => store.autoRefreshMetric
  );
  useEffect(() => {
    if (selectedAutoRefreshInterval === 0) return;
    const interval = setInterval(() => {
      handleRefresh();
    }, selectedAutoRefreshInterval);

    return () => {
      clearInterval(interval);
    };
  }, [selectedAutoRefreshInterval]);

  return (
    <Stack direction="column" my={2}>
      <Stack
        direction={isLargeScreen ? 'row' : 'column'}
        justifyContent={isLargeScreen ? 'space-between' : 'unset'}
        gap={isLargeScreen ? 3 : 2}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          className="filtering"
          width="100%"
          maxWidth={isLargeScreen ? '600px' : 'unset'}
        >
          <TextField
            size="small"
            InputProps={{
              startAdornment: (
                <IconButton size="small" onClick={handleSearch}>
                  <SearchTwoToneIcon />
                </IconButton>
              ),
              endAdornment: localSearch ? (
                <IconButton size="small" onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              ) : null
            }}
            placeholder={`Search ${title}`}
            onChange={(e) => setLocalSearch(e.target.value)}
            value={localSearch}
            onKeyDown={handleKeyDown}
            fullWidth
          />
          <Button
            variant="contained"
            sx={{
              borderRadius: '6px',
              height: theme.spacing(5),
              minWidth: theme.spacing(6),
              background: theme.palette.primary.dark
            }}
            onClick={() => handleClickOpen()}
          >
            <FilterListIcon htmlColor={theme.colors.primary.lighter} />
          </Button>
          {open && (
            <AdvanceSearchSocialMedia open={open} onClose={handleClose} />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent={isLargeScreen ? 'unset' : 'space-between'}
          spacing={2}
        >
          <FormControl size="small" sx={{ width: 135 }}>
            <SelectDateRange />
          </FormControl>
          <Stack direction="row" gap={1}>
            <TooltipCustomize title="Refresh all metric" placement="top-start">
              <Button
                variant="contained"
                onClick={handleRefresh}
                sx={{
                  borderRadius: '6px',
                  height: theme.spacing(5),
                  minWidth: theme.spacing(6),
                  background: theme.palette.primary.main
                }}
              >
                <RefreshIcon htmlColor={theme.colors.primary.lighter} />
              </Button>
            </TooltipCustomize>
            <FormControl size="small" sx={{ width: 100 }}>
              <SelectAutoRefreshInterval />
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
      <DateRangeLabel label={`Viewing ${title} within`} />
    </Stack>
  );
}

export default BottomHeader;
