import gql from 'graphql-tag';

export const ADD_GROUP_PORTAL = gql`
  mutation groupPortal_addGroupPortal(
    $projectId: Id!
    $name: String!
    $portals: [String!]!
  ) {
    groupPortal_addGroupPortal(
      projectId: $projectId
      name: $name
      portals: $portals
    ) {
      id
      name
      displayName
      portals
      isActive
      createdAt
    }
  }
`;

export const DELETE_GROUP_PORTAL = gql`
  mutation GroupPortal_deleteGroupPortal($groupPortalId: Id!) {
    groupPortal_deleteGroupPortal(groupPortalId: $groupPortalId) {
      id
      name
      displayName
      portals
      isActive
      createdAt
    }
  }
`;

export const EDIT_GROUP_PORTAL = gql`
  mutation groupPortal_updateGroupPortal(
    $groupPortalId: Id!
    $name: String!
    $portals: [String!]!
    $projectId: Id!
  ) {
    groupPortal_updateGroupPortal(
      groupPortalId: $groupPortalId
      name: $name
      portals: $portals
      projectId: $projectId
    ) {
      id
      name
      displayName
      portals
      isActive
      createdAt
    }
  }
`;
