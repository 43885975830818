import gql from 'graphql-tag';
import {
  analyticArgumentsOfflineMedia,
  analyticArgumentsQueryOfflineMedia
} from '../analyticArgument';

export const CREATE_DOWNLOAD_CSV_JOB_FOR_OFFLINE_MEDIA = gql`
mutation download_createDownloadCsvJobForOfflineMedia(${analyticArgumentsQueryOfflineMedia}){
    download_createDownloadCsvJobForOfflineMedia(${analyticArgumentsOfflineMedia}) {
      status
    }
  }
`;

export const MANAGE_SENTIMENT_OFFLINE_MEDIA = gql`
  mutation offlineMedia_manageSentimentOnArticle(
    $projectId: Id!
    $attachArticles: [String!]!
    $sentiment: Sentiment!
  ) {
    offlineMedia_manageSentimentOnArticle(
      projectId: $projectId
      attachArticles: $attachArticles
      sentiment: $sentiment
    ) {
      projectId
      sentiment
      attachArticles {
        originalId
        createdAt
        datePublished
        title
        content
        people
        place
        sentiment
        sourceName
        updatedAt
        writer
        prValues
        isEdited
        attachments {
          link
        }
      }
    }
  }
`;

export const MANAGE_VISIBILITY_OFFLINE_MEDIA = gql`
  mutation offlineMedia_manageVisibilityOnArticle(
    $projectId: Id!
    $attachArticles: [String!]!
    $visibility: Visibility!
  ) {
    offlineMedia_manageVisibilityOnArticle(
      projectId: $projectId
      attachArticles: $attachArticles
      visibility: $visibility
    ) {
      projectId
      visibility
      attachArticles {
        originalId
        createdAt
        datePublished
        title
        content
        people
        place
        sentiment
        sourceName
        updatedAt
        writer
        prValues
        isEdited
        attachments {
          link
        }
      }
    }
  }
`;

export const ADD_LABEL_ARTICLE = gql`
  mutation article_addLabelArticle(
    $projectId: Id!
    $name: String!
    $requestColor: String!
    $categoryArticle: CategoryArticle!
  ) {
    article_addLabelArticle(
      projectId: $projectId
      name: $name
      requestColor: $requestColor
      categoryArticle: $categoryArticle
    ) {
      id
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const EDIT_LABEL_ARTICLE = gql`
  mutation article_editLabelArticle(
    $labelId: Id!
    $name: String!
    $requestColor: String!
  ) {
    article_editLabelArticle(
      labelId: $labelId
      name: $name
      requestColor: $requestColor
    ) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const DELETE_LABEL_ARTICLE = gql`
  mutation article_deleteLabelArticle($labelId: Id!) {
    article_deleteLabelArticle(labelId: $labelId) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const ADD_AUTO_LABEL_ARTICLE = gql`
  mutation article_createAutoLabel(
    $projectId: Id!
    $clippingList: [Id!]!
    $name: String!
    $description: String
    $start: Timestamp!
    $labelList: [Id!]!
    $phrasesList: [[String!]!]!
    $categoryArticle: CategoryArticle!
  ) {
    article_createAutoLabel(
      projectId: $projectId
      clippingList: $clippingList
      name: $name
      description: $description
      start: $start
      labelList: $labelList
      phrasesList: $phrasesList
      categoryArticle: $categoryArticle
    ) {
      clippingList {
        id
        name
        createdAt
        startDate
      }
      createdAt
      description
      id
      isActive
      labelList {
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
      name
      phrasesList
      projectId
      start
    }
  }
`;

export const EDIT_AUTO_LABEL_ARTICLE = gql`
  mutation article_editAutoLabel(
    $projectId: Id!
    $clippingList: [Id!]!
    $name: String!
    $description: String
    $start: Timestamp!
    $labelList: [Id!]!
    $phrasesList: [[String!]!]!
    $autoLabelArticleId: Id!
  ) {
    article_editAutoLabel(
      projectId: $projectId
      clippingList: $clippingList
      name: $name
      description: $description
      start: $start
      labelList: $labelList
      phrasesList: $phrasesList
      autoLabelArticleId: $autoLabelArticleId
    ) {
      clippingList {
        id
        name
        createdAt
        startDate
      }
      createdAt
      description
      id
      isActive
      labelList {
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
      name
      phrasesList
      projectId
      start
    }
  }
`;

export const DELETE_AUTO_LABEL_ARTICLE = gql`
  mutation article_deleteAutoLabel($autoLabelArticleId: Id!) {
    article_deleteAutoLabel(autoLabelArticleId: $autoLabelArticleId) {
      clippingList {
        id
        name
        createdAt
        startDate
      }
      createdAt
      description
      id
      isActive
      labelList {
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
      name
      phrasesList
      projectId
      start
    }
  }
`;

export const ADD_CLIPPING = gql`
  mutation article_addClippingArticle(
    $projectId: Id!
    $name: String!
    $startingDate: Timestamp!
    $phrasesList: [[String!]!]!
    $categoryArticle: CategoryArticle!
  ) {
    article_addClippingArticle(
      projectId: $projectId
      name: $name
      phrasesList: $phrasesList
      startingDate: $startingDate
      categoryArticle: $categoryArticle
    ) {
      id
      name
      createdAt
      phrases
      startingDate
      __typename
    }
  }
`;

export const DELETE_CLIPPING = gql`
  mutation article_deleteClippingArticle($clippingId: Id!) {
    article_deleteClippingArticle(clippingId: $clippingId) {
      id
      name
      createdAt
      phrases
      startingDate
      __typename
    }
  }
`;

export const EDIT_CLIPPING = gql`
  mutation article_editClippingArticle(
    $name: String!
    $startingDate: Timestamp!
    $phrasesList: [[String!]!]!
    $clippingId: Id!
  ) {
    article_editClippingArticle(
      name: $name
      phrasesList: $phrasesList
      startingDate: $startingDate
      clippingId: $clippingId
    ) {
      id
      name
      createdAt
      phrases
      startingDate
      __typename
    }
  }
`;

export const MANAGE_ARTICLES_ON_CLIPPING = gql`
  mutation article_manageArticlesOnClipping(
    $projectId: Id!
    $clippingId: Id!
    $attachArticles: [String!]!
    $removeArticles: [String!]!
  ) {
    article_manageArticlesOnClipping(
      projectId: $projectId
      clippingId: $clippingId
      attachArticles: $attachArticles
      removeArticles: $removeArticles
    ) {
      projectId
      manageResponse {
        remove {
          originalId
          createdAt
          datePublished
          title
          content
          people
          place
          quote
          sentiment
          sourceName
          updatedAt
          writer
          prValues
          clippingList {
            id
            name
            isActive
            createdAt
          }
          labelArticleList {
            id
            name
            displayName
            colorType
            isActive
            createdAt
          }
          listOfClippingId
        }
        attach {
          originalId
          createdAt
          datePublished
          title
          content
          people
          place
          quote
          sentiment
          sourceName
          updatedAt
          writer
          prValues
          clippingList {
            id
            name
            isActive
            createdAt
          }
          labelArticleList {
            id
            name
            displayName
            colorType
            isActive
            createdAt
          }
          listOfClippingId
        }
      }
    }
  }
`;

export const DELETE_COMPARISON = gql`
  mutation comparisonOfflineMedian_deleteComparison($comparisonId: Id!) {
    comparisonOfflineMedia_deleteComparison(comparisonId: $comparisonId) {
      id
      namespace
      displayName
      isDefault
      createdAt
      colors
    }
  }
`;

export const SET_DEFAULT_COMPARISON = gql`
  mutation comparisonOfflineMedia_setDefault(
    $projectId: Id!
    $comparisonId: Id!
  ) {
    comparisonOfflineMedia_setDefault(
      projectId: $projectId
      comparisonId: $comparisonId
    ) {
      id
      namespace
      displayName
      isDefault
      createdAt
      colors
    }
  }
`;

export const MANAGE_CLIPPING_ON_ARTICLE = gql`
  mutation article_manageClippingOnArticles(
    $projectId: Id!
    $articlesId: [String!]!
    $attachClippings: [Id!]!
    $removeClippings: [Id!]!
  ) {
    article_manageClippingOnArticles(
      projectId: $projectId
      articlesId: $articlesId
      attachClippings: $attachClippings
      removeClippings: $removeClippings
    ) {
      projectId
      attachArticle {
        originalId
        createdAt
        datePublished
        title
        content
        people
        place
        quote
        sentiment
        sourceName
        updatedAt
        writer
        prValues
        clippingList {
          id
          name
          isActive
          createdAt
        }
        labelArticleList {
          id
          name
          displayName
          colorType
          isActive
          createdAt
        }
        listOfClippingId
        attachments {
          link
        }
      }
    }
  }
`;

export const MANAGE_SENTIMENT_ON_ARTICLE = gql`
  mutation article_manageSentimentOnArticle(
    $projectId: Id!
    $clippingId: Id!
    $attachArticles: [String!]!
    $sentiment: Sentiment
  ) {
    article_manageSentimentOnArticle(
      projectId: $projectId
      clippingId: $clippingId
      attachArticles: $attachArticles
      sentiment: $sentiment
    ) {
      projectId
      attachArticles {
        originalId
        isEdited
        createdAt
        datePublished
        title
        content
        people
        place
        quote
        sentiment
        sourceName
        updatedAt
        writer
        prValues
        clippingList {
          id
          name
          isActive
          createdAt
        }
        labelArticleList {
          id
          name
          displayName
          colorType
          isActive
          createdAt
        }
        listOfClippingId
        attachments {
          link
        }
      }
      sentiment
      clipping {
        id
        name
        isActive
        createdAt
      }
    }
  }
`;

export const MANAGE_LABEL_ON_ARTICLE = gql`
  mutation article_manageLabelOnArticles(
    $projectId: Id!
    $articlesId: [String!]!
    $attachLabels: [Id!]!
    $removeLabels: [Id!]!
    $clippingId: Id!
  ) {
    article_manageLabelOnArticles(
      projectId: $projectId
      articlesId: $articlesId
      attachLabels: $attachLabels
      removeLabels: $removeLabels
      clippingId: $clippingId
    ) {
      projectId
      attachArticle {
        originalId
        createdAt
        datePublished
        title
        content
        people
        place
        quote
        sentiment
        sourceName
        updatedAt
        writer
        prValues
        clippingList {
          id
          name
          isActive
          createdAt
        }
        labelArticleList {
          id
          name
          displayName
          colorType
          isActive
          createdAt
        }
        listOfClippingId
        attachments {
          link
        }
      }
    }
  }
`;

export const CREATE_COMPARISON = gql`
  mutation comparisonOfflineMedia_addComparison(
    $projectId: Id!
    $comparisonName: String!
    $comparisonGroupsOfflineMedia: [ComparisonGroupInputOfflineMedia!]!
    $sourceType: SourceTypeOfm!
  ) {
    comparisonOfflineMedia_addComparison(
      projectId: $projectId
      comparisonName: $comparisonName
      comparisonGroupsOfflineMedia: $comparisonGroupsOfflineMedia
      sourceType: $sourceType
    ) {
      id
      namespace
      displayName
      createdAt
      colors
      groups {
        id
        name
        displayName
        createdAt
        clippingList
        labelList
        color
        __typename
      }
      __typename
    }
  }
`;

export const EDIT_COMPARISON = gql`
  mutation comparisonOfflineMedia_editComparison(
    $comparisonId: Id!
    $comparisonName: String!
    $comparisonGroupsOfflineMedia: [ComparisonGroupInputOfflineMedia!]!
  ) {
    comparisonOfflineMedia_editComparison(
      comparisonId: $comparisonId
      comparisonName: $comparisonName
      comparisonGroupsOfflineMedia: $comparisonGroupsOfflineMedia
    ) {
      id
      namespace
      displayName
      createdAt
      colors
      __typename
    }
  }
`;
