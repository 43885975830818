import React from 'react';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';

interface Props {
  detail: any;
}
const InformationDetailDownloadMediaOnline: React.FC<Props> = ({ detail }) => {
  const { mediaList = [], start, end, phrase } = detail;

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5">Filter by:</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">Media List : </Typography>
        <Typography variant="subtitle2">{mediaList.join(', ')}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">{`Phrase : ${phrase}`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">{`Date Range : ${moment(start).format(
          'DD MMM YYYY'
        )} - ${moment(end).format('DD MMM YYYY')}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default InformationDetailDownloadMediaOnline;
