import {
  Box,
  FormControl,
  Stack,
  TextField,
  IconButton,
  Button,
  Select,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SelectDateRange from 'src/components/SelectDateRange';
import { KeyboardEvent, useState } from 'react';
import { useAppSelector } from 'src/app/hooks';
import ModalAddAutoClipping from 'src/components/ModalAddAutoClipping';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useQueryUrl from 'src/hooks/useQuery';
import { useNavigate } from 'react-router';
import { STREAM_PANEL_ONLINE_MEDIA } from 'src/route';
import {
  reducerUpdateIncludeKeywords,
  reducerUpdateLanguageSelected
} from 'src/features/onlineMediaStream';
import { useDispatch } from 'react-redux';
import { reducerUpdateAdvanceSearchOnmIncludeKeywords } from 'src/features/advanceSearchOnlineMedia';

const CustomBox = styled(Box)(
  ({ theme }) => `
    // width: calc(100vw - ${theme.sidebar.width} - ${theme.spacing(10)});
    margin: ${theme.spacing(2, 0)};
    // position: absolute;
`
);

const FilterBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
);

function BottomHeader() {
  const query = useQueryUrl();
  const id = query.get('id');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { languageSelected, languages, includeKeywords } = useAppSelector(
    (state) => state.storeOnlineMediaStream
  );

  const [localSearch, setLocalSearch] = useState<string>(includeKeywords[0]);
  const handleSearch = () => {
    dispatch(reducerUpdateIncludeKeywords(localSearch));
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') handleSearch();
  };

  const handleClear = () => {
    setLocalSearch('');
    dispatch(reducerUpdateIncludeKeywords(''));
  };

  const handleChangeLanguage = function (event) {
    const language = event.target.value;
    dispatch(reducerUpdateLanguageSelected(language));
  };

  return (
    <CustomBox>
      <FilterBox>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            size="small"
            sx={{ width: 509 }}
            InputProps={{
              startAdornment: (
                <IconButton size="small" onClick={handleSearch}>
                  <SearchTwoToneIcon />
                </IconButton>
              ),
              endAdornment: localSearch ? (
                <IconButton size="small" onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              ) : null
            }}
            placeholder="Search phrases"
            onChange={(e) => setLocalSearch(e.target.value)}
            value={localSearch}
            onKeyDown={handleKeyDown}
          />
          <ModalAddAutoClipping />
        </Stack>
        <Stack spacing={2} direction="row">
          <FormControl size="small" sx={{ width: 135 }}>
            <Select
              id="language"
              name="language"
              value={languageSelected}
              onChange={handleChangeLanguage}
            >
              <MenuItem
                key={'all-language'}
                id={'all-language'}
                value={'all-language'}
              >
                All Language
              </MenuItem>
              {languages.map(({ name, language }) => (
                <MenuItem key={language} id={language} value={language}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{ width: 135 }}>
            <SelectDateRange />
          </FormControl>
        </Stack>
      </FilterBox>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mt: id ? 2 : 1 }}
      >
        {id && (
          <Button
            size="small"
            variant="text"
            color="primary"
            startIcon={<ArrowBackIcon />}
            sx={{
              borderRadius: 1
            }}
            onClick={() => navigate(STREAM_PANEL_ONLINE_MEDIA)}
          >
            Media Profile Preview
          </Button>
        )}
      </Stack>
    </CustomBox>
  );
}

export default BottomHeader;
