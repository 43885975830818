import gql from 'graphql-tag';

export const OBJECT_MANAGEMENT_ADD_OBJECT = gql`
  mutation objectManagement_addObject(
    $projectId: Id!
    $streamType: StreamType!
    $socialMedia: SocialMedia!
    $content: String!
    $extraContent: String
    $isHashtag: Boolean!
    $objectName: String!
    $displayPic: String
    $includes: [String!]
    $excludes: [String!]
    $languages: [String!]
    $timezones: [String!]
  ) {
    objectManagement_addObject(
      projectId: $projectId
      streamType: $streamType
      socialMedia: $socialMedia
      content: $content
      extraContent: $extraContent
      isHashtag: $isHashtag
      objectName: $objectName
      displayPic: $displayPic
      includes: $includes
      excludes: $excludes
      languages: $languages
      timezones: $timezones
    ) {
      id
      socialMedia
      streamType
      content
      extraContent
      objectName
      isHashtag
      createdAt
      openApiId
      displayPicture
      includes
      excludes
      languages
    }
  }
`;

export const OBJECT_MANAGEMENT_ADD_GROUP = gql`
  mutation objectManagement_addObjectGroup(
    $projectId: Id!
    $objectList: [Id!]!
    $groupName: String!
    $templateName: String!
  ) {
    objectManagement_addObjectGroup(
      projectId: $projectId
      objectList: $objectList
      groupName: $groupName
      templateName: $templateName
    ) {
      id
      name
      displayName
      createdAt
      objectList {
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
        includes
        excludes
      }
    }
  }
`;
export const OBJECT_MANAGEMENT_EDIT_GROUP = gql`
  mutation objectManagement_editObjectGroup(
    $projectId: Id!
    $objectList: [Id!]!
    $objectGroupId: Id!
    $groupName: String!
    $templateName: String!
  ) {
    objectManagement_editObjectGroup(
      projectId: $projectId
      objectList: $objectList
      objectGroupId: $objectGroupId
      groupName: $groupName
      templateName: $templateName
    ) {
      id
      name
      displayName
      createdAt
      objectList {
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
        includes
        excludes
      }
    }
  }
`;

export const OBJECT_MANAGEMENT_DELETE_GROUP = gql`
  mutation objectManagement_deleteObjectGroup($objectGroupId: Id!) {
    objectManagement_deleteObjectGroup(objectGroupId: $objectGroupId) {
      id
      name
      displayName
      createdAt
      objectList {
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
        includes
        excludes
      }
    }
  }
`;

export const OBJECT_MANAGEMENT_EDIT_OBJECT = gql`
  mutation objectManagement_editObject(
    $objectId: Id!
    $objectName: String!
    $keywords: [String!]
    $includes: [String!]
    $excludes: [String!]
    $languages: [String!]
    $timezones: [String!]
  ) {
    objectManagement_editObject(
      objectId: $objectId
      objectName: $objectName
      keywords: $keywords
      includes: $includes
      excludes: $excludes
      languages: $languages
      timezones: $timezones
    ) {
      id
      socialMedia
      streamType
      content
      extraContent
      objectName
      isHashtag
      createdAt
      openApiId
      displayPicture
      includes
      excludes
      languages
    }
  }
`;

export const OBJECT_MANAGEMENT_DELETE_OBJECT = gql`
  mutation objectManagement_deleteObject($objectId: Id!) {
    objectManagement_deleteObject(objectId: $objectId) {
      id
      socialMedia
      streamType
      content
      extraContent
      objectName
      isHashtag
      createdAt
      openApiId
      includes
      excludes
    }
  }
`;
