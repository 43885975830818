/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  FormControl,
  Stack,
  TextField,
  IconButton,
  useTheme,
  Button,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SelectDateRange from 'src/components/SelectDateRange';
import { useLocation } from 'react-router';
import { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  initialState,
  reducerUpdateAdvanceClear,
  reducerUpdateAdvanceSearchIncludeKeywords
} from 'src/features/advanceSearchSocialMedia';
import AdvanceSearchSocialMedia from 'src/components/AdvanceSearchSocialMedia';
import ClearIcon from '@mui/icons-material/Clear';
import DateRangeLabel from '../../components/DateRangeLabel';
import FilterListIcon from '@mui/icons-material/FilterList';
import changeObjectToQuery from 'src/utils/changeObjectToQuery';
import TooltipCustomize from 'src/components/TooltipCustomize';
import useComparison from 'src/services/useComparison';
import {
  IDateRange,
  IGranularity,
  IRawPostmadeOutputEntity,
  ITablePaginationAttribute
} from 'src/models/general';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import RefreshIcon from '@mui/icons-material/Refresh';
import moment from 'moment';
import useQueryUrl from 'src/hooks/useQuery';
import { useGranularity } from 'src/hooks/useGranularity';
import SelectAutoRefreshInterval from 'src/components/SelectAutoRefreshInterval';

const CustomBox = styled(Box)(
  ({ theme }) => `
    // width: calc(100vw - ${theme.sidebar.width} - ${theme.spacing(10)});
    margin: ${theme.spacing(2, 0)};
    // position: absolute;
`
);

const FilterBox = styled(Box)(
  () => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
);

function BottomHeader() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { pathname } = useLocation();
  const title = pathname.split('/')[2];
  const queryURL = useQueryUrl();
  const comparisonId = queryURL.get('id');
  const { granular, setGlanular, getGranularityUnit } = useGranularity();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const advanceSearch = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );
  const {
    streamsCommentingToPost,
    selectedOriginalToPost,
    searchOriginalToPost,
    labelList,
    ...rest
  } = advanceSearch;
  const query = changeObjectToQuery(rest);
  const [localSearch, setLocalSearch] = useState<string>(query);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') handleSearch();
  };

  const handleClear = () => {
    setLocalSearch('');
    dispatch(reducerUpdateAdvanceClear(initialState));
  };

  const handleSearch = () => {
    dispatch(reducerUpdateAdvanceSearchIncludeKeywords([localSearch]));
  };
  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setLocalSearch(query);
  }, [advanceSearch]);

  const projectId = useAppSelector((state) => state.users.project.id);

  const advanceSearchSocialMedia = useAppSelector(
    (state) => state.storeAdvanceSearchSocialMedia
  );

  const fillAdvanceSearchSocialMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchSocialMedia);
  }, [advanceSearchSocialMedia]);

  const { dateRangeFormatted } = useAppSelector(
    (state) => state.analyticArguments
  );

  const initialTableAttribute: ITablePaginationAttribute<IRawPostmadeOutputEntity> =
    {
      columnName: 'engagementRate',
      limit: 20,
      page: 1,
      sortingMethod: 'desc'
    };

  const finalPayload = {
    ...fillAdvanceSearchSocialMedia,
    projectId,
    ...dateRangeFormatted,
    objectList: [],
    granularity: getGranularityUnit({ ...dateRangeFormatted }),
    timezone: 7,
    ...initialTableAttribute,
    comparisonId
  };

  const {
    getAllMetricCompatisonSosmed,
    getDayToDayEngagement,
    getDayToDayFollowersGrowth,
    getDayToDayTalk
  } = useComparison();

  const { engagementGranularity, followersGranularity, talkGranularity } =
    useAppSelector((state) => state.storeComparison);

  const handleRefresh = () => {
    getDayToDayEngagement({
      ...finalPayload,
      granularity: engagementGranularity
    });
    getDayToDayFollowersGrowth({
      ...finalPayload,
      granularity: followersGranularity
    });
    getDayToDayTalk({
      ...finalPayload,
      granularity: talkGranularity
    });
    getAllMetricCompatisonSosmed(finalPayload);
  };

  // auto refresh metric
  const { selectedAutoRefreshInterval } = useAppSelector(
    (store) => store.autoRefreshMetric
  );
  useEffect(() => {
    if (selectedAutoRefreshInterval === 0) return;
    const interval = setInterval(() => {
      handleRefresh();
    }, selectedAutoRefreshInterval);

    return () => clearInterval(interval);
  }, [selectedAutoRefreshInterval]);

  return (
    <Stack direction="column" my={2}>
      <Stack
        direction={isLargeScreen ? 'row' : 'column'}
        justifyContent={isLargeScreen ? 'space-between' : 'unset'}
        gap={isLargeScreen ? 3 : 2}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          className="filtering"
          width="100%"
          maxWidth={isLargeScreen ? '600px' : 'unset'}
        >
          <TextField
            size="small"
            InputProps={{
              startAdornment: (
                <IconButton size="small" onClick={handleSearch}>
                  <SearchTwoToneIcon />
                </IconButton>
              ),
              endAdornment: localSearch ? (
                <IconButton size="small" onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              ) : null
            }}
            placeholder="Search stream"
            onChange={(e) => setLocalSearch(e.target.value)}
            value={localSearch}
            onKeyDown={handleKeyDown}
            fullWidth
          />
          <Button
            variant="contained"
            sx={{
              borderRadius: '6px',
              height: theme.spacing(5),
              minWidth: theme.spacing(6),
              background: theme.palette.primary.dark
            }}
            onClick={() => handleClickOpen()}
          >
            <FilterListIcon />
          </Button>
          {open && (
            <AdvanceSearchSocialMedia open={open} onClose={handleClose} />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent={isLargeScreen ? 'unset' : 'space-between'}
          spacing={2}
          className="timeframeAndRefresh"
        >
          <FormControl size="small" sx={{ width: 135 }}>
            <SelectDateRange />
          </FormControl>
          <Stack gap={1} direction="row">
            <TooltipCustomize title="Refresh all metric" placement="top-start">
              <Button
                variant="contained"
                onClick={handleRefresh}
                sx={{
                  borderRadius: '6px',
                  height: theme.spacing(5),
                  minWidth: theme.spacing(6),
                  background: theme.palette.primary.main
                }}
              >
                <RefreshIcon />
              </Button>
            </TooltipCustomize>
            <FormControl size="small" sx={{ width: 100 }}>
              <SelectAutoRefreshInterval />
            </FormControl>
          </Stack>
        </Stack>
      </Stack>

      <DateRangeLabel label={`Viewing ${title} within`} />
    </Stack>
  );
}

export default BottomHeader;
