import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  ListItem,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useDebounce } from 'src/hooks/useDebounce';
import { withStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { filterOptionLocation } from 'src/utils/filterOptionLocation';
import {  TPeopleLocation } from 'src/models/people';
import usePeople from 'src/services/usePeople';
import CheckboxCustomNl from '../AdvanceSearchSocialMedia/CheckboxCustomNl';
import { IOptionValidationCheckbox } from '../AdvanceSearchSocialMedia';
import { ILabel, TValidation } from 'src/models/general';
import ModalSelectGender from '../ModalSelectGender';
import ModalSelectAge from '../ModalSelectAge';
import ModalSelectLabelPeople from '../ModalSelectLabelPeople';
import ModalSelectInterest from '../ModalSelectInterest';
import { reducerUpdatePeopleAdvanceSearch } from 'src/features/advanceSearchPeoplePanel';

const CustomAutocomplete = withStyles({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input':
      {
        padding: 0
      }
  }
})((props: any) => <Autocomplete {...props} />);

const tfStyle = {
  '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
    color: 'blue',
    visibility: 'visible'
  }
};

const FabSmall = styled(Fab)(
  ({ theme }) => `
    min-height: unset;
    background-color: transparent;
    width: ${theme.spacing(3)};
    height: ${theme.spacing(3)};
    color: #E35200;
    border: 1px solid #E35200;

  `
);

type Props = {
  open: boolean;
  onClose: () => void;
};

const AdvanceSearchPeoplePanel = ({ open, onClose }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [debouncedState, setDebouncedState] = useDebounce();

  const {
    includeUsername,
    locationList,
    genderList,
    genderValidationList,
    ageList,
    ageRangeValidationList,
    labelList,
    labelValidationList,
    interestList,
    interestValidationList
  } = useAppSelector((state) => state.storePeopleAdvanceSearch);
  const { locationList: locationListStore } = useAppSelector(
    (state) => state.storePeople
  );

  const isValueAvailable = function <T>(values: Array<T>, valString: T) {
    const value = values.find((value: T) => value === valString);
    return value ? true : false;
  };

  const handleChangeOption = (prev, optionId) =>
    prev.map((option) => {
      if (option.id === optionId)
        return { ...option, isChecked: !option.isChecked };
      return option;
    });

  // Select label
  const [labelListLocal, setLabelListLocal] =
    useState<Array<ILabel>>(labelList);

  const [anchorElLabel, setAnchorElLabel] = useState<HTMLDivElement | null>(
    null
  );
  const handleClickLabel = (event: any) => {
    setAnchorElLabel(event.currentTarget);
  };
  const handleCloseLabel = function () {
    setAnchorElLabel(null);
  };
  const openLabel = Boolean(anchorElLabel);

  const isLabelValidationChecked = isValueAvailable<TValidation>(
    labelValidationList,
    'validated'
  );
  const isLabelUnValidatedChecked = isValueAvailable<TValidation>(
    labelValidationList,
    'unvalidated'
  );

  const [labelValidationOptions, setLabelValidationOptions] = useState<
    Array<IOptionValidationCheckbox>
  >([
    {
      id: 'validated',
      name: 'validated',
      label: 'Validated',
      isChecked: isLabelValidationChecked
    },
    {
      id: 'unvalidated',
      name: 'unvalidated',
      label: 'Unvalidated',
      isChecked: isLabelUnValidatedChecked
    }
  ]);

  const handleClickLabelValidation = (optionId: TValidation) => {
    setLabelValidationOptions((prev) => handleChangeOption(prev, optionId));
  };

  // Select interest
  const [interestListLocal, setInterestListLocal] =
    useState<Array<ILabel>>(interestList);

  const [anchorElInterest, setAnchorElInterest] =
    useState<HTMLDivElement | null>(null);

  const isInterestValidationChecked = isValueAvailable<TValidation>(
    interestValidationList,
    'validated'
  );
  const isInterestUnValidatedChecked = isValueAvailable<TValidation>(
    interestValidationList,
    'unvalidated'
  );

  const [interestValidationOptions, setInterestValidationOptions] = useState<
    Array<IOptionValidationCheckbox>
  >([
    {
      id: 'validated',
      name: 'validated',
      label: 'Validated',
      isChecked: isInterestValidationChecked
    },
    {
      id: 'unvalidated',
      name: 'unvalidated',
      label: 'Unvalidated',
      isChecked: isInterestUnValidatedChecked
    }
  ]);

  const handleClickInterestValidation = (optionId: TValidation) => {
    setInterestValidationOptions((prev) => handleChangeOption(prev, optionId));
  };

  const handleClickInterest = (event: any) => {
    setAnchorElInterest(event.currentTarget);
  };
  const handleCloseInterest = function () {
    setAnchorElInterest(null);
  };
  const openInterest = Boolean(anchorElInterest);

  // Select username
  const usernameRef = useRef(null);
  const [includeUsernamesLocal, setIncludeUsernamesLocal] =
    useState<Array<string>>(includeUsername);

  const handleSearchUsername = (value) => {
    setIncludeUsernamesLocal(value);
  };

  useEffect(() => {
    setIncludeUsernamesLocal(includeUsername);
  }, [includeUsername]);

  // Select location
  const { getLocationList } = usePeople();
  const [selectLocationLocal, setSelectLocationLocal] =
    useState<Array<TPeopleLocation>>(locationList);

  const handleSearchLocation = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    const phrase = event.target.value;
    setDebouncedState(phrase);
  };

  useMemo(() => {
    if (!debouncedState) return;
    getLocationList({ phrase: debouncedState, count: 10 });
  }, [debouncedState]);

  const handleSelectLocation = (location) => {
    setSelectLocationLocal(location);
  };

  // Select Gender
  const [genderListLocal, setGenderListLocal] = useState(genderList);
  const [anchorElGender, setAnchorElGender] = useState<HTMLDivElement | null>(
    null
  );
  const handleClickGender = (event: any) => {
    setAnchorElGender(event.currentTarget);
  };

  const handleCloseGender = function () {
    setAnchorElGender(null);
  };
  const openGender = Boolean(anchorElGender);

  const isGenderValidationChecked = isValueAvailable<TValidation>(
    genderValidationList,
    'validated'
  );
  const isGenderUnValidatedChecked = isValueAvailable<TValidation>(
    genderValidationList,
    'unvalidated'
  );

  const [genderValidationOptions, setGenderValidationOptions] = useState<
    Array<IOptionValidationCheckbox>
  >([
    {
      id: 'validated',
      name: 'validated',
      label: 'Validated',
      isChecked: isGenderValidationChecked
    },
    {
      id: 'unvalidated',
      name: 'unvalidated',
      label: 'Unvalidated',
      isChecked: isGenderUnValidatedChecked
    }
  ]);

  const handleClickGenderValidation = (optionId: TValidation) => {
    setGenderValidationOptions((prev) => handleChangeOption(prev, optionId));
  };

  // Select Age
  const [ageListLocal, setAgeListLocal] = useState(ageList);
  const [anchorElAge, setAnchorElAge] = useState<HTMLDivElement | null>(null);
  const handleClickAge = (event: any) => {
    setAnchorElAge(event.currentTarget);
  };

  const handleCloseAge = function () {
    setAnchorElAge(null);
  };
  const openAge = Boolean(anchorElAge);

  const isAgeValidationChecked = isValueAvailable<TValidation>(
    ageRangeValidationList,
    'validated'
  );
  const isAgeUnValidatedChecked = isValueAvailable<TValidation>(
    ageRangeValidationList,
    'unvalidated'
  );

  const [ageValidationOptions, setAgeValidationOptions] = useState<
    Array<IOptionValidationCheckbox>
  >([
    {
      id: 'validated',
      name: 'validated',
      label: 'Validated',
      isChecked: isAgeValidationChecked
    },
    {
      id: 'unvalidated',
      name: 'unvalidated',
      label: 'Unvalidated',
      isChecked: isAgeUnValidatedChecked
    }
  ]);

  const handleClickAgeValidation = (optionId: TValidation) => {
    setAgeValidationOptions((prev) => handleChangeOption(prev, optionId));
  };

  const handleDiscard = () => {
    setIncludeUsernamesLocal([]);
    setLabelListLocal([]);
    setInterestListLocal([]);
    setGenderListLocal([]);
    setAgeListLocal([]);
    setSelectLocationLocal([]);
    setGenderValidationOptions([
      {
        id: 'validated',
        name: 'validated',
        label: 'Validated',
        isChecked: false
      },
      {
        id: 'unvalidated',
        name: 'unvalidated',
        label: 'Unvalidated',
        isChecked: false
      }
    ]);
    setAgeValidationOptions([
      {
        id: 'validated',
        name: 'validated',
        label: 'Validated',
        isChecked: false
      },
      {
        id: 'unvalidated',
        name: 'unvalidated',
        label: 'Unvalidated',
        isChecked: false
      }
    ]);
    setInterestValidationOptions([
      {
        id: 'validated',
        name: 'validated',
        label: 'Validated',
        isChecked: false
      },
      {
        id: 'unvalidated',
        name: 'unvalidated',
        label: 'Unvalidated',
        isChecked: false
      }
    ]);
    setLabelValidationOptions([
      {
        id: 'validated',
        name: 'validated',
        label: 'Validated',
        isChecked: false
      },
      {
        id: 'unvalidated',
        name: 'unvalidated',
        label: 'Unvalidated',
        isChecked: false
      }
    ]);
  };

  const handleDone = () => {
    dispatch(
      reducerUpdatePeopleAdvanceSearch({
        includeUsername: includeUsernamesLocal,
        locationList: selectLocationLocal,
        locationValidationList: [],
        ageList: ageListLocal,
        ageRangeValidationList: ageValidationOptions
          .filter((option) => option.isChecked)
          .map((option) => option.id),
        genderList: genderListLocal,
        genderValidationList: genderValidationOptions
          .filter((option) => option.isChecked)
          .map((option) => option.id),
        interestList: interestListLocal,
        interestValidationList: interestValidationOptions
          .filter((option) => option.isChecked)
          .map((option) => option.id),
        labelList: labelListLocal,
        labelValidationList: labelValidationOptions
          .filter((option) => option.isChecked)
          .map((option) => option.id)
      })
    );
    onClose();
  };

  console.log(labelListLocal);
  console.log(interestListLocal);

  return (
    <>
      <Dialog onClose={onClose} open={open} fullWidth maxWidth={'md'}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(3, 3, 0),
            p: theme.spacing(2, 2, 0, 2)
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
              fontSize: 20,
              textAlign: 'left'
            }}
          >
            Advance Search
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            m: theme.spacing(1, 2)
          }}
        >
          <Divider sx={{ mb: theme.spacing(2) }} />
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                Username
              </Typography>
              <CustomAutocomplete
                multiple
                id="username-includes"
                size="small"
                options={[]}
                defaultValue={[]}
                freeSolo
                ref={usernameRef}
                value={includeUsernamesLocal}
                renderTags={(values: Array<any>, getTagProps) =>
                  values.map((option: string, index: number) => (
                    <Chip
                      key={`${option}-${index}-includes`}
                      variant="outlined"
                      label={<Typography>{option}</Typography>}
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(_e, value) => {
                  handleSearchUsername(value);
                }}
                forcePopupIcon={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="includes"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    placeholder="Separated by Enter"
                    sx={{ tfStyle }}
                    onPaste={(event) => {
                      const usernames = event.clipboardData.getData('text');
                      const splitUsernames = usernames.split(',');
                      const trimAndFilterUsername = splitUsernames
                        .filter((word) => word.trim().length > 1)
                        .map((word) => word.trim());

                      const value = [
                        ...includeUsernamesLocal,
                        ...trimAndFilterUsername
                      ];
                      handleSearchUsername(value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item lg={12}>
              <Typography sx={{ mb: theme.spacing(0.5) }} variant="h5">
                Location
              </Typography>
              <CustomAutocomplete
                forcePopupIcon={false}
                multiple
                id="location-autocomplete"
                options={locationListStore.data}
                value={selectLocationLocal}
                renderTags={(values: Array<any>, getTagProps) =>
                  values.map((location, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      key={`${location.state}-${index}-includes`}
                      variant="outlined"
                      size="small"
                      label={
                        <Typography>
                          {filterOptionLocation(location)
                            .map((opt: any) => opt.displayName)
                            .join(', ')}
                        </Typography>
                      }
                    />
                  ))
                }
                getOptionLabel={(option) =>
                  option ? JSON.stringify(option) : ''
                }
                onChange={(
                  _event: React.ChangeEvent<{}>,
                  value: TPeopleLocation
                ) => {
                  handleSelectLocation(value);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="locations"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    placeholder="Location"
                    sx={{ tfStyle }}
                    onChange={handleSearchLocation}
                  />
                )}
                renderOption={(props, option) => {
                  return (
                    <ListItem {...props}>
                      <Stack direction={'row'} justifyContent="space-between">
                        <Typography variant="subtitle2">
                          {filterOptionLocation(option)
                            .map((opt: any) => opt.displayName)
                            .join(', ')}
                        </Typography>
                      </Stack>
                    </ListItem>
                  );
                }}
              />
            </Grid>
            <Grid item lg={12}>
              <Typography variant="h5">Gender</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                // sx={{ mb: theme.spacing(1) }}
              >
                <Stack direction="row" gap={1}>
                  {genderValidationOptions.map((option) => (
                    <CheckboxCustomNl
                      {...option}
                      key={option.id}
                      onClick={handleClickGenderValidation}
                    />
                  ))}
                </Stack>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClickGender}
                  sx={{ margin: theme.spacing(2, 1.5) }}
                >
                  {genderListLocal.length > 0 ? 'Select +' : 'Select Gender'}
                </Button>
              </Stack>
              {genderListLocal.length > 0 && (
                <Box
                  sx={{
                    background: '#FBFBFB',
                    borderRadius: theme.spacing(1),
                    maxHeight: theme.spacing(9 * 4)
                  }}
                >
                  {genderListLocal.map((gender) => (
                    <Box
                      sx={{
                        padding: theme.spacing(1, 2)
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Box sx={{ maxWidth: '70%' }}>
                          <Typography>{gender.label}</Typography>
                        </Box>
                        <Stack direction="row" alignItems="center" spacing={3}>
                          <FabSmall
                            size="small"
                            sx={{
                              marginLeft: theme.spacing(2)
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: theme.spacing(1.5)
                              }}
                              onClick={() =>
                                setGenderListLocal((prev) =>
                                  prev.filter(
                                    (prevLabel) => prevLabel.id !== gender.id
                                  )
                                )
                              }
                            />
                          </FabSmall>
                        </Stack>
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
            <Grid item lg={12}>
              <Typography variant="h5">Age</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                // sx={{ mb: theme.spacing(1) }}
              >
                <Stack direction="row" gap={1}>
                  {ageValidationOptions.map((option) => (
                    <CheckboxCustomNl
                      {...option}
                      key={option.id}
                      onClick={handleClickAgeValidation}
                    />
                  ))}
                </Stack>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClickAge}
                  sx={{ margin: theme.spacing(2, 1.5) }}
                >
                  {ageListLocal.length > 0 ? 'Select +' : 'Select age'}
                </Button>
              </Stack>
              {ageListLocal.length > 0 && (
                <Box
                  sx={{
                    background: '#FBFBFB',
                    borderRadius: theme.spacing(1)
                  }}
                >
                  {ageListLocal.map((age) => (
                    <Box
                      sx={{
                        padding: theme.spacing(1, 2)
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Box sx={{ maxWidth: '70%' }}>
                          <Typography>{age.label}</Typography>
                        </Box>
                        <Stack direction="row" alignItems="center" spacing={3}>
                          <FabSmall
                            size="small"
                            sx={{
                              marginLeft: theme.spacing(2)
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: theme.spacing(1.5)
                              }}
                              onClick={() =>
                                setAgeListLocal((prev) =>
                                  prev.filter(
                                    (prevLabel) => prevLabel.id !== age.id
                                  )
                                )
                              }
                            />
                          </FabSmall>
                        </Stack>
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
            <Grid item lg={12}>
              <Typography variant="h5">Interest</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                // sx={{ mb: theme.spacing(1) }}
              >
                <Stack direction="row" gap={1}>
                  {interestValidationOptions.map((option) => (
                    <CheckboxCustomNl
                      {...option}
                      key={option.id}
                      onClick={handleClickInterestValidation}
                    />
                  ))}
                </Stack>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClickInterest}
                  sx={{ margin: theme.spacing(2, 1.5) }}
                >
                  {interestListLocal.length > 0
                    ? 'Select +'
                    : 'Select interest'}
                </Button>
              </Stack>
              {interestListLocal.length > 0 && (
                <Box
                  sx={{
                    background: '#FBFBFB',
                    borderRadius: theme.spacing(1)
                  }}
                >
                  {interestListLocal.map((interest) => (
                    <Box
                      sx={{
                        padding: theme.spacing(1, 2)
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Box sx={{ maxWidth: '70%' }}>
                          <Typography>{interest.displayName}</Typography>
                        </Box>
                        <Stack direction="row" alignItems="center" spacing={3}>
                          <FabSmall
                            size="small"
                            sx={{
                              marginLeft: theme.spacing(2)
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: theme.spacing(1.5)
                              }}
                              onClick={() =>
                                setInterestListLocal((prev) =>
                                  prev.filter(
                                    (prevLabel) => prevLabel.id !== interest.id
                                  )
                                )
                              }
                            />
                          </FabSmall>
                        </Stack>
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
            <Grid item lg={12}>
              <Typography variant="h5">Label</Typography>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                // sx={{ mb: theme.spacing(1) }}
              >
                <Stack direction="row" gap={1}>
                  {labelValidationOptions.map((option) => (
                    <CheckboxCustomNl
                      {...option}
                      key={option.id}
                      onClick={handleClickLabelValidation}
                    />
                  ))}
                </Stack>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClickLabel}
                  sx={{ margin: theme.spacing(2, 1.5) }}
                >
                  {labelListLocal.length > 0 ? 'Select +' : 'Select label'}
                </Button>
              </Stack>
              {labelListLocal.length > 0 && (
                <Box
                  sx={{
                    background: '#FBFBFB',
                    borderRadius: theme.spacing(1)
                  }}
                >
                  {labelListLocal.map((label) => (
                    <Box
                      sx={{
                        padding: theme.spacing(1, 2)
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}
                      >
                        <Box sx={{ maxWidth: '70%' }}>
                          <Typography>{label.displayName}</Typography>
                        </Box>
                        <Stack direction="row" alignItems="center" spacing={3}>
                          <FabSmall
                            size="small"
                            sx={{
                              marginLeft: theme.spacing(2)
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: theme.spacing(1.5)
                              }}
                              onClick={() =>
                                setLabelListLocal((prev) =>
                                  prev.filter(
                                    (prevLabel) => prevLabel.id !== label.id
                                  )
                                )
                              }
                            />
                          </FabSmall>
                        </Stack>
                      </Stack>
                    </Box>
                  ))}
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Stack
            spacing={2}
            justifyContent="center"
            display="flex"
            direction="row"
            sx={{ mt: theme.spacing(3) }}
          >
            <Button
              variant="outlined"
              sx={{
                width: theme.spacing(20)
              }}
              onClick={handleDiscard}
              size="medium"
            >
              Discard
            </Button>
            <Button
              variant="contained"
              sx={{
                width: theme.spacing(20)
              }}
              onClick={handleDone}
              size="medium"
            >
              Done
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <ModalSelectGender
        open={openGender}
        onClose={handleCloseGender}
        prevSelectedGender={genderListLocal}
        onSubmit={(genders) => {
          handleCloseGender();
          setGenderListLocal(genders);
        }}
      />
      <ModalSelectAge
        open={openAge}
        onClose={handleCloseAge}
        prevSelectedAgeRange={ageListLocal}
        onSubmit={(ages) => {
          handleCloseAge();
          setAgeListLocal(ages);
        }}
      />
      <ModalSelectLabelPeople
        open={openLabel}
        onClose={handleCloseLabel}
        onSubmit={(labels) => {
          // close modal label
          handleCloseLabel();
          // setLabelListLocal to labels
          setLabelListLocal(labels);
        }}
        prevSelectedLabels={labelListLocal}
      />
      <ModalSelectInterest
        open={openInterest}
        onClose={handleCloseInterest}
        onSubmit={(interests) => {
          // close modal label
          handleCloseInterest();
          // setLabelListLocal to labels
          setInterestListLocal(interests);
        }}
        prevSelectedInterests={interestListLocal}
      />
    </>
  );
};

export default AdvanceSearchPeoplePanel;
