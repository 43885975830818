import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'src/app/store';

interface SidebarToggleState {
  toggle: boolean
}

const initialState: SidebarToggleState = {
  toggle: true,
}

export const sidebarToggleSlice = createSlice({
  name: 'sidebarToggle',
  initialState,
  reducers: {
    toogleSidebar: (state: SidebarToggleState, action: PayloadAction<boolean>)=> {
        state.toggle = action.payload
    }
  },
})

export const { toogleSidebar } = sidebarToggleSlice.actions

export const sidebarToggle = (state: RootState) => state.sidebarToggle.toggle

export default sidebarToggleSlice.reducer