/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  IconButton,
  OutlinedInput,
  styled,
  Theme,
  useTheme
} from '@mui/material';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import ColorLabelPicker from './ColorLabelPicker';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import useLabels from 'src/services/useLabels';
import { SxProps } from '@mui/system';
// import { ILabel } from 'src/models/general';
import {
  reducerUpdatelabelListSosmed,
  reducerUpdateArticleLabel,
  reducerUpdatePeopleLabel,
  reducerAddlabelListSosmed
} from 'src/features/label';
import { useLocation } from 'react-router';
import CloseIcon from '@mui/icons-material/Close';
import usePeople from 'src/services/usePeople';
import { reducerUpdateInterestList } from 'src/features/people';
import { TabVariant } from 'src/content/settings/MonitoredAssets/components/HeaderLabel';
import { GET_LABEL } from 'src/services/graphql/offlineMedia/query';

interface Props {
  title?: string;
  variantButton?: 'text' | 'outlined' | 'contained';
  titleButton?: string;
  sxButton?: SxProps<Theme>;
  dataList?: any;
  initialValues?: any;
  isEditing?: boolean;
  isHidden?: boolean;
  isStreamSelected?: boolean;
  onCloseEdit?: () => void;
  onSubmit?: (payload: any) => void;
  variantLabel?: TabVariant | 'interest';
}

interface ColorLabels {
  name: string;
  color: string;
}

const Container = styled(Box)(
  ({ theme }) => `
  display: grid;
  grid-column-gap: ${theme.spacing(1)};
  grid-template-columns: auto auto auto auto auto;
  margin-top: ${theme.spacing(2)}
    `
);

const Item = styled(Box)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)}
    `
);

const colors: Array<ColorLabels> = [
  { name: 'Red', color: '#E35200' },
  { name: 'Yellow', color: '#F4CB02' },
  { name: 'Blue', color: '#005BE3' },
  { name: 'Green', color: '#38D389' },
  { name: 'Orange', color: '#F4B002' },
  { name: 'Purple', color: '#8902DC' },
  { name: 'Black', color: '#051C26' },
  { name: 'Tosca', color: '#65CEEF' },
  { name: 'Coffee  ', color: '#6F4E37' }
];

const ModalNewLabel: React.FC<Props> = ({
  title = 'Create New Label',
  variantButton = 'contained',
  titleButton = 'New Label',
  sxButton = {
    borderRadius: 1
  },
  dataList,
  initialValues,
  isEditing = false,
  isHidden = true,
  onCloseEdit,
  isStreamSelected,
  onSubmit = (payload) => {},
  variantLabel
}) => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const theme = useTheme();
  const params = new URLSearchParams(search);
  let tab = params.get('tab');
  let value = variantLabel;
  const [openAdd, setOpenAdd] = useState(false);
  const [colorSelected, setColorSelected] = useState<string>('#6F4E37');
  const [label, setLabel] = useState<string>('');

  useEffect(() => {
    setLabel(isEditing ? initialValues.name : null);
  }, [isEditing]);

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const projectId = useAppSelector((state) => state.users.project.id);
  const {
    addStreamLabel,
    resAddStreamLabel,
    editStreamLabel,
    addArticleLabel,
    resAddArticleLabel,
    editArticleLabel,
    addPeopleLabel,
    resAddPeopleLabel,
    editPeopleLabel,
    addLabelOfm,
    resAddLabelOfm,
    editLabelOfm,
    resEditLabelOfm
  } = useLabels();

  const { createInterest, resCreateInterest } = usePeople();

  const addLabelDefault = () => {
    addStreamLabel({
      variables: {
        projectId,
        name: label,
        requestColor: colorSelected
      }
    }).then((response) => {
      if (!response?.data) return;
      const { labellingSystem_createLabel } = response.data;
      // const result = labellingSystem_createLabel;
      if (isStreamSelected) {
        onSubmit([labellingSystem_createLabel.id]);
      }
      dispatch(reducerAddlabelListSosmed(labellingSystem_createLabel));
      handleCloseAdd();
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    switch (value) {
      case 'stream_label':
        if (!isEditing) {
          addLabelDefault();
        } else {
          editStreamLabel({
            variables: {
              projectId,
              name: label,
              requestColor: colorSelected,
              labelId: initialValues.id
            }
          }).then((response) => {
            if (!response?.data) return;
            const { labellingSystem_updateLabel } = response.data;
            const updateLabelStream = dataList.map((labelStream) => {
              if (labelStream.id === labellingSystem_updateLabel.id) {
                return labellingSystem_updateLabel;
              }
              return labelStream;
            });
            dispatch(
              reducerUpdatelabelListSosmed({
                data: [...updateLabelStream],
                networkStatus: 1,
                loading: false
              })
            );
            onCloseEdit();
          });
        }
        break;
      case 'label_article_onm':
        if (!isEditing) {
          addArticleLabel({
            variables: {
              projectId,
              name: label,
              requestColor: colorSelected
            }
          }).then((response) => {
            if (!response?.data) return;
            const { labellingArticle_addLabelArticle } = response.data;
            dispatch(
              reducerUpdateArticleLabel({
                data: [...dataList, labellingArticle_addLabelArticle],
                networkStatus: 1,
                loading: false
              })
            );
            handleCloseAdd();
            if (isStreamSelected) {
              onSubmit([labellingArticle_addLabelArticle.id]);
              return;
            }
            onSubmit(labellingArticle_addLabelArticle);
          });
        } else {
          editArticleLabel({
            variables: {
              projectId: projectId,
              name: label,
              requestColor: colorSelected,
              labelId: initialValues.id
            }
          }).then((response) => {
            if (!response?.data) return;
            const { labellingArticle_updateLabelArticle } = response.data;
            const updateLabelArticle = dataList.map((labelArticle) => {
              if (labelArticle.id === labellingArticle_updateLabelArticle.id) {
                return labellingArticle_updateLabelArticle;
              }
              return labelArticle;
            });
            dispatch(
              reducerUpdateArticleLabel({
                data: [...updateLabelArticle],
                networkStatus: 1,
                loading: false
              })
            );
            onCloseEdit();
          });
        }
        break;
      case 'user_label':
        if (!isEditing) {
          addPeopleLabel({
            variables: {
              projectId,
              displayName: label,
              color: colorSelected
            }
          }).then((response) => {
            if (!response?.data) return;
            const { peoplePanel_createLabel } = response.data;
            dispatch(
              reducerUpdatePeopleLabel({
                data: [...dataList, peoplePanel_createLabel],
                networkStatus: 1,
                loading: false
              })
            );
            handleCloseAdd();
            if (isStreamSelected) {
              onSubmit([peoplePanel_createLabel.id]);
            }
          });
        } else {
          editPeopleLabel({
            variables: {
              projectId: projectId,
              name: label,
              color: colorSelected,
              labelId: initialValues.id
            }
          }).then((response) => {
            if (!response?.data) return;
            const { peoplePanel_updateLabel } = response.data;
            const updateLabelPeople = dataList.map((labelPeople) => {
              if (labelPeople.id === peoplePanel_updateLabel.id) {
                return peoplePanel_updateLabel;
              }
              return labelPeople;
            });
            dispatch(
              reducerUpdatePeopleLabel({
                data: [...updateLabelPeople],
                networkStatus: 1,
                loading: false
              })
            );
            onCloseEdit();
          });
        }
        break;
      case 'interest':
        if (!isEditing) {
          createInterest({
            variables: {
              projectId: projectId,
              displayName: label,
              color: colorSelected
            }
          }).then((response) => {
            if (!response?.data) return;
            const { peoplePanel_createInterest } = response.data;
            dispatch(
              reducerUpdateInterestList({
                data: [...dataList, peoplePanel_createInterest],
                networkStatus: 1,
                loading: false
              })
            );
            handleCloseAdd();
            if (isStreamSelected) {
              onSubmit([peoplePanel_createInterest.id]);
            }
          });
        }
        break;
      case 'label_article_ofm':
        // for add label ofm
        if (!isEditing) {
          addLabelOfm({
            variables: {
              projectId: projectId,
              name: label,
              requestColor: colorSelected,
              categoryArticle: 'offlineMedia'
            },
            refetchQueries: [GET_LABEL, 'article_getLabelArticle'],
            onCompleted: (data) => {
              const { article_addLabelArticle } = data;
              if (isStreamSelected) {
                onSubmit([article_addLabelArticle.id]);
                return;
              }
              onSubmit(article_addLabelArticle);
              handleCloseAdd();
            }
          });
          return;
        }
        // for edit label ofm
        editLabelOfm({
          variables: {
            labelId: initialValues.id,
            name: label,
            requestColor: colorSelected
          },
          refetchQueries: [GET_LABEL, 'article_getLabelArticle'],
          onCompleted: () => onCloseEdit()
        });
        break;
      default:
        addLabelDefault();
        break;
    }
  };

  return (
    <>
      {isHidden ? null : (
        <Button
          size="small"
          variant={variantButton}
          color="primary"
          endIcon={<AddIcon />}
          sx={sxButton}
          onClick={handleClickOpenAdd}
        >
          {titleButton}
        </Button>
      )}

      <Dialog
        onClose={isEditing ? onCloseEdit : handleCloseAdd}
        open={isEditing ? isEditing : openAdd}
        fullWidth
        maxWidth={'md'}
      >
        <IconButton
          aria-label="close"
          onClick={isEditing ? onCloseEdit : handleCloseAdd}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(3, 3, 1),
            textAlign: 'center'
          }}
        >
          <Typography variant="h3">{title}</Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            m: theme.spacing(1, 3)
          }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl fullWidth size="medium">
              <FormLabel>
                {variantLabel === 'interest' ? 'Interest name' : 'Label name'}
              </FormLabel>
              <OutlinedInput
                defaultValue={isEditing ? initialValues.name : null}
                name="objectName"
                type="search"
                onChange={(e) => setLabel(e.target.value)}
              />
            </FormControl>
            <Container>
              {colors.map(({ color, name }) => (
                <Item key={color}>
                  <ColorLabelPicker
                    color={color}
                    isActive={color === colorSelected}
                    label={name}
                    onClick={(color) => setColorSelected(color)}
                  />
                </Item>
              ))}
            </Container>
            <Box display="flex" justifyContent="center" mt={theme.spacing(4)}>
              <Button
                variant="contained"
                type="submit"
                size="large"
                sx={{
                  background: theme.palette.primary.dark,
                  width: theme.spacing(20)
                }}
                disabled={
                  resAddStreamLabel.loading ||
                  resAddArticleLabel.loading ||
                  resAddPeopleLabel.loading ||
                  resAddLabelOfm.loading
                }
              >
                Done
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalNewLabel;
