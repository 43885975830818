import { Grid, Paper, Box } from '@mui/material';
import Cover from 'src/assets/image/cover-register-account.jpeg';
import CssBaseline from '@mui/material/CssBaseline';
import PricingByPromo from './PricingByPromo';
import FormPayment from './FormPayment';
import { useLocation } from 'react-router';
import { useEffect } from 'react';
import useRegister from 'src/services/useRegister';

const Checkout = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const registerId = params.get('id');
  const { getXenditPaymentChannel, getUserPlanSimulationById } = useRegister();
  useEffect(() => {
    getXenditPaymentChannel();
    getUserPlanSimulationById(registerId);
  });
  return (
    <>
      <CssBaseline />
      <Grid container component="main" sx={{ height: '100vh', overflowY: 'scroll' }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              pt: 4,
              pr: 10,
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <FormPayment registerId={registerId} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          sx={{
            backgroundImage: `url(${Cover})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <Box
            sx={{
              pt: 4,
              pl: 10,
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%'
            }}
          >
            <PricingByPromo />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Checkout;
