import React from 'react';
import { Grid, Typography } from '@mui/material';
import TagSocialMedia from 'src/components/TagSocialMedia';
import { addSymbolObject } from 'src/utils/addSymbolObject';
import moment from 'moment';
import { IObject } from 'src/models/objectManagement';
import { useAppSelector } from 'src/app/hooks';

interface Props {
  detail: any;
}
const InformationDetailDownloadSocialMedia: React.FC<Props> = ({ detail }) => {
  const { start, end, objectIdList } = detail;
  // get object list from global state
  const objectList: IObject[] = useAppSelector(
    (state) => state.objectManagement.objectList.data
  );
  // filter object list by using id comparison
  const filteredObjectList: IObject[] = objectList.filter((object: IObject) =>
    objectIdList.includes(object.id)
  );
  return (
    <Grid container maxWidth={'600px'} gap={'6px'}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5">Objects:</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {filteredObjectList.slice(0, 20).map((object) => (
          <TagSocialMedia
            key={object.id}
            socialMedia={object.socialMedia}
            text={addSymbolObject(object).objectName}
          />
        ))}
        {filteredObjectList.length > 20 && (
          <TagSocialMedia
            socialMedia={filteredObjectList[0].socialMedia}
            text={'. . .'}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">{`Date Range: ${moment(start).format(
          'DD MMM YYYY'
        )} - ${moment(end).format('DD MMM YYYY')}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default InformationDetailDownloadSocialMedia;
