import { Box, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router';
import TooltipCustomize from 'src/components/TooltipCustomize';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function HeaderCustomReport() {
  //   const { pathname } = useLocation();
  //   const title = pathname.split('/')[2];

  const setTitle = () => {
    // switch (title) {
    //   case 'social_media':
    //     return 'Social Media Stream';
    //   default:
    //     return title.charAt(0).toUpperCase() + title.slice(1);
    // }
  };

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="h1"
              sx={{
                mr: 1
              }}
            >
              {/* {setTitle()} */}Custom Report
            </Typography>
            <TooltipCustomize
              title={'Build Custom Report'}
              placement="top-start"
            >
              <InfoOutlinedIcon
                fontSize="small"
                sx={{ transform: 'translateY(-4px)' }}
              />
            </TooltipCustomize>
          </Stack>
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
}

export default HeaderCustomReport;
