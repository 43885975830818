import { Box, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import TooltipCustomize from 'src/components/TooltipCustomize';
import BottomHeader from './Bottom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function HeaderOfflineMedia() {
  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <Typography
                variant="h1"
                sx={{
                  mr: 1
                }}
              >
                Others Media Stream
              </Typography>
              <TooltipCustomize
                title={'List of all others media'}
                placement="top-start"
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  sx={{ transform: 'translateY(-4px)' }}
                />
              </TooltipCustomize>
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12}>
          <BottomHeader />
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
}

export default HeaderOfflineMedia;
