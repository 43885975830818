import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useRef } from 'react';
import { useAppDispatch } from 'src/app/hooks';
import {
  reducerUpdateAllMetricLoading,
  reducerUpdateIgnoredStreamPanel,
  reducerUpdateStreamPanel
} from 'src/features/sosmedStream';
import { IAdvanceSearchSocialMedia } from 'src/models/general';
import {
  APPLY_LABEL_TO_ALL_STREAM_ON_RETWEET,
  MANAGE_LABELS_ON_STREAM,
  MANAGE_LABELS_ON_STREAM_BY_FILTER
} from './graphql/labels/mutation';
import {
  MANAGE_CONTENT_TYPE_ON_STREAM,
  MANAGE_CONTENT_TYPE_ON_STREAM_BY_FILTER,
  MANAGE_SENTIMENT_ON_STREAM,
  MANAGE_SENTIMENT_ON_STREAM_BY_FILTER,
  MANAGE_VISIBILITIES_ON_STREAM_BY_FILTER,
  MANAGE_VISIBILITY_BY_REQUEST,
  MANAGE_VISIBILITY_ON_STREAM,
  UPDATE_SENTIMENT
} from './graphql/stream/mutation';
import {
  GET_IGNORED_RAW_STREAM_OUTPUT_V2,
  GET_RAW_STREAM_OUTPUT
} from './graphql/stream/query';
import getNS from 'uuid/v4';
import uuidNameSpace from 'uuid/v5';
import { IStreamOutputWithPagination } from 'src/models/stream';
const RequestNameSpace = getNS();

interface IGetStreamOutputResponse {
  rawStream_getRawStreamOutputV2: IStreamOutputWithPagination;
}

interface IGetIgnoredStreamOutputResponse {
  rawStream_getIgnoredRawStreamOutputV2: IStreamOutputWithPagination;
}

const useStreams = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const lastPromiseStreamOutput: any = useRef();

  const fetchStreamOutputWithPagination = function (
    payload: IAdvanceSearchSocialMedia
  ) {
    return client
      .query<IGetStreamOutputResponse>({
        query: GET_RAW_STREAM_OUTPUT,
        variables: payload,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_RAW_STREAM_OUTPUT',
            RequestNameSpace
          )
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.rawStream_getRawStreamOutputV2
        };
      });
  };

  const getStreamOutputWithPagination = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateAllMetricLoading(true));
    const response = fetchStreamOutputWithPagination(payload);
    lastPromiseStreamOutput.current = response;
    if (response === lastPromiseStreamOutput.current) {
      response.then((response) => {
        dispatch(reducerUpdateStreamPanel(response));
      });
    }
  };

  const fetchIgnoredStreamOutputWithPagination = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IGetIgnoredStreamOutputResponse>({
      query: GET_IGNORED_RAW_STREAM_OUTPUT_V2,
      variables: payload,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_IGNORED_RAW_STREAM_OUTPUT_V2',
          RequestNameSpace
        )
      }
    });
    return {
      ...response,
      data: response.data.rawStream_getIgnoredRawStreamOutputV2
    };
  };

  const getIgnoredStreamOutputWithPagination = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    const response = fetchIgnoredStreamOutputWithPagination(payload);
    lastPromiseStreamOutput.current = response;
    if (response === lastPromiseStreamOutput.current) {
      response.then((response) => {
        dispatch(reducerUpdateIgnoredStreamPanel(response));
      });
    }
  };

  const [updateSentimentOnStream, resUpdateSentimentOnStream] = useMutation(
    MANAGE_SENTIMENT_ON_STREAM
  );
  const [updateLabelOnStream, responseUpdateLabelOnStream] = useMutation(
    MANAGE_LABELS_ON_STREAM
  );
  const [updateAllLabelOnStream, responseUpdateAllLabelOnStream] = useMutation(
    MANAGE_LABELS_ON_STREAM_BY_FILTER
  );
  const [
    applyLabelToAllStreamBasedOnRetweet,
    responseApplyLabelToAllStreamBasedOnRetweet
  ] = useMutation(APPLY_LABEL_TO_ALL_STREAM_ON_RETWEET);
  const [updateAllSentimentOnStream, responseUpdateAllSentimentOnStream] =
    useMutation(MANAGE_SENTIMENT_ON_STREAM_BY_FILTER);
  const [updateContentTypeOnStream, responseUpdateContentTypeOnStream] =
    useMutation(MANAGE_CONTENT_TYPE_ON_STREAM);
  const [updateAllContentTypeOnStream, responseUpdateAllContentTypeOnStream] =
    useMutation(MANAGE_CONTENT_TYPE_ON_STREAM_BY_FILTER);
  const [updateSentiment, responseUpdateSentiment] =
    useMutation(UPDATE_SENTIMENT);
  const [updateVisibility, responseUpdateVisibility] = useMutation(
    MANAGE_VISIBILITY_BY_REQUEST
  );
  const [ignorePost, responseIgnorePost] = useMutation(
    MANAGE_VISIBILITY_ON_STREAM
  );
  const [updateAllVisibilitiesOnStream, responseUpdateAllVisibilitiesOnStream] =
    useMutation(MANAGE_VISIBILITIES_ON_STREAM_BY_FILTER);
  const [restorePost, responseRestorePost] = useMutation(
    MANAGE_VISIBILITY_ON_STREAM
  );

  return {
    getStreamOutputWithPagination,
    updateSentimentOnStream,
    resUpdateSentimentOnStream,
    updateLabelOnStream,
    responseUpdateLabelOnStream,
    updateAllLabelOnStream,
    responseUpdateAllLabelOnStream,
    applyLabelToAllStreamBasedOnRetweet,
    responseApplyLabelToAllStreamBasedOnRetweet,
    updateAllSentimentOnStream,
    responseUpdateAllSentimentOnStream,
    updateContentTypeOnStream,
    responseUpdateContentTypeOnStream,
    updateAllContentTypeOnStream,
    responseUpdateAllContentTypeOnStream,
    updateSentiment,
    responseUpdateSentiment,
    updateVisibility,
    responseUpdateVisibility,
    ignorePost,
    responseIgnorePost,
    updateAllVisibilitiesOnStream,
    responseUpdateAllVisibilitiesOnStream,
    restorePost,
    responseRestorePost,
    getIgnoredStreamOutputWithPagination
  };
};

export default useStreams;
