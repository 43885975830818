import App from './App';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
// import { PostHogProvider } from 'posthog-js/react';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import { store } from 'src/app/store';
import client from 'src/services/graphql/settings';
import { StrictMode } from 'react';
import './styles.css';

const postHogOptions = {
  api_host: 'https://app.posthog.com'
};

ReactDOM.render(
  <StrictMode>
    {/* <PostHogProvider
      apiKey="phc_Cn56X1rGXM9VFGkjsIn8Sa0CTexin4guDI0OSwEwntv"
      options={postHogOptions}
    > */}
    <ApolloProvider client={client}>
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </ApolloProvider>
    {/* </PostHogProvider> */}
  </StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
