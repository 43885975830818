import { Box, Collapse, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from 'src/app/hooks';
import { useTopNotification } from 'src/hooks/useTopNotification';

const TopNotification = () => {
  //TODO: implementasi jika grace period gak bisa diclose
  const { message, open, severity, showCloseButton } = useAppSelector(
    (state) => state.storeAlert.topNotification
  );
  const { handleCloseNotification } = useTopNotification();
  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        <Alert
          severity={severity}
          action={
            showCloseButton ? (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  handleCloseNotification();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            ) : null
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
};

export default TopNotification;
