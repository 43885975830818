import { gql } from '@apollo/client';

export const slideContent = `
            widgetConfigId
            granularity{
                unit
                value
            }
            contentTypeList
            excludeKeywords
            excludeRt
            fromUsername
            includeKeywords
            metric
            category
            objectGroupId
            objectList
            postOwnership
            retweetFromOriginalIds
            sentiment
            sentimentConfidenceLevelMax
            sentimentConfidenceLevelMin
            showEmptyLabel
            timezone
            titleContent
            validationList
            visualization
            sourceType
            dataSource
            comparisonId
            quantity
            page
            limitRow
            sortingByColumn
            labelList
            replyToOriginalId
          comparisonIdOnlineMedia
          mediaList
          searchType
          clippingId
          languageList
            `;
// customReportId
// projectId
// slideId

export const slideLayoutDetails = `
          sectionHeader {
            title
          }
          titleSlide {
            title
            subTitle
          }
          singleContents {
            title
            contents {
              ${slideContent}
            }
          }
          twoContents {
            title
            contents {
              ${slideContent}
            }
          }
          threeContents {
            title
            contents {
              ${slideContent}
            }
          }
          fourContents {
            title
            contents {
              ${slideContent}
            }
          }
          nineContents {
            title
            contents {
              ${slideContent}
            }
          }
          oneTwoContents {
            title
            contents {
              ${slideContent}
            }
          }
          oneThreeContents {
            title
            contents {
              ${slideContent}
            }
          }
          twoOneContents {
            title
            contents {
              ${slideContent}
            }
          }
          threeOneContents {
            title
            contents {
              ${slideContent}
            }
          }
          verticalFourContents {
            title
            contents {
              ${slideContent}
            }
          }
          sixContents {
            title
            contents {
              ${slideContent}
            }
          }
      `;

export const GET_REPORT_LIST = gql`
  query customReportV2_getCustomReport(
    $projectId: Id!
    $page: Int!
    $limit: Int!
    $name: String
  ) {
    customReportV2_getCustomReport(
      projectId: $projectId
      page: $page
      limit: $limit
      name: $name
    ) {
      limit
      page
      totalRows
      totalPages
      result {
        id
        displayName
        createdAt
      }
    }
  }
`;

export const GET_REPORT_BY_ID = gql`
  query customReportV2_getCustomReportById($customReportId: Id!) {
    customReportV2_getCustomReportById(customReportId: $customReportId) {
      id
      displayName
      createdAt
    }
  }
`;

export const GET_SLIDES = gql`
  query customReportV2_getSlidesWithPagination(
    $projectId: Id!
    $customReportId: Id!
    $page: Int!
    $limit: Int!
  ) {
    customReportV2_getSlidesWithPagination(
      projectId: $projectId
      customReportId: $customReportId
      page: $page
      limit: $limit
    ) {
      limit
      page
      totalRows
      totalPages
      result {
        id
        pagePosition
        slideType
        details {
          ${slideLayoutDetails}
        }
      }
    }
  }
`;

export const GET_REPORT_METRICS_OPTION = gql`
  query customReportv2_getMetrics {
    customReportV2_getMetrics {
      category
      metrics {
        label
        value
      }
    }
  }
`;

export const GET_REPORT_VISUALIZATION = gql`
  query customReportV2_getVisualizations($metric: String!, $category: String!) {
    customReportV2_getVisualizations(metric: $metric, category: $category) {
      label
      value
    }
  }
`;

export const GET_WIDGET_DATA = gql`
  query customReportV2_getWidgetData(
    $customReportArgument: CustomReportWidgetArgument!
    $slideId: Id!
    $startDate: Timestamp!
    $endDate: Timestamp!
  ) {
    customReportV2_getWidgetData(
      customReportArgument: $customReportArgument
      slideId: $slideId
      startDate: $startDate
      endDate: $endDate
    ) {
      responseType
      details {
        chronologicalGroup {
          contentType
          values {
            timestamp
            value
          }
        }
        chronological {
          contentType
          values {
            timestamp
            value
          }
        }
        nameValue {
          name
          value
        }
        table {
          header
          body {
            cells {
              cellName
              cellValue
            }
          }
          rowCount
        }
        numericWithGrowth {
          recentValue
          growth
          pastValue
          contentType
          __typename
        }
        user {
          socialMedia
          fromName
          value
          talk
          followers
        }
        peakTime {
          peakTimeValue {
            name
            data
          }
          max
          min
        }
        groupSentiment {
          groupName
          positive
          neutral
          negative
        }
        groupPosition {
          name
          popularity
          positivity
        }
        streamOutput {
          originalId
          socialMedia
          displayPicture
          fromId
          fromName
          timestamp
          shareCount
          likeCount
          commentCount
          engagement
          engagementRate
          content
          link
          label {
            displayName
            id
          }
          reach
          impression
          contentType
          sentiment
          viewCount
          mediaLink
        }
        numericOutput {
          value
          name
        }
        onlineMediaStream {
          originalId
          createdAt
          datePublished
          displayPicture
          title
          content
          link
          sourceName
        }
        conversationByMedia {
          socialMedia
          talk
          talker
          reach
          impression
        }
      }
    }
  }
`;
