import {
  Box,
  FormControl,
  Stack,
  TextField,
  IconButton,
  Button,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import SelectDateRange from 'src/components/SelectDateRange';
import { KeyboardEvent, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import DateRangeLabel from '../../components/DateRangeLabel';
import ClearIcon from '@mui/icons-material/Clear';
import useOnlineMedia from 'src/services/useOnlineMedia';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { IGranularity } from 'src/models/general';
import TooltipCustomize from 'src/components/TooltipCustomize';
import RefreshIcon from '@mui/icons-material/Refresh';
import { reducerUpdateAdvanceSearchOfflineMediaPhrases } from 'src/features/advanceSearchOfflineMedia';

const CustomBox = styled(Box)(
  ({ theme }) => `
    // width: calc(100vw - ${theme.sidebar.width} - ${theme.spacing(10)});
    margin: ${theme.spacing(2, 0)};
    // position: absolute;
`
);

const FilterBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
);

function BottomHeader() {
  const theme = useTheme();
  const [localSearch, setLocalSearch] = useState<string>('');
  const dispatch = useAppDispatch();
  const advanceSearchOnlineMedia = useAppSelector(
    (state) => state.storeAdvanceSearchOnlineMedia
  );
  const projectId = useAppSelector((state) => state.users.project.id);
  const { dateRangeFormatted } = useAppSelector(
    (state) => state.analyticArguments
  );

  const fillAdvanceSearchOnlineMedia = useMemo(() => {
    return fillParamAdvanceSearchEmpty(advanceSearchOnlineMedia);
  }, [advanceSearchOnlineMedia]);

  const { getAllMetricStatistic } = useOnlineMedia();

  const granularity: IGranularity = {
    unit: 'day',
    value: 1
  };

  const finalPayload = {
    ...fillAdvanceSearchOnlineMedia,
    projectId,
    ...dateRangeFormatted,
    granularity,
    timezone: 7,
    page: 1,
    limit: 20
  };

  const handleSearch = () => {
    // getAllMetricStatistic({ ...finalPayload, phrases: [localSearch] });
    dispatch(reducerUpdateAdvanceSearchOfflineMediaPhrases([localSearch]));
    // console.log('phrase entered');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') handleSearch();
  };

  const handleClear = () => {
    setLocalSearch('');
    dispatch(reducerUpdateAdvanceSearchOfflineMediaPhrases([]));
  };

  const handleRefresh = () => {
    // getAllMetricStatistic(finalPayload);
  };

  return (
    <CustomBox>
      <FilterBox>
        <Stack direction="row" alignItems="center" spacing={2}>
          <TextField
            size="small"
            sx={{ width: 509 }}
            InputProps={{
              startAdornment: (
                <IconButton size="small" onClick={handleSearch}>
                  <SearchTwoToneIcon />
                </IconButton>
              ),
              endAdornment: localSearch ? (
                <IconButton size="small" onClick={handleClear}>
                  <ClearIcon />
                </IconButton>
              ) : null
            }}
            placeholder="Search phrase"
            onChange={(e) => setLocalSearch(e.target.value)}
            value={localSearch}
            onKeyDown={handleKeyDown}
          />
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <FormControl size="small" sx={{ width: 135 }}>
            <SelectDateRange />
          </FormControl>
          {/* <TooltipCustomize title="Refresh all metric" placement="top-start">
            <Button
              variant="contained"
              onClick={handleRefresh}
              sx={{
                borderRadius: '6px',
                height: theme.spacing(5),
                minWidth: theme.spacing(6),
                background: theme.palette.primary.main
              }}
            >
              <RefreshIcon />
            </Button>
          </TooltipCustomize> */}
        </Stack>
      </FilterBox>
      <DateRangeLabel label={`Viewing data within`} />
    </CustomBox>
  );
}

export default BottomHeader;
