import {
  Box,
  Checkbox,
  Chip,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';

interface CheckboxCustomProps {
  id: string;
  name: string;
  label: string;
  isChecked: boolean;
  onClick: (id: string) => void;
}

interface ColorChip {
  colorChip: string;
}

export const ChipCustom = styled(Chip)<ColorChip>(
  ({ theme, colorChip }) => `
        background-color: ${theme.palette.primary.main};
        border-radius: ${theme.spacing(1)};
        padding: 10px ${theme.spacing(1)};
        height: 36px;
        cursor: pointer;
        &:hover {
          background-color: ${theme.palette.primary.dark};
        }
        .MuiChip-label {
            color: ${colorChip};
            font-size: 14px;
            line-height: 130%;
            margin: 0 8px;
        }
        .MuiChip-avatarSmall {
            width: 14px;
            color: ${colorChip};
        }
        .MuiChip-deleteIcon {
            font-size: 14px;
            color: ${colorChip};
        }
      `
);

const CheckboxCustom = styled(Checkbox)(
  ({ theme }) => `
        &:hover {
          background-color: unset;
        }
        padding: 0
      `
);

const CheckboxCustomNl: React.FC<CheckboxCustomProps> = ({
  id,
  name,
  label,
  isChecked,
  onClick
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        mr: 2,
        border: `1px solid rgba(0, 159, 227, 0.2)`,
        borderRadius: theme.spacing(1)
      }}
      key={id}
    >
      <CheckboxCustom
        name={name}
        checked={isChecked}
        onClick={() => onClick(id)}
        checkedIcon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            label={
              <Stack direction="row" alignItems="center">
                <CheckCircleOutlineIcon
                  sx={{
                    mr: 1,
                    width: 16,
                    height: 16,
                    color: theme.palette.common.white
                  }}
                />{' '}
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme.palette.common.white,
                    fontSize: 13,
                    fontWeight: 'normal'
                  }}
                >
                  {label}
                </Typography>
              </Stack>
            }
            size="small"
            sx={{
              background: theme.palette.primary.main,
              padding: '10px 56px 10px 8px'
            }}
            colorChip={theme.palette.common.white}
          />
        }
        icon={
          <ChipCustom
            variant="filled"
            deleteIcon={<CheckIcon />}
            label={
              <Stack direction="row" alignItems="center">
                <PanoramaFishEyeIcon
                  sx={{
                    mr: 1,
                    width: 16,
                    height: 16,
                    color: theme.palette.primary.main
                  }}
                />{' '}
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: theme.palette.primary.main,
                    fontSize: 13,
                    fontWeight: 'normal'
                  }}
                >
                  {label}
                </Typography>
              </Stack>
            }
            size="small"
            sx={{
              background: theme.palette.primary.light,
              padding: '10px 56px 10px 8px'
            }}
            colorChip={theme.palette.common.black}
          />
        }
      />
    </Box>
  );
};

export default CheckboxCustomNl;
