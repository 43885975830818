import { Box, FormControl, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import SelectDateRange from 'src/components/SelectDateRange';
import { useMemo } from 'react';
import { useAppSelector } from 'src/app/hooks';
import ModalSelectWidget from 'src/components/ModalSelectWidget';
import DateRangeLabel from '../../components/DateRangeLabel';

const CustomBox = styled(Box)(
  ({ theme }) => `
    width: calc(100vw - ${theme.sidebar.width} - ${theme.spacing(10)});
    margin: ${theme.spacing(2, 0)};
    position: absolute;
`
);

const FilterBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
);

function BottomHeader() {
  const { dashboardList, dashboardSelectedById } = useAppSelector(
    (state) => state.storeCustomDashboard
  );
  const customDashboardWidgetMetadataList = useMemo(() => {
    const filter = dashboardList.data.filter(
      (dashboard) => dashboard.id === dashboardSelectedById
    )[0]?.customDashboardWidgetMetadataList;
    return filter ? filter : [];
  }, [dashboardSelectedById, dashboardList]);

  return (
    <CustomBox>
      <FilterBox>
        <Stack direction="row" spacing={2}>
          <FormControl size="small" sx={{ width: 135 }}>
            <SelectDateRange />
          </FormControl>
          <ModalSelectWidget
            prevSelectedWidget={customDashboardWidgetMetadataList}
          />
        </Stack>
      </FilterBox>
      <DateRangeLabel label={`Viewing data within`} />
    </CustomBox>
  );
}

export default BottomHeader;
