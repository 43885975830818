import { gql } from '@apollo/client';

const peopleResult = `
originalId
socialMedia
displayPicture
gender
ageRange
username
displayName
link
interestList {
  id
  name
  displayName
  isProjectDefault
  color
  isActive
  createdAt
}
labelList {
  id
  name
  displayName
  isProjectDefault
  color
  isActive
  createdAt
}
location {
  city {
    id
    parentId
    name
    displayName
    level
    createdAt
  }
  state {
    id
    parentId
    name
    displayName
    level
    createdAt
  }
  country {
    id
    parentId
    name
    displayName
    level
    createdAt
  }
}
`;

export const GET_PEOPLE = gql`
query peoplePanel_getEngagedUserWithPagination(
  $projectId: Id!
  $start: Timestamp!
  $end: Timestamp!
  $interestIdList: [Id!]!
  $interestValidationList: [ValidationType!]!
  $labelIdList: [Id!]!
  $labelValidationList: [ValidationType!]!
  $ageList: [AgeRange!]!
  $ageRangeValidationList: [ValidationType!]!
  $countryIdList: [Id!]!
  $objectList: [Id!]!
  $cityIdList: [Id!]!
  $stateIdList: [Id!]!
  $locationValidationList: [ValidationType!]!
  $genderList: [Gender!]!
  $genderValidationList: [ValidationType!]!
  $includeUsername: [String!]
  $granularity: GranularityInput!
  $timezone: Int!
  $page: Int!
  $sizeToFetch: Int!
) {
  peoplePanel_getEngagedUserWithPagination(
  projectId: $projectId
  start: $start
  end: $end
  interestIdList: $interestIdList
  interestValidationList: $interestValidationList
  labelIdList: $labelIdList
  labelValidationList: $labelValidationList
  ageList: $ageList
  ageRangeValidationList: $ageRangeValidationList
  countryIdList: $countryIdList
  objectList: $objectList
  cityIdList: $cityIdList
  stateIdList: $stateIdList
  locationValidationList: $locationValidationList
  genderList: $genderList
  genderValidationList: $genderValidationList
  includeUsername: $includeUsername
  granularity: $granularity
  timezone: $timezone
  page: $page
  sizeToFetch: $sizeToFetch
  ){
    limit
    page
    totalPages
    totalRows
    result {
      ${peopleResult}
    }
  }
}
`;

export const GET_INTEREST_LIST = gql`
  query peoplePanel_getInterestList($projectId: Id!) {
    peoplePanel_getInterestList(projectId: $projectId) {
      displayName
      name
      color
      isProjectDefault
      displayName
      id
      __typename
    }
  }
`;

export const UPDATE_GENDER_BY_SELECTED = gql`
mutation peoplePanel_updateGenderOfEngagedUsersBySelection($projectId: Id!, $userIds: [UserIds!]!, $gender: Gender!) {
  peoplePanel_updateGenderOfEngagedUsersBySelection(projectId: $projectId, userIds: $userIds, gender: $gender) {
    ${peopleResult}
    __typename
  }
}
`;

export const UPDATE_AGE_RANGE_BY_SELECTED = gql`
mutation peoplePanel_updateAgeOfEngagedUsersBySelection(
  $projectId: Id!
  $userIds: [UserIds!]!
  $ageRange: AgeRange!
) {
  peoplePanel_updateAgeOfEngagedUsersBySelection(
    projectId: $projectId
    userIds: $userIds
    ageRange: $ageRange
  ) {
    ${peopleResult}
    __typename
  }
}

`;

export const CREATE_INTEREST = gql`
  mutation peoplePanel_createInterest(
    $projectId: Id!
    $displayName: String!
    $color: String!
  ) {
    peoplePanel_createInterest(
      projectId: $projectId
      displayName: $displayName
      color: $color
    ) {
      id
      name
      displayName
      color
      isActive
      createdAt
      isProjectDefault
      __typename
    }
  }
`;

export const UPDATE_PEOPLE_LABELS = gql`
mutation peoplePanel_updateLabelOfEngagedUsersBySelection($projectId: Id!, $userIds: [UserIds!]!, $attachLabels: [Id!]!, $removeLabels: [Id!]!) {
  peoplePanel_updateLabelOfEngagedUsersBySelection(projectId: $projectId, userIds: $userIds, attachLabels: $attachLabels, removeLabels: $removeLabels) {
    ${peopleResult}
    __typename
  }
}
`;

export const UPDATE_PEOPLE_INTERESTS = gql`
  mutation peoplePanel_updateInterestOfEngagedUsersBySelection(
    $projectId: Id!
    $userIds: [UserIds!]!
    $attachInterest: [Id!]!
    $removeInterest: [Id!]!
  ) {
    peoplePanel_updateInterestOfEngagedUsersBySelection(
      projectId: $projectId
      userIds: $userIds
      attachInterest: $attachInterest
      removeInterest: $removeInterest
    ) {
      ${peopleResult}
      __typename
    }
  }
`;

export const GET_PEOPLE_LOCATION_LIST = gql`
  query peoplePanel_getLocationList($phrase: String!, $count: Int!) {
    peoplePanel_getLocationList(phrase: $phrase, count: $count) {
      city {
        id
        parentId
        name
        displayName
        level
      }
      state {
        id
        parentId
        name
        displayName
        level
      }
      country {
        id
        parentId
        name
        displayName
        level
      }
      __typename
    }
  }
`;

export const UPDATE_PEOPLE_LOCATION = gql`
  mutation peoplePanel_updateLocationEngagedUsersBySelection(
    $projectId: Id!
    $userIds: [UserIds!]!
    $country: Id!
    $state: Id
    $city: Id
  ) {
    peoplePanel_updateLocationOfEngagedUsersBySelection(
      projectId: $projectId
      userIds: $userIds
      country: $country
      state: $state
      city: $city
    ) {
      ${peopleResult}
    }
  }
`;

export const IGNORE_PEOPLE_BY_SELECTION = gql`
mutation peoplePanel_ignoreEngagedUsersBySelection($projectId: Id!, $userIds: [UserIds!]!, $visibility: Visibility!) {
  peoplePanel_ignoreEngagedUsersBySelection(projectId: $projectId, userIds: $userIds, visibility: $visibility) {
    ${peopleResult}
    __typename
  }
}
`;

export const GET_IGNORED_PEOPLE_LIST = gql`
query peoplePanel_getIgnoreEngagedUserWithPagination(
  $projectId: Id!
  $start: Timestamp!
  $end: Timestamp!
  $interestIdList: [Id!]!
  $interestValidationList: [ValidationType!]!
  $labelIdList: [Id!]!
  $labelValidationList: [ValidationType!]!
  $ageList: [AgeRange!]!
  $ageRangeValidationList: [ValidationType!]!
  $countryIdList: [Id!]!
  $objectList: [Id!]!
  $cityIdList: [Id!]!
  $stateIdList: [Id!]!
  $locationValidationList: [ValidationType!]!
  $genderList: [Gender!]!
  $genderValidationList: [ValidationType!]!
  $includeUsername: [String!]!
  $page: Int!
  $sizeToFetch: Int!
  $timezone: Int!
  $granularity: GranularityInput!
) {
  peoplePanel_getIgnoreEngagedUserWithPagination(
    projectId: $projectId
    objectList: $objectList
    start: $start
    end: $end
    interestIdList: $interestIdList
    interestValidationList: $interestValidationList
    labelIdList: $labelIdList
    labelValidationList: $labelValidationList
    ageList: $ageList
    ageRangeValidationList: $ageRangeValidationList
    countryIdList: $countryIdList
    cityIdList: $cityIdList
    stateIdList: $stateIdList
    locationValidationList: $locationValidationList
    genderList: $genderList
    genderValidationList: $genderValidationList
    includeUsername: $includeUsername
    page: $page
    sizeToFetch: $sizeToFetch
    timezone: $timezone
    granularity: $granularity
  ) {
    result {
      ${peopleResult}
      __typename
    }
    totalRows
    totalPages
    page
    limit
  }
}
`;

export const UPDATE_GENDER_BY_FILTER = gql `
mutation peoplePanel_updateGenderOfEngagedUsersByFilter(
  $projectId: Id!
  $start: Timestamp!
  $end: Timestamp!
  $interestIdList: [Id!]!
  $interestValidationList: [ValidationType!]!
  $labelIdList: [Id!]!
  $labelValidationList: [ValidationType!]!
  $ageList: [AgeRange!]!
  $ageRangeValidationList: [ValidationType!]!
  $countryIdList: [Id!]!
  $objectList: [Id!]!
  $cityIdList: [Id!]!
  $stateIdList: [Id!]!
  $locationValidationList: [ValidationType!]!
  $genderList: [Gender!]!
  $genderValidationList: [ValidationType!]!
  $includeUsername: [String!]
  $granularity: GranularityInput!
  $timezone: Int!
  $gender: Gender!
) {
  peoplePanel_updateGenderOfEngagedUsersByFilter(
    projectId: $projectId
    start: $start
    end: $end
    interestIdList: $interestIdList
    interestValidationList: $interestValidationList
    labelIdList: $labelIdList
    labelValidationList: $labelValidationList
    ageList: $ageList
    ageRangeValidationList: $ageRangeValidationList
    countryIdList: $countryIdList
    objectList: $objectList
    cityIdList: $cityIdList
    stateIdList: $stateIdList
    locationValidationList: $locationValidationList
    genderList: $genderList
    genderValidationList: $genderValidationList
    includeUsername: $includeUsername
    granularity: $granularity
    timezone: $timezone
    gender: $gender
  ) {
    id
    total
    progress
    removeGender
    updateGender
    ageRangeList
    interestList {
      id
      name
      displayName
    }
    labelList {
      id
      name
      displayName
    }
    cityList {
      id
      name
    }
    stateList {
      id
      name
    }
    countryList {
      id
      name
    }
    __typename
  }
}
`

export const UPDATE_AGE_RANGE_BY_FILTER = gql `
mutation peoplePanel_updateAgeRangeOfEngagedUsersByFilter(
  $projectId: Id!
  $start: Timestamp!
  $end: Timestamp!
  $interestIdList: [Id!]!
  $interestValidationList: [ValidationType!]!
  $labelIdList: [Id!]!
  $labelValidationList: [ValidationType!]!
  $ageList: [AgeRange!]!
  $ageRangeValidationList: [ValidationType!]!
  $countryIdList: [Id!]!
  $objectList: [Id!]!
  $cityIdList: [Id!]!
  $stateIdList: [Id!]!
  $locationValidationList: [ValidationType!]!
  $genderList: [Gender!]!
  $genderValidationList: [ValidationType!]!
  $includeUsername: [String!]
  $granularity: GranularityInput!
  $timezone: Int!
  $ageRange: AgeRange!
) {
  peoplePanel_updateAgeRangeOfEngagedUsersByFilter(
    projectId: $projectId
    start: $start
    end: $end
    interestIdList: $interestIdList
    interestValidationList: $interestValidationList
    labelIdList: $labelIdList
    labelValidationList: $labelValidationList
    ageList: $ageList
    ageRangeValidationList: $ageRangeValidationList
    countryIdList: $countryIdList
    objectList: $objectList
    cityIdList: $cityIdList
    stateIdList: $stateIdList
    locationValidationList: $locationValidationList
    genderList: $genderList
    genderValidationList: $genderValidationList
    includeUsername: $includeUsername
    granularity: $granularity
    timezone: $timezone
    ageRange: $ageRange
  ) {
    id
    total
    progress
    ageRangeList
    genderList
    removeAgeRange
    updateAgeRange
    interestList {
      id
      name
      displayName
    }
    labelList {
      id
      name
      displayName
    }
    cityList {
      id
      name
    }
    stateList {
      id
      name
    }
    countryList {
      id
      name
    }
  }
}
`

export const UPDATE_PEOPLE_INTEREST_BY_FILTER = gql `
mutation peoplePanel_updateInterestsOfEngagedUsersByFilter(
  $projectId: Id!
  $start: Timestamp!
  $end: Timestamp!
  $interestIdList: [Id!]!
  $interestValidationList: [ValidationType!]!
  $labelIdList: [Id!]!
  $labelValidationList: [ValidationType!]!
  $ageList: [AgeRange!]!
  $ageRangeValidationList: [ValidationType!]!
  $countryIdList: [Id!]!
  $objectList: [Id!]!
  $cityIdList: [Id!]!
  $stateIdList: [Id!]!
  $locationValidationList: [ValidationType!]!
  $genderList: [Gender!]!
  $genderValidationList: [ValidationType!]!
  $includeUsername: [String!]
  $granularity: GranularityInput!
  $timezone: Int!
  $attachInterest: [Id!]!
  $removeInterest: [Id!]!
) {
  peoplePanel_updateInterestsOfEngagedUsersByFilter(
    projectId: $projectId
    start: $start
    end: $end
    interestIdList: $interestIdList
    interestValidationList: $interestValidationList
    labelIdList: $labelIdList
    labelValidationList: $labelValidationList
    ageList: $ageList
    ageRangeValidationList: $ageRangeValidationList
    countryIdList: $countryIdList
    objectList: $objectList
    cityIdList: $cityIdList
    stateIdList: $stateIdList
    locationValidationList: $locationValidationList
    genderList: $genderList
    genderValidationList: $genderValidationList
    includeUsername: $includeUsername
    granularity: $granularity
    timezone: $timezone
    attachInterest: $attachInterest
    removeInterest: $removeInterest
  ) {
    id
    total
    progress
    attachInterest {
      id
      name
    }
    removeInterest {
      id
      name
    }
    ageRangeList
    interestList {
      id
      name
      displayName
    }
    labelList {
      id
      name
      displayName
    }
    cityList {
      id
      name
    }
    stateList {
      id
      name
    }
    countryList {
      id
      name
    }
    __typename
  }
}
`

export const UPDATE_PEOPLE_LABELS_BY_FILTER = gql `
  mutation peoplePanel_updateLabelsOfEngagedUsersByFilter(
  $projectId: Id!
  $start: Timestamp!
  $end: Timestamp!
  $interestIdList: [Id!]!
  $interestValidationList: [ValidationType!]!
  $labelIdList: [Id!]!
  $labelValidationList: [ValidationType!]!
  $ageList: [AgeRange!]!
  $ageRangeValidationList: [ValidationType!]!
  $countryIdList: [Id!]!
  $objectList: [Id!]!
  $cityIdList: [Id!]!
  $stateIdList: [Id!]!
  $locationValidationList: [ValidationType!]!
  $genderList: [Gender!]!
  $genderValidationList: [ValidationType!]!
  $includeUsername: [String!]
  $granularity: GranularityInput!
  $timezone: Int!
  $attachLabels: [Id!]!
  $removeLabels: [Id!]!
) {
  peoplePanel_updateLabelsOfEngagedUsersByFilter(
    projectId: $projectId
    start: $start
    end: $end
    interestIdList: $interestIdList
    interestValidationList: $interestValidationList
    labelIdList: $labelIdList
    labelValidationList: $labelValidationList
    ageList: $ageList
    ageRangeValidationList: $ageRangeValidationList
    countryIdList: $countryIdList
    objectList: $objectList
    cityIdList: $cityIdList
    stateIdList: $stateIdList
    locationValidationList: $locationValidationList
    genderList: $genderList
    genderValidationList: $genderValidationList
    includeUsername: $includeUsername
    granularity: $granularity
    timezone: $timezone
    attachLabels: $attachLabels
    removeLabels: $removeLabels
  ) {
    id
    total
    progress
    attachLabels {
      id
      name
    }
    removeLabels {
      id
      name
    }
    ageRangeList
    interestList {
      id
      name
      displayName
    }
    labelList {
      id
      name
      displayName
    }
    cityList {
      id
      name
    }
    stateList {
      id
      name
    }
    countryList {
      id
      name
    }
    __typename
  }
}
`

export const UPDATE_PEOPLE_LOCATION_BY_FILTER = gql `
  mutation peoplePanel_updateLocationOfEngagedUsersByFilter(
    $projectId: Id!
  $start: Timestamp!
  $end: Timestamp!
  $interestIdList: [Id!]!
  $interestValidationList: [ValidationType!]!
  $labelIdList: [Id!]!
  $labelValidationList: [ValidationType!]!
  $ageList: [AgeRange!]!
  $ageRangeValidationList: [ValidationType!]!
  $countryIdList: [Id!]!
  $objectList: [Id!]!
  $cityIdList: [Id!]!
  $stateIdList: [Id!]!
  $locationValidationList: [ValidationType!]!
  $genderList: [Gender!]!
  $genderValidationList: [ValidationType!]!
  $includeUsername: [String!]
  $granularity: GranularityInput!
  $timezone: Int!
  $city: Id,
  $state: Id,
  $country: Id!
  ){
  peoplePanel_updateLabelsOfEngagedUsersByFilter(
    projectId: $projectId
    start: $start
    end: $end
    interestIdList: $interestIdList
    interestValidationList: $interestValidationList
    labelIdList: $labelIdList
    labelValidationList: $labelValidationList
    ageList: $ageList
    ageRangeValidationList: $ageRangeValidationList
    countryIdList: $countryIdList
    objectList: $objectList
    cityIdList: $cityIdList
    stateIdList: $stateIdList
    locationValidationList: $locationValidationList
    genderList: $genderList
    genderValidationList: $genderValidationList
    includeUsername: $includeUsername
    granularity: $granularity
    timezone: $timezone
    attachLabels: $attachLabels
    removeLabels: $removeLabels
  ) {
    id
    total
    progress
    ageRangeList
    interestList {
      id
      name
      displayName
    }
    labelList {
      id
      name
      displayName
    }
    cityList {
      id
      name
    }
    stateList {
      id
      name
    }
    countryList {
      id
      name
    }
    updateCity {
    id
    parentId
    name
    displayName
    level
    createdAt
  }
    updateCountry {
    id
    parentId
    name
    displayName
    level
    createdAt
  }
    updateState {
    id
    parentId
    name
    displayName
    level
    createdAt
  }
    __typename
  }}
`