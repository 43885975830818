import gql from 'graphql-tag';

const CHECK_NOTIFICATION = gql`
  query notification_getNotification($projectId: Id!) {
    notification_getNotification(projectId: $projectId) {
      id
      title
      projectId
      parentId
      typeNotification
      subTypeNotification
      status
      totalProgress
      currentProgress
      createdAt
      detail {
        updateContentMediaType {
          contentMediaTypeUpdate
          start
          end
          objectList {
            id
            socialMedia
            streamType
            extraContent
            objectName
            isHashtag
          }
          timezoneOffset
          sentiments
          postOwnerShip
          labels {
            id
            name
            displayName
            colorType
          }
          keywordsInclude
          keywordsExclude
          processedBatches
          totalBatches
          totalData
          labels {
            id
            name
            displayName
            colorType
          }
          status
          id
          __typename
        }
        downloadReport {
          projectId
          startDate
          endDate
          timezone
          progress
          totalJob
          status
          fileName
          link
        }
        updateSentiment {
          start
          end
          objectList {
            id
            socialMedia
            streamType
            extraContent
            objectName
            isHashtag
          }
          timezoneOffset
          sentiments
          postOwnerShip
          labels {
            id
            name
            displayName
            colorType
            isActive
            createdAt
            __typename
          }
          keywordsInclude
          keywordsExclude
          processedBatches
          totalBatches
          totalData
          labels {
            id
            name
            displayName
            colorType
          }
          status
          id
        }
        onDemandOnlineMediaSearch {
          id
          projectId
          phrases
          totalJob
          status
          processedArticles
          createdAt
          __typename
        }
        ignoreStream {
          start
          end
          objectList {
            id
            socialMedia
            streamType
            extraContent
            objectName
            isHashtag
          }
          timezoneOffset
          sentiments
          postOwnerShip
          labels {
            id
            name
            displayName
            colorType
          }
          keywordsInclude
          keywordsExclude
          processedBatches
          totalBatches
          totalData
          labels {
            id
            name
            displayName
            colorType
          }
          status
          id
          __typename
        }
        firstTimeSocialMediaData {
          id
          projectId
          totalJob
          successCount
          errorCount
          pendingCount
          createdAt
          details {
            projectId
            firstProjectCrawId
            status
            streamType
            socialMedia
            content
            objectName
            createdAt
          }
        }
        downloadSocialMedia {
          id
          start
          end
          objectIdList
          status
          createdAt
          link
          email
        }
        downloadOnlineMedia {
          id
          start
          end
          mediaList
          phrase
          status
          createdAt
          link
        }
        downloadOfflineMedia {
          id
          start
          end
          mediaList
          phrase
          status
          createdAt
          link
        }
        updateLabel {
          start
          end
          objectList {
            id
            socialMedia
            streamType
            extraContent
            objectName
            isHashtag
          }
          timezoneOffset
          sentiments
          postOwnerShip
          labels {
            id
            name
            displayName
            colorType
          }
          keywordsInclude
          keywordsExclude
          processedBatches
          totalBatches
          totalData
          attachLabels {
            id
            name
            displayName
            colorType
          }
          removeLabels {
            id
            name
            displayName
            colorType
          }
          status
          id
          __typename
        }
        updateSentimentOnm {
          projectId
          start
          end
          timezoneOffset
          sentimentUpdate
          processedBatches
          totalBatches
          totalData
          processedData
          status
          id
          clipping {
            id
            name
            isActive
            phrases
            createdAt
            startDate
            projectId
          }
        }
      }
    }
  }
`;

const GET_NOTIFICATION_DETAIL_V2 = gql`
  query notification_getNotificationDetail($notificationId: Id!) {
    notification_getNotificationDetail(notificationId: $notificationId) {
      updateLabelOnm {
        projectId
        start
        end
        timezoneOffset
        attachLabels
        removeLabels
        processedBatches
        totalBatches
        totalData
        processedData
        status
        id
        clipping {
          id
          name
        }
        sentiments
        keywordInclude
        includeKeywords
        operator
        labels
        attachLabelsObject {
          id
          name
          displayName
          colorType
          isActive
          createdAt
          clippingId
        }
        removeLabelsObject {
          id
          name
          displayName
          colorType
          isActive
          createdAt
          clippingId
        }
        labelsObject {
          id
          name
          displayName
          colorType
          isActive
          createdAt
          clippingId
        }
        labelList
        mediaList
        excludeKeywords
        searchType
        validationList
        sentiment
        languageList
      }
      updateSentimentOnm {
        projectId
        start
        end
        timezoneOffset
        sentimentUpdate
        processedBatches
        totalBatches
        totalData
        processedData
        status
        id
        clipping {
          id
          name
        }
        labelList
        mediaList
        excludeKeywords
        includeKeywords
        searchType
        validationList
        sentiment
        languageList
      }
      updateContentMediaType {
        contentMediaTypeUpdate
        start
        end
        objectList {
          id
          socialMedia
          streamType
          extraContent
          objectName
          isHashtag
        }
        timezoneOffset
        sentiments
        postOwnerShip
        labels {
          id
          name
          displayName
          colorType
        }
        keywordsInclude
        keywordsExclude
        processedBatches
        totalBatches
        totalData
        labels {
          id
          name
          displayName
          colorType
        }
        status
        id
        __typename
      }
      updateAgeRangeEngagedUser {
        projectId
        startDate
        endDate
        interestList {
          id
          name
          displayName
          color
        }
        interestValidationList
        ageList
        ageValidationList
        labelList {
          id
          name
          displayName
          color
        }
        labelValidationList
        objectList {
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
        }
        countryList {
          id
          parentId
          name
          displayName
          level
        }
        stateList {
          id
          parentId
          name
          displayName
          level
        }
        cityList {
          id
          parentId
          name
          displayName
          level
        }
        locationValidationList
        genderList
        genderValidationList
        includeUsername
        timezone
        age
      }
      updateGenderEngagedUser {
        startDate
        endDate
        interestList {
          id
          name
          displayName
          color
        }
        interestValidationList
        ageList
        ageValidationList
        labelList {
          id
          name
          displayName
          color
        }
        labelValidationList
        objectList {
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
        }
        countryList {
          id
          parentId
          name
          displayName
          level
        }
        stateList {
          id
          parentId
          name
          displayName
          level
        }
        cityList {
          id
          parentId
          name
          displayName
          level
        }
        locationValidationList
        genderList
        genderValidationList
        includeUsername
        timezone
      }
      updateInterestEngagedUser {
        startDate
        endDate
        interestList {
          id
          name
          displayName
          color
        }
        interestValidationList
        ageList
        ageValidationList
        labelList {
          id
          name
          displayName
          color
        }
        labelValidationList
        objectList {
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
        }
        countryList {
          id
          parentId
          name
          displayName
          level
        }
        stateList {
          id
          parentId
          name
          displayName
          level
        }
        cityList {
          id
          parentId
          name
          displayName
          level
        }
        locationValidationList
        genderList
        genderValidationList
        includeUsername
        timezone
        attachInterest {
          id
          name
          displayName
          color
        }
        removeInterest {
          id
          name
          displayName
          color
        }
      }
      updateLocationEngagedUser {
        startDate
        endDate
        interestList {
          id
          name
          displayName
          color
        }
        interestValidationList
        ageList
        ageValidationList
        labelList {
          id
          name
          displayName
          color
        }
        labelValidationList
        objectList {
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
        }
        countryList {
          id
          parentId
          name
          displayName
          level
        }
        stateList {
          id
          parentId
          name
          displayName
          level
        }
        cityList {
          id
          parentId
          name
          displayName
          level
        }
        locationValidationList
        genderList
        genderValidationList
        includeUsername
        timezone
        city {
          id
          parentId
          name
          displayName
          level
        }
        state {
          id
          parentId
          name
          displayName
          level
        }
        country {
          id
          parentId
          name
          displayName
          level
        }
      }
      downloadReport {
        projectId
        startDate
        endDate
        timezone
        progress
        totalJob
        status
        fileName
        link
      }
      updateSentiment {
        start
        end
        objectList {
          id
          socialMedia
          streamType
          extraContent
          objectName
          isHashtag
        }
        timezoneOffset
        sentiments
        postOwnerShip
        labels {
          id
          name
          displayName
          colorType
        }
        keywordsInclude
        keywordsExclude
        processedBatches
        totalBatches
        totalData
        labels {
          id
          name
          displayName
          colorType
        }
        status
        id
      }
      onDemandOnlineMediaSearch {
        id
        projectId
        phrases
        totalJob
        status
        processedArticles
        createdAt
      }
      ignoreStream {
        start
        end
        objectList {
          id
          socialMedia
          streamType
          extraContent
          objectName
          isHashtag
        }
        timezoneOffset
        sentiments
        postOwnerShip
        labels {
          id
          name
          displayName
          colorType
        }
        keywordsInclude
        keywordsExclude
        processedBatches
        totalBatches
        totalData
        labels {
          id
          name
          displayName
          colorType
        }
        status
        id
        __typename
      }
      firstTimeSocialMediaData {
        id
        projectId
        totalJob
        successCount
        errorCount
        pendingCount
        createdAt
        details {
          projectId
          firstProjectCrawId
          status
          streamType
          socialMedia
          content
          objectName
          createdAt
        }
      }
      downloadSocialMedia {
        id
        start
        end
        objectIdList
        status
        createdAt
        link
        email
      }
      downloadOnlineMedia {
        id
        start
        end
        mediaList
        phrase
        status
        createdAt
        link
      }
      downloadOfflineMedia {
        id
        start
        end
        mediaList
        phrase
        status
        createdAt
        link
      }
      downloadCustomDashboard {
        id
        link
      }
      updateLabel {
        start
        end
        objectList {
          id
          socialMedia
          streamType
          extraContent
          objectName
          isHashtag
        }
        timezoneOffset
        sentiments
        postOwnerShip
        labels {
          id
          name
          displayName
          colorType
        }
        keywordsInclude
        keywordsExclude
        processedBatches
        totalBatches
        totalData
        attachLabels {
          id
          name
          displayName
          colorType
        }
        removeLabels {
          id
          name
          displayName
          colorType
        }
        status
        id
        __typename
      }
      downloadOnlineMediaClustering {
        createdAt
        desiredClusterCount
        end
        id
        link
        mediaList
        phrase
        start
        status
      }
      updateVisibilityOnm {
        projectId
        start
        end
        timezoneOffset
        visibility
        processedBatches
        totalBatches
        totalData
        processedData
        status
        id
        clipping {
          id
          name
        }
        labelList
        mediaList
        excludeKeywords
        includeKeywords
        searchType
        validationList
        sentiment
        languageList
      }
    }
  }
`;

export { CHECK_NOTIFICATION, GET_NOTIFICATION_DETAIL_V2 };
