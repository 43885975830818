import gql from "graphql-tag";
import { analyticArguments, analyticArgumentsQuery } from "../analyticArgument";

export const CAMPAIGN_GET_CAMPAIGNS = gql`
query campaign_getCampaigns($projectId: Id!) {
  campaign_getCampaigns(projectId: $projectId) {
    id
    projectId
	campaignName
    dateStart
    dateEnd
	campaignProgress
	createdAt
	campaignObjectDetails{
		objectModel{
			id
      socialMedia
      streamType
      content
      extraContent
      isHashtag
      objectName
      createdAt
		}
		campaignProgress
		createdAt
		dateStart
		dateEnd
		talk
		talker
		reach
		impression
	}
  }
}
`;

export const CAMPAIGN_GET_CAMPAIGN_BY_ID = gql`
query campaign_getCampaign($campaignId: Id!) {
  campaign_getCampaignById(campaignId: $campaignId) {
    id
    projectId
	campaignName
    dateStart
    dateEnd
	campaignProgress
	createdAt
	campaignObjectDetails{
		objectModel{
			id
      socialMedia
      streamType
      content
      extraContent
      isHashtag
      objectName
      createdAt
		}
		campaignProgress
		createdAt
		dateStart
		dateEnd
		talk
		talker
		reach
		impression
	}
  }
}

`;

export const CAMPAIGN_GET_CAMPAIGNS_HISTORY = gql`
query campaign_getCampaignsHistory($projectId: Id!) {
  campaign_getCampaignsHistory(projectId: $projectId) {
    id
	campaignName
    dateStart
    dateEnd
    projectId
	campaignProgress
	createdAt
	campaignObjectDetails{
		objectModel{
			id
      socialMedia
      streamType
      content
      extraContent
      isHashtag
      objectName
      createdAt
		}
		campaignProgress
		createdAt
		dateStart
		dateEnd
		talk
		talker
		reach
		impression
	}
  }
}

`;


export const CAMPAIGN_GET_AUDIENCE_ENGAGEMENT = gql`
  query campaign_getAudienceEngagement(${analyticArgumentsQuery}) {
    campaign_getAudienceEngagement(${analyticArguments}) {
        max
        min
        peakTimeValue {
          name
          data
        }
    }
  }
`;

export const CAMPAIGN_GET_GROUP_CHRONOLOGICAL_CAMPAIGN = gql`
query campaign_getChronologicalGrouped(${analyticArgumentsQuery}) {
  campaign_getChronologicalGrouped(${analyticArguments}) {
    contentType
    values {
      timestamp
      value
    }
  }
}`;

export const CAMPAIGN_GET_CHRONOLOGICAL_IMPRESSION = gql`
query campaign_getChronologicalImpression(${analyticArgumentsQuery}) {
    campaign_getChronologicalImpression(${analyticArguments}) {
        values {
            timestamp
            value
          }
  }
}
`;

export const CAMPAIGN_GET_CHRONOLOGICAL_REACH = gql`
query campaign_getChronologicalReach(${analyticArgumentsQuery}) {
    campaign_getChronologicalReach(${analyticArguments}) {
        values {
            timestamp
            value
          }
  }
}
`;

export const CAMPAIGN_GET_CHRONOLOGICAL_TALK = gql`
query campaign_getChronologicalTalk(${analyticArgumentsQuery}) {
    campaign_getChronologicalTalk(${analyticArguments}) {
        values {
            timestamp
            value
          }
  }
}
`;

export const CAMPAIGN_GET_CHRONOLOGICAL_TALKER = gql`
query campaign_getChronologicalTalker(${analyticArgumentsQuery}) {
    campaign_getChronologicalTalker(${analyticArguments}) {
        values {
            timestamp
            value
          }
  }
}
`;

export const CAMPAIGN_GET_TOTAL_GROUPED_CAMPAIGN = gql`
query campaign_getTotalGrouped(${analyticArgumentsQuery}) {
  campaign_getTotalGrouped(${analyticArguments}) {
    recentValue
    growth
    pastValue
    contentType
  }
}
`;

export const CAMPAIGN_GET_TOTAL_IMPRESSION = gql`
query campaign_getImpression(${analyticArgumentsQuery}) {
    campaign_getImpression(${analyticArguments}) {
        recentValue
        growth
        pastValue
  }
}
`;

export const CAMPAIGN_GET_TOTAL_REACH = gql`
query campaign_getReach(${analyticArgumentsQuery}) {
    campaign_getReach(${analyticArguments}) {
        recentValue
        growth
        pastValue
  }
}
`;

export const CAMPAIGN_GET_TOTAL_TALK = gql`
query campaign_getTalk(${analyticArgumentsQuery}) {
    campaign_getTalk(${analyticArguments}) {
        recentValue
        growth
        pastValue
  }
}
`;

export const CAMPAIGN_GET_TOTAL_TALKER = gql`
query campaign_getTalker(${analyticArgumentsQuery}) {
    campaign_getTalker(${analyticArguments}) {
        recentValue
        growth
        pastValue
  }
}
`;

export const CAMPAIGN_GET_WORD_CLOUD_TALK = gql`
query campaign_getWordCloudTalk(${analyticArgumentsQuery}) {
    campaign_getWordCloudTalk(${analyticArguments}) {
        name
        value
  }
}
`;

export const CAMPAIGN_GET_STREAM_PREVIEW = gql`
query campaign_getStreamPreview(${analyticArgumentsQuery}) {
    campaign_getStreamPreview(${analyticArguments}) {
      result{
        originalId
        socialMedia
        displayPicture
        link
        content
        fromId
        fromName
        timestamp
        like
        share
        comment
        audience
        engagementRate
        sentiment
        objectModel{
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
          createdAt
          openApiId
        }
        postOwnership
      }
      pagination{
        next 
        hasNext
      }
  }
}
`;

export const CAMPAIGN_GET_TOP_TALKER = gql`
query campaign_getTopTalker(${analyticArgumentsQuery}) {
    campaign_getTopTalker(${analyticArguments}) {
      socialMedia
      fromName
      value
  }
}
`;

export const CAMPAIGN_GET_TOP_INFLUENCER = gql`
query campaign_getTopInfluencer(${analyticArgumentsQuery}) {
    campaign_getTopInfluencer(${analyticArguments}) {
      socialMedia
      fromName
      value
  }
}
`;

export const CAMPAIGN_GET_FINISHED_CAMPAIGN = gql`
  query campaign_getFinishedCampaign($projectId: Id!) {
    campaign_getFinishedCampaign(projectId: $projectId) {
      id
      objectModel {
        id
        socialMedia
        objectName
      }
      dateStart
      dateEnd
      progress
      talk
      talker
      reach
      impression
      benchmark {
        baseline
        value
        benchmark
      }
    }
  }
`;

export const CAMPAIGN_GET_ONGOING_CAMPAIGN = gql`
  query campaign_getOngoingCampaign($projectId: Id!) {
    campaign_getOngoingCampaign(projectId: $projectId) {
      id
      objectModel {
        id
        socialMedia
        objectName
      }
      dateStart
      dateEnd
      progress
      talk
      talker
      reach
      impression
      benchmark {
        benchmark
      }
    }
  }
`;

export const CAMPAIGN_GET_ONGOING_CAMPAIGN_OBJECT_LIST = gql`
query campaign_getOngoingCampaignObjectList($projectId: Id!) {
  campaign_getOngoingCampaignObjectList(projectId: $projectId) {
    id
    objectModel {
      id
      socialMedia
      objectName
      __typename
    }
    dateStart
    dateEnd
    __typename
  }
}`;

export const CAMPAIGN_GET_SOCIAL_MEDIA_SUMMARY = gql`
query campaign_getSocialMediaSummary(${analyticArgumentsQuery}) {
    campaign_getSocialMediaSummary(${analyticArguments}) {
        socialMedia
        talk
        talker
        reach
        impression
    }
}`;