import { Box, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/app/hooks';
import BottomHeader from './Bottom';
import ObjectPreview from 'src/components/ObjectPreview';
import CustomDashboardObject from 'src/components/CustomDashboardObject';
import ModalManageObjectByGroup from 'src/components/ModalManageObjectByGroup';
import TooltipCustomize from 'src/components/TooltipCustomize';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function HeaderCustomDashboard() {
  const { objectListByObjectType } = useAppSelector(
    (state) => state.objectManagement
  );

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Stack direction="row" alignItems="center">
            <Typography
              variant="h1"
              sx={{
                mr: 1
              }}
            >
              Custom Dashboard
            </Typography>
            <TooltipCustomize
              title={'Personalize your own Dashboard with customizable metrics'}
              placement="top-start"
            >
              <InfoOutlinedIcon
                fontSize="small"
                sx={{ transform: 'translateY(-4px)' }}
              />
            </TooltipCustomize>
          </Stack>
        </Grid>
        <Grid item lg={12}>
          <Box mt={2}>
            <CustomDashboardObject />
          </Box>
        </Grid>
        <Grid item lg={12}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            mt={1.5}
            mb={3}
          >
            <ModalManageObjectByGroup
              title="Custom Dashboard"
              isDashboard={true}
            />
            <ObjectPreview objectList={objectListByObjectType} />
          </Stack>
        </Grid>

        <Grid item lg={12}>
          <BottomHeader />
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
}

export default HeaderCustomDashboard;
