import gql from 'graphql-tag';

export const CUSTOM_DASHBOARD_MANAGE_WIDGET = gql`
  mutation customDashboard_manageWidgetOnCustomDashboard(
    $projectId: Id!
    $customDashboardId: Id!
    $customDashboardWidgetMetadataIds: [Id!]!
  ) {
    customDashboard_manageWidgetOnCustomDashboard(
      projectId: $projectId
      customDashboardId: $customDashboardId
      customDashboardWidgetMetadataIds: $customDashboardWidgetMetadataIds
    ) {
      id
      objectList {
        id
        objectName
      }
      customDashboardWidgetMetadataList {
        id
        metric
        displayName
        description
        size
        title
        visualization
      }
      __typename
    }
  }
`;

export const ADD_CUSTOM_DASHBOARD = gql`
  mutation customDashboard_addCustomDashboard(
    $projectId: Id!
    $displayName: String!
  ) {
    customDashboard_addCustomDashboard(
      projectId: $projectId
      displayName: $displayName
    ) {
      id
      name
      displayName
      createdAt
      objectList {
        id
        socialMedia
        streamType
        content
        extraContent
        isHashtag
        objectName
        createdAt
      }
      customDashboardWidgetMetadataList {
        id
        metric
        displayName
        description
        size
        title
        visualization
      }
    }
  }
`;

export const EDIT_CUSTOM_DASHBOARD = gql`
  mutation customDashboard_editCustomDashboard(
    $projectId: Id!
    $customDashboardId: Id!
    $displayName: String!
    $objectIds: [Id!]!
  ) {
    customDashboard_editCustomDashboard(
      projectId: $projectId
      customDashboardId: $customDashboardId
      displayName: $displayName
      objectIds: $objectIds
    ) {
      id
      name
      displayName
      createdAt
      objectList {
        id
        socialMedia
        streamType
        content
        extraContent
        isHashtag
        objectName
        createdAt
      }
      customDashboardWidgetMetadataList {
        id
        metric
        displayName
        description
        size
        title
        visualization
      }
    }
  }
`;

export const DELETE_CUSTOM_DASHBOARD = gql`
  mutation customDashboard_deleteCustomDashboard($customDashboardId: Id!) {
    customDashboard_deleteCustomDashboard(
      customDashboardId: $customDashboardId
    ) {
      id
      objectList {
        id
        objectName
      }
      customDashboardWidgetMetadataList {
        id
        metric
        displayName
        description
        size
        title
        visualization
      }
      __typename
    }
  }
`;

export const CUSTOM_DASHBOARD_EDIT_WHITE_LABEL = gql`
  mutation customDashboard_editWhiteLabel(
    $projectId: Id!
    $colorTitleReport: String
    $titleReport: String
    $coverImageReport: String
  ) {
    customDashboard_editWhiteLabel(
      projectId: $projectId
      colorTitleReport: $colorTitleReport
      titleReport: $titleReport
      coverImageReport: $coverImageReport
    ) {
      titleReport
      name
      colorTitleReport
      coverImageReport
    }
  }
`;

export const CUSTOM_DASHBOARD_CREATE_JOB_DOWNLOAD = gql`
  mutation customDashboard_createJobDownloadCustomDashboard(
    $projectId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $objectList: [Id!]!
    $granularity: GranularityInput!
    $timezone: Int!
    $fromUsername: String!
    $sentimentConfidenceLevelMin: BigDecimal!
    $sentimentConfidenceLevelMax: BigDecimal!
    $customDashboardId:Id!
    $isWhiteLabel:Boolean!
  ) {
    customDashboard_createJobDownloadCustomDashboard(
      projectId: $projectId
      start: $start
      end: $end
      objectList: $objectList
      granularity: $granularity
      timezone: $timezone
      fromUsername: $fromUsername
      sentimentConfidenceLevelMin: $sentimentConfidenceLevelMin
      sentimentConfidenceLevelMax: $sentimentConfidenceLevelMax
      customDashboardId:$customDashboardId
      isWhiteLabel:$isWhiteLabel
    ) {
      id
      customDashboardId
    }
  }

`;
