import { Tab, Tabs } from '@mui/material';

interface IProps {
  tab: string;
  label: string;
  handleChange: (_: React.SyntheticEvent, newValue: string) => void;
}

const TabNetworkAnalysis = ({ tab, handleChange, label }: IProps) => {
  return (
    <Tabs
      value={tab !== 'metrics' ? label : tab}
      onChange={handleChange}
      aria-label="basic tabs example"
    >
      <Tab label={label.toUpperCase()} value={label} />
      <Tab label="Metrics" value="metrics" />
    </Tabs>
  );
};

export default TabNetworkAnalysis;
