import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { IGender, IPeople, TGender } from 'src/models/people';

type Props = {
  gender: TGender;
  handleChange: (event: SelectChangeEvent, person: IPeople) => void;
};

export const genderItem: IGender[] = [
  {
    id: 'male',
    value: 'male',
    label: 'Male'
  },
  {
    id: 'female',
    value: 'female',
    label: 'Female'
  },
  {
    id: 'undefined',
    value: 'undefined',
    label: 'Undefined'
  }
];

const SelectGender = (props: Props) => {
  return (
    <Select
      labelId="gender-select-label"
      id="gender-select"
      name="gender-select"
      value={props.gender ?? 'none'}
      onChange={props.handleChange}
    >
      {genderItem.map(({ id, value, label }) => (
        <MenuItem key={id} id={id} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectGender;
