import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useAppDispatch } from 'src/app/hooks';
import {
  reducerUpdateGroupObject,
  reducerUpdateObjectList,
  reducerUpdateObjectManagementLimit,
  reducerUpdateObjectManagementStreamPreview,
  reducerUpdateObjectManagementStreamPreviewLoading,
  reducerUpdateSocialProfileAutoComplete,
  reducerUpdateSocialProfileAutoCompleteLoading
} from 'src/features/objectManagement';
import {
  IGroupObject,
  IGroupObjectPayload,
  IObjectAutoCompletePayload,
  IObjectLimit,
  IObjectManagementStreamPreview,
  IObjectManagementStreamPreviewPayload,
  IObject,
  ISocialProfileAutoComplete
} from 'src/models/objectManagement';
import {
  OBJECT_MANAGEMENT_ADD_GROUP,
  OBJECT_MANAGEMENT_DELETE_GROUP,
  OBJECT_MANAGEMENT_EDIT_GROUP,
  OBJECT_MANAGEMENT_DELETE_OBJECT,
  OBJECT_MANAGEMENT_EDIT_OBJECT,
  OBJECT_MANAGEMENT_ADD_OBJECT
} from './graphql/objectManagement/mutation';
import {
  GET_OBJECT_LIMIT,
  GET_OBJECTS_WITH_ENTITY,
  OBJECT_MANAGEMENT_GET_AUTOCOMPLETE,
  OBJECT_MANAGEMENT_GET_GROUP_LIST,
  OBJECT_MANAGEMENT_GET_STREAM_PREVIEW
} from './graphql/objectManagement/query';
import { API_ONBOARDING_UPDATE_ROLE_AND_INDUSTRY } from './graphql/onboarding/mutation';

interface IGetObjectAutoCompleteResponse {
  objectManagement_getAutocomplete: Array<ISocialProfileAutoComplete>;
}

interface IGetObjectListResponse {
  objectManagement_getObjectList: Array<IObject>;
}

interface IGetGroupObjectResponse {
  objectManagement_getObjectGroupList: Array<IGroupObject>;
}

interface IGetObjectManagementStreamPreviewResponse {
  objectManagement_getStreamPreview: IObjectManagementStreamPreview;
}

interface IGetObjectLimitResponse {
  objectManagement_getObjectLimit: IObjectLimit;
}

export interface IGetObjectsWithEntitiesResponse {
  objectManagement_getObjectListIncludeEntity: IObject[];
}

const useObjectManagement = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const fetchObjectManagementAutoComplete = async function (
    payload: IObjectAutoCompletePayload
  ) {
    return await client
      .query<IGetObjectAutoCompleteResponse>({
        query: OBJECT_MANAGEMENT_GET_AUTOCOMPLETE,
        variables: payload
      })
      .then((response) => {
        return {
          ...response,
          data: response.data?.objectManagement_getAutocomplete
        };
      });
  };

  const getObjectManagementAutoComplete = async function (
    payload: IObjectAutoCompletePayload
  ) {
    dispatch(reducerUpdateSocialProfileAutoCompleteLoading(true));
    const response = await fetchObjectManagementAutoComplete(payload);
    dispatch(reducerUpdateSocialProfileAutoCompleteLoading(false));
    if (!response.data) return;
    dispatch(reducerUpdateSocialProfileAutoComplete(response));
  };

  const fetchObjectList = async function (projectId: string) {
    return await client
      .query<IGetObjectsWithEntitiesResponse>({
        query: GET_OBJECTS_WITH_ENTITY,
        variables: { projectId }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.objectManagement_getObjectListIncludeEntity
        };
      });
  };

  const getObjectList = async function (payload: string) {
    const response = await fetchObjectList(payload);
    if (!response.data) return;
    dispatch(reducerUpdateObjectList(response));
    return response;
  };

  const fetchGroupObject = async function (payload: IGroupObjectPayload) {
    return await client
      .query<IGetGroupObjectResponse>({
        query: OBJECT_MANAGEMENT_GET_GROUP_LIST,
        variables: payload
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.objectManagement_getObjectGroupList
        };
      });
  };

  const getGroupObject = async function (payload: IGroupObjectPayload) {
    const response = await fetchGroupObject(payload);
    if (!response.data) return;
    dispatch(reducerUpdateGroupObject(response));
    return response;
  };

  const fetchStreamPreview = async function (
    payload: IObjectManagementStreamPreviewPayload
  ) {
    return await client
      .query<IGetObjectManagementStreamPreviewResponse>({
        query: OBJECT_MANAGEMENT_GET_STREAM_PREVIEW,
        variables: payload
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.objectManagement_getStreamPreview
        };
      });
  };

  const getObjectManagementStreamPreview = async function (
    payload: IObjectManagementStreamPreviewPayload
  ) {
    dispatch(reducerUpdateObjectManagementStreamPreviewLoading(true));
    const response = await fetchStreamPreview(payload);
    if (!response.data) return;
    dispatch(reducerUpdateObjectManagementStreamPreview(response));
  };

  const fetchObjectLimit = async function (projectId: string) {
    return await client
      .query<IGetObjectLimitResponse>({
        query: GET_OBJECT_LIMIT,
        variables: { projectId }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.objectManagement_getObjectLimit
        };
      });
  };

  const getObjectObjectLimit = async function (projectId: string) {
    const response = await fetchObjectLimit(projectId);
    if (!response.data) return;
    dispatch(reducerUpdateObjectManagementLimit(response));
  };

  const [updateRoleAndIndustry, responseUpdateRoleAndIndustry] = useMutation(
    API_ONBOARDING_UPDATE_ROLE_AND_INDUSTRY
  );
  const [objectManagementAddGroup, resObjectManagementAddGroup] = useMutation(
    OBJECT_MANAGEMENT_ADD_GROUP
  );
  const [objectManagementEditGroup, resObjectManagementEditGroup] = useMutation(
    OBJECT_MANAGEMENT_EDIT_GROUP
  );
  const [objectManagementDeleteGroup, resObjectManagementDeleteGroup] =
    useMutation(OBJECT_MANAGEMENT_DELETE_GROUP);

  const [postAddObject, responseAddObject] = useMutation(
    OBJECT_MANAGEMENT_ADD_OBJECT
  );

  const [postEditObject, responseEditObject] = useMutation(
    OBJECT_MANAGEMENT_EDIT_OBJECT
  );

  const [postDeleteObject, resDeleteObject] = useMutation(
    OBJECT_MANAGEMENT_DELETE_OBJECT
  );

  return {
    updateRoleAndIndustry,
    responseUpdateRoleAndIndustry,
    getObjectManagementAutoComplete,
    getObjectList,
    getGroupObject,
    getObjectManagementStreamPreview,
    objectManagementAddGroup,
    resObjectManagementAddGroup,
    objectManagementEditGroup,
    resObjectManagementEditGroup,
    objectManagementDeleteGroup,
    resObjectManagementDeleteGroup,
    postDeleteObject,
    resDeleteObject,
    postEditObject,
    responseEditObject,
    getObjectObjectLimit,
    postAddObject,
    responseAddObject
  };
};

export default useObjectManagement;
