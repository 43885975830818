import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILabel, ITableWithPagination } from 'src/models/general';
import {
  IAutoLabel,
  IAutoLabelOnm,
  ILabelArticle,
  ILabelPeople
} from 'src/models/label';

interface Labels {
  labelList: ApolloQueryResult<Array<ILabel>>;
  labelArticle: ApolloQueryResult<Array<ILabelArticle>>;
  labelPeople: ApolloQueryResult<Array<ILabelPeople>>;
  autoLabelSosmed: ApolloQueryResult<Array<IAutoLabel>>;
  autoLabelOnm: ApolloQueryResult<ITableWithPagination<IAutoLabelOnm>>;
  autoLabelOfm: ITableWithPagination<IAutoLabelOnm>;
}

const initPagination = {
  result: [],
  limit: 10,
  page: 1,
  totalPages: 1,
  totalRows: 0
};

const initialState: Labels = {
  labelList: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  labelArticle: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  labelPeople: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  autoLabelSosmed: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  autoLabelOnm: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initPagination
  },
  autoLabelOfm: initPagination
};

export const onboarding = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    reducerUpdatelabelListSosmed: (
      state: Labels,
      action: PayloadAction<ApolloQueryResult<Array<ILabel>>>
    ) => {
      state.labelList = action.payload;
    },
    reducerAddlabelListSosmed: (
      state: Labels,
      action: PayloadAction<ILabel>
    ) => {
      state.labelList.data = [...state.labelList.data, action.payload];
    },
    reducerUpdateArticleLabel: (
      state: Labels,
      action: PayloadAction<ApolloQueryResult<Array<ILabelArticle>>>
    ) => {
      state.labelArticle = action.payload;
    },
    reducerUpdatePeopleLabel: (
      state: Labels,
      action: PayloadAction<ApolloQueryResult<Array<ILabelPeople>>>
    ) => {
      state.labelPeople = action.payload;
    },
    reducerUpdateAutoLabelSosmed: (
      state: Labels,
      action: PayloadAction<ApolloQueryResult<Array<IAutoLabel>>>
    ) => {
      state.autoLabelSosmed = action.payload;
    },
    reducerUpdateAddAutoLabelSosmed: (
      state: Labels,
      action: PayloadAction<IAutoLabel>
    ) => {
      state.autoLabelSosmed.data = [
        ...state.autoLabelSosmed.data,
        action.payload
      ];
    },
    reducerUpdateAutoLabelOnm: (
      state: Labels,
      action: PayloadAction<
        ApolloQueryResult<ITableWithPagination<IAutoLabelOnm>>
      >
    ) => {
      state.autoLabelOnm = action.payload;
    },
    reducerUpdateAutoLabelOfm: (
      state: Labels,
      action: PayloadAction<ITableWithPagination<IAutoLabelOnm>>
    ) => {
      state.autoLabelOfm = action.payload;
    }
  }
});

export const {
  reducerUpdatelabelListSosmed,
  reducerAddlabelListSosmed,
  reducerUpdateArticleLabel,
  reducerUpdatePeopleLabel,
  reducerUpdateAutoLabelSosmed,
  reducerUpdateAddAutoLabelSosmed,
  reducerUpdateAutoLabelOnm,
  reducerUpdateAutoLabelOfm
} = onboarding.actions;

export default onboarding.reducer;
