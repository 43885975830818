interface Value {
  timestamp: Date;
  value: number;
}

export interface ChronologicalList {
  contentType: string;
  values: Array<Value>;
}

export const buildDataLine = function (chronological: ChronologicalList) {
  return {
    name: chronological.contentType,
    data: chronological.values.map(({ timestamp, value }) => {
      return [timestamp, value];
    })
  };
};

export const buildDataLineGroup = function (
  chronologicalList: Array<ChronologicalList>
) {
  return chronologicalList.map((chronological) => ({
    name: chronological.contentType,
    data: chronological.values.map(({ timestamp, value }) => {
      return [timestamp, value];
    })
  }));
};
