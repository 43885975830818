/* eslint-disable react-hooks/exhaustive-deps */
import {
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import React, { FC, useMemo, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAppSelector } from 'src/app/hooks';
import ChipSocialMediaObjectSelector from 'src/components/ChipSocialMediaObjectSelector';
import Empty from 'src/components/Empty';
import ModalAddSocialProfile from 'src/components/ModalAddSocialProfile';
import { IObject } from 'src/models/objectManagement';
import SearchIcon from '@mui/icons-material/Search';
import { useFiltersByName } from 'src/hooks/useFiltersByName';
import { useUnlimitedScroll } from 'src/hooks/useUnlimitedScroll';
import { useSelectItemDefaultSelected } from 'src/hooks/useSelectItemDefaultSelected';

interface Props {
  objectList: Array<IObject>;
  onParentVisible: () => void;
  onSelect: (items: Array<string>) => void;
}

interface Item {
  name: string;
}

const SectionSocialProfile: FC<Props> = ({
  objectList,
  onParentVisible,
  onSelect
}) => {
  const theme = useTheme();
  const { basicObjectQuota } = useAppSelector(
    (state) => state.users.project.onBoarding
  );

  const objectListIdActive = useAppSelector(
    (state) => state.analyticArguments.objectIdList
  );

  const addName = useMemo((): Array<IObject & Item> => {
    return objectList.map((object) => ({
      ...object,
      name: object.objectName
    }));
  }, [objectList]);

  const { filteredList, handleFilter } = useFiltersByName<IObject>(addName);

  const { fetchMoreData, infiniteScroll } =
    useUnlimitedScroll<IObject>(filteredList);

  const { selectedItemByIds, handleClick, isSelected } =
    useSelectItemDefaultSelected<IObject>(filteredList, objectListIdActive);

  useEffect(() => {
    if (selectedItemByIds.length < 1) return;
    onSelect(selectedItemByIds);
  }, [selectedItemByIds]);

  return (
    <>
      <DialogTitle
        sx={{
          m: theme.spacing(3, 3, 1),
          p: theme.spacing(2, 3, 0, 3)
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3">
            Social Profile
            <span
              style={{
                marginLeft: theme.spacing(1),
                fontWeight: 400,
                color: theme.palette.primary.main
              }}
            >
              {objectList.length}
            </span>
            /
            <span
              style={{
                fontWeight: 400
              }}
            >
              {basicObjectQuota}
            </span>
          </Typography>
          <ModalAddSocialProfile
            title="Social Profile"
            onHide={onParentVisible}
            objectList={objectList}
          />
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          m: theme.spacing(1, 3)
        }}
      >
        <TextField
          fullWidth
          id="standard-basic"
          placeholder="Search Profile"
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          sx={{
            mb: theme.spacing(3)
          }}
          onChange={(e) => handleFilter(e.target.value)}
        />
        <InfiniteScroll
          dataLength={infiniteScroll.list.length}
          next={fetchMoreData}
          hasMore={infiniteScroll.hasMore}
          loader={<div />}
          height={104}
          endMessage={<div />}
        >
          {infiniteScroll.list.length > 0 ? (
            infiniteScroll.list.map(
              ({
                id,
                content,
                objectName,
                socialMedia,
                isHashtag,
                streamType
              }) => {
                const isItemSelected = !isSelected(id);
                return (
                  <ChipSocialMediaObjectSelector
                    key={`${id}${content}`}
                    id={content}
                    name={objectName}
                    socialMedia={socialMedia}
                    streamType={isHashtag ? 'hashtag' : streamType}
                    sx={{ m: '0 8px 8px 0' }}
                    onClick={(event) => handleClick(event, id)}
                    checked={isItemSelected}
                  />
                );
              }
            )
          ) : (
            <Empty />
          )}
        </InfiniteScroll>
      </DialogContent>
    </>
  );
};

export default SectionSocialProfile;
