import React from 'react';

export const useCounter = (initialCount = 0)=> {
    const [count, setCount] = React.useState(initialCount);
    const increment = React.useCallback(() => setCount(c => c + 1), []);
    const decrement = React.useCallback(() => setCount(c => c - 1), []);
    const setInitCount = (value: number)=> {
        setCount(value);
    }
    return { count, increment, decrement, setInitCount };
}