export function combineDateAndTime(date: Date, time: Date) {
  const mins = ('0' + time.getMinutes()).slice(-2);
  const hours = ('0' + time.getHours()).slice(-2);
  const timeString = hours + ':' + mins + ':00';
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const dateString = '' + year + '-' + month + '-' + day;
  const datec = dateString + 'T' + timeString;
  return new Date(datec);
}
