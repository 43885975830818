import { IPeopleAdvanceSearchSlice } from 'src/features/advanceSearchPeoplePanel';

export const fillPeopleAdvanceSearchParams = (
  state: IPeopleAdvanceSearchSlice
) => {
  const interestIdList = state.interestList.map((interest) => interest.id);
  const labelIdList = state.labelList.map((label) => label.id) ?? [];
  const ageList = state.ageList.map((age) => age.id) ?? [];
  const genderList = state.genderList.map((gender) => gender.id) ?? [];
  const countryIdList =
    state.locationList.map((location) => location.country.id) ?? [];
  const stateIdList =
    state.locationList.map((location) => location.state.id) ?? [];
  const cityIdList =
    state.locationList.map((location) => location.city.id) ?? [];
  return {
    ...state,
    interestIdList,
    labelIdList,
    ageList,
    genderList,
    countryIdList,
    stateIdList,
    cityIdList
  };
};
