import { Box, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import SelectDateRange from 'src/components/SelectDateRange';
import { useLocation } from 'react-router';
import DateRangeLabel from '../../components/DateRangeLabel';

const CustomBox = styled(Box)(
  ({ theme }) => `
    // width: calc(100vw - ${theme.sidebar.width} - ${theme.spacing(10)});
    margin: ${theme.spacing(2, 0)};
    // position: absolute;
`
);

const FilterBox = styled(Box)(
  () => `
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
);

function BottomHeader() {
  const { pathname } = useLocation();
  const title = pathname.split('/')[2];

  return (
    <CustomBox>
      <FilterBox>
        <FormControl size="small" sx={{ width: 135 }}>
          <SelectDateRange />
        </FormControl>
      </FilterBox>
      <DateRangeLabel label={`Viewing ${title} within`} />
    </CustomBox>
  );
}

export default BottomHeader;
