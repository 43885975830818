import {
  Box,
  Button,
  Fab,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router';
import { OFFLINE_MEDIA_CLIPPING } from 'src/route';
import {
  ArrowBack,
  Download,
  KeyboardArrowDown,
  Search
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import useOfflineMedia from 'src/services/useOfflineMedia';
import useQueryUrl from 'src/hooks/useQuery';
import { useAppSelector } from 'src/app/hooks';
import { useMutation } from '@apollo/client';
import { CREATE_DOWNLOAD_CSV_JOB_FOR_OFFLINE_MEDIA } from 'src/services/graphql/offlineMedia/mutation';
import { IGranularity } from 'src/models/general';
import { fillParamAdvanceSearchEmpty } from 'src/utils/fillParamAdvanceSearchEmpth';
import { useAlert } from 'src/hooks/useAlert';
import { generateOfmDoc } from 'src/utils/generateOfmDoc';
import { saveAs } from 'file-saver';
import { Packer } from 'docx';
import TableSelectGroupPortal from 'src/components/TableSelectGroupPortal';
import { useFiltersByName } from 'src/hooks/useFiltersByName';
import { IGroupPortal } from 'src/models/groupPortal';
import useGroupPortal from 'src/services/useGroupPortal';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

const HeaderOfflineMediaClippingById = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { handleClickAlert } = useAlert();
  const { pathname } = useLocation();
  const id = pathname.split('/')[3];

  const query = useQueryUrl();
  const portal = query.get('portal');
  const articleId = query.get('id');
  const projectId = useAppSelector((state) => state.users.project.id);
  const { dateRangeFormatted } = useAppSelector(
    (state) => state.analyticArguments
  );

  const { getGroupPortal } = useGroupPortal();

  const { languageSelected, activeArticle } = useAppSelector(
    (state) => state.storeOfflineMediaStream
  );

  const advSearchOfflineMedia = useAppSelector(
    (state) => state.advanceSearchOfflineMedia
  );

  const fillAdvanceSearchOfflineMedia = fillParamAdvanceSearchEmpty(
    advSearchOfflineMedia
  );

  const { groupPortalList, selectedGroupPortalId } = useAppSelector(
    (state) => state.groupPortal
  );
  const { filteredList, handleFilter } = useFiltersByName<IGroupPortal>(
    groupPortalList.data
  );

  const granularity: IGranularity = {
    unit: 'day',
    value: 1
  };

  const [openSelectGroup, setOpenSelectGroup] = useState(false);

  const { getClippingOfflineMediaById, resGetClippingOfflineMediaById } =
    useOfflineMedia();

  const [downloadOfflineMedia] = useMutation(
    CREATE_DOWNLOAD_CSV_JOB_FOR_OFFLINE_MEDIA
  );

  const finalPayload = {
    ...fillAdvanceSearchOfflineMedia,
    ...dateRangeFormatted,
    clippingId: id,
    includeKeywords: advSearchOfflineMedia.includeKeywords,
    languageList: languageSelected === 'all-language' ? [] : [languageSelected],
    projectId,
    granularity,
    timezone: 7
  };

  const [anchorElDownload, setAnchorElDownload] =
    useState<HTMLButtonElement | null>(null);

  const openDownload = Boolean(anchorElDownload);
  const idPopup = openDownload ? 'download-popover' : undefined;

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDownload(event.currentTarget);
  };

  const handleCloseDownload = () => {
    setAnchorElDownload(null);
  };

  const handleDownloadWord = () => {
    handleCloseDownload();
    const doc = generateOfmDoc(activeArticle);
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${activeArticle.originalId}.docx`);
    });
  };

  const handleDownloadCsv = () => {
    handleCloseDownload();
    downloadOfflineMedia({
      variables: {
        ...finalPayload,
        mediaList: !portal || portal === 'all-media' ? [] : [portal]
      },
      onCompleted: () =>
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          severity: 'success',
          message:
            'It may take some time for the report to be processed.\n The report can be downloaded from the Activity List on the top right corner.'
        }),
      onError(error) {
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          severity: 'error',
          message: 'Failed to download report, please try again.'
        });
        console.log(error.message);
      }
    });
  };

  useEffect(() => {
    getGroupPortal(projectId);
  }, [projectId]);

  useEffect(() => {
    getClippingOfflineMediaById({
      variables: {
        clippingId: id
      }
    });
  }, [id]);

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={2}
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Stack direction="row" alignItems="center">
              <Fab
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.grey[200]}`,
                  mr: theme.spacing(2)
                }}
                size="small"
                onClick={() => navigate(OFFLINE_MEDIA_CLIPPING)}
              >
                <ArrowBack />
              </Fab>
              {!resGetClippingOfflineMediaById.loading && (
                <Typography variant="h1">
                  {
                    resGetClippingOfflineMediaById.data
                      ?.article_getClippingArticleById.name
                  }
                </Typography>
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item lg={12}>
          <Stack direction="row" justifyContent="space-between">
            <FormControl size="small" sx={{ flexBasis: '370px', mb: 2 }}>
              <Select
                name="group"
                open={openSelectGroup}
                onClose={() => setOpenSelectGroup(false)}
                onOpen={() => setOpenSelectGroup(true)}
                value={selectedGroupPortalId}
                defaultValue={selectedGroupPortalId}
              >
                <MenuItem
                  value={'_default'}
                  key={'_default'}
                  sx={{ display: 'none' }}
                >
                  Group
                </MenuItem>
                {groupPortalList.data.map((group) => (
                  <MenuItem
                    key={group.id}
                    value={group.id}
                    sx={{ display: 'none' }}
                  >
                    {group.displayName}
                  </MenuItem>
                ))}

                <Box sx={{ p: theme.spacing(2) }}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h4">Group</Typography>
                    {/* <Button
                    size="small"
                    variant="outlined"
                    onClick={(event) => {
                      handleClickOpenAddGroup();
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  >
                    New group +
                  </Button> */}
                  </Stack>
                  {/* <Input
                  startAdornment={
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  }
                  fullWidth
                  placeholder="Search Source"
                  onChange={(event) => {
                    handleFilter(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                /> */}
                  <Box sx={{ mt: theme.spacing(1) }}>
                    <TableSelectGroupPortal
                      groupPortals={[
                        {
                          id: '_default',
                          name: 'All',
                          displayName: 'All',
                          portals: [],
                          isActive: false,
                          createdAt: '-'
                        },
                        ...filteredList
                      ]}
                      selectedGroupById={selectedGroupPortalId}
                      loading={groupPortalList.loading}
                      onClick={() => setOpenSelectGroup(false)}
                    />
                  </Box>
                </Box>
              </Select>
            </FormControl>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              startIcon={<Download />}
              endIcon={<KeyboardArrowDown />}
              sx={{
                minWidth: theme.spacing(6),
                height: 36,
                backgroundColor: '#FBFBFB',
                borderRadius: theme.spacing(1)
              }}
              onClick={handleClickDownload}
            >
              Download
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Popover
        id={idPopup}
        open={openDownload}
        anchorEl={anchorElDownload}
        onClose={handleCloseDownload}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <List>
          {articleId ? (
            <ListItemButton onClick={() => handleDownloadWord()}>
              <ListItemText primary="Word - Article" />
            </ListItemButton>
          ) : (
            <ListItemButton onClick={() => handleDownloadCsv()}>
              <ListItemText primary="CSV - All Metrik" />
            </ListItemButton>
          )}
        </List>
      </Popover>
    </HeaderWrapper>
  );
};

export default HeaderOfflineMediaClippingById;
