import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {
  Button,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  useTheme
} from '@mui/material';
import { useAppSelector } from 'src/app/hooks';
import { Box } from '@mui/system';
import { IObject } from 'src/models/objectManagement';
import InputKeywordOrHashtag from '../InputKeywordOrHashtag';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  title: string;
  onHide?: () => void;
  objectList: Array<IObject>;
  buttonLabel?: string;
  initialValues?: any;
  data?: any;
  isEditing?: boolean;
  isHidden?: boolean;
}

const ModalAddKeywordAndHashtag: React.FC<Props> = ({
  title,
  onHide,
  objectList,
  buttonLabel = 'Keyword',
  initialValues,
  data,
  isEditing = false,
  isHidden = false
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (isEditing) {
      onHide();
    }
    setOpen(false);
  };

  const theme = useTheme();

  const tempInputObjects = useAppSelector(
    (state) => state.objectManagement.tempInputObjects
  );

  const { advanceObjectQuota, advanceObjectQuotaUsed } = useAppSelector(
    (state) => state.users.project.onBoarding
  );

  const isOnBoarding = useAppSelector(
    (state) => state.users.project.onBoarding.isOnBoarding
  );

  return (
    <>
      {isHidden ? null : (
        <Button
          variant="outlined"
          onClick={handleClickOpen}
          endIcon={<AddIcon />}
        >
          {buttonLabel}
        </Button>
      )}
      <Dialog
        onClose={handleClose}
        open={isEditing ? isEditing : open}
        fullWidth
        maxWidth={'md'}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(3, 3, 0),
            p: theme.spacing(2, 3, 0, 3)
          }}
        >
          <Typography variant="h4">
            {title}
            <span
              style={{
                marginLeft: theme.spacing(1),
                fontWeight: 400,
                color: theme.palette.primary.main
              }}
            >
              {objectList.length}
            </span>
            {!isOnBoarding && tempInputObjects.length > 0 && (
              <span
                style={{
                  fontWeight: 400,
                  color: theme.palette.success.main
                }}
              >
                {`(+${tempInputObjects.length})`}
              </span>
            )}
            /
            <span
              style={{
                fontWeight: 400
              }}
            >
              {advanceObjectQuota}
            </span>
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            m: theme.spacing(1, 3)
          }}
        >
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <Typography variant="caption">
                Your quota is defined when your payment plan is purchased.
              </Typography>
            </Grid>
            <Grid item lg={12}>
              <Box sx={{ mt: 1 }}>
                <InputKeywordOrHashtag
                  onClose={handleClose}
                  tempValues={initialValues}
                  isEditing={isEditing}
                  dataList={data}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalAddKeywordAndHashtag;
