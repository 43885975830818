import gql from 'graphql-tag';
import { analyticArguments, analyticArgumentsQuery } from '../analyticArgument';

export const GET_CONTENT_TOTAL_GROUP_CONTENT = gql`
  query content_getGroupedTotal(${analyticArgumentsQuery}) {
    content_getGroupedTotal(${analyticArguments}) {
      recentValue
      growth
      pastValue
      contentType
    }
  }
`;

export const GET_CONTENT_TOTAL_LIKE = gql`
  query content_getTotalLike(${analyticArgumentsQuery}) {
    content_getTotalLike(${analyticArguments}) {
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_CONTENT_TOTAL_SHARE = gql`
  query content_getTotalShare(${analyticArgumentsQuery}) {
    content_getTotalShare(${analyticArguments}) {
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_CONTENT_TOTAL_COMMENT = gql`
  query content_getTotalComment(${analyticArgumentsQuery}) {
    content_getTotalComment(${analyticArguments}) {
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_CONTENT_TOTAL_POSTMADE = gql`
  query content_getTotalPostMade(${analyticArgumentsQuery}) {
    content_getTotalPostMade(${analyticArguments}) {
      recentValue
      growth
      pastValue
    }
  }
`;

export const GET_CONTENT_CHRONOLOGICAL_LIKE = gql`
  query content_getChronologicalLike(${analyticArgumentsQuery}) {
    content_getChronologicalLike(${analyticArguments}) {
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_CONTENT_CHRONOLOGICAL_POSTMADE = gql`
  query content_getChronologicalPostMade(${analyticArgumentsQuery}) {
    content_getChronologicalPostMade(${analyticArguments}) {
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_CONTENT_CHRONOLOGICAL_SHARE = gql`
  query content_getChronologicalShare(${analyticArgumentsQuery}) {
    content_getChronologicalShare(${analyticArguments}) {
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_CONTENT_CHRONOLOGICAL_COMMENT = gql`
  query content_getChronologicalComment(${analyticArgumentsQuery}) {
    content_getChronologicalComment(${analyticArguments}) {
      values {
        timestamp
        value
      }
    }
  }
`;

export const GET_CONTENT_CHRONOLOGICAL_ENGAGEMENT_RATE = gql`
query content_getChronologicalEngagementRate(${analyticArgumentsQuery}) {
  content_getChronologicalEngagementRate(${analyticArguments}) {
    values {
      timestamp
      value
    }
  }
}
`;

export const GET_CONTENT_GROUP_CONTENT_CHRONOLOGICAL = gql`
query content_getGroupedChronological(${analyticArgumentsQuery}) {
  content_getGroupedChronological(${analyticArguments}) {
    contentType
    values {
      timestamp
      value
    }
  }
}
`;

export const GET_CONTENT_TOP_POSTMADE = gql`
  query content_getTopPostMade(${analyticArgumentsQuery},$columnName: SortingByColumn, $sortingMethod: SortingType) {
    content_getTopPostMade(${analyticArguments}, columnName: $columnName, sortingMethod: $sortingMethod) {
      originalId
      socialMedia
      displayPicture
      fromId
      fromName
      content
      link
      timestamp
      shareCount
      likeCount
      commentCount
      reach
      impression
      engagementRate
      benchmark {
        benchmark
      }
    }
  }
`;

export const GET_CONTENT_TOP_POSTMADE_PAGINATION = gql`
  query content_getTopPostMadeWithPaginationV2(${analyticArgumentsQuery},$columnName: SortingByColumn, $sortingMethod: SortingType, $page: Int!, $limit: Int!) {
    content_getTopPostMadeWithPaginationV2(${analyticArguments}, columnName: $columnName, sortingMethod: $sortingMethod, page: $page, limit: $limit) {
      result {
        originalId
        socialMedia
        displayPicture
        fromId
        fromName
        content
        viewCount
        link
        timestamp
        shareCount
        likeCount
        commentCount
        reach
        impression
        contentType
        engagement
        engagementRate
        mediaLink
        benchmark {
          benchmark
        }
      }
      totalRows
      totalPages
      page
      limit
    }
  }
`;

export const GET_CONTENT_COMMENT_STREAM_BY_ORIGINAL_ID = gql`
  query rawStream_getCommentStreamByOriginalId(
    $originalId: String!
    $projectId: Id!
    $start: Timestamp!
    $end: Timestamp!
    $timezone: Int!
    $objectList: [Id!]!
  ) {
    rawStream_getCommentStreamByOriginalId(
      originalId: $originalId
      projectId: $projectId
      start: $start
      end: $end
      timezone: $timezone
      objectList: $objectList
    ) {
      result {
        replyToContent {
          originalId
          socialMedia
          displayPicture
          fromId
          fromName
          timestamp
          share
          like
          comment
          audience
          engagementRate
          sentiment
          objectModel {
            id
          }
          postOwnership
          content
          link
          label {
            id
          }
          isEdited
          streamVisibility
        }
        originalId
        socialMedia
        displayPicture
        fromId
        fromName
        timestamp
        share
        like
        comment
        audience
        engagementRate
        sentiment
        objectModel {
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
          createdAt
          openApiId
          displayPicture
          includes
          excludes
        }
        postOwnership
        content
        link
        label {
          id
          name
          displayName
          colorType
          isActive
          createdAt
        }
        isEdited
        streamVisibility
      }
      pagination {
        next
        hasNext
      }
      total
      totalBySentiment {
        positive
        negative
        neutral
      }
    }
  }
`;

export const GET_CONTENT_PEAKTIME = gql`
  query content_getPeakTime(${analyticArgumentsQuery}) {
    content_getPeakTime(${analyticArguments}) {
      max
      min
      peakTimeValue {
        name
        data
      }
    }
  }
`;

export const GET_CONTENT_WORD_CLOUD = gql`
  query content_getPostMadeWordCloud(${analyticArgumentsQuery}) {
    content_getPostMadeWordCloud(${analyticArguments}) {
      name
      value
    }
  }
`;

export const GET_CONTENT_TYPE_TOTAL = gql`
  query content_getContentTypeTotal(${analyticArgumentsQuery}) {
    content_getContentTypeTotal(${analyticArguments}) {
      text
      video
      image
    }
  }
`;

export const GET_CONTENT_STREAM_PREVIEW = gql`
query content_getStreamPreview(${analyticArgumentsQuery}) {
    content_getStreamPreview(${analyticArguments}) {
      result{
        originalId
        socialMedia
        displayPicture
        link
        content
        fromId
        fromName
        timestamp
        like
        share
        comment
        audience
        engagementRate
        sentiment
        objectModel{
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
          createdAt
          openApiId
        }
        postOwnership
      }
      pagination{
        next 
        hasNext
      }
      total
      totalBySentiment {
        positive
        negative
        neutral
      }
  }
}
`;
