import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
  Observable,
  from,
  HttpLink
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';

const connections: { [key: string]: any } = {};

const projectIdPemprovJateng = 'f8f9ea6a-18aa-4b7b-8e19-7f4a2498bf1e';
const projectIdTransportUmum = 'dbfaae30-4da4-4e6d-a1c9-718df02e06ee';

const isUsingClusteringEndpoint = (endpoint: string, projectId: string) => {
  return (
    endpoint === 'clustering' ||
    projectId === projectIdPemprovJateng ||
    projectId === projectIdTransportUmum
  );
};

const projectIdLink = setContext(() => {
  const projectId = localStorage.getItem('project');
  return { projectId: projectId ?? '' };
});

// conditional endpoint based on contexts
const httpDirectionalLink = ApolloLink.split(
  // conditional
  (operation) =>
    isUsingClusteringEndpoint(
      operation.getContext().endpoint,
      operation.getContext().projectId
    ),
  // if condition true
  new HttpLink({
    uri: process.env.REACT_APP_CLUSTERING_API_URL,
    credentials: 'include'
  }),
  // if condition false
  new HttpLink({ uri: process.env.REACT_APP_API_URL, credentials: 'include' })
);

const cancelRequestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      const context = operation.getContext();
      const connectionHandle = forward(operation).subscribe({
        next: (...arg) => observer.next(...arg),
        error: (...arg) => {
          cleanUp();
          observer.error(...arg);
        },
        complete: (...arg) => {
          cleanUp();
          observer.complete(...arg);
        }
      });

      const cleanUp = () => {
        connectionHandle?.unsubscribe();
        delete connections[context.requestTrackerId];
      };

      if (context.requestTrackerId) {
        const controller = new AbortController();
        controller.signal.onabort = cleanUp;
        operation.setContext({
          ...context,
          fetchOptions: {
            signal: controller.signal,
            ...context?.fetchOptions,
            queryDeduplication: false
          }
        });

        if (connections[context.requestTrackerId]) {
          console.log('Abort!');
          connections[context.requestTrackerId]?.abort();
        }

        connections[context.requestTrackerId] = controller;
      }

      return connectionHandle;
    })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const defaultOptions: any = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true
  },
  mutate: {
    errorPolicy: 'all'
  }
};

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      'X-JWT-Token': token ? token : ''
    }
  };
});

const retryLink = new RetryLink({
  attempts: (count, operation, error) => {
    if (count >= 5) return false;
    switch (error.message) {
      // catch error for chrome
      case 'Failed to fetch':
        return true;
      // catch error for firefox
      case 'NetworkError when attempting to fetch resource.':
        return true;
      // catch error for safari
      case 'Load failed':
        return true;
      default:
        return false;
    }
  },
  delay: (count, operation, error) => {
    console.log('Retrying ' + operation.operationName);
    return count * 1000 * Math.random();
  }
});

const client = new ApolloClient({
  link: from([
    errorLink,
    retryLink,
    authLink,
    projectIdLink,
    cancelRequestLink,
    httpDirectionalLink
  ]),
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions,
  connectToDevTools: true
});

export default client;
