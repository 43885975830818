import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
  useTheme
} from '@mui/material';
import { useAppSelector } from 'src/app/hooks';
import { styled } from '@mui/material/styles';
import menuItems from '../SidebarMenu/items';
import { useState } from 'react';
import { MenuItem as IMenuItem } from '../SidebarMenu/items';
import { useLocation, useNavigate } from 'react-router';

const CustomSubheader = styled(ListSubheader)(({ theme }) => ({
  fontSize: '12px',
  color: 'white',
  backgroundColor: 'transparent',
  paddingInline: '0',
  textAlign: 'center',
  textTransform: 'uppercase'
}));

const menuItemStyles = {
  paddingLeft: '0',
  color: 'white !important',
  '&:hover': {
    background: 'transparent !important',
    opacity: '70%'
  }
};

const listItemButtonStyles = {
  minHeight: 48,
  width: '70%',
  marginInline: 'auto',
  px: 2.5,
  '&:hover': {
    background: 'transparent',
    opacity: '70%'
  }
  // '&.Mui-selected': {
  //   background: '#064E66'
  // }
};

const MiniSidebarMenu = () => {
  const theme = useTheme();
  const sidebarToggle = useAppSelector((state) => state.sidebarToggle.toggle);
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const menuOpen = Boolean(anchorEl);
  const [currentSubmenu, setCurrentSubmenu] = useState<IMenuItem>(null);
  const isSnaDna = Boolean(
    useAppSelector((state) => state.users.project.flags.isSnaDna)
  );
  const isPeoplePanel = Boolean(
    useAppSelector((state) => state.users.project.flags.isPeoplePanel)
  );
  const isClustering = Boolean(
    useAppSelector((state) => state.users.project.flags.isClustering)
  );
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentPage = pathname;
  const mainLevelMenu = pathname.split('/')[1];

  const handleClickMenu = (event: React.MouseEvent, subMenu: IMenuItem) => {
    if (!subMenu.items) {
      navigate(subMenu.link);
      return;
    }
    setAnchorEl(event.currentTarget);
    setCurrentSubmenu(subMenu);
  };

  const handleClickSubmenu = (event: React.MouseEvent, link: string) => {
    navigate(link);
    handleCloseMenu();
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <List sx={{ paddingInline: '0' }}>
      {menuItems.slice(0, 2).map((menuItem) => {
        return (
          <>
            <CustomSubheader>{menuItem.heading}</CustomSubheader>
            {menuItem.items.map((item) => {
              const ItemIcon: any = item.icon;
              return (
                <>
                  <ListItem
                    key={item.name}
                    disablePadding
                    sx={{
                      display: 'block',
                      ...(mainLevelMenu === item.link.slice(1) && {
                        borderRight: `4px solid ${theme.sidebar.menuItemColorActive}`,
                        borderRadius: '0.2em',
                        '&.Mui-selected': {
                          background: theme.sidebar.menuItemBgActive
                        }
                      })
                    }}
                  >
                    <ListItemButton
                      selected={mainLevelMenu === item.link.slice(1)}
                      onClick={(e) => handleClickMenu(e, item)}
                      sx={{
                        ...listItemButtonStyles,
                        justifyContent: sidebarToggle ? 'initial' : 'center'
                      }}
                      id="menu-button"
                      aria-controls={menuOpen ? 'sub-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={menuOpen ? 'true' : undefined}
                    >
                      <ListItemIcon
                        sx={{
                          color: 'white',
                          minWidth: 0,
                          justifyContent: 'center'
                        }}
                      >
                        <ItemIcon />
                      </ListItemIcon>
                    </ListItemButton>
                  </ListItem>
                </>
              );
            })}
          </>
        );
      })}
      <Divider
        sx={{
          width: '70%',
          marginInline: 'auto',
          marginTop: '34px',
          background: 'rgba(255, 255, 255, 0.2)'
        }}
      />
      <Menu
        PaperProps={{
          sx: {
            width: '216px',
            padding: '16px',
            background: theme.sidebar.background
          }
        }}
        id="sub-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'menu-button'
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Typography
          sx={{ fontWeight: '700', marginBottom: '20px', color: 'white' }}
        >
          {currentSubmenu && currentSubmenu.name}
        </Typography>
        {currentSubmenu !== null &&
          currentSubmenu.items &&
          currentSubmenu.items.map((subItem, index) => {
            if (!isSnaDna) {
              if (subItem.name === 'SNA' || subItem.name === 'DNA') return;
            }
            if (!isPeoplePanel) {
              if (subItem.name === 'People Panel') return;
            }
            if (!isClustering) {
              if (subItem.name === 'Clustering') return;
            }
            return (
              <>
                <MenuItem
                  sx={{
                    ...menuItemStyles,
                    backgroundColor: theme.sidebar.background,
                    ...(currentPage === subItem.link && {
                      // color: '#009FE3 !important'
                      color: `${theme.sidebar.menuItemColorActive} !important`
                    })
                  }}
                  onClick={(e) => handleClickSubmenu(e, subItem.link)}
                >
                  {currentPage === subItem.link
                    ? `• ${subItem.name}`
                    : subItem.name}
                </MenuItem>
                {index !== currentSubmenu.items.length - 1 && (
                  <Divider sx={{ backgroundColor: theme.sidebar.dividerBg }} />
                )}
              </>
            );
          })}
      </Menu>
    </List>
  );
};

export default MiniSidebarMenu;
