const languages = [
    {
        value: "",
        label: "Default"
    },
    {
        value: "any",
        label: "Any Language"
    },
    {
        value: "ar",
        label: "Arabic"
    },
    {
        value: "bn",
        label: "Bangla"
    },
    {
        value: "eu",
        label: "Basque"
    },
    {
        value: "bg",
        label: "Bulgarian"
    },
    {
        value: "ca",
        label: "Calatan"
    },
    {
        value: "hr",
        label: "Croatian"
    },
    {
        value: "cs",
        label: "Czech"
    },
    {
        value: "da",
        label: "Danish"
    },
    {
        value: "nl",
        label: "Dutch"
    },
    {
        value: "en",
        label: "English"
    },
    {
        value: "fi",
        label: "Finnish"
    },
    {
        value: "fr",
        label: "French"
    },
    {
        value: "de",
        label: "German"
    },
    //
    {
        value: "el",
        label: "Greek"
    },
    {
        value: "gu",
        label: "Gurajati"
    },
    {
        value: "he",
        label: "Hebrew"
    },
    {
        value: "hi",
        label: "Hindi"
    },
    {
        value: "hu",
        label: "Hungarian"
    },
    {
        value: "id",
        label: "Indonesian"
    },
    {
        value: "it",
        label: "Italian"
    },
    {
        value: "ja",
        label: "Japanese"
    },
    {
        value: "kn",
        label: "Kannada"
    },
    {
        value: "ko",
        label: "Korean"
    },
    {
        value: "mr",
        label: "Marathi"
    },
    {
        value: "no",
        label: "Norwegian"
    },
    {
        value: "fa",
        label: "Persian"
    },

]

export default languages;