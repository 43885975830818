import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApolloQueryResult } from '@apollo/client';
import {
  ICalculatePlan,
  IPackagePlan,
  IPackageStream,
  IPackageStreamSimulation,
  IUserInvoice,
  IUserPlan
} from 'src/models/billingSystem';

interface BillingState {
  currentPlan: ApolloQueryResult<IUserPlan>;
  invoices: ApolloQueryResult<Array<IUserInvoice>>;
  calculatePlan: ApolloQueryResult<ICalculatePlan>;
  detailInvoice: ApolloQueryResult<IUserInvoice>;
  pakagesStreamQuota: ApolloQueryResult<Array<IPackageStream>>;
  packageStreamSimulation: ApolloQueryResult<IPackageStreamSimulation>;
  currentRate: ApolloQueryResult<number>;
}

const initPackagePlan: IPackagePlan = {
  id: '',
  name: '',
  displayName: '',
  basicObjectQuota: 0,
  advanceObjectQuota: 0,
  onlineMediaObjectQuota: 0
};

const initCurrentPlan: IUserPlan = {
  id: '',
  advanceObjectQuota: 0,
  basicObjectQuota: 0,
  email: '',
  name: '',
  period: 1,
  periodEnd: new Date(),
  periodStart: new Date(),
  status: '',
  currency: '',
  isApply: false,
  nextAdvanceObjectPrice: 0,
  nextAdvanceObjectQuota: 0,
  nextBasicObjectPrice: 0,
  nextBasicObjectQuota: 0,
  nextPeriodEnd: new Date(),
  nextPeriodStart: new Date(),
  nextTotal: 0,
  currentUsageStreamQuota: 0,
  packagePlan: initPackagePlan,
  streamQuota: 0,
  total: 0,
  nextInvoiceId: '',
  countryId: ''
};

const initCalculatePlan: ICalculatePlan = {
  extensionFees: 0,
  total: 0,
  upgradeFees: 0,
  changeReason: 'upgrade_only_by_user',
  removedBasicObject: 0,
  removedAdvanceObject: 0,
  isApply: false
};

const initialState: BillingState = {
  currentPlan: {
    data: initCurrentPlan,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  invoices: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  calculatePlan: {
    data: initCalculatePlan,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  detailInvoice: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  pakagesStreamQuota: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  packageStreamSimulation: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  currentRate: {
    data: undefined,
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  }
};

export const billing = createSlice({
  name: 'billingSystem',
  initialState,
  reducers: {
    reducerUpdateCurrentPlan: (
      state: BillingState,
      action: PayloadAction<ApolloQueryResult<IUserPlan>>
    ) => {
      state.currentPlan = action.payload;
    },
    reducerUpdateInvoices: (
      state: BillingState,
      action: PayloadAction<ApolloQueryResult<Array<IUserInvoice>>>
    ) => {
      state.invoices = action.payload;
    },
    reducerUpdateCalculatePlan: (
      state: BillingState,
      action: PayloadAction<ApolloQueryResult<ICalculatePlan>>
    ) => {
      state.calculatePlan = action.payload;
    },
    reducerResetCalculatePlan: (
      state: BillingState,
      _action: PayloadAction
    ) => {
      state.calculatePlan = { ...state.calculatePlan, data: initCalculatePlan };
    },
    reducerUpdateDetailInvoice: (
      state: BillingState,
      action: PayloadAction<ApolloQueryResult<IUserInvoice>>
    ) => {
      state.detailInvoice = action.payload;
    },
    reducerUpdateStreamSimulation: (
      state: BillingState,
      action: PayloadAction<ApolloQueryResult<IPackageStreamSimulation>>
    ) => {
      state.packageStreamSimulation = action.payload;
    },
    reducerUpdatePackagesStreamQuota: (
      state: BillingState,
      action: PayloadAction<ApolloQueryResult<Array<IPackageStream>>>
    ) => {
      state.pakagesStreamQuota = action.payload;
    },
    reducerUpdateCurrentRate: (
      state: BillingState,
      action: PayloadAction<ApolloQueryResult<number>>
    ) => {
      state.currentRate = action.payload;
    }
  }
});

export const {
  reducerUpdateCurrentPlan,
  reducerUpdateInvoices,
  reducerUpdateCalculatePlan,
  reducerResetCalculatePlan,
  reducerUpdateDetailInvoice,
  reducerUpdatePackagesStreamQuota,
  reducerUpdateStreamSimulation,
  reducerUpdateCurrentRate
} = billing.actions;

export default billing.reducer;
