import gql from "graphql-tag";

const DELETE_NOTIFICATION = gql`
  mutation notification_deleteNotification($notificationId: Id!) {
    notification_deleteNotification(notificationId: $notificationId) {
      id
      tableName
      projectId
      typeNotification
      subTypeNotification
      status
      totalProgress
      currentProgress
    }
  }
`;
const DELETE_ALL_NOTIFICATION = gql`
  mutation notification_deleteAllNotification(
    $projectId: Id!
  
  ) {
    notification_deleteAllNotification(
      projectId: $projectId
    
    ) {
      id
      tableName
      projectId
      typeNotification
      subTypeNotification
      status
      totalProgress
      currentProgress
      
      __typename
    }
  }
`;

export { DELETE_NOTIFICATION, DELETE_ALL_NOTIFICATION };
