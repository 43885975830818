import { useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, DialogActions, useTheme } from '@mui/material';
import { IObject } from 'src/models/objectManagement';
import SectionKeywordHashtag from './components/SectionKeywordHashtag';

interface Props {
  open: boolean;
  onClose: () => void;
  objectList: Array<IObject>;
  onDone: (selectedKeywordIds: Array<string>) => void;
  loading?: boolean;
}

const ModalAddHashtagToCampaign: React.FC<Props> = ({
  open,
  onClose,
  objectList,
  onDone,
  loading
}) => {
  const theme = useTheme();

  const objectListKeywordAndHashtag = useMemo((): Array<IObject> => {
    return objectList.filter((object) => object.isHashtag);
  }, [objectList]);

  const [selectedKeywordIds, setSelectedKeywordIds] = useState<Array<string>>(
    []
  );

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={'md'}>
      <SectionKeywordHashtag
        objectList={objectListKeywordAndHashtag}
        onSelect={(keywordAndHashtagIds) =>
          setSelectedKeywordIds(keywordAndHashtagIds)
        }
        onParentVisible={() => {}}
        selectedKeywordIds={selectedKeywordIds}
      />
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          pb: theme.spacing(5)
        }}
      >
        <Button
          variant="contained"
          sx={{
            background: theme.palette.primary.dark,
            width: theme.spacing(20)
          }}
          size="large"
          onClick={() => onDone(selectedKeywordIds)}
          disabled={loading}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddHashtagToCampaign;
