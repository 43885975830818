import { Box, Fab, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { SOCIAL_MEDIA_CAMPAIGN } from 'src/route';
import { useAppSelector } from 'src/app/hooks';
import ModalFinishCampaign from 'src/components/ModalFinishCampaign';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

function HeaderPreferenceCampaign() {
  const navigate = useNavigate();
  const theme = useTheme();
  const campaignSelected = useAppSelector(
    (state) => state.storeSocialMediaCampaign.campaignSelected
  );
  console.log(campaignSelected);
  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Grid container>
        <Grid item lg={12}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Fab
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.grey[200]}`
                }}
                size="small"
                onClick={() => navigate(SOCIAL_MEDIA_CAMPAIGN)}
              >
                <ArrowBackIcon />
              </Fab>
              <Typography variant="h1">
                {campaignSelected?.campaignName}
              </Typography>
            </Stack>
            <ModalFinishCampaign />
          </Stack>
        </Grid>
      </Grid>
    </HeaderWrapper>
  );
}

export default HeaderPreferenceCampaign;
