import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  reducerResetCalculatePlan,
  reducerUpdateCalculatePlan,
  reducerUpdateCurrentPlan,
  reducerUpdateCurrentRate,
  reducerUpdateDetailInvoice,
  reducerUpdateInvoices,
  reducerUpdatePackagesStreamQuota,
  reducerUpdateStreamSimulation
} from 'src/features/billing';
import { useAlert } from 'src/hooks/useAlert';
import {
  ICalculatePlan,
  IPackageStream,
  IPackageStreamSimulation,
  IUserInvoice,
  IUserPlan,
  IUserPlanMinimal
} from 'src/models/billingSystem';
import {
  BILLING_PLAN_CHECKOUT,
  BILLING_STREAM_CHECKOUT,
  SETTINGS
} from 'src/route';
import {
  BILLING_APPLY_PLAN,
  BILLING_APPSUMO_INVOICE_CHECKOUT_PLAN_STREAM,
  BILLING_INVOICE_CHECKOUT,
  BILLING_INVOICE_CHECKOUT_PLAN_STREAM,
  UPGRADE_PLAN
} from './graphql/billing/mutation';
import {
  BILLING_GET_PAKAGE_PLAN_STREAM,
  BILLING_APPSUMO_GET_PLAN_STREAM_SIMULATION,
  CALCULATE_PLAN,
  GET_USER_INVOICE,
  GET_USER_INVOICE_BY_ID,
  GET_USER_PLAN,
  BILLING_APPSUMO_GET_USER_INVOICE,
  BILLING_APPSUMO_GET_USER_INVOICE_BY_ID,
  BILLING_CURRENCY_CONVERTER
} from './graphql/billing/query';

interface IPayloadPlanStream {
  packagePlanId: string;
  promoCode?: string;
}

interface IGetUserPlanResponse {
  billing_getUserPlan: IUserPlan;
}

interface IGetUserInvoicesResponse {
  billing_getUserInvoice: Array<IUserInvoice>;
}

interface IGetAppSumoInvoicesResponse {
  billingAppSumo_getUserInvoice: Array<IUserInvoice>;
}

interface IGetUserInvoiceByIdResponse {
  billing_getUserInvoiceById: IUserInvoice;
}

interface IGetAppSumoInvoiceByIdResponse {
  billingAppSumo_getUserInvoiceById: IUserInvoice;
}

interface IGetCalculatePlanResponse {
  billing_calculatePlan: ICalculatePlan;
}

interface IGetPackagePlanStreamResponse {
  billingAppSumo_getPackagePlanStream: Array<IPackageStream>;
}

interface IGetStreamSimulationResponse {
  billingAppSumo_getPlanStreamSimulation: IPackageStreamSimulation;
}

interface IGetCurrentRateResponse {
  user_currencyConverter: { value: number };
}

const useBillingSystem = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { invoices, currentPlan, packageStreamSimulation } = useAppSelector(
    (state) => state.storeBilling
  );
  const { handleClickAlert } = useAlert();

  const fetchUserPlan = async function (projectId: string) {
    try {
      return await client
        .query<IGetUserPlanResponse>({
          query: GET_USER_PLAN,
          variables: { projectId }
        })
        .then((response) => {
          return { ...response, data: response.data.billing_getUserPlan };
        });
    } catch (e) {
      console.log(e?.message);
    }
  };

  const getCurrentPlan = async function (projectId: string) {
    const response = await fetchUserPlan(projectId);
    if (!response?.data) return;
    dispatch(reducerUpdateCurrentPlan(response));
  };

  const fetchUserInvoices = async function (projectId: string) {
    try {
      return await client
        .query<IGetUserInvoicesResponse>({
          query: GET_USER_INVOICE,
          variables: { projectId }
        })
        .then((response) => {
          return { ...response, data: response.data.billing_getUserInvoice };
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getUserInvoices = async function (projectId: string) {
    const response = await fetchUserInvoices(projectId);
    dispatch(reducerUpdateInvoices(response));
  };

  const fetchUserInvoicesAppSumo = async function (projectId: string) {
    try {
      return await client
        .query<IGetAppSumoInvoicesResponse>({
          query: BILLING_APPSUMO_GET_USER_INVOICE,
          variables: { projectId }
        })
        .then((response) => {
          return {
            ...response,
            data: response.data.billingAppSumo_getUserInvoice
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getUserInvoicesAppSumo = async function (projectId: string) {
    const response = await fetchUserInvoicesAppSumo(projectId);
    dispatch(reducerUpdateInvoices(response));
  };

  const fetchUserInvoiceById = async function (invoiceId: string) {
    try {
      return await client
        .query<IGetUserInvoiceByIdResponse>({
          query: GET_USER_INVOICE_BY_ID,
          variables: { invoiceId }
        })
        .then((response) => {
          return {
            ...response,
            data: response.data.billing_getUserInvoiceById
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getUserInvoiceById = async function (invoiceId: string) {
    const response = await fetchUserInvoiceById(invoiceId);
    dispatch(reducerUpdateDetailInvoice(response));
  };

  const fetchUserInvoiceAppSumoById = async function (invoiceId: string) {
    try {
      return await client
        .query<IGetAppSumoInvoiceByIdResponse>({
          query: BILLING_APPSUMO_GET_USER_INVOICE_BY_ID,
          variables: { invoiceId }
        })
        .then((response) => {
          return {
            ...response,
            data: response.data.billingAppSumo_getUserInvoiceById
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getUserInvoiceAppSumoById = async function (invoiceId: string) {
    const response = await fetchUserInvoiceAppSumoById(invoiceId);
    dispatch(reducerUpdateDetailInvoice(response));
  };

  const fetchUserCalculatePlan = async function (payload: IUserPlanMinimal) {
    try {
      return await client
        .query<IGetCalculatePlanResponse>({
          query: CALCULATE_PLAN,
          variables: payload
        })
        .then((response) => {
          return { ...response, data: response.data.billing_calculatePlan };
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getUserCalculatePlan = async function (payload: IUserPlanMinimal) {
    const response = await fetchUserCalculatePlan(payload);
    dispatch(reducerUpdateCalculatePlan(response));
  };

  const fetchPackagePlanStream = async function () {
    try {
      return await client
        .query<IGetPackagePlanStreamResponse>({
          query: BILLING_GET_PAKAGE_PLAN_STREAM
        })
        .then((response) => {
          return {
            ...response,
            data: response.data.billingAppSumo_getPackagePlanStream
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getPackagePlanStream = async function () {
    const response = await fetchPackagePlanStream();
    dispatch(reducerUpdatePackagesStreamQuota(response));
  };

  const fetchPlanStreamSimulation = async function (
    payload: IPayloadPlanStream
  ) {
    try {
      return await client
        .query<IGetStreamSimulationResponse>({
          query: BILLING_APPSUMO_GET_PLAN_STREAM_SIMULATION,
          variables: payload
        })
        .then((response) => {
          return {
            ...response,
            data: response.data.billingAppSumo_getPlanStreamSimulation
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getPlanStreamSimulation = async function (payload: IPayloadPlanStream) {
    dispatch(
      reducerUpdateStreamSimulation({
        ...packageStreamSimulation,
        loading: true
      })
    );
    const response = await fetchPlanStreamSimulation(payload);
    dispatch(reducerUpdateStreamSimulation(response));
    navigate(BILLING_STREAM_CHECKOUT);
  };

  const fetchCurrentRate = async function (payload: string) {
    try {
      return await client
        .query<IGetCurrentRateResponse>({
          query: BILLING_CURRENCY_CONVERTER,
          variables: { countryId: payload }
        })
        .then((response) => {
          return {
            ...response,
            data: response.data.user_currencyConverter.value
          };
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getCurrentRate = async function (payload: string) {
    const response = await fetchCurrentRate(payload);
    dispatch(reducerUpdateCurrentRate(response));
  };

  const [postCheckoutPlanStream, responseCheckoutPlanStream] = useMutation(
    BILLING_INVOICE_CHECKOUT_PLAN_STREAM,
    {
      onCompleted: ({ billingAppSumo_invoiceCheckoutPlanStream }) => {
        reducerUpdateInvoices({
          ...invoices,
          data: [...invoices.data, billingAppSumo_invoiceCheckoutPlanStream]
        });
        reducerResetCalculatePlan();
      }
    }
  );

  const [postUpgradePlan, responseUpgradePlan] = useMutation(UPGRADE_PLAN, {
    onCompleted: ({ billing_upgradePlan }) => {
      reducerUpdateInvoices({
        ...invoices,
        data: [...invoices.data, billing_upgradePlan]
      });
      navigate(`${BILLING_PLAN_CHECKOUT}?invoice_id=${billing_upgradePlan.id}`);
      reducerResetCalculatePlan();
    }
  });

  const [postRemoveObjects, responsePostRemoveObjects] = useMutation(
    UPGRADE_PLAN,
    {
      onCompleted: ({ billing_upgradePlan }) => {
        console.log(billing_upgradePlan.planCalculation.total);
        if (billing_upgradePlan.planCalculation.total !== 0) {
          reducerUpdateInvoices({
            ...invoices,
            data: [invoices.data, billing_upgradePlan]
          });
          navigate(
            `${BILLING_PLAN_CHECKOUT}?invoice_id=${billing_upgradePlan.id}`
          );
        } else {
          navigate(`${SETTINGS}?tab=billing`);
        }
      },
      onError(error) {
        handleClickAlert({
          horizontal: 'center',
          vertical: 'top',
          message: "Couldn't remove object, please try again",
          severity: 'error'
        });
        console.log(error);
      }
    }
  );

  const [postApplyPlan, responseApplyPlan] = useMutation(BILLING_APPLY_PLAN, {
    onCompleted: ({ billing_applyPlan }) => {
      reducerUpdateCurrentPlan({
        ...currentPlan,
        data: { ...currentPlan.data, isApply: false }
      });
      reducerUpdateInvoices({
        ...invoices,
        data: [...invoices.data, billing_applyPlan]
      });
    }
  });

  const [postInvoiceCheckout, responseInvoiceCheckout] = useMutation(
    BILLING_INVOICE_CHECKOUT,
    {
      onCompleted: ({ billing_invoiceCheckout }) => {
        navigate(billing_invoiceCheckout.url);
      }
    }
  );

  const [postAppSumoCheckoutPlanStream, resPostAppSumoCheckoutPlanStream] =
    useMutation(BILLING_APPSUMO_INVOICE_CHECKOUT_PLAN_STREAM, {
      onCompleted: (response) => {
        const data = response?.billingAppSumo_invoiceCheckoutPlanStream;
        if (!data) return;
        window.location.href = data.url;
      }
    });

  return {
    getCurrentPlan,
    getUserInvoices,
    getUserInvoiceById,
    getUserCalculatePlan,
    postUpgradePlan,
    responseUpgradePlan,
    postApplyPlan,
    responseApplyPlan,
    postInvoiceCheckout,
    responseInvoiceCheckout,
    getPackagePlanStream,
    getPlanStreamSimulation,
    postCheckoutPlanStream,
    responseCheckoutPlanStream,
    getUserInvoicesAppSumo,
    getUserInvoiceAppSumoById,
    postAppSumoCheckoutPlanStream,
    resPostAppSumoCheckoutPlanStream,
    getCurrentRate,
    postRemoveObjects,
    responsePostRemoveObjects
  };
};

export default useBillingSystem;
