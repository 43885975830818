import { IObject } from 'src/models/objectManagement';
import { convertExactObjectName } from './convertExactObjectName';

export const addSymbolObject = function (object: IObject) {
  const formattedObjectName = convertExactObjectName(object.objectName);
  if (object.streamType === 'account') {
    return {
      ...object,
      objectName: `@${formattedObjectName}`
    };
  } else if (object.streamType === 'keyword' && !object.isHashtag) {
    return {
      ...object,
      objectName: `~${formattedObjectName}`
    };
  } else {
    return object;
  }
};
