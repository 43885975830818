/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import LogoNoLimit from 'src/assets/image/nolimit-logo-flat.svg';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Country } from 'src/models/register';
import useRegister from 'src/services/useRegister';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useLocation } from 'react-router';
import { reducerUpdateCountryId } from 'src/features/register';
import { getLogoByHostname } from 'src/utils/getLogoByHostname';

interface Props {
  period: any;
}

export interface RegisterAccount {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  countryId: string;
  zipCode: string;
}

function validationSchema() {
  return Yup.object({
    companyName: Yup.string().required('Company name is required'),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    countryId: Yup.string().required('Country is required'),
    email: Yup.string().email().required(),
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirmation Password is required'),
    zipCode: Yup.string().required()
  });
}

const FormAccount: FC<Props> = ({ period }) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const planCurrency = params.get('currency');
  const planParam = params.get('plan');
  const countries = useAppSelector((state) => state.register.countries);
  const { countryId } = useAppSelector((state) => state.register);
  const planSimulation = useAppSelector(
    (state) => state.register.planSimulation
  );
  const { getCountries, postUserRegister, responseUserRegister } =
    useRegister();
  const packagePlan = useAppSelector((state) => state.register.userPackagePlan);
  const { logo, width } = getLogoByHostname(true);

  const { handleChange, handleSubmit, errors, values, touched } =
    useFormik<RegisterAccount>({
      initialValues: {
        companyName: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        countryId:
          planCurrency === 'idr'
            ? '9cd88e16-510e-49f0-9ce8-f4d27f0b85bb'
            : '162021c9-becc-11eb-a7ce-1e87ea3f1b00',
        zipCode: ''
      },
      validationSchema: validationSchema(),
      onSubmit: (value) => {
        const socialProfileByPackage = packagePlan.data.find(
          (plan) => plan.name === planParam
        );
        dispatch(reducerUpdateCountryId(value.countryId));
        const payload = {
          ...value,
          ...planSimulation.data,
          packagePlanId: socialProfileByPackage.id,
          periodSubscription: period.id,
          advanceObject: planSimulation.data.quotaAdvance,
          basicObject: planSimulation.data.quotaBasic,
          onlineMediaObject: planSimulation.data.quotaOnlineMedia
        };
        postUserRegister({
          variables: payload
        });
      }
    });

  const [showPassword, setShowPassword] = useState([false, false]);
  const handleClickShowPassword = (index) => {
    setShowPassword((prev) =>
      prev.map((p, i) => {
        if (i === index) return !p;
        return p;
      })
    );
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    getCountries();
  }, []);

  const dispatch = useAppDispatch();
  const handleChangeCountry = (countryId: string) => {
    dispatch(reducerUpdateCountryId(countryId));
  };

  const theme = useTheme();
  const isSmallOrLess = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        width: '536px'
      }}
    >
      <form onSubmit={handleSubmit} id="accountForm">
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <img src={logo} alt={'company Logo'} width={width} />
          </Grid>
          <Grid item lg={12}>
            <Divider />
          </Grid>
          <Grid item lg={12}>
            <Typography variant="h1">Start Your Journey</Typography>
            <Typography variant="caption">
              Just one more step to start analyzing and monitoring your social
              media! Stay worry free with a 30-day money back guarantee.
            </Typography>
          </Grid>
          <Grid item lg={6}>
            <FormControl fullWidth size="medium">
              <FormLabel>First Name</FormLabel>
              <OutlinedInput
                name="firstName"
                onChange={handleChange}
                error={errors.firstName && touched.firstName}
                value={values.firstName}
              />
              <FormHelperText
                error
                variant="outlined"
                margin="dense"
                sx={{ ml: 0 }}
              >
                {errors.firstName && touched.firstName && errors.firstName}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={6}>
            <FormControl fullWidth size="medium">
              <FormLabel>Last Name</FormLabel>
              <OutlinedInput
                name="lastName"
                onChange={handleChange}
                error={errors.lastName && touched.lastName}
                value={values.lastName}
              />
              <FormHelperText
                error
                variant="outlined"
                margin="dense"
                sx={{ ml: 0 }}
              >
                {errors.lastName && touched.lastName && errors.lastName}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <FormControl fullWidth size="medium">
              <FormLabel>Company Name</FormLabel>
              <OutlinedInput
                name="companyName"
                onChange={handleChange}
                error={errors.companyName && touched.companyName}
                value={values.companyName}
              />
              <FormHelperText
                error
                variant="outlined"
                margin="dense"
                sx={{ ml: 0 }}
              >
                {errors.companyName &&
                  touched.companyName &&
                  errors.companyName}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <FormControl fullWidth size="medium">
              <FormLabel>Country</FormLabel>
              <Select
                id="countryId"
                name="countryId"
                value={values.countryId}
                onChange={(e) => {
                  handleChange(e);
                  handleChangeCountry(e.target.value);
                }}
                fullWidth
                error={errors.countryId && touched.countryId}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {countries.data.map((country: Country) => (
                  <MenuItem value={country.id} key={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText
                error
                variant="outlined"
                margin="dense"
                sx={{ ml: 0 }}
              >
                {errors.countryId && touched.countryId && errors.countryId}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <FormControl fullWidth size="medium">
              <FormLabel>Postal Code</FormLabel>
              <OutlinedInput
                name="zipCode"
                onChange={handleChange}
                error={errors.zipCode && touched.zipCode}
                value={values.zipCode}
                autoComplete="false"
              />
              <FormHelperText
                error
                variant="outlined"
                margin="dense"
                sx={{ ml: 0 }}
              >
                {errors.zipCode && touched.zipCode && errors.zipCode}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <FormControl fullWidth size="medium">
              <FormLabel>Email Address</FormLabel>
              <OutlinedInput
                name="email"
                onChange={handleChange}
                error={errors.email && touched.email}
                value={values.email}
                autoComplete="new-password"
              />
              <FormHelperText
                error
                variant="outlined"
                margin="dense"
                sx={{ ml: 0 }}
              >
                {errors.email && touched.email && errors.email}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <FormControl fullWidth size="medium">
              <FormLabel>Password</FormLabel>
              <OutlinedInput
                name="password"
                onChange={handleChange}
                error={errors.password && touched.password}
                value={values.password}
                type={showPassword[0] ? 'text' : 'password'}
                autoComplete="new-password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(0)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword[0] ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error
                variant="outlined"
                margin="dense"
                sx={{ ml: 0 }}
              >
                {errors.password && touched.password && errors.password}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item lg={12}>
            <FormControl fullWidth size="medium">
              <FormLabel>Confirm Password</FormLabel>
              <OutlinedInput
                name="passwordConfirmation"
                onChange={handleChange}
                error={
                  errors.passwordConfirmation && touched.passwordConfirmation
                }
                value={values.passwordConfirmation}
                type={showPassword[1] ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword(1)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword[1] ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText
                error
                variant="outlined"
                margin="dense"
                sx={{ ml: 0 }}
              >
                {errors.passwordConfirmation &&
                  touched.passwordConfirmation &&
                  errors.passwordConfirmation}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          size="large"
          sx={
            isSmallOrLess
              ? {
                  display: 'none'
                  // my: 4,
                  // px: 8,
                  // position: 'absolute',
                  // bottom: -680,
                  // width: 'calc(100% - 32px)'
                }
              : {
                  my: 4,
                  px: 8
                }
          }
          disabled={responseUserRegister.loading}
        >
          Continue
        </Button>
      </form>
    </Box>
  );
};

export default FormAccount;
