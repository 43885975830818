import { Box, Fab, Grid, Stack, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import BottomHeader from './Bottom';
import { useNavigate } from 'react-router';
import { SOCIAL_MEDIA_COMPARISON } from 'src/route';
import { FC, useMemo } from 'react';
import useQueryUrl from 'src/hooks/useQuery';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GroupObject from 'src/components/GroupObject';
import ModalManageObjectByGroup from 'src/components/ModalManageObjectByGroup';
import ObjectPreview from 'src/components/ObjectPreview';
import { useAppSelector } from 'src/app/hooks';
import TooltipCustomize from 'src/components/TooltipCustomize';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface Props {
  onClick: () => void;
}

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    box-shadow: ${theme.header.boxShadow};
    justify-content: space-between;
    width: 100%;
    background: ${theme.header.background};
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

const HeaderWrapperSec = styled(Box)(
  ({ theme }) => `
    background: #fbfbfb;
    color: ${theme.header.textColor};
    padding: ${theme.spacing(1, 4, 0, 4)};
    right: 0;
    z-index: 5;
    justify-content: space-between;
    width: 100%;
    @media (min-width: ${theme.breakpoints.values.lg}px) {
        left: ${theme.sidebar.width};
        width: auto;
    }
`
);

const HeaderComparisonDashboard: FC<Props> = ({ onClick }) => {
  const query = useQueryUrl();
  const title = query.get('title');
  const comparisonId = query.get('id');
  const navigate = useNavigate();
  const theme = useTheme();
  const objectListByObjectType = useAppSelector(
    (state) => state.objectManagement.objectListByObjectType
  );
  const { comparisonList } = useAppSelector((state) => state.storeComparison);

  const getSourceType = function () {
    if (comparisonList.data.length < 1) return;
    const findComparison = comparisonList.data.find(
      (comparison) => comparison.id === comparisonId
    );
    return findComparison.sourceType;
  };

  const sourceType = useMemo(getSourceType, [
    comparisonId,
    comparisonList.data
  ]);

  return (
    <>
      <HeaderWrapper display="flex" alignItems="center">
        <Grid container>
          <Grid item lg={12}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ mb: 2 }}
            >
              <Fab
                sx={{
                  background: theme.palette.common.white,
                  border: `1px solid ${theme.palette.grey[200]}`
                }}
                size="small"
                onClick={() => navigate(SOCIAL_MEDIA_COMPARISON)}
              >
                <ArrowBackIcon />
              </Fab>
              <Stack direction="row" alignItems="center">
                <Typography
                  variant="h1"
                  sx={{
                    mr: 1
                  }}
                >
                  {title}
                </Typography>
                <TooltipCustomize
                  title={
                    'Comparison analysis between each object, group of object or label'
                  }
                  placement="top-start"
                >
                  <InfoOutlinedIcon
                    fontSize="small"
                    sx={{ transform: 'translateY(-4px)' }}
                  />
                </TooltipCustomize>
              </Stack>
            </Stack>
          </Grid>
          {sourceType === 'label' && (
            <>
              <Grid item lg={12}>
                <Box mt={2}>
                  <GroupObject />
                </Box>
              </Grid>
              <Grid item lg={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  mt={1.5}
                  mb={3}
                >
                  <ModalManageObjectByGroup title="Social Profile" />
                  <ObjectPreview objectList={objectListByObjectType} />
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </HeaderWrapper>
      <HeaderWrapperSec display="flex" alignItems="center">
        <Grid container>
          <Grid item sm={12}>
            <BottomHeader key={title} />
          </Grid>
        </Grid>
      </HeaderWrapperSec>
    </>
  );
};

export default HeaderComparisonDashboard;
