import React from 'react';
import { Grid, Typography } from '@mui/material';
import TagSocialMedia from 'src/components/TagSocialMedia';
import { addSymbolObject } from 'src/utils/addSymbolObject';
import TagLabel from 'src/components/TagLabel';
import moment from 'moment';

interface Props {
  detail: any;
}

const InformationDetailUpdateLabel: React.FC<Props> = ({ detail }) => {
  const {
    objectList = [],
    start,
    end,
    labels = [],
    keywordsInclude,
    postOwnerShip,
    attachLabels,
    removeLabels,
    sentiments
  } = detail;

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5">Filter by:</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">Objects : </Typography>
        {objectList.slice(0, 20).map((object) => (
          <TagSocialMedia
            key={object.id}
            socialMedia={object.socialMedia}
            text={addSymbolObject(object).objectName}
          />
        ))}
        {objectList.length > 20 && (
          <TagSocialMedia socialMedia={'twitter'} text={'. . .'} />
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">{`Date Range : ${moment(start).format(
          'DD MMM YYYY'
        )} - ${moment(end).format('DD MMM YYYY')}`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">Labels : </Typography>
        {labels.map((label) => (
          <TagLabel
            text={label.displayName}
            key={label.id}
            color={label.colorType}
          />
        ))}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">{`Keywords : ${keywordsInclude?.join(
          ', '
        )}`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">{`Post Ownership : ${postOwnerShip?.join(
          ', '
        )}`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">{`Sentiments : ${sentiments?.join(
          ', '
        )}`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 8 }}>
        <Typography variant="h5">Change to :</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">{`Attach Labels : `}</Typography>
        {attachLabels.map((label) => (
          <TagLabel
            text={label.displayName}
            key={label.id}
            color={label.colorType}
          />
        ))}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="subtitle2">{`Remove Labels : `}</Typography>
        {removeLabels.map((label) => (
          <TagLabel
            text={label.displayName}
            key={label.id}
            color={label.colorType}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default InformationDetailUpdateLabel;
