/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {
  Avatar,
  Button,
  capitalize,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { IWidgetMetaData, TVisualization } from 'src/models/customDashboard';
import useCustomDashboard from 'src/services/useCustomDashboard';
import GridViewIcon from '@mui/icons-material/GridView';
import { reducerUpdateDashboardList } from 'src/features/customDashboard';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import GradientIcon from '@mui/icons-material/Gradient';
import BarChartIcon from '@mui/icons-material/BarChart';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface NumberSelected {
  selected: boolean;
}

const SelectorWidget = styled(Box)<NumberSelected>(
  ({ theme, selected }) => `
    width: 100%;
    height: ${theme.spacing(10)};
    padding: ${theme.spacing(1)};
    background: ${
      selected
        ? 'linear-gradient(90deg, #DDF5FF 0%, #C1FEFA 50.65%, #C1ECFE 100%)'
        : '#FFFFFF'
    };
    border: ${selected ? '2px solid #051C26' : '1px solid #CFCFCF'};
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
`
);

const AvatarCustom = styled(Avatar)<NumberSelected>(
  ({ theme, selected }) => `
    width: ${theme.spacing(2.5)};
    height: ${theme.spacing(2.5)};
    background: ${selected ? theme.palette.primary.main : '#F5F5F5'}
  `
);

interface Props {
  prevSelectedWidget?: Array<IWidgetMetaData>;
  isShowBtn?: boolean;
  handleClickBtn?: () => void;
  isModalOpen?: boolean;
}

const getCustomDashboardIcon = (customDashboard: TVisualization) => {
  switch (customDashboard) {
    case 'text':
      return <TextSnippetIcon fontSize="small" color="primary" />;
    case 'line_chart':
      return <ShowChartIcon fontSize="small" color="primary" />;
    case 'bar_chart':
      return <BarChartIcon fontSize="small" color="primary" />;
    case 'bubble':
      return <GradientIcon fontSize="small" color="primary" />;
    case 'table':
      return <TableChartIcon fontSize="small" color="primary" />;
    case 'pie':
      return <PieChartOutlineIcon fontSize="small" color="primary" />;
    default:
      return <PieChartOutlineIcon fontSize="small" color="primary" />;
  }
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12
  }
}));

// const TypographyStyle = withStyles((theme: Theme) => ({
//   root: {
//     '&:first-child': {
//       marginTop: 0
//     }
//   }
// }))(Typography);

const ModalSelectWidget: React.FC<Props> = ({
  prevSelectedWidget = [],
  isShowBtn = true,
  handleClickBtn,
  isModalOpen = false
}) => {
  const { manageWidgetCustomDashboard, resManageWidgetCustomDashboard } =
    useCustomDashboard();
  const dispatch = useAppDispatch();
  const projectId = useAppSelector((state) => state.users.project.id);
  const { widgetMetaDataList, dashboardSelectedById, dashboardList } =
    useAppSelector((state) => state.storeCustomDashboard);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('all');
  const [widgetData, setWidgetData] = useState<Array<IWidgetMetaData>>(
    widgetMetaDataList.data
  );

  useEffect(() => {
    setWidgetData(widgetMetaDataList.data);
  }, [dashboardSelectedById]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();

  const [selectedLocal, setSelectedLocal] =
    useState<Array<IWidgetMetaData>>(prevSelectedWidget);

  useMemo(() => {
    if (!prevSelectedWidget) return;
    setSelectedLocal(prevSelectedWidget);
  }, [prevSelectedWidget]);

  const index = (id: string) => selectedLocal.findIndex((obj) => obj.id === id);

  const handleClick = function (widget: IWidgetMetaData) {
    const selectedIndex = selectedLocal.findIndex(
      (obj) => obj.id === widget.id
    );
    let newSelected: IWidgetMetaData[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedLocal, widget);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedLocal.slice(1));
    } else if (selectedIndex === selectedLocal.length - 1) {
      newSelected = newSelected.concat(selectedLocal.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedLocal.slice(0, selectedIndex),
        selectedLocal.slice(selectedIndex + 1)
      );
    }
    setSelectedLocal(newSelected);
  };

  const filterWidget = (visualization) => {
    const newWidget = widgetMetaDataList.data.filter((val) => {
      return val.visualization === visualization;
    });
    if (visualization === 'all') return setWidgetData(widgetMetaDataList.data);
    setWidgetData(newWidget);
  };

  useEffect(() => {
    filterWidget(value);
  }, [value]);

  const handleSubmit = function () {
    manageWidgetCustomDashboard({
      variables: {
        projectId,
        customDashboardWidgetMetadataIds: selectedLocal.map(
          (widget) => widget.id
        ),
        customDashboardId: dashboardSelectedById
      }
    }).then((res) => {
      const updateDataDashboardList = dashboardList.data.map((dashboard) => {
        if (dashboard.id === dashboardSelectedById)
          return {
            ...dashboard,
            customDashboardWidgetMetadataList:
              res.data.customDashboard_manageWidgetOnCustomDashboard
                .customDashboardWidgetMetadataList
          };
        return dashboard;
      });
      dispatch(
        reducerUpdateDashboardList({
          ...dashboardList,
          data: updateDataDashboardList
        })
      );
      isShowBtn ? handleClose() : handleClickBtn();
    });
  };

  const handleRemoveSelected = (id) => {
    const newSelected = selectedLocal.filter((val) => {
      return val.id !== id;
    });
    setSelectedLocal(newSelected);
  };

  const handleOnDragEnd = (result) => {
    const items = Array.from(selectedLocal);
    const [reorderItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderItem);
    setSelectedLocal(items);
  };

  const categoryList = [
    'content',
    'community',
    'campaign',
    'conversation',
    'general'
  ];

  const widgetListView = (category, idx) => {
    return (
      <Stack>
        {widgetData.filter((val) => {
          return val.category === category;
        }).length > 0 ? (
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 600,
              color: '#4B4B4B',
              marginY: theme.spacing(3)
              // marginTop: category !== 'content' ? theme.spacing(3) : 0
              // marginTop: theme.spacing(3)
              // marginBottom: theme.spacing(3)
              // marginTop: idx > 0 ? theme.spacing(3) : 0
              // margin: theme.spacing(3, 2, 3, 10)
              // marginBottom: idx > 0 ? theme.spacing(3) : theme.spacing(3)
            }}
          >
            {capitalize(category)}
          </Typography>
        ) : null}

        <Grid container spacing={2}>
          {widgetData
            .filter((val) => {
              return val.category === category;
            })
            .map((widget) => {
              const positionSelected = index(widget.id);
              return (
                <Grid item lg={4} key={widget.id}>
                  <FormControlLabel
                    sx={{ marginLeft: 0, width: '100%' }}
                    control={
                      <Checkbox
                        onChange={(e) => {
                          handleClick(widget);
                        }}
                        value={widget.id}
                        sx={{
                          opacity: 0,
                          width: 0,
                          height: 0,
                          position: 'absolute',
                          left: -999,
                          top: 999
                        }}
                      />
                    }
                    label={
                      <SelectorWidget
                        selected={positionSelected === -1 ? false : true}
                      >
                        <Stack direction="column">
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <AvatarCustom
                              selected={positionSelected === -1 ? false : true}
                            >
                              {positionSelected !== -1 && positionSelected + 1}
                            </AvatarCustom>
                          </Box>
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'center',
                              mt: theme.spacing(0.7)
                            }}
                          >
                            <Box sx={{ marginRight: theme.spacing(2) }}>
                              {getCustomDashboardIcon(widget.visualization)}
                            </Box>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                fontWeight: 600,
                                color: '#051C26'
                              }}
                            >
                              {widget.displayName}
                            </Typography>
                          </Box>
                        </Stack>
                      </SelectorWidget>
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
      </Stack>
    );
  };

  return (
    <>
      {isShowBtn ? (
        <Button
          variant="contained"
          sx={{
            borderRadius: '6px',
            background: theme.palette.primary.dark
          }}
          size="small"
          onClick={handleClickOpen}
          endIcon={<GridViewIcon />}
          disabled={dashboardSelectedById === '_default'}
        >
          Manage Widget
        </Button>
      ) : null}

      <Dialog
        onClose={isShowBtn ? handleClose : handleClickBtn}
        open={isShowBtn ? open : isModalOpen}
        maxWidth={'lg'}
      >
        <IconButton
          aria-label="close"
          onClick={isShowBtn ? handleClose : handleClickBtn}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(3, 3, 0),
            p: theme.spacing(2, 3, 0, 3)
          }}
        >
          <Stack flexDirection={'row'} justifyContent="space-between">
            <Typography sx={{ fontWeight: 700, fontSize: '18px' }}>
              Select widget to add
            </Typography>
            <Typography>
              <span
                style={{
                  marginLeft: theme.spacing(1),
                  fontWeight: 700,
                  fontSize: '18px',
                  color: theme.palette.primary.main
                }}
              >
                {selectedLocal.length}
              </span>{' '}
              /{' '}
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '18px'
                }}
              >
                {widgetMetaDataList.data.length}
              </span>
            </Typography>
          </Stack>

          <Stack flexDirection={'row'} justifyContent="space-between">
            <Box marginTop={theme.spacing(2)} sx={{ width: '100%' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange}>
                    <Tab
                      label={
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 600,
                            color: '#051C26'
                          }}
                        >
                          All
                        </Typography>
                      }
                      value="all"
                    />
                    <Tab
                      label={
                        <Stack
                          direction={'row'}
                          alignItems="center"
                          spacing={theme.spacing(0.5)}
                        >
                          {getCustomDashboardIcon('text')}
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: 600,
                              color: '#051C26'
                            }}
                          >
                            Text
                          </Typography>
                        </Stack>
                      }
                      value="text"
                    />
                    <Tab
                      label={
                        <Stack
                          direction={'row'}
                          alignItems="center"
                          spacing={theme.spacing(0.5)}
                        >
                          {getCustomDashboardIcon('line_chart')}
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: 600,
                              color: '#051C26'
                            }}
                          >
                            Line
                          </Typography>
                        </Stack>
                      }
                      value="line_chart"
                    />
                    <Tab
                      label={
                        <Stack
                          direction={'row'}
                          alignItems="center"
                          spacing={theme.spacing(0.5)}
                        >
                          {getCustomDashboardIcon('bar_chart')}
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: 600,
                              color: '#051C26'
                            }}
                          >
                            Bar
                          </Typography>
                        </Stack>
                      }
                      value="bar_chart"
                    />
                    <Tab
                      label={
                        <Stack
                          direction={'row'}
                          alignItems="center"
                          spacing={theme.spacing(0.5)}
                        >
                          {getCustomDashboardIcon('bubble')}
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: 600,
                              color: '#051C26'
                            }}
                          >
                            Heat Map
                          </Typography>
                        </Stack>
                      }
                      value="bubble"
                    />
                    <Tab
                      label={
                        <Stack
                          direction={'row'}
                          alignItems="center"
                          spacing={theme.spacing(0.5)}
                        >
                          {getCustomDashboardIcon('table')}
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: 600,
                              color: '#051C26'
                            }}
                          >
                            Table
                          </Typography>
                        </Stack>
                      }
                      value="table"
                    />
                    <Tab
                      label={
                        <Stack
                          direction={'row'}
                          alignItems="center"
                          spacing={theme.spacing(0.5)}
                        >
                          {getCustomDashboardIcon('pie')}
                          <Typography
                            variant="subtitle2"
                            sx={{
                              fontWeight: 600,
                              color: '#051C26'
                            }}
                          >
                            Pie
                          </Typography>
                        </Stack>
                      }
                      value="pie"
                    />
                  </TabList>
                </Box>
              </TabContext>
            </Box>
            <Stack
              flexDirection="row"
              alignItems="center"
              marginRight={theme.spacing(25)}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 600,
                  color: '#4B4B4B'
                }}
              >
                Selected
              </Typography>
              <LightTooltip title="These selected metrics will appear in order of placement from top left to bottom right">
                <Stack paddingLeft={1}>
                  <InfoOutlinedIcon sx={{ fontSize: 13, color: '#9D9D9C' }} />
                </Stack>
              </LightTooltip>
            </Stack>
          </Stack>
        </DialogTitle>
        <Stack flexDirection={'row'} sx={{ width: '1186px' }}>
          <DialogContent
            sx={{
              m: theme.spacing(2, 1, 2, 4),
              paddingTop: '0',
              background: '#F5F5F5',
              // background: 'yellow',
              maxHeight: theme.spacing(38.5),
              width: '780px'
            }}
          >
            {/* <Stack sx={{ marginTop: 2 }}> */}
            {categoryList.map((val, index) => {
              return widgetListView(val, index);
            })}
            {/* </Stack> */}
          </DialogContent>
          <DialogContent
            sx={{
              m: theme.spacing(2, 4, 2, 0),
              background: '#F5F5F5',
              maxHeight: theme.spacing(38.5),
              width: '247px'
            }}
          >
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="Widget">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {selectedLocal.map((local, index) => {
                      return (
                        <Draggable
                          key={local.id}
                          draggableId={local.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <>
                                <Stack
                                  direction="row"
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                  spacing={2}
                                  sx={{
                                    width: '229px',
                                    border: '1px solid #CFCFCF',
                                    borderRadius: '4px',
                                    height: '30px',
                                    // background: 'white',
                                    background: snapshot.isDragging
                                      ? 'linear-gradient(90deg, #DDF5FF 0%, #C1FEFA 50.65%, #C1ECFE 100%)'
                                      : 'white',
                                    marginBottom: 1
                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    alignItems={'center'}
                                    spacing={1}
                                  >
                                    <DragIndicatorIcon fontSize="small" />
                                    {getCustomDashboardIcon(
                                      local.visualization
                                    )}
                                    <Typography
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: 12
                                      }}
                                    >
                                      {local.displayName}
                                    </Typography>
                                  </Stack>

                                  <Stack
                                    justifyContent="center"
                                    alignItems="center"
                                    marginRight={1}
                                  >
                                    <IconButton
                                      size="small"
                                      aria-label="close"
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.grey[500]
                                      }}
                                      onClick={() =>
                                        handleRemoveSelected(local.id)
                                      }
                                    >
                                      <CloseIcon style={{ fontSize: 14 }} />
                                    </IconButton>
                                  </Stack>
                                </Stack>
                              </>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </DialogContent>
        </Stack>
        <DialogActions>
          <Button
            size="large"
            variant="contained"
            sx={{ width: theme.spacing(20) }}
            onClick={handleSubmit}
            disabled={resManageWidgetCustomDashboard.loading}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ModalSelectWidget;
