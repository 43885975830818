import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
  capitalize
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/app/hooks';
import Activity from './component/Activity';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import { useMemo, useState } from 'react';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import InfiniteScroll from 'react-infinite-scroll-component';
import Empty from 'src/components/Empty';
import { useUnlimitedScroll } from 'src/hooks/useUnlimitedScroll';
import { IProject } from 'src/models/users';
import { useFiltersByName } from 'src/hooks/useFiltersByName';
import { SOCIAL_MEDIA_OVERVIEW } from 'src/route';
import { usePostHog } from 'posthog-js/react';
import { IPostHogUserProps } from 'src/models/general';

interface Item {
  name: string;
}

const CustomBox = styled(Box)(
  ({ theme }) => `
    max-width: 100vw;
    padding: ${theme.spacing(2, 4)};
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 6;
    overflow: hidden;
    background: ${theme.header.background}
`
);

function TopHeader() {
  const theme = useTheme();
  // const posthog = usePostHog();
  const project = useAppSelector((state) => state.users.project);
  const projectList = useAppSelector(
    (state) => state.users.userProfile.data.projectList
  );

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const addName = useMemo((): Array<IProject & Item> => {
    return projectList.map((object) => ({
      ...object,
      name: object.displayName
    }));
  }, [projectList]);

  const { filteredList, handleFilter } = useFiltersByName<IProject>(addName);
  const { fetchMoreData, infiniteScroll } =
    useUnlimitedScroll<IProject>(filteredList);

  const handleChangeProject = (projectNew: IProject) => {
    window.localStorage.setItem('project', projectNew.id);
    window.location.assign(SOCIAL_MEDIA_OVERVIEW);
    // posthog.people.set({
    //   projectId: projectNew.id,
    //   projectName: projectNew.displayName
    // });
  };

  return (
    <CustomBox>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="h3">{capitalize(project.name)}</Typography>
        {projectList.length > 1 && (
          <Button
            onClick={handleClickOpen}
            size="small"
            variant="outlined"
            sx={{
              height: theme.spacing(3.2),
              ml: theme.spacing(2),
              fontWeight: 600,
              background: '#FBFBFB',
              border: '1px solid rgba(5, 28, 38, 0.08)'
            }}
            endIcon={<AutorenewIcon />}
          >
            Switch
          </Button>
        )}
      </Stack>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth={'sm'}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          sx={{
            m: theme.spacing(5, 3, 0),
            p: theme.spacing(2, 2, 0)
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: 20,
              textAlign: 'left'
            }}
          >
            Search Your Project
          </Typography>
        </DialogTitle>
        <DialogContent
          sx={{
            m: theme.spacing(1, 3)
          }}
        >
          <form>
            <FormControl fullWidth size="medium">
              <TextField
                sx={{ mt: 2 }}
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon
                        sx={{ color: theme.palette.primary.main }}
                      />
                    </InputAdornment>
                  )
                }}
                placeholder="Search project"
                onChange={(e) => handleFilter(e.target.value)}
              />
            </FormControl>
          </form>
          <Stack>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 13,
                textAlign: 'left',
                color: theme.palette.primary.dark,
                m: theme.spacing(4, 0, 1, 0)
              }}
            >
              All Project
            </Typography>
            <InfiniteScroll
              dataLength={infiniteScroll.list.length}
              next={fetchMoreData}
              hasMore={infiniteScroll.hasMore}
              loader={<div />}
              height={288}
              endMessage={<div />}
            >
              {infiniteScroll.list.length > 0 ? (
                infiniteScroll.list.map((row) => {
                  const isActive = row.id === project.id;
                  return (
                    <div key={row.id}>
                      {isActive ? (
                        <Button
                          disabled
                          style={{
                            background: '#C1ECFE'
                          }}
                          sx={{
                            borderRadius: 1,
                            margin: theme.spacing(0, 0, 1.5, 1)
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              fontSize: 14,
                              textAlign: 'left',
                              color: theme.palette.primary.main
                            }}
                          >
                            {capitalize(row.displayName)}
                          </Typography>
                        </Button>
                      ) : (
                        <Button
                          sx={{
                            borderRadius: 1,
                            margin: theme.spacing(0, 0, 1.5, 1)
                          }}
                        >
                          <Typography
                            onClick={() => handleChangeProject(row)}
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              fontSize: 14,
                              textAlign: 'left',
                              color: theme.palette.primary.main,
                              cursor: 'pointer'
                            }}
                          >
                            {capitalize(row.displayName)}
                          </Typography>
                        </Button>
                      )}
                    </div>
                  );
                })
              ) : (
                <Stack
                  sx={{
                    height: 'calc(80vh - 400px)',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <Empty />
                </Stack>
              )}
            </InfiniteScroll>
          </Stack>
        </DialogContent>
      </Dialog>
      <Activity />
    </CustomBox>
  );
}

export default TopHeader;
