/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'src/app/hooks';
import { ILabel } from 'src/models/general';
import SearchIcon from '@mui/icons-material/Search';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import { useFiltersByName } from 'src/hooks/useFiltersByName';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CloseIcon from '@mui/icons-material/Close';
import { useUnlimitedScroll } from 'src/hooks/useUnlimitedScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalNewLabel from '../ModalNewLabel';

interface ModalSelectLabelProps {
  onClose: (event: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  open: boolean;
  prevSelectedLabels?: Array<ILabel>;
  onSubmit: (labelsSelected: Array<ILabel>) => void;
  title?: string;
}

const TableCellHeader = styled(TableCell)(
  ({ theme }) => `
        z-index: 3;
        background: ${theme.palette.primary.main};
        color: ${theme.palette.common.white};
    `
);

const ModalSelectLabel: React.FC<ModalSelectLabelProps> = ({
  open,
  onClose,
  onSubmit,
  prevSelectedLabels = [],
  title = 'Select Label'
}) => {
  const labels = useAppSelector((state) => state.storeLabel.labelList.data);
  const [selectedLabelsLocal, setSelectedLabelsLocal] =
    useState<Array<ILabel>>(prevSelectedLabels);
  const theme = useTheme();

  const isSelected = (id: string) =>
    selectedLabelsLocal.findIndex((obj) => obj.id === id) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedLabelsLocal(labels);
      return;
    }
    setSelectedLabelsLocal([]);
  };

  const handleClickLabel = function (label: ILabel) {
    const selectedIndex = selectedLabelsLocal.findIndex(
      (obj) => obj.id === label.id
    );
    let newSelected: ILabel[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedLabelsLocal, label);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedLabelsLocal.slice(1));
    } else if (selectedIndex === selectedLabelsLocal.length - 1) {
      newSelected = newSelected.concat(selectedLabelsLocal.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedLabelsLocal.slice(0, selectedIndex),
        selectedLabelsLocal.slice(selectedIndex + 1)
      );
    }
    setSelectedLabelsLocal(newSelected);
  };

  const { start, end } = useAppSelector(
    (state) => state.analyticArguments.dateRangeFormatted
  );
  const [selectTimeFrame, setSelectTimeFrame] = useState<boolean>(false);
  const filterByDate = useMemo((): Array<ILabel> => {
    if (!selectTimeFrame) return labels;
    return labels.filter(
      (row) => new Date(row.createdAt) >= new Date(start) && new Date(end)
    );
  }, [labels, selectTimeFrame]);

  const { filteredList, handleFilter } = useFiltersByName<ILabel>(filterByDate);
  const { fetchMoreData, infiniteScroll } =
    useUnlimitedScroll<ILabel>(filteredList);

  useEffect(() => {
    if (!prevSelectedLabels) return;
    setSelectedLabelsLocal(prevSelectedLabels);
  }, [prevSelectedLabels]);

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth={'md'}>
      <DialogTitle
        sx={{
          m: theme.spacing(3, 3, 1)
        }}
      >
        <IconButton
          aria-label="close"
          onClick={(e) => onClose(e)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h3" sx={{ textAlign: 'left' }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          m: theme.spacing(1, 3)
        }}
      >
        <Box display="flex" justifyContent="flex-end" sx={{ width: '100%' }}>
          <ModalNewLabel
            variantButton="contained"
            sxButton={{
              borderRadius: '6px',
              minWidth: theme.spacing(15),
              height: 36,
              background: theme.palette.primary.dark
            }}
            titleButton="Add Label"
            dataList={labels}
            isHidden={false}
          />
        </Box>
        <Stack direction="row" spacing={2} sx={{ mb: theme.spacing(1) }}>
          <Box sx={{ minWidth: theme.spacing(25) }}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<FilterNoneIcon />}
                  checkedIcon={<LibraryAddCheckIcon />}
                />
              }
              label="Show by timeframe"
              onChange={() => setSelectTimeFrame((click) => !click)}
            />
          </Box>
          <TextField
            fullWidth
            id="standard-basic"
            placeholder="Search Label"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            sx={{
              mb: theme.spacing(3)
            }}
            onChange={(e) => handleFilter(e.target.value)}
          />
        </Stack>

        <InfiniteScroll
          dataLength={infiniteScroll.list.length}
          next={fetchMoreData}
          hasMore={infiniteScroll.hasMore}
          loader={<div />}
          height={300}
          endMessage={<div />}
        >
          <TableContainer component={Paper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCellHeader padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedLabelsLocal.length > 0 &&
                        selectedLabelsLocal.length < labels.length
                      }
                      size="small"
                      sx={{ ml: theme.spacing(0.2) }}
                      icon={
                        <FilterNoneIcon
                          sx={{
                            color: theme.palette.common.white,
                            width: theme.spacing(2.3),
                            height: theme.spacing(2.3)
                          }}
                        />
                      }
                      checkedIcon={
                        <LibraryAddCheckIcon
                          sx={{
                            color: theme.palette.common.white,
                            width: theme.spacing(2.3),
                            height: theme.spacing(2.3)
                          }}
                        />
                      }
                      indeterminateIcon={
                        <IndeterminateCheckBoxIcon
                          sx={{
                            color: theme.palette.common.white,
                            width: theme.spacing(2.2),
                            height: theme.spacing(2.2)
                          }}
                        />
                      }
                      onChange={handleSelectAllClick}
                    />
                  </TableCellHeader>
                  <TableCellHeader align="left" size="small">
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: 13, color: theme.palette.common.white }}
                    >
                      Label list
                    </Typography>
                  </TableCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {infiniteScroll.list.length > 0 ? (
                  infiniteScroll.list.map((label) => {
                    const isChecked = isSelected(label.id);
                    return (
                      <TableRow key={label.id}>
                        <TableCell align="center" size="small">
                          <FormControlLabel
                            sx={{ marginLeft: 0 }}
                            control={
                              <Checkbox
                                size="small"
                                checked={isChecked}
                                onChange={(e) => {
                                  handleClickLabel(label);
                                }}
                                name="labels"
                                color="primary"
                                value={label.id}
                              />
                            }
                            label=""
                          />
                        </TableCell>
                        <TableCell align="left" size="small">
                          {label.displayName}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow hover={false}>
                    <TableCell align="center" colSpan={2}>
                      <Box
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center'
                        }}
                      >
                        <Avatar>N/A</Avatar>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            size="large"
            onClick={() => onSubmit(selectedLabelsLocal)}
            sx={{ width: theme.spacing(20), mt: theme.spacing(2) }}
          >
            Done
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSelectLabel;
