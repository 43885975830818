import gql from "graphql-tag";

export const UPGRADE_PLAN = gql`
  mutation billing_upgradePlan(
    $projectId: Id!
    $userPlanId: Id!
    $advanceObject: Int!
    $basicObject: Int!
    $period: Int!
    $isApply:Boolean!
    $removedObject: [String!]!
  ) {
    billing_upgradePlan(
      projectId: $projectId
      userPlanId: $userPlanId
      advanceObject: $advanceObject
      basicObject: $basicObject
      period: $period
      isApply:$isApply
      removedObject:$removedObject
    ) {
      id
      invoiceDate
      dueDate
      invoiceNumber
      status
      periodStart
      periodEnd
      plan {
        account
        currency
        keyword
        period
        promoCode
      },
      planCalculation {
        discount
        extensionFees
        isPromoValid
        isUsedPromo
        promoCode
        subTotal
        total
        totalNotes
        upgradeFees
      },
      project {
        companyName
        name
        userEmail
        userName
      },
      payments {
        groupName
        groupCode
        groupIcon
        channels {
          id
          name
          logo
        }
      }
    }
  }
`;

export const BILLING_INVOICE_CHECKOUT = gql`
  mutation billing_invoiceCheckout(
    $invoiceId: String!
    $paymentGroupCode: String!
    $paymentGroupCodeChannelId: String!
  ) {
    billing_invoiceCheckout(
      invoiceId: $invoiceId
      paymentGroupCode: $paymentGroupCode
      paymentGroupCodeChannelId: $paymentGroupCodeChannelId
    ) {
      url
    }
  }
`;

export const USER_REMOVE_SELECTED_OBJECTS = gql`
  mutation user_removeSelectedObject($projectId: Id!, $objectList: [Id!]!) {
    user_removeSelectedObject(projectId: $projectId, objectList: $objectList) {
        objectName
        __typename
    }
}
`;

export const BILLING_INVOICE_CHECKOUT_PLAN_STREAM = gql`
  mutation billingAppSumo_invoiceCheckoutPlanStream(
    $projectId: Id!
    $packagePlanId: Id!
    $promoCode: String
  ) {
    billingAppSumo_invoiceCheckoutPlanStream(
        projectId: $projectId
        packagePlanId: $packagePlanId
        promoCode: $promoCode
    ) {
        name
        packagePlanId
        quota
        total
        subTotal
        discount
        message
        currency
        symbolBySelectedCountry
        __typename
    }
  }
`;

export const BILLING_APPSUMO_INVOICE_CHECKOUT_PLAN_STREAM = gql`
mutation billingAppSumo_invoiceCheckoutPlanStream(
  $projectId: Id!
  $packagePlanId: Id!
  $promoCode: String
) {
  billingAppSumo_invoiceCheckoutPlanStream(
      projectId: $projectId
      packagePlanId: $packagePlanId
      promoCode: $promoCode
  ) {
  url
      __typename
  }
}
`;

export const BILLING_APPLY_PLAN = gql`
mutation billing_applyPlan(
  $projectId: Id!
) {
  billing_applyPlan(
    projectId: $projectId
  ) {
  id
    invoiceNumber
    invoiceDate
    dueDate
    status
    payments{
      channels{
        id
        name
        logo
      }
      groupName
      groupCode
      groupIcon
    }
    project{
      name
      companyName
      userName
      userEmail
    }
    plan{
      account
      keyword
      period
      currency
      promoCode
    }
    planCalculation{
      subTotal
      discount
      total
      totalNotes
      isUsedPromo
      isPromoValid
      promoCode
      upgradeFees
      extensionFees
      registrasionFees
    }
}
}`;

