import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommunityNumericOutputWithGrowth } from 'src/models/community';
import {
  IDataWorldCloud,
  INumericOutputWithGrowth,
  IUserEngagement,
  IValuePieChart
} from 'src/models/general';

const initNumericalOutput: INumericOutputWithGrowth = {
  contentType: '',
  growth: null,
  pastValue: null,
  recentValue: null
};

const initNumericals: ICommunityNumericOutputWithGrowth = {
  follower: initNumericalOutput,
  talker: initNumericalOutput,
  reply: initNumericalOutput,
  responseRate: initNumericalOutput
};

const initWordCloud: IDataWorldCloud = {
  data: [],
  min: 0,
  max: 1
};

export interface ICommunity {
  numericals: ApolloQueryResult<ICommunityNumericOutputWithGrowth>;
  overalPeformance: ApolloQueryResult<any>;
  topTalker: ApolloQueryResult<Array<IUserEngagement>>;
  topInfluencer: ApolloQueryResult<Array<IUserEngagement>>;
  topEngagement: ApolloQueryResult<Array<IUserEngagement>>;
  engagedUserByGender: ApolloQueryResult<Array<IValuePieChart>>;
  engagedUserByLocation: ApolloQueryResult<Array<IValuePieChart>>;
  engagedUserByInterest: ApolloQueryResult<Array<IValuePieChart>>;
  wordCloudByBio: ApolloQueryResult<IDataWorldCloud>;
  wordCloudByTalk: ApolloQueryResult<IDataWorldCloud>;
}

const initialState: ICommunity = {
  numericals: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initNumericals
  },
  overalPeformance: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  topTalker: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  topInfluencer: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  topEngagement: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  engagedUserByInterest: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  engagedUserByGender: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  engagedUserByLocation: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  wordCloudByBio: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initWordCloud
  },
  wordCloudByTalk: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initWordCloud
  }
};

export const sosmedCommunity = createSlice({
  name: 'sosmedCommunity',
  initialState,
  reducers: {
    reducerUpdateCommunityNumericals: (
      state: ICommunity,
      action: PayloadAction<
        ApolloQueryResult<ICommunityNumericOutputWithGrowth>
      >
    ) => {
      state.numericals = action.payload;
    },
    reducerUpdateCommunityOveralPeformance: (
      state: ICommunity,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.overalPeformance = action.payload;
    },
    reducerUpdateCommunityOveralPeformanceLoading: (
      state: ICommunity,
      action: PayloadAction<boolean>
    ) => {
      state.overalPeformance.loading = action.payload;
    },
    reducerUpdateCommunityTopTalker: (
      state: ICommunity,
      action: PayloadAction<ApolloQueryResult<Array<IUserEngagement>>>
    ) => {
      state.topTalker = action.payload;
    },
    reducerUpdateCommunityTopInfluencer: (
      state: ICommunity,
      action: PayloadAction<ApolloQueryResult<Array<IUserEngagement>>>
    ) => {
      state.topInfluencer = action.payload;
    },
    reducerUpdateCommunityTopEngagement: (
      state: ICommunity,
      action: PayloadAction<ApolloQueryResult<Array<IUserEngagement>>>
    ) => {
      state.topEngagement = action.payload;
    },
    reducerUpdateCommunityEngagedUserByInterest: (
      state: ICommunity,
      action: PayloadAction<ApolloQueryResult<Array<IValuePieChart>>>
    ) => {
      state.engagedUserByInterest = action.payload;
    },
    reducerUpdateCommunityEngagedUserByGender: (
      state: ICommunity,
      action: PayloadAction<ApolloQueryResult<Array<IValuePieChart>>>
    ) => {
      state.engagedUserByGender = action.payload;
    },
    reducerUpdateCommunityEngagedUserByLocation: (
      state: ICommunity,
      action: PayloadAction<ApolloQueryResult<Array<IValuePieChart>>>
    ) => {
      state.engagedUserByLocation = action.payload;
    },
    reducerUpdateCommunityWordCloudByBio: (
      state: ICommunity,
      action: PayloadAction<ApolloQueryResult<IDataWorldCloud>>
    ) => {
      state.wordCloudByBio = action.payload;
    },
    reducerUpdateCommunityWordCloudByTalk: (
      state: ICommunity,
      action: PayloadAction<ApolloQueryResult<IDataWorldCloud>>
    ) => {
      state.wordCloudByTalk = action.payload;
    },
    reducerUpdateCommunityAllMetricLoading: (
      state: ICommunity,
      action: PayloadAction<boolean>
    ) => {
      state.engagedUserByGender.loading = action.payload;
      state.engagedUserByInterest.loading = action.payload;
      state.engagedUserByLocation.loading = action.payload;
      state.numericals.loading = action.payload;
      state.overalPeformance.loading = action.payload;
      state.topInfluencer.loading = action.payload;
      state.topTalker.loading = action.payload;
      state.wordCloudByBio.loading = action.payload;
      state.wordCloudByTalk.loading = action.payload;
      state.topEngagement.loading = action.payload;
    },
    reducerUpdateCommunityAllMetricLoadingPerDay: (
      state: ICommunity,
      action: PayloadAction<boolean>
    ) => {
      state.engagedUserByGender.loading = action.payload;
      state.engagedUserByInterest.loading = action.payload;
      state.engagedUserByLocation.loading = action.payload;
      state.topInfluencer.loading = action.payload;
      state.topTalker.loading = action.payload;
      state.wordCloudByBio.loading = action.payload;
      state.wordCloudByTalk.loading = action.payload;
      state.topEngagement.loading = action.payload;
    }
  }
});

export const {
  reducerUpdateCommunityAllMetricLoading,
  reducerUpdateCommunityNumericals,
  reducerUpdateCommunityEngagedUserByGender,
  reducerUpdateCommunityEngagedUserByInterest,
  reducerUpdateCommunityEngagedUserByLocation,
  reducerUpdateCommunityOveralPeformance,
  reducerUpdateCommunityTopInfluencer,
  reducerUpdateCommunityTopTalker,
  reducerUpdateCommunityTopEngagement,
  reducerUpdateCommunityWordCloudByBio,
  reducerUpdateCommunityWordCloudByTalk,
  reducerUpdateCommunityAllMetricLoadingPerDay,
  reducerUpdateCommunityOveralPeformanceLoading
} = sosmedCommunity.actions;

export default sosmedCommunity.reducer;
