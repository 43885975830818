import React from 'react';
import { Grid, Typography } from '@mui/material';
import moment from 'moment';
import useStyles from './styles';

interface Props {
  detail: any;
}
const InformationDetailFirstTimeSocialMediaData: React.FC<Props> = ({
  detail
}) => {
  const { details } = detail;
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography variant="h5">Get Data For: </Typography>
      </Grid>
      {details.map((detail) => (
        <div className={classes.border}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="subtitle2">{`Social Media : ${detail.socialMedia}`}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="subtitle2">{`Stream Type : ${detail.streamType}`}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="subtitle2">{`Name : ${detail.objectName}`}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="subtitle2">{`Content : ${detail.socialMedia}`}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant="subtitle2">{`Created At : ${moment(
              detail.createdAt
            ).format('DD MMM YYYY')}`}</Typography>
          </Grid>
        </div>
      ))}
    </Grid>
  );
};

export default InformationDetailFirstTimeSocialMediaData;
