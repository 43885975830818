import { useApolloClient } from '@apollo/react-hooks';
import { useAppDispatch } from 'src/app/hooks';
import uuidNameSpace from 'uuid/v5';
import getNS from 'uuid/v4';

import {
  reducerUpdateCommunityAllMetricLoading,
  reducerUpdateCommunityEngagedUserByGender,
  reducerUpdateCommunityEngagedUserByInterest,
  reducerUpdateCommunityEngagedUserByLocation,
  reducerUpdateCommunityNumericals,
  reducerUpdateCommunityOveralPeformance,
  reducerUpdateCommunityTopInfluencer,
  reducerUpdateCommunityTopTalker,
  reducerUpdateCommunityTopEngagement,
  reducerUpdateCommunityWordCloudByBio,
  reducerUpdateCommunityWordCloudByTalk,
  reducerUpdateCommunityAllMetricLoadingPerDay,
  reducerUpdateCommunityOveralPeformanceLoading
} from 'src/features/sosmedCommunity';
import { ICommunityNumericOutputWithGrowth } from 'src/models/community';
import {
  IAdvanceSearchSocialMedia,
  IChronologicalOutput,
  INumericOutputWithGrowth,
  IUserEngagement,
  IValuePieChart,
  IWordCloud
} from 'src/models/general';
import { buildDataLineGroup } from 'src/utils/buildDataLineGroup';
import {
  GET_COMMUNITY_BEST_ENGAGED_USER,
  GET_COMMUNITY_FOLLOWERS_BY_GENDER,
  GET_COMMUNITY_FOLLOWERS_BY_INTEREST,
  GET_COMMUNITY_FOLLOWERS_BY_LOCATION,
  GET_COMMUNITY_GROUP_CHRONOLOGICAL_COMMUNITY,
  GET_COMMUNITY_GROUP_TOTAL,
  GET_COMMUNITY_POPULAR_ENGAGED_USER,
  GET_COMMUNITY_WORD_CLOUD_BIO,
  GET_COMMUNITY_WORD_CLOUD_TALK,
  GET_COMMUNITY_TOP_ENGAGEMENT
} from './graphql/community/query';
import { useRef } from 'react';

const RequestNameSpace = getNS();

interface INumericOutputWithGrowthResponse {
  community_getGroupedTotal: Array<INumericOutputWithGrowth>;
}

interface IChronologicalOutputResponse {
  community_getGroupedAudienceGrowth: Array<IChronologicalOutput>;
}

interface IWordCloudBioResponse {
  community_getWordCloudBio: Array<IWordCloud>;
}

interface IWordCloudTalkResponse {
  community_getWordCloudTalk: Array<IWordCloud>;
}

interface IFollowerLocationResponse {
  community_getFollowerLocation: Array<IValuePieChart>;
}

interface IFollowerGenderResponse {
  community_getFollowerGender: Array<IValuePieChart>;
}

interface IFollowerInterestResponse {
  community_getFollowerInterest: Array<IValuePieChart>;
}

interface ITopTalkerResponse {
  community_getBestEngagedUser: Array<IUserEngagement>;
}

interface ITopInfluencerResponse {
  community_getPopularEngagedUser: Array<IUserEngagement>;
}

interface ITopEngagementResponse {
  community_getTopEngagement: Array<IUserEngagement>;
}

const initNumericalOutput: INumericOutputWithGrowth = {
  contentType: '',
  growth: null,
  pastValue: null,
  recentValue: null
};

const initNumericals: ICommunityNumericOutputWithGrowth = {
  follower: initNumericalOutput,
  talker: initNumericalOutput,
  reply: initNumericalOutput,
  responseRate: initNumericalOutput
};

const useCommunity = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const lastPromiseNumericals: any = useRef();
  const lastPromiseChronological: any = useRef();
  const lastPromiseWordCloudByBio: any = useRef();
  const lastPromiseWordCloudByTalk: any = useRef();
  const lastPromiseEngageByLocation: any = useRef();
  const lastPromiseEngageByGender: any = useRef();
  const lastPromiseEngageByInterest: any = useRef();
  const lastPromiseTopInfluencer: any = useRef();
  const lastPromiseTopEngagement: any = useRef();
  const lastPromiseTopTalker: any = useRef();

  const fetchNumericals = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<INumericOutputWithGrowthResponse>({
      query: GET_COMMUNITY_GROUP_TOTAL,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_COMMUNITY_GROUP_TOTAL',
          RequestNameSpace
        )
      }
    });
    const dataGroupNumerical = response.data.community_getGroupedTotal;
    let mappingNumerical: ICommunityNumericOutputWithGrowth = initNumericals;
    dataGroupNumerical.forEach((numerical) => {
      mappingNumerical = {
        ...mappingNumerical,
        [numerical.contentType]: numerical
      };
    });
    return { ...response, data: mappingNumerical };
  };

  const fetchChronologicalCommunity = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IChronologicalOutputResponse>({
      query: GET_COMMUNITY_GROUP_CHRONOLOGICAL_COMMUNITY,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_COMMUNITY_GROUP_CHRONOLOGICAL_COMMUNITY',
          RequestNameSpace
        )
      }
    });
    return {
      ...response,
      data: response.data.community_getGroupedAudienceGrowth
    };
  };

  const fetchWordCloudByBio = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IWordCloudBioResponse>({
      query: GET_COMMUNITY_WORD_CLOUD_BIO,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_COMMUNITY_WORD_CLOUD_BIO',
          RequestNameSpace
        )
      }
    });
    const wordClouds = response.data.community_getWordCloudBio.map((word) => ({
      text: word.name,
      value: word.value
    }));
    const data =
      wordClouds.length > 0 ? wordClouds.map((word) => word.value) : [];
    const max = data.length > 0 ? Math.max(...data) : 0;
    const min = data.length > 0 ? Math.min(...data) : 1;
    return { ...response, data: { data: wordClouds, max, min } };
  };

  const fetchWordCloudByTalk = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IWordCloudTalkResponse>({
      query: GET_COMMUNITY_WORD_CLOUD_TALK,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_COMMUNITY_WORD_CLOUD_TALK',
          RequestNameSpace
        )
      }
    });
    const wordClouds = response.data.community_getWordCloudTalk.map((word) => ({
      text: word.name,
      value: word.value
    }));
    const data =
      wordClouds.length > 0 ? wordClouds.map((word) => word.value) : [];
    const max = data.length > 0 ? Math.max(...data) : 0;
    const min = data.length > 0 ? Math.min(...data) : 1;
    return { ...response, data: { data: wordClouds, max, min } };
  };

  const fetchEngagedByLocations = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IFollowerLocationResponse>({
      query: GET_COMMUNITY_FOLLOWERS_BY_LOCATION,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_COMMUNITY_FOLLOWERS_BY_LOCATION',
          RequestNameSpace
        )
      }
    });
    return { ...response, data: response.data.community_getFollowerLocation };
  };

  const fetchEngagedByGender = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IFollowerGenderResponse>({
      query: GET_COMMUNITY_FOLLOWERS_BY_GENDER,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_COMMUNITY_FOLLOWERS_BY_GENDER',
          RequestNameSpace
        )
      }
    });
    return { ...response, data: response.data.community_getFollowerGender };
  };

  const fetchEngagedByInterest = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<IFollowerInterestResponse>({
      query: GET_COMMUNITY_FOLLOWERS_BY_INTEREST,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_COMMUNITY_FOLLOWERS_BY_INTEREST',
          RequestNameSpace
        )
      }
    });
    return { ...response, data: response.data.community_getFollowerInterest };
  };

  const fetchTopTalker = async function (parameter: IAdvanceSearchSocialMedia) {
    const response = await client.query<ITopTalkerResponse>({
      query: GET_COMMUNITY_BEST_ENGAGED_USER,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_COMMUNITY_BEST_ENGAGED_USER',
          RequestNameSpace
        )
      }
    });
    return { ...response, data: response.data.community_getBestEngagedUser };
  };

  const fetchTopInfluencer = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<ITopInfluencerResponse>({
      query: GET_COMMUNITY_POPULAR_ENGAGED_USER,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_COMMUNITY_POPULAR_ENGAGED_USER',
          RequestNameSpace
        )
      }
    });
    return { ...response, data: response.data.community_getPopularEngagedUser };
  };

  const fetchTopEngagement = async function (
    parameter: IAdvanceSearchSocialMedia
  ) {
    const response = await client.query<ITopEngagementResponse>({
      query: GET_COMMUNITY_TOP_ENGAGEMENT,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_COMMUNITY_TOP_ENGAGEMENT',
          RequestNameSpace
        )
      }
    });
    return { ...response, data: response.data.community_getTopEngagement };
  };

  const getNumericals = (parameter: IAdvanceSearchSocialMedia) => {
    const response = fetchNumericals(parameter);
    lastPromiseNumericals.current = response;
    if (response === lastPromiseNumericals.current) {
      response.then((response) => {
        dispatch(reducerUpdateCommunityNumericals(response));
      });
    }
  };

  const getChronological = (parameter: IAdvanceSearchSocialMedia) => {
    dispatch(reducerUpdateCommunityOveralPeformanceLoading(true));
    const response = fetchChronologicalCommunity(parameter);
    lastPromiseChronological.current = response;
    if (response === lastPromiseChronological.current) {
      response.then((response) => {
        dispatch(reducerUpdateCommunityOveralPeformance(response));
      });
    }
  };

  const getWordCloudByBio = (parameter: IAdvanceSearchSocialMedia) => {
    const response = fetchWordCloudByBio(parameter);
    lastPromiseWordCloudByBio.current = response;
    if (response === lastPromiseWordCloudByBio.current) {
      response.then((response) =>
        dispatch(reducerUpdateCommunityWordCloudByBio(response))
      );
    }
  };

  const getWordCloudByTalk = (parameter: IAdvanceSearchSocialMedia) => {
    const response = fetchWordCloudByTalk(parameter);
    lastPromiseWordCloudByTalk.current = response;
    if (response === lastPromiseWordCloudByTalk.current) {
      response.then((response) =>
        dispatch(reducerUpdateCommunityWordCloudByTalk(response))
      );
    }
  };

  const getEngagedByLocation = (parameter: IAdvanceSearchSocialMedia) => {
    const response = fetchEngagedByLocations(parameter);
    lastPromiseEngageByLocation.current = response;
    if (response === lastPromiseEngageByLocation.current) {
      response.then((response) =>
        dispatch(reducerUpdateCommunityEngagedUserByLocation(response))
      );
    }
  };

  const getEngagedByGender = (parameter: IAdvanceSearchSocialMedia) => {
    const response = fetchEngagedByGender(parameter);
    lastPromiseEngageByGender.current = response;
    if (response === lastPromiseEngageByGender.current) {
      response.then((response) =>
        dispatch(reducerUpdateCommunityEngagedUserByGender(response))
      );
    }
  };

  const getEngagedByInterest = (parameter: IAdvanceSearchSocialMedia) => {
    const response = fetchEngagedByInterest(parameter);
    lastPromiseEngageByInterest.current = response;
    if (response === lastPromiseEngageByInterest.current) {
      response.then((response) =>
        dispatch(reducerUpdateCommunityEngagedUserByInterest(response))
      );
    }
  };

  const getTopTalker = (parameter: IAdvanceSearchSocialMedia) => {
    const response = fetchTopTalker(parameter);
    lastPromiseTopTalker.current = response;
    if (response === lastPromiseTopTalker.current) {
      response.then((res) => dispatch(reducerUpdateCommunityTopTalker(res)));
    }
  };

  const getTopInfluencer = (parameter: IAdvanceSearchSocialMedia) => {
    const response = fetchTopInfluencer(parameter);
    lastPromiseTopInfluencer.current = response;
    if (response === lastPromiseTopInfluencer.current) {
      response.then((res) =>
        dispatch(reducerUpdateCommunityTopInfluencer(res))
      );
    }
  };

  const getTopEngagement = (parameter: IAdvanceSearchSocialMedia) => {
    const response = fetchTopEngagement(parameter);
    lastPromiseTopEngagement.current = response;
    if (response === lastPromiseTopEngagement.current) {
      response.then((res) =>
        dispatch(reducerUpdateCommunityTopEngagement(res))
      );
    }
  };

  const getCommunityAllMetric = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateCommunityAllMetricLoading(true));

    getNumericals(payload);
    getChronological(payload);
    getWordCloudByBio(payload);
    getWordCloudByTalk(payload);
    getEngagedByLocation(payload);
    getEngagedByGender(payload);
    getEngagedByInterest(payload);
    getTopTalker(payload);
    getTopEngagement(payload);
    getTopInfluencer(payload);
  };

  const getCommunityAllMetricPerDay = async function (
    payload: IAdvanceSearchSocialMedia
  ) {
    dispatch(reducerUpdateCommunityAllMetricLoadingPerDay(true));

    const responseWordCloudByBio = await fetchWordCloudByBio(payload);
    dispatch(reducerUpdateCommunityWordCloudByBio(responseWordCloudByBio));

    const responseWordCloudByTalk = await fetchWordCloudByTalk(payload);
    dispatch(reducerUpdateCommunityWordCloudByTalk(responseWordCloudByTalk));

    const responseEngagedByLocations = await fetchEngagedByLocations(payload);
    dispatch(
      reducerUpdateCommunityEngagedUserByLocation(responseEngagedByLocations)
    );

    const responseEngagedByGender = await fetchEngagedByGender(payload);
    dispatch(
      reducerUpdateCommunityEngagedUserByGender(responseEngagedByGender)
    );

    const responseEngagedByInterest = await fetchEngagedByInterest(payload);
    dispatch(
      reducerUpdateCommunityEngagedUserByInterest(responseEngagedByInterest)
    );

    const responseTopTalker = await fetchTopTalker(payload);
    dispatch(reducerUpdateCommunityTopTalker(responseTopTalker));

    const responseTopInfluencer = await fetchTopInfluencer(payload);
    dispatch(reducerUpdateCommunityTopInfluencer(responseTopInfluencer));

    const responseTopEngagement = await fetchTopEngagement(payload);
    dispatch(reducerUpdateCommunityTopEngagement(responseTopEngagement));
  };

  return {
    getCommunityAllMetric,
    getCommunityAllMetricPerDay,
    getChronological,
    getNumericals,
    getWordCloudByBio,
    getWordCloudByTalk,
    getEngagedByLocation,
    getEngagedByGender,
    getEngagedByInterest,
    getTopTalker,
    getTopEngagement,
    getTopInfluencer
  };
};

export default useCommunity;
