import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILanguage } from 'src/models/general';
import {
  IOfflineMedia,
  IOfflineMediaPagination
} from 'src/models/offlineMedia';

interface IStream {
  articleList: IOfflineMediaPagination;
  includeKeywords: Array<string>;
  streamSelected: Array<IOfflineMedia>;
  streamUpdatingSentiments: Array<IOfflineMedia>;
  streamUpdatingRemoves: Array<IOfflineMedia>;
  streamUpdatingApplyClipping: Array<IOfflineMedia>;
  streamUpdatingLabels: Array<IOfflineMedia>;
  languages: Array<ILanguage>;
  languageSelected: string;
  isSelectedAllPrimary: boolean;
  activeArticle?: IOfflineMedia;
  mediaList: string[];
}

const initialState: IStream = {
  articleList: {
    totalRows: 0,
    totalPages: 0,
    limit: 20,
    page: 1,
    result: []
  },
  includeKeywords: [],
  streamSelected: [],
  streamUpdatingSentiments: [],
  streamUpdatingLabels: [],
  streamUpdatingRemoves: [], // for loading delete article
  streamUpdatingApplyClipping: [], // for loading apply clipping
  languages: [],
  languageSelected: 'all-language',
  isSelectedAllPrimary: false,
  activeArticle: null,
  mediaList: []
};

export const offlineMediaStream = createSlice({
  name: 'offlineMediaStream',
  initialState,
  reducers: {
    reducerUpdateOfflineMediaIncludeKeywords: (
      state: IStream,
      action: PayloadAction<string>
    ) => {
      state.includeKeywords = [action.payload];
    },
    reducerSetOfflineMediaArticleList: (
      state: IStream,
      action: PayloadAction<IOfflineMediaPagination>
    ) => {
      state.articleList = action.payload;
    },
    reducerUpdateSelectedOfflineMedia: (
      state: IStream,
      action: PayloadAction<Array<IOfflineMedia>>
    ) => {
      state.streamSelected = action.payload;
    },
    reducerSentimentLoadingOfflineMedia: (
      state: IStream,
      action: PayloadAction<Array<IOfflineMedia>>
    ) => {
      state.streamUpdatingSentiments = action.payload;
    },
    reducerRemoveLoadingOfflineMedia: (
      state: IStream,
      action: PayloadAction<Array<IOfflineMedia>>
    ) => {
      state.streamUpdatingRemoves = action.payload;
    },
    reducerUpdateOfflineMediaArticles: (
      state: IStream,
      action: PayloadAction<Array<IOfflineMedia>>
    ) => {
      const updatedArticle = state.articleList.result.map((article) => {
        const articleUpdate = action.payload.find(
          (re) => re.originalId === article.originalId
        );
        if (articleUpdate) {
          return {
            ...article,
            sentiment: articleUpdate.sentiment,
            clippingList: articleUpdate.clippingList,
            labelArticleList: articleUpdate.labelArticleList,
            isEdited: true
          };
        }
        return article;
      });
      state.articleList.result = updatedArticle;
    },
    reducerUpdateRemoveOfflineMedia: (
      state: IStream,
      action: PayloadAction<Array<IOfflineMedia>>
    ) => {
      const updatedArticle = state.articleList.result.filter(
        (article) =>
          !action.payload
            .map((res) => res.originalId)
            .includes(article.originalId)
      );
      state.articleList.result = updatedArticle;
    },
    reducerUpdateLanguages: (
      state: IStream,
      action: PayloadAction<Array<ILanguage>>
    ) => {
      state.languages = action.payload;
    },
    reducerUpdateLanguageSelected: (
      state: IStream,
      action: PayloadAction<string>
    ) => {
      state.languageSelected = action.payload;
    },
    reducerUpdateIsSelectedAllPrimary: (
      state: IStream,
      action: PayloadAction<boolean>
    ) => {
      state.isSelectedAllPrimary = action.payload;
    },
    reducerUpdateStreamSelected: (
      state: IStream,
      action: PayloadAction<Array<IOfflineMedia>>
    ) => {
      state.streamSelected = action.payload;
    },
    reducerUpdateUpdatingApplyClipping: (
      state: IStream,
      action: PayloadAction<Array<IOfflineMedia>>
    ) => {
      state.streamUpdatingApplyClipping = action.payload;
    },
    reducerFinishUpdateApplyClipping: (
      state: IStream,
      action: PayloadAction<Array<IOfflineMedia>>
    ) => {
      const update = state.streamUpdatingApplyClipping.filter(
        (streamPrev) =>
          !action.payload.find(
            (streamUpdate) => streamUpdate.originalId === streamPrev.originalId
          )
      );
      state.streamUpdatingApplyClipping = update;
    },
    reducerFinishUpdateStreamSentiment: (
      state: IStream,
      action: PayloadAction<Array<IOfflineMedia>>
    ) => {
      const updateSentiment = state.streamUpdatingSentiments.filter(
        (streamPrev) =>
          action.payload.find(
            (streamUpdate) => streamUpdate.originalId !== streamPrev.originalId
          )
      );
      state.streamUpdatingSentiments = updateSentiment;
    },
    reducerLabelingLoadingOfflineMedia: (
      state: IStream,
      action: PayloadAction<Array<IOfflineMedia>>
    ) => {
      state.streamUpdatingLabels = action.payload;
    },
    reducerUpdateActiveArticle: (
      state: IStream,
      action: PayloadAction<IOfflineMedia>
    ) => {
      state.activeArticle = action.payload;
    },
    reducerUpdateMediaList: (
      state: IStream,
      action: PayloadAction<string[]>
    ) => {
      state.mediaList = action.payload;
    }
  }
});

export const {
  reducerUpdateOfflineMediaIncludeKeywords,
  reducerSetOfflineMediaArticleList,
  reducerUpdateSelectedOfflineMedia,
  reducerSentimentLoadingOfflineMedia,
  reducerRemoveLoadingOfflineMedia,
  reducerUpdateOfflineMediaArticles,
  reducerUpdateRemoveOfflineMedia,
  reducerUpdateLanguages,
  reducerUpdateLanguageSelected,
  reducerUpdateIsSelectedAllPrimary,
  reducerUpdateStreamSelected,
  reducerUpdateUpdatingApplyClipping,
  reducerFinishUpdateApplyClipping,
  reducerFinishUpdateStreamSentiment,
  reducerLabelingLoadingOfflineMedia,
  reducerUpdateActiveArticle,
  reducerUpdateMediaList
} = offlineMediaStream.actions;

export default offlineMediaStream.reducer;
