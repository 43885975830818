export const fillParamAdvanceSearchEmpty = (stateAdvanceSearch) => {
  const sentiment =
    stateAdvanceSearch.sentiment?.length > 0 ? stateAdvanceSearch.sentiment : ["positive", "negative", "neutral"];
  const postOwnership =
    stateAdvanceSearch.postOwnership?.length > 0 ? stateAdvanceSearch.postOwnership : ["postmade", "talk", "reply"];
  const contentTypeList =
    stateAdvanceSearch.contentTypeList?.length > 0
      ? stateAdvanceSearch.contentTypeList
      : ["text", "image", "video", "others"];
  const validationList =
    stateAdvanceSearch.validationList?.length > 0 ? stateAdvanceSearch.validationList : ["validated", "unvalidated"];
  return { ...stateAdvanceSearch, sentiment, postOwnership, contentTypeList, validationList };
};
