import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Radio, useTheme } from '@mui/material';
import { IGroupObject } from 'src/models/objectManagement';
import moment from 'moment';
import { useAppDispatch } from 'src/app/hooks';
import { reducerUpdateObjectManagementGroupSelectedById } from 'src/features/objectManagement';

interface Props {
  groupObjects: Array<IGroupObject>;
  loading?: boolean;
  groupSelectedById: string | undefined;
  onClick: () => void;
}

const TableGroupObject: React.FC<Props> = ({
  groupObjects,
  loading = false,
  groupSelectedById,
  onClick
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleClick = (id: string) => {
    onClick();
    dispatch(reducerUpdateObjectManagementGroupSelectedById(id));
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: theme.spacing(30) }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>No</TableCell>
            <TableCell>Group</TableCell>
            <TableCell align="center">Added</TableCell>
            <TableCell align="center">Object Count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupObjects.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              hover
              onClick={() => handleClick(row.id)}
            >
              <TableCell component="th" scope="row">
                <Radio
                  checked={row.id === groupSelectedById}
                  value={row.id}
                  name="group-object"
                  size="small"
                  sx={{ color: theme.palette.primary.main }}
                />
              </TableCell>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{row.displayName}</TableCell>
              <TableCell align="center">
                {row.createdAt ? moment(row.createdAt).format('D/M/YY') : '-'}
              </TableCell>
              <TableCell align="center">{row.objectList.length}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableGroupObject;
