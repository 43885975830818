/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { styled, Chip, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { TSocialMedia, TStreamType } from 'src/models/general';
import { getSymbolByStreamType } from 'src/utils/getSymbolByStreamType';
import GetSocialMediaIcon from '../GetSocialMediaIcon';

interface Props {
  children?: React.ReactNode;
  text: string;
  socialMedia?: TSocialMedia;
  streamType?: TStreamType;
}

const ChipCustom = styled(Chip)(
  ({ theme }) => `
    background-color: ${theme.palette.primary.main};
    border-radius: ${theme.spacing(1)};
    padding: 10px ${theme.spacing(1)};
    margin: 0 0 8px 12px;
    height: 29px;
    cursor: pointer;
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
    .MuiChip-label {
        color: #0A789D;
        font-size: 14px;
        line-height: 130%;
        margin: 0 8px;
    }
    .MuiChip-avatarSmall {
        width: 14px;
        color: #0A789D;
    }
  `
);

const TagSocialMedia: React.FC<Props> = ({
  text,
  socialMedia,
  streamType
}) => {
  const theme = useTheme();

  const symbol = useMemo(() => getSymbolByStreamType(streamType), [streamType]);
  return (
    <ChipCustom
      variant="filled"
      avatar={<GetSocialMediaIcon socialMedia={socialMedia} />}
      label={`${symbol}${text}`}
      size="small"
      sx={{
        background: theme.palette.primary.light
      }}
    />
  );
};

export default TagSocialMedia;
