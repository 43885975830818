import { gql } from '@apollo/client';
import { analyticArguments, analyticArgumentsQuery } from '../analyticArgument';

const result = `
  originalId
  socialMedia
  displayPicture
  link
  content
  fromId
  fromName
  timestamp
  share
  comment
  like
  audience
  engagementRate
  sentiment
  objectModel{
    id
    socialMedia
    streamType
    objectName
    isHashtag
  }
  postOwnership
  label{
    id
    name
    displayName
    colorType
    isActive
  }
  isEdited
  streamVisibility
  `;

export const GET_RAW_STREAM_OUTPUT = gql`
  query rawStream_getRawStreamOutputV2(
    ${analyticArgumentsQuery}, 
    $columnName: SortingByColumn, 
    $sortingMethod: SortingType, 
    $page: Int!, 
    $limit: Int!, 
    ) {
      rawStream_getRawStreamOutputV2(
        ${analyticArguments}, 
        columnName: $columnName, 
        sortingMethod: $sortingMethod, 
        page: $page, 
        limit: $limit) {
          totalRows
          totalPages
          page
          limit
          result{
            ${result}
            entity {
              id
              name
            }
            contentType
            replyToContent {
              ${result}
            }
            viewCount
          }
      }
  }`;

export const GET_IGNORED_RAW_STREAM_OUTPUT_V2 = gql`
query rawStream_getIgnoredRawStreamOutputV2(${analyticArgumentsQuery},$columnName: SortingByColumn, $sortingMethod: SortingType, $page: Int!, $limit: Int!) {
  rawStream_getIgnoredRawStreamOutputV2(${analyticArguments},columnName: $columnName, sortingMethod: $sortingMethod, page: $page, limit: $limit) {
    totalRows
    totalPages
    page
    limit
    result{
      ${result}
      entity {
        id
        name
      }
      replyToContent {
        ${result}
      }
    }
  }
}`;

export const GET_RAW_STREAM_OUTPUT_TAKE_TEEN = gql`
  query rawStream_getRawStreamOutputTakeTen(
    ${analyticArgumentsQuery},
  ) {
    rawStream_getRawStreamOutputTakeTen(
      ${analyticArguments}
    ) {
      originalId
      socialMedia
      displayPicture
      link
      content
      fromId
      fromName
      timestamp
      share
      comment
      like
      audience
      engagementRate
      sentiment
      objectModel {
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
        isHashtag
        createdAt
        openApiId
      }
      postOwnership
      label {
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
      isEdited
      streamVisibility
      replyToContent {
        originalId
        socialMedia
        displayPicture
        link
        content
        fromId
        fromName
        timestamp
        share
        comment
        like
        audience
        engagementRate
        sentiment
        objectModel {
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
          createdAt
          openApiId
        }
        postOwnership
        label {
          id
          name
          displayName
          colorType
          isActive
          createdAt
        }
        isEdited
        streamVisibility
      }
    }
  }
`;

export const GET_LANGUAGES_V2 = gql`
  query Languages_getLanguagesV2 {
    languages_getLanguagesV2 {
      name
      language
    }
  }
`;
