import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import {
  reducerUpdateOnlineMediaArticleList,
  reducerUpdateOnlineMediaArticleListLoading,
  reducerUpdateOnlineMediaChronologicalArticle,
  reducerUpdateOnlineMediaChronologicalArticleLoading,
  reducerUpdateOnlineMediaChronologicalMedia,
  reducerUpdateOnlineMediaChronologicalMediaLoading,
  reducerUpdateOnlineMediaChronologicalSentiment,
  reducerUpdateOnlineMediaClippingList,
  reducerUpdateOnlineMediaClippingListLoading,
  reducerUpdateOnlineMediaMediaListLoading,
  reducerUpdateOnlineMediaStatisticLoading,
  reducerUpdateOnlineMediaStreamAricles,
  reducerUpdateOnlineMediaTotalArticle,
  reducerUpdateOnlineMediaTotalMedia,
  reducerUpdateOnlineMediaTotalPRValue,
  reducerUpdateOnlineMediaWordClouds,
  reducerUpdateOnlineTalkBySentiment,
  reducerUpdateOnlineTopLocations,
  reducerUpdateOnlineTopPeoples
} from 'src/features/onlineMediaStream';
import {
  reducerUpdateOnmComparisonEntity,
  reducerUpdateOnmComparisonList,
  reducerUpdateSentimentAnalysis,
  reducerUpdateBrandPosition,
  reducerUpdateAdvanceMetric,
  reducerUpdateAllLoadingComparison,
  reducerUpdateDayToDayArticle,
  reducerUpdateDayToDayMedia,
  reducerUpdateShareOfVoice,
  IHeadCellAdvanceMetrics,
  initHeadCells
} from 'src/features/onmComparison';
import {
  IArticleByMedia,
  IBrandPayload,
  IBrandPayload as IBrandValidationPayload,
  IBrandPositioning,
  IBrandSentiment,
  IClipping,
  IOnlineMediaStreamOutputEntityWithPagination,
  IOnmAdvanceMetric,
  IPayloadOnlineMedia,
  ITopLocation,
  ITopPeople,
  IGranularity,
  TSearchType,
  TValidation
} from 'src/models/onlineMedia';
import { buildDataLineGroup } from 'src/utils/buildDataLineGroup';
import {
  ADD_COMPARISON_ONLINE_MEDIA,
  ADD_ONLINE_MEDIA_CLIPPING,
  DELETE_ONLINE_MEDIA_CLIPPING,
  EDIT_ONLINE_MEDIA_CLIPPING,
  MANAGE_ARTICLES_ON_CLIPPING,
  MANAGE_CLIPPING_ON_ARTICLE,
  MANAGE_LABEL_ON_ARTICLE,
  EDIT_COMPARISON_ONLINE_MEDIA,
  MANAGE_LABEL_ON_ARTICLE_BY_PRIMARY,
  MANAGE_SENTIMENT_ON_ARTICLE,
  MANAGE_SENTIMENT_ON_ARTICLE_BY_FILTER,
  DELETE_COMPARISON_ONLINE_MEDIA,
  SET_DEFAULT_ONLINE_MEDIA,
  ONLINE_MEDIA_ADD_MEDIA_SOURCE,
  ONLINE_MEDIA_ADD_CLIPPING_v5,
  ONLINE_MEDIA_EDIT_CLIPPING_v5,
  ONLINE_MEDIA_DELETE_CLIPPING_v5,
  MANAGE_VISIBILITY_ON_ARTICLE_BY_FILTER,
  DOWNLOAD_CLUSTERING_CSV
} from './graphql/onlineMedia/mutation';
import {
  GET_ADVANCE_METRIC_ONLINE_MEDIA,
  GET_ARTICLE_V2,
  GET_BRAND_POPULARITY_ONLINE_MEDIA,
  GET_BRAND_POSITIONING_ONLINE_MEDIA,
  GET_BRAND_SENTIMENT_ONLINE_MEDIA,
  GET_CHRONOLOGICAL_ARTICLE,
  GET_CHRONOLOGICAL_ARTICLE_BY_BRAND,
  GET_CHRONOLOGICAL_MEDIA,
  GET_CHRONOLOGICAL_MEDIA_BY_BRAND,
  GET_CHRONOLOGICAL_SENTIMENT,
  GET_CLUSTERING_LIMIT,
  GET_COMPARISON_BY_ID,
  GET_COMPARISON_ONLINE_MEDIA_NAMESPACE_LIST,
  GET_ONLINE_MEDIA_SENTIMENT_ANALYSIS,
  GET_TOP_LOCATION,
  GET_TOP_PEOPLE,
  GET_TOTAL_ARTICLE,
  GET_TOTAL_ARTICLE_BY_MEDIA,
  GET_TOTAL_MEDIA,
  GET_TOTAL_PR_VALUES,
  GET_WORD_CLOUD_NEWS,
  ONLINE_MEDIA_GET_CLIPPING_V5
} from './graphql/onlineMedia/query';
import uuidNameSpace from 'uuid/v5';
import getNS from 'uuid/v4';
import { SET_ADVANCE_METRIC_SELECTION } from './graphql/comparison/mutation';
import {
  IChronological,
  IChronologicalOutput,
  INumericOutput,
  ITableWithPagination,
  IWordCloud,
  TPostOwnership,
  TSentiment
} from 'src/models/general';
import {
  INamespace,
  INamespaceOnm,
  IOnmComparisonEntity
} from 'src/models/comparison';
import { generateGroupSentiment } from 'src/utils/generateChronologicalGroup';

const RequestNameSpace = getNS();

interface ProjectId {
  projectId: string;
}

interface IGetClippingPayload {
  projectId: string;
  limit: number;
  page: number;
  name: string;
}

interface IGetArticleByMediaResponse {
  onlineMedia_getTotalArticleByMedia: Array<IArticleByMedia>;
}

interface IGetArticlesResponse {
  onlineMedia_getArticleV2: IOnlineMediaStreamOutputEntityWithPagination;
}

interface IGetClippingsV5Response {
  onlineMedia_getClippingWithPagination: ITableWithPagination<IClipping>;
}

export interface IGetTotalArticleResponse {
  onlineMedia_getTotalArticle: INumericOutput;
}

interface IGetTotalMediaResponse {
  onlineMedia_getTotalMedia: INumericOutput;
}

interface IGetTotalPRValue {
  onlineMedia_getTotalPRValues: INumericOutput;
}

interface IGetChronologicalArticleResponse {
  onlineMedia_getChronologicalArticle: IChronologicalOutput;
}

interface IGetChronologicalMediaOutput {
  onlineMedia_getChronologicalMedia: IChronologicalOutput;
}

interface IGetChronologicalSentiment {
  onlineMedia_getChronologicalSentiment: Array<IChronologicalOutput>;
}

interface IGetSentimentAnalysis {
  onlineMedia_getSentimentAnalysis: Array<INumericOutput>;
}

interface IGetWordCloudNewsResponse {
  onlineMedia_getWordCloudNews: Array<IWordCloud>;
}

interface IGetTopPeopleResponse {
  onlineMedia_getTopPeople: Array<ITopPeople>;
}

interface IGetTopLocationResponse {
  onlineMedia_getTopLocation: Array<ITopLocation>;
}

interface IGetOnmComparisonResponse {
  comparisonOnlineMedia_getComparisonNamespaceList: Array<INamespaceOnm>;
}

interface IGetComparisonEntityResponse {
  comparisonOnlineMedia_getComparisonById: IOnmComparisonEntity;
}

interface IGetBrandPositioningResponse {
  comparisonOnlineMedia_getBrandPositioning: Array<IBrandPositioning>;
}

interface IGetBrandSentimentOnmResponse {
  comparisonOnlineMedia_getBrandSentiment: Array<IBrandSentiment>;
}

interface IGetShareOfVoiceResponse {
  comparisonOnlineMedia_getBrandPopularity: Array<INumericOutput>;
}

interface IGetDayToDayMediaResponse {
  comparisonOnlineMedia_getChronologicalMediaByBrand: Array<IChronological>;
}

interface IGetDayToDayArticleResponse {
  comparisonOnlineMedia_getChronologicalArticleByBrand: Array<IChronological>;
}

interface IGetAdvanceMetricResponse {
  comparisonOnlineMedia_getAdvanceMetricOnlineMedia: Array<IOnmAdvanceMetric>;
}

export interface IUpdateLabelByFilterParams {
  projectId: string;
  start: string;
  end: string;
  mediaList: string[];
  granularity: IGranularity;
  timezone: number;
  phrases?: string[];
  includeKeywords?: string[];
  excludeKeywords?: string[];
  searchType?: TSearchType;
  sentiment?: TSentiment[];
  postOwnership?: TPostOwnership[];
  labelList?: string[];
  languageList?: string[];
  clippingId?: string;
  validationList?: TValidation[];
  attachLabels: string[];
  removeLabels: string[];
}

const useOnlineMedia = function () {
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const { comparisonList } = useAppSelector((state) => state.onmComparison);
  const currentAdvanceMetric = useAppSelector(
    (state) => state.onmComparison.advanceMetric
  );
  const [headCellsAdvanceMetrics, setHeadCellAdvanceMetrics] =
    useState<Array<IHeadCellAdvanceMetrics>>(initHeadCells);
  const lastPromiseBrand: any = useRef();
  const lastPromiseSov: any = useRef();
  const lastPromiseSentiment: any = useRef();
  const lastPromiseAdvance: any = useRef();
  const lastPromiseMedia: any = useRef();
  const lastPromiseArticle: any = useRef();

  const fetchTotalArticleByMedia = async function (
    payload: IPayloadOnlineMedia
  ) {
    return await client
      .query<IGetArticleByMediaResponse>({
        query: GET_TOTAL_ARTICLE_BY_MEDIA,
        variables: payload
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.onlineMedia_getTotalArticleByMedia
        };
      });
  };

  const getTotalArticleByMedia = async function (payload: IPayloadOnlineMedia) {
    dispatch(reducerUpdateOnlineMediaMediaListLoading(true));
    const response = await fetchTotalArticleByMedia(payload);
    dispatch(reducerUpdateOnlineMediaStreamAricles(response));
  };

  const fetchArticle = async function (payload: IPayloadOnlineMedia) {
    return await client
      .query<IGetArticlesResponse>({
        query: GET_ARTICLE_V2,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.onlineMedia_getArticleV2 };
      });
  };

  const getArticle = async function (payload: IPayloadOnlineMedia) {
    dispatch(reducerUpdateOnlineMediaArticleListLoading(true));
    const response = await fetchArticle(payload);
    dispatch(reducerUpdateOnlineMediaArticleList(response));
  };

  const fetchClippingV5 = async function (payload: ProjectId) {
    return await client
      .query<IGetClippingsV5Response>({
        query: ONLINE_MEDIA_GET_CLIPPING_V5,
        variables: payload
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.onlineMedia_getClippingWithPagination
        };
      });
  };

  const getClippingsV5 = async function (payload: IGetClippingPayload) {
    dispatch(reducerUpdateOnlineMediaClippingListLoading(true));
    const response = await fetchClippingV5(payload);
    dispatch(reducerUpdateOnlineMediaClippingList(response));
  };

  const fetchTotalArticle = async function (payload: IPayloadOnlineMedia) {
    return await client
      .query<IGetTotalArticleResponse>({
        query: GET_TOTAL_ARTICLE,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.onlineMedia_getTotalArticle };
      });
  };

  const getTotalArticle = async function (payload: IPayloadOnlineMedia) {
    const response = await fetchTotalArticle(payload);
    dispatch(reducerUpdateOnlineMediaTotalArticle(response));
  };

  const fetchTotalMedia = async function (payload: IPayloadOnlineMedia) {
    return await client
      .query<IGetTotalMediaResponse>({
        query: GET_TOTAL_MEDIA,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.onlineMedia_getTotalMedia };
      });
  };

  const getTotalMedia = async function (payload: IPayloadOnlineMedia) {
    const response = await fetchTotalMedia(payload);
    dispatch(reducerUpdateOnlineMediaTotalMedia(response));
  };

  const fetchTotalPRValue = async function (payload: IPayloadOnlineMedia) {
    return await client
      .query<IGetTotalPRValue>({
        query: GET_TOTAL_PR_VALUES,
        variables: payload
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.onlineMedia_getTotalPRValues
        };
      });
  };

  const getTotalPRValue = async function (payload: IPayloadOnlineMedia) {
    const response = await fetchTotalPRValue(payload);
    dispatch(reducerUpdateOnlineMediaTotalPRValue(response));
  };

  const fetchChronologicalArticle = async function (
    payload: IPayloadOnlineMedia
  ) {
    return await client
      .query<IGetChronologicalArticleResponse>({
        query: GET_CHRONOLOGICAL_ARTICLE,
        variables: payload
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.onlineMedia_getChronologicalArticle
        };
      });
  };

  const getChronologicalArticle = async function (
    payload: IPayloadOnlineMedia
  ) {
    dispatch(reducerUpdateOnlineMediaChronologicalArticleLoading(true));
    const response = await fetchChronologicalArticle(payload);
    dispatch(reducerUpdateOnlineMediaChronologicalArticle(response));
  };

  const fetchChronologicalMedia = async function (
    payload: IPayloadOnlineMedia
  ) {
    return await client
      .query<IGetChronologicalMediaOutput>({
        query: GET_CHRONOLOGICAL_MEDIA,
        variables: payload
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.onlineMedia_getChronologicalMedia
        };
      });
  };

  const getChronologicalMedia = async function (payload: IPayloadOnlineMedia) {
    dispatch(reducerUpdateOnlineMediaChronologicalMediaLoading(true));
    const response = await fetchChronologicalMedia(payload);
    dispatch(reducerUpdateOnlineMediaChronologicalMedia(response));
  };

  const fetchChronologicalSentiment = async function (
    payload: IPayloadOnlineMedia
  ) {
    return await client
      .query<IGetChronologicalSentiment>({
        query: GET_CHRONOLOGICAL_SENTIMENT,
        variables: payload
      })
      .then((response) => {
        const generateDataLine = buildDataLineGroup(
          response.data.onlineMedia_getChronologicalSentiment
        );
        return { ...response, data: generateDataLine };
      });
  };

  const getChronologicalSentiment = async function (
    payload: IPayloadOnlineMedia
  ) {
    const response = await fetchChronologicalSentiment(payload);
    dispatch(reducerUpdateOnlineMediaChronologicalSentiment(response));
  };

  const fetchSentimentAnalysis = async function (payload: IPayloadOnlineMedia) {
    return await client
      .query<IGetSentimentAnalysis>({
        query: GET_ONLINE_MEDIA_SENTIMENT_ANALYSIS,
        variables: payload
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.onlineMedia_getSentimentAnalysis
        };
      });
  };

  const getSentimentAnalysis = async function (payload: IPayloadOnlineMedia) {
    const response = await fetchSentimentAnalysis(payload);
    dispatch(reducerUpdateOnlineTalkBySentiment(response));
  };

  const fetchWordCloudNews = async function (payload: IPayloadOnlineMedia) {
    return await client
      .query<IGetWordCloudNewsResponse>({
        query: GET_WORD_CLOUD_NEWS,
        variables: payload
      })
      .then((response) => {
        const wordClouds = response.data.onlineMedia_getWordCloudNews.map(
          (word: IWordCloud) => ({
            text: word.name,
            value: word.value
          })
        );
        const data =
          wordClouds.length > 0 ? wordClouds.map((word) => word.value) : [];
        const max = data.length > 0 ? Math.max(...data) : 0;
        const min = data.length > 0 ? Math.min(...data) : 1;
        return { ...response, data: { data: wordClouds, max, min } };
      });
  };

  const getWordCloudNews = async function (payload: IPayloadOnlineMedia) {
    const response = await fetchWordCloudNews(payload);
    dispatch(reducerUpdateOnlineMediaWordClouds(response));
  };

  const fetchTopPeople = async function (payload: IPayloadOnlineMedia) {
    return await client
      .query<IGetTopPeopleResponse>({
        query: GET_TOP_PEOPLE,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.onlineMedia_getTopPeople };
      });
  };

  const getTopPeople = async function (payload: IPayloadOnlineMedia) {
    const response = await fetchTopPeople(payload);
    dispatch(reducerUpdateOnlineTopPeoples(response));
  };

  const fetchTopLocation = async function (payload: IPayloadOnlineMedia) {
    return await client
      .query<IGetTopLocationResponse>({
        query: GET_TOP_LOCATION,
        variables: payload
      })
      .then((response) => {
        return { ...response, data: response.data.onlineMedia_getTopLocation };
      });
  };

  const getTopLocation = async function (payload: IPayloadOnlineMedia) {
    const response = await fetchTopLocation(payload);
    dispatch(reducerUpdateOnlineTopLocations(response));
  };

  const fetchListOnmComparison = async function (projectId: string) {
    return await client
      .query<IGetOnmComparisonResponse>({
        query: GET_COMPARISON_ONLINE_MEDIA_NAMESPACE_LIST,
        variables: {
          projectId
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.comparisonOnlineMedia_getComparisonNamespaceList
        };
      });
  };

  const getListOnmComparison = async function (projectId: string) {
    const response = await fetchListOnmComparison(projectId);
    dispatch(reducerUpdateOnmComparisonList(response));
  };

  const fetchOnmComparisonEntity = async function (comparisonId: string) {
    return await client
      .query<IGetComparisonEntityResponse>({
        query: GET_COMPARISON_BY_ID,
        variables: {
          comparisonId
        }
      })
      .then((response) => {
        return {
          ...response,
          data: response.data.comparisonOnlineMedia_getComparisonById
        };
      });
  };

  const getOnmComparisonEntity = async function (comparisonId: string) {
    const response = await fetchOnmComparisonEntity(comparisonId);
    dispatch(reducerUpdateOnmComparisonEntity(response));
  };

  const getAllMetricStatistic = async (payload: IPayloadOnlineMedia) => {
    dispatch(reducerUpdateOnlineMediaStatisticLoading(true));
    getTotalArticleByMedia(payload);
    getTotalArticle(payload);
    getTotalMedia(payload);
    getTotalPRValue(payload);
    getChronologicalArticle(payload);
    getChronologicalMedia(payload);
    getChronologicalSentiment(payload);
    getWordCloudNews(payload);
    getSentimentAnalysis(payload);
    getTopPeople(payload);
    getTopLocation(payload);
  };

  const keyMappingChronological = function (
    chronologicals: Array<IChronological>
  ) {
    return chronologicals.map((chronological) => ({
      name: chronological.name,
      data: chronological.values.map((value) => [value.timestamp, value.value])
    }));
  };

  const getBrandPositioning = function (parameter: IBrandPayload) {
    const response = fetchBrandPositioning(parameter);
    lastPromiseBrand.current = response;
    if (response === lastPromiseBrand.current) {
      response.then((response) => {
        dispatch(reducerUpdateBrandPosition(response));
      });
    }
  };
  const fetchBrandPositioning = async function (parameter: IBrandPayload) {
    const resBrandPositioning =
      await client.query<IGetBrandPositioningResponse>({
        query: GET_BRAND_POSITIONING_ONLINE_MEDIA,
        variables: parameter,
        context: {
          requestTrackerId: uuidNameSpace(
            'GET_BRAND_POSITIONING_ONLINE_MEDIA',
            RequestNameSpace
          )
        }
      });
    const generateData =
      resBrandPositioning.data.comparisonOnlineMedia_getBrandPositioning.map(
        (position) => ({
          name: position.name,
          data: [[position?.positivity, position?.popularity]]
        })
      );
    return { ...resBrandPositioning, data: generateData };
  };

  const getBrandSentimentAnalysisOnm = function (
    parameter: IBrandValidationPayload
  ) {
    const response = fetchBrandSentimentAnalysisOnm(parameter);
    lastPromiseSentiment.current = response;
    if (response === lastPromiseSentiment.current) {
      response.then((response) => {
        dispatch(reducerUpdateSentimentAnalysis(response));
      });
    }
  };

  const getShareOfVoice = function (parameter: IBrandPayload) {
    const response = fetchShareOfVoice(parameter);
    lastPromiseSov.current = response;
    if (response === lastPromiseSov.current) {
      response.then((response) => {
        dispatch(reducerUpdateShareOfVoice(response));
      });
    }
  };

  const fetchShareOfVoice = async function (parameter: IBrandPayload) {
    const resShareOfVoice = await client.query<IGetShareOfVoiceResponse>({
      query: GET_BRAND_POPULARITY_ONLINE_MEDIA,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_BRAND_POPULARITY_ONLINE_MEDIA',
          RequestNameSpace
        )
      }
    });
    return {
      ...resShareOfVoice,
      data: resShareOfVoice.data.comparisonOnlineMedia_getBrandPopularity
    };
  };

  const fetchBrandSentimentAnalysisOnm = async function (
    parameter: IBrandValidationPayload
  ) {
    const response = await client.query<IGetBrandSentimentOnmResponse>({
      query: GET_BRAND_SENTIMENT_ONLINE_MEDIA,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_BRAND_SENTIMENT_ONLINE_MEDIA',
          RequestNameSpace
        )
      }
    });
    const generateData = generateGroupSentiment(
      response.data?.comparisonOnlineMedia_getBrandSentiment,
      ['positive', 'negative', 'neutral']
    );
    return { ...response, data: generateData };
  };

  const getDayToDayMedia = function (parameter: IBrandValidationPayload) {
    const response = fetchDayToDayMedia(parameter);
    lastPromiseMedia.current = response;
    if (response === lastPromiseMedia.current) {
      response.then((response) => {
        dispatch(reducerUpdateDayToDayMedia(response));
      });
    }
  };

  const fetchDayToDayMedia = async function (
    parameter: IBrandValidationPayload
  ) {
    const response = await client.query<IGetDayToDayMediaResponse>({
      query: GET_CHRONOLOGICAL_MEDIA_BY_BRAND,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_CHRONOLOGICAL_MEDIA_BY_BRAND',
          RequestNameSpace
        )
      }
    });
    const generateData = keyMappingChronological(
      response.data?.comparisonOnlineMedia_getChronologicalMediaByBrand
    );
    return { ...response, data: generateData };
  };

  const getDayToDayArticle = function (parameter: IBrandValidationPayload) {
    const response = fetchDayToDayArticle(parameter);
    lastPromiseArticle.current = response;
    if (response === lastPromiseArticle.current) {
      response.then((response) => {
        dispatch(reducerUpdateDayToDayArticle(response));
      });
    }
  };

  const fetchDayToDayArticle = async function (
    parameter: IBrandValidationPayload
  ) {
    const response = await client.query<IGetDayToDayArticleResponse>({
      query: GET_CHRONOLOGICAL_ARTICLE_BY_BRAND,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_CHRONOLOGICAL_ARTICLE_BY_BRAND',
          RequestNameSpace
        )
      }
    });
    const generateData = keyMappingChronological(
      response.data?.comparisonOnlineMedia_getChronologicalArticleByBrand
    );
    return { ...response, data: generateData };
  };

  const getAdvanceMetric = function (parameter: IBrandPayload) {
    const response = fetchAdvanceMetric(parameter);
    lastPromiseAdvance.current = response;
    if (response === lastPromiseAdvance.current) {
      response.then((response) => {
        dispatch(reducerUpdateAdvanceMetric(response));
      });
    }
  };

  const fetchAdvanceMetric = async function (parameter: IBrandPayload) {
    const response = await client.query<IGetAdvanceMetricResponse>({
      query: GET_ADVANCE_METRIC_ONLINE_MEDIA,
      variables: parameter,
      context: {
        requestTrackerId: uuidNameSpace(
          'GET_ADVANCE_METRIC_ONLINE_MEDIA',
          RequestNameSpace
        )
      }
    });

    const mappingMetric =
      response.data?.comparisonOnlineMedia_getAdvanceMetricOnlineMedia.map(
        ({
          category,
          totalArticle,
          totalMedia,
          prValue,
          sentimentPositive,
          sentimentNeutral,
          sentimentNegative
        }) => {
          const objectMetric = {
            category,
            totalArticle,
            totalMedia,
            prValue,
            sentimentPositive,
            sentimentNeutral,
            sentimentNegative
          };
          return objectMetric;
        }
      );
    return { ...response, data: mappingMetric };
  };

  const getAllMetricComparisonOnm = (parameter: IBrandPayload) => {
    dispatch(reducerUpdateAllLoadingComparison(true));
    getBrandPositioning(parameter);
    getShareOfVoice(parameter);
    getBrandSentimentAnalysisOnm(parameter);
    getDayToDayMedia(parameter);
    getDayToDayArticle(parameter);
    getAdvanceMetric(parameter);
  };

  const [setComparisonTableColumn, responseComparisonTableColumn] = useMutation(
    SET_ADVANCE_METRIC_SELECTION,
    {
      onCompleted: ({ comparison_updateAdvanceMetricSelection }) => {
        setHeadCellAdvanceMetrics((prevCells: Array<IHeadCellAdvanceMetrics>) =>
          prevCells.map((col) => {
            const column =
              comparison_updateAdvanceMetricSelection[0].values.find(
                (val) => val.computationName === col.id
              );
            return { ...col, active: column.isActive };
          })
        );
        const mappingMetric = comparison_updateAdvanceMetricSelection.map(
          (metric: any) => {
            const objectMetric = { group: metric.name };
            headCellsAdvanceMetrics.forEach((headMetric) => {
              metric.values.forEach((val) => {
                if (headMetric.id === val.computationName && val.isActive) {
                  Object.assign(objectMetric, {
                    [val.computationName]: val.value
                  });
                }
              });
            });
            return objectMetric;
          }
        );
        dispatch(
          reducerUpdateAdvanceMetric({
            ...currentAdvanceMetric,
            data: mappingMetric
          })
        );
      },
      context: {
        requestTrackerId: uuidNameSpace(
          'SET_ADVANCE_METRIC_SELECTION',
          RequestNameSpace
        )
      },
      onError(err) {
        console.log(err);
      }
    }
  );

  const [postAddAutoClipping, resPostAddAutoClipping] = useMutation(
    ADD_ONLINE_MEDIA_CLIPPING
  );

  const [postDeleteAutoClipping, resPostDeleteAutoClipping] = useMutation(
    DELETE_ONLINE_MEDIA_CLIPPING
  );

  const [postEditAutoClipping, resPostEditAutoClipping] = useMutation(
    EDIT_ONLINE_MEDIA_CLIPPING
  );

  const [postComparisonOnm, resPostComparisonOnm] = useMutation(
    ADD_COMPARISON_ONLINE_MEDIA
  );

  const [editComparisonOnm, resEditComparisonOnm] = useMutation(
    EDIT_COMPARISON_ONLINE_MEDIA
  );

  const [addMediaSource, resAddMediaSource] = useMutation(
    ONLINE_MEDIA_ADD_MEDIA_SOURCE
  );

  const [postUpdateSentimentArticle, resPostUpdateSentimentArticle] =
    useMutation(MANAGE_SENTIMENT_ON_ARTICLE);
  const [postDeleteArticle, resPostDeleteArticle] = useMutation(
    MANAGE_ARTICLES_ON_CLIPPING
  );

  const [addArticlesToClippings, responseArticlesToClippings] = useMutation(
    MANAGE_CLIPPING_ON_ARTICLE
  );
  const [applyLabelArticle, resApplyLabelArticle] = useMutation(
    MANAGE_LABEL_ON_ARTICLE
  );

  const [updateSentimentByFilter, responseUpdateSentimentByFilter] =
    useMutation(MANAGE_SENTIMENT_ON_ARTICLE_BY_FILTER);

  const [updateLabelByFilter, responseUpdateLabelByFilter] = useMutation<
    any,
    IUpdateLabelByFilterParams
  >(MANAGE_LABEL_ON_ARTICLE_BY_PRIMARY);

  const [deleteOnmComparison, resDeleteOnmComparison] = useMutation(
    DELETE_COMPARISON_ONLINE_MEDIA,
    {
      onCompleted: ({ comparisonOnlineMedia_deleteComparison }) => {
        const filterComparisons = comparisonList.data.filter(
          (namespace) =>
            namespace.id !== comparisonOnlineMedia_deleteComparison.id
        );
        dispatch(
          reducerUpdateOnmComparisonList({
            ...comparisonList,
            data: filterComparisons
          })
        );
      },
      onError(err) {
        console.log(err);
      }
    }
  );

  const [setOnmDefault, responseSetOnmDefault] = useMutation(
    SET_DEFAULT_ONLINE_MEDIA,
    {
      onCompleted: ({ comparisonOnlineMedia_setDefault }) => {
        const updateComparisonList = comparisonList.data.map((namespace) => {
          if (namespace.id !== comparisonOnlineMedia_setDefault.id)
            return { ...namespace, isDefault: false };
          return comparisonOnlineMedia_setDefault;
        });
        dispatch(
          reducerUpdateOnmComparisonList({
            ...comparisonList,
            data: updateComparisonList
          })
        );
      },
      onError(err) {
        console.log(err);
      }
    }
  );

  const [addClippingV5, responseAddClippingV5] = useMutation(
    ONLINE_MEDIA_ADD_CLIPPING_v5
  );

  const [editClippingV5, responseEditClippingV5] = useMutation(
    ONLINE_MEDIA_EDIT_CLIPPING_v5
  );

  const [deleteClippingV5, responseDeleteClippingV5] = useMutation(
    ONLINE_MEDIA_DELETE_CLIPPING_v5
  );

  const [removeArticlesByFilter, responseRemoveArticlesByFilter] = useMutation(
    MANAGE_VISIBILITY_ON_ARTICLE_BY_FILTER
  );

  const [downloadClusteringCsv, resDownloadClusteringCsv] = useMutation(
    DOWNLOAD_CLUSTERING_CSV
  );

  return {
    getTotalArticleByMedia,
    postAddAutoClipping,
    resPostAddAutoClipping,
    getArticle,
    getAllMetricStatistic,
    getTotalArticle,
    getTotalMedia,
    getTotalPRValue,
    getChronologicalArticle,
    getChronologicalMedia,
    getChronologicalSentiment,
    getSentimentAnalysis,
    getTopLocation,
    getTopPeople,
    getWordCloudNews,
    postDeleteAutoClipping,
    resPostDeleteAutoClipping,
    postEditAutoClipping,
    resPostEditAutoClipping,
    postUpdateSentimentArticle,
    resPostUpdateSentimentArticle,
    postDeleteArticle,
    resPostDeleteArticle,
    addArticlesToClippings,
    responseArticlesToClippings,
    applyLabelArticle,
    resApplyLabelArticle,
    postComparisonOnm,
    resPostComparisonOnm,
    getListOnmComparison,
    getOnmComparisonEntity,
    editComparisonOnm,
    resEditComparisonOnm,
    updateSentimentByFilter,
    responseUpdateSentimentByFilter,
    updateLabelByFilter,
    responseUpdateLabelByFilter,
    deleteOnmComparison,
    resDeleteOnmComparison,
    setOnmDefault,
    responseSetOnmDefault,
    getAllMetricComparisonOnm,
    setComparisonTableColumn,
    responseComparisonTableColumn,
    headCellsAdvanceMetrics,
    addMediaSource,
    resAddMediaSource,
    addClippingV5,
    responseAddClippingV5,
    editClippingV5,
    responseEditClippingV5,
    deleteClippingV5,
    responseDeleteClippingV5,
    getClippingsV5,
    removeArticlesByFilter,
    responseRemoveArticlesByFilter,
    downloadClusteringCsv,
    resDownloadClusteringCsv
  };
};

export default useOnlineMedia;
