import gql from 'graphql-tag';
import { analyticArguments, analyticArgumentsQuery } from '../analyticArgument';

export const GET_COMMUNITY_GROUP_TOTAL = gql`
query community_getGroupedTotal(${analyticArgumentsQuery}) {
  community_getGroupedTotal(${analyticArguments}) {
    recentValue
    growth
    pastValue
    contentType
  }
}`;

export const GET_COMMUNITY_GROUP_CHRONOLOGICAL_COMMUNITY = gql`
query community_getGroupedAudienceGrowth(${analyticArgumentsQuery}) {
  community_getGroupedAudienceGrowth(${analyticArguments}) {
    contentType
    values {
      timestamp
      value
    }
  }
}`;

export const GET_COMMUNITY_TOTAL_FOLLOWER = gql`
  query community_getTotalFollower(${analyticArgumentsQuery}) {
    community_getTotalFollower(${analyticArguments}) {
      recentValue
      growth
      pastValue
    }
  }`;

export const GET_COMMUNITY_TOTAL_ENGAGED_USER = gql`
  query community_getTotalEngagedUser(${analyticArgumentsQuery}) {
    community_getTotalEngagedUser(${analyticArguments}) {
      recentValue
      growth
      pastValue
    }
  }`;

export const GET_COMMUNITY_AUDIENCE_GROWTH_FACEBOOK = gql`
query community_getAudienceGrowthFacebook(${analyticArgumentsQuery}) {
    community_getAudienceGrowthFacebook(${analyticArguments}) {
    values {
        timestamp
        value
      }
  }
}`;

export const GET_COMMUNITY_AUDIENCE_GROWTH_INSTAGRAM = gql`
query community_getAudienceGrowthInstagram(${analyticArgumentsQuery}) {
    community_getAudienceGrowthInstagram(${analyticArguments}) {
    values {
        timestamp
        value
      }
  }
}`;

export const GET_COMMUNITY_AUDIENCE_GROWTH_TWITTER = gql`
query community_getAudienceGrowthTwitter(${analyticArgumentsQuery}) {
    community_getAudienceGrowthTwitter(${analyticArguments}) {
    values {
        timestamp
        value
      }
  }
}`;

export const GET_COMMUNITY_AUDIENCE_GROWTH_YOUTUBE = gql`
query community_getAudienceGrowthYoutube(${analyticArgumentsQuery}) {
    community_getAudienceGrowthYoutube(${analyticArguments}) {
    values {
        timestamp
        value
      }
  }
}`;

export const GET_COMMUNITY_WORD_CLOUD_TALK = gql`
query community_getWordCloudTalk(${analyticArgumentsQuery}) {
    community_getWordCloudTalk(${analyticArguments}) {
    name
    value
  }
}`;

export const GET_COMMUNITY_WORD_CLOUD_BIO = gql`
query community_getWordCloudBio(${analyticArgumentsQuery}) {
    community_getWordCloudBio(${analyticArguments}) {
    name
    value
  }
}`;

export const GET_COMMUNITY_BEST_ENGAGED_USER = gql`
query community_getBestEngagedUser(${analyticArgumentsQuery}) {
    community_getBestEngagedUser(${analyticArguments}) {
      comment
      engagement
      followers
      fromName
      like
      share
      socialMedia
      talk
      value
      view
      following
      __typename
  }
}`;

export const GET_COMMUNITY_POPULAR_ENGAGED_USER = gql`
query community_getPopularEngagedUser(${analyticArgumentsQuery}) {
    community_getPopularEngagedUser(${analyticArguments}) {
      comment
      engagement
      followers
      fromName
      like
      share
      socialMedia
      talk
      value
      view
      following
      __typename
  }
}`;

export const GET_COMMUNITY_STREAM_PREVIEW = gql`
query community_getStreamPreview(${analyticArgumentsQuery}) {
    community_getStreamPreview(${analyticArguments}) {
      result{
        originalId
        socialMedia
        displayPicture
        link
        content
        fromId
        fromName
        timestamp
        like
        share
        comment
        audience
        engagementRate
        sentiment
        objectModel{
          id
          socialMedia
          streamType
          content
          extraContent
          objectName
          isHashtag
          createdAt
          openApiId
        }
        postOwnership
      }
      pagination{
        next 
        hasNext
      }
  }
}`;

export const GET_COMMUNITY_FOLLOWERS_BY_LOCATION = gql`
query community_getFollowerLocation(${analyticArgumentsQuery}) {
    community_getFollowerLocation(${analyticArguments}) {
        name
        value
  }
}`;

export const GET_COMMUNITY_FOLLOWERS_BY_INTEREST = gql`
query community_getFollowerInterest(${analyticArgumentsQuery}) {
  community_getFollowerInterest(${analyticArguments}) {
        name
        value
  }
}`;

export const GET_COMMUNITY_FOLLOWERS_BY_GENDER = gql`
query community_getFollowerGender(${analyticArgumentsQuery}) {
  community_getFollowerGender(${analyticArguments}) {
        name
        value
  }
}`;

export const GET_COMMUNITY_TOP_ENGAGEMENT = gql`
query community_getTopEngagement(${analyticArgumentsQuery}){
  community_getTopEngagement(${analyticArguments}){
    comment
      engagement
      followers
      fromName
      like
      share
      socialMedia
      talk
      value
      view
      following
  }
}`;
