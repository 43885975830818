import gql from 'graphql-tag';

export const GET_GROUP_PORTAL = gql`
  query groupPortal_getGroupPortal($projectId: Id!) {
    groupPortal_getGroupPortal(projectId: $projectId) {
      id
      name
      displayName
      portals
      isActive
      createdAt
    }
  }
`;
