import gql from 'graphql-tag';

export const GET_CUSTOM_DASHBOARD_WIDGET_DATA = gql`
  query customDashboard_getWidgetData(
    $granularity: GranularityInput!
    $startDate: Timestamp!
    $endDate: Timestamp!
    $objectIds: [Id!]
    $projectId: Id!
    $timezone: Int!
    $customDashboardWidgetMetadataId: Id!
  ) {
    customDashboard_getWidgetData(
      granularity: $granularity
      startDate: $startDate
      endDate: $endDate
      objectIds: $objectIds
      projectId: $projectId
      timezone: $timezone
      customDashboardWidgetMetadataId: $customDashboardWidgetMetadataId
    ) {
      responseType
      details {
        chronologicalGroup {
          contentType
          values {
            timestamp
            value
          }
        }
        chronological {
          contentType
          values {
            timestamp
            value
          }
        }

        nameValue {
          name
          value
        }

        table {
          header
          body {
            cells {
              cellName
              cellValue
            }
          }
          rowCount
        }

        numericWithGrowth {
          recentValue
          growth
          pastValue
          contentType
          __typename
        }

        user {
          socialMedia
          fromName
          value
          talk
          followers
        }
        peakTime {
          peakTimeValue {
            name
            data
          }
          max
          min
        }
      }
      __typename
    }
  }
`;

export const GET_CUSTOM_DASHBOARD_WIDGET_METADATA = gql`
  query customDashboard_getCustomDashboardWidgetMetadata {
    customDashboard_getCustomDashboardWidgetMetadata {
      id
      metric
      displayName
      description
      size
      title
      visualization
      category
    }
  }
`;
export const GET_CUSTOM_DASHBOARD = gql`
  query customDashboard_getCustomDashboard($projectId: Id!) {
    customDashboard_getCustomDashboard(projectId: $projectId) {
      id
      name
      displayName
      createdAt
      objectList {
        id
        socialMedia
        streamType
        content
        extraContent
        isHashtag
        objectName
        createdAt
      }
      customDashboardWidgetMetadataList {
        id
        metric
        displayName
        description
        size
        title
        visualization
      }
    }
  }
`;

export const CUSTOM_DASHBOARD_DOWNLOAD_PDF = gql`
  query customDashboard_downloadPdf(
    $granularity: GranularityInput!
    $startDate: Timestamp!
    $endDate: Timestamp!
    $objectIds: [Id!]
    $projectId: Id!
    $timezone: Int!
    $customDashboardId: Id!
    $isWhiteLabel:Boolean
  ) {
  customDashboard_downloadPdf(
    projectId: $projectId
    objectIds: $objectIds
    startDate: $startDate
    endDate: $endDate
    timezone:$timezone
    customDashboardId:$customDashboardId
    granularity: $granularity
    isWhiteLabel: $isWhiteLabel
  ) {
        url
      }
  }
`;
