import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IReport } from 'src/models/customReport';
import {
  IGranularity,
  TContentType,
  TPostOwnership,
  TSentiment,
  TValidation
} from 'src/models/general';
import { TSearchType } from 'src/models/onlineMedia';
import { TMetricCategory } from 'src/services/useCustomReport';

export type TSourceType = 'social_media' | 'online_media';
export type TDataType =
  | 'object'
  | 'group'
  | 'comparison'
  | 'clipping'
  | 'comparisonOnm';
export type TVisualization =
  | 'bar_chart'
  | 'bar_chart_horizontal'
  | 'bubble'
  | 'line_chart'
  | 'table'
  | 'pie'
  | 'text'
  | 'positioning_map'
  | 'sample_post'
  | 'sample_talk'
  | 'sample_talk_single'
  | 'sample_post_single'
  | 'sample_article_multiple'
  | 'sample_article_single'
  | 'stack_bar_chart'
  | 'word_cloud';

export interface IContent {
  widgetConfigId: string;
  titleContent: string;
  sourceType: TSourceType;
  dataSource: TDataType;
  objectList: Array<string>;
  objectGroupId: string;
  comparisonId: string;
  metric: string;
  category: TMetricCategory;
  visualization: TVisualization;
  granularity?: IGranularity;
  quantity?: number;
  sortingByColumn?: string;
  page?: number; // index of sample
  // advance search --------------
  includeKeywords?: Array<string>;
  excludeKeywords?: Array<string>;
  fromUsername?: string;
  sentiment?: Array<TSentiment>;
  postOwnership?: Array<TPostOwnership>;
  contentTypeList?: Array<TContentType>;
  validationList?: Array<TValidation>;
  labelList?: Array<string>;
  replyToOriginalId?: Array<string>;
  showEmptyLabel?: boolean;
  sentimentConfidenceLevelMin?: number;
  sentimentConfidenceLevelMax?: number;
  excludeRt?: boolean;
  timezone?: number;
  // advance search online media
  clippingId: string;
  comparisonIdOnlineMedia: string;
  mediaList?: Array<string>;
  searchType?: TSearchType;
  languageList?: Array<string>;
  limitRow?: number;
}

export const advanceSearchInitialStateSM = {
  includeKeywords: [],
  excludeKeywords: [],
  sentiment: [],
  fromUsername: '',
  postOwnership: [],
  validationList: [],
  contentTypeList: [],
  labelList: [],
  replyToOriginalId: [],
  showEmptyLabel: false,
  sentimentConfidenceLevelMin: 0,
  sentimentConfidenceLevelMax: 1,
  excludeRt: false,
  timezone: 7
};
export const advanceSearchInitialStateONM = {
  sentiment: [],
  validationList: [],
  mediaList: [],
  includeKeywords: [],
  excludeKeywords: [],
  searchType: 'allSearch',
  labelList: []
};

interface ILayoutContents {
  title: string;
  subTitle: string;
  contents: Array<IContent> | null;
}

export type TSlideType =
  | 'titleSlide'
  | 'singleContents'
  | 'twoContents'
  | 'threeContents'
  | 'fourContents'
  | 'nineContents'
  | 'sectionHeader'
  | 'oneTwoContents'
  | 'oneThreeContents'
  | 'twoOneContents'
  | 'threeOneContents'
  | 'verticalFourContents'
  | 'sixContents';

export interface ISlide {
  id: string;
  slideType: TSlideType;
  pagePosition: number;
  details: {
    titleSlide: ILayoutContents | null;
    singleContents: ILayoutContents | null;
    twoContents: ILayoutContents | null;
    threeContents: ILayoutContents | null;
    fourContents: ILayoutContents | null;
    nineContents: ILayoutContents | null;
    sectionHeader: ILayoutContents | null;
    oneTwoContents: ILayoutContents | null;
    oneThreeContents: ILayoutContents | null;
    twoOneContents: ILayoutContents | null;
    threeOneContents: ILayoutContents | null;
    verticalFourContents: ILayoutContents | null;
    sixContents: ILayoutContents | null;
  };
}

export enum TActiveField {
  title = -1,
  content1 = 0,
  content2 = 1,
  content3,
  content4,
  content5,
  content6,
  content7,
  content8,
  content9
}

interface IIsSaved {
  [id: string]: boolean;
}

const initialIsSaved = {
  '-1': true, // for title
  '0': true, // for content1
  '1': true, // for content2
  '2': true, // for content3
  '3': true, // for content4
  '4': true, // for content5
  '5': true, // for content6
  '6': true, // for content7
  '7': true, // for content8
  '8': true // for content9
};

///////////////////////////////////////////////////////////
export interface ICustomReportSlice {
  reportList: {
    result: Array<IReport>;
    page: number;
    limit: number;
    totalRows: number;
    totalPages: number;
  };
  selectedReport: IReport | undefined;
  isSaved: IIsSaved;
  activeField: TActiveField;
  activeSlide: ISlide | undefined;
  slideList: Array<ISlide>;
  totalSlideRows: number;
  refetchSignal: boolean;
}

const initialState: ICustomReportSlice = {
  reportList: {
    result: [],
    page: 1,
    limit: 10,
    totalRows: 0,
    totalPages: 0
  },
  selectedReport: undefined,
  isSaved: initialIsSaved,
  activeField: -1,
  activeSlide: undefined,
  slideList: [],
  totalSlideRows: 0,
  refetchSignal: false
};

export const customReport = createSlice({
  name: 'customReport',
  initialState,
  reducers: {
    reducerSetReportList: (
      state: ICustomReportSlice,
      action: PayloadAction<{
        result: Array<IReport>;
        page: number;
        limit: number;
        totalRows: number;
        totalPages: number;
      }>
    ) => {
      state.reportList = action.payload;
    },
    reducerSetSelectedReport: (
      state: ICustomReportSlice,
      action: PayloadAction<IReport>
    ) => {
      state.selectedReport = action.payload;
    },
    reducerAddSlide: (
      state: ICustomReportSlice,
      action: PayloadAction<ISlide>
    ) => {
      state.slideList = [...state.slideList, action.payload];
    },
    reducerSetActiveSlide: (
      state: ICustomReportSlice,
      action: PayloadAction<ISlide>
    ) => {
      state.activeSlide = action.payload;
      state.activeField = -1;
      state.isSaved = initialIsSaved;
    },
    reducerSetActiveField: (
      state: ICustomReportSlice,
      action: PayloadAction<TActiveField>
    ) => {
      state.activeField = action.payload;
    },
    reducerSetActiveSlideTitle: (
      state: ICustomReportSlice,
      action: PayloadAction<string>
    ) => {
      state.activeSlide.details[state.activeSlide.slideType] = {
        ...state.activeSlide.details[state.activeSlide.slideType],
        title: action.payload
      };
      state.isSaved[-1] = false;
    },
    reducerSetActiveSlideSubtitle: (
      state: ICustomReportSlice,
      action: PayloadAction<string>
    ) => {
      state.activeSlide.details[state.activeSlide.slideType] = {
        ...state.activeSlide.details[state.activeSlide.slideType],
        subTitle: action.payload
      };
      state.isSaved[-1] = false;
    },
    reducerUpdateActiveSlideContent: (
      state: ICustomReportSlice,
      action: PayloadAction<{
        data: Partial<IContent>;
      }>
    ) => {
      state.activeSlide.details[state.activeSlide.slideType].contents[
        state.activeField
      ] = {
        ...state.activeSlide.details[state.activeSlide.slideType].contents[
          state.activeField
        ],
        ...action.payload.data
      };
      state.isSaved[state.activeField] = false;
    },
    reducerSetSlides: (
      state: ICustomReportSlice,
      action: PayloadAction<ISlide[]>
    ) => {
      state.slideList = action.payload;
    },
    reducerSaveSlide: (
      state: ICustomReportSlice,
      action: PayloadAction<ISlide>
    ) => {
      const editedSlides = state.slideList.map((slide) => {
        if (slide.id === action.payload.id) return action.payload;
        return slide;
      });
      state.slideList = editedSlides;
      state.isSaved = initialIsSaved;
    },
    reducerHandleDiscard: (
      state: ICustomReportSlice,
      action: PayloadAction<{
        data: {
          title?: string;
          content?: IContent;
        };
      }>
    ) => {
      if (state.activeField === -1) {
        state.activeSlide.details[state.activeSlide.slideType].title =
          action.payload.data.title;
      } else {
        state.activeSlide.details[state.activeSlide.slideType].contents[
          state.activeField
        ] = action.payload.data.content;
      }
      state.isSaved[state.activeField] = true;
    },
    reducerRefetchSignal: (
      state: ICustomReportSlice,
      action: PayloadAction<boolean>
    ) => {
      state.refetchSignal = action.payload;
    },
    reducerSetTotalSlideRows: (
      state: ICustomReportSlice,
      action: PayloadAction<number>
    ) => {
      state.totalSlideRows = action.payload;
    }
  }
});

export const {
  reducerSetReportList,
  reducerSetSelectedReport,
  reducerAddSlide,
  reducerSetActiveSlide,
  reducerSetActiveField,
  reducerSetActiveSlideTitle,
  reducerSetActiveSlideSubtitle,
  reducerUpdateActiveSlideContent,
  reducerSetSlides,
  reducerSaveSlide,
  reducerHandleDiscard,
  reducerRefetchSignal,
  reducerSetTotalSlideRows
} = customReport.actions;

export default customReport.reducer;
