import { Grid, Stack, Typography, Link } from '@mui/material';
import { FC } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';

interface Props {
  src: string;
  name: string;
  content: string;
  originalPost: string;
}

const StreamPreview: FC<Props> = ({ src, name, content, originalPost }) => {
  return (
    <Grid container spacing={2}>
      <Grid item lg={10}>
        <Stack direction="row" alignItems="center">
          <img
            src={src}
            alt={name}
            style={{
              borderRadius: 6,
              width: 72,
              height: 72,
              marginRight: 8
            }}
          />
          <Typography variant="subtitle2" sx={{ color: '#4B4B4B' }}>
            {content}
          </Typography>
        </Stack>
      </Grid>
      <Grid
        item
        lg={2}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Link href={originalPost} underline="none" target="_blank">
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle2">View post</Typography>{' '}
            <LaunchIcon
              sx={{ width: 16, height: 16, color: '#051C26', ml: 1 }}
            />
          </Stack>
        </Link>
      </Grid>
    </Grid>
  );
};

export default StreamPreview;
