import { useLazyQuery, useMutation } from '@apollo/client';
import { useAppDispatch } from 'src/app/hooks';
import {
  reducerRemoveLoadingOfflineMedia,
  reducerSentimentLoadingOfflineMedia,
  reducerSetOfflineMediaArticleList,
  reducerUpdateRemoveOfflineMedia,
  reducerUpdateOfflineMediaArticles
} from 'src/features/offlineMediaStream';
import {
  IAddClippingPayload,
  IAddClippingResponse,
  IArticleOfflineMediaResponse,
  IClippingListPayload,
  IClippingListResponse,
  IEditClippingPayload,
  IEditClippingResponse,
  IGetClippingByIdPayload,
  IGetClippingByIdResponse,
  IManageSentimentOfflineMediaResponse,
  IManageVisibilityOfflineMediaResponse,
  ITotalArticleByOfflineMediaResponse,
  IDeleteClippingArticlesPayload,
  IDeleteClippingArticlesResponse,
  IArticleOfflineMediaPayload,
  IAddArticlesToClippingsResponse,
  IAddArticlesToClippingsPayload,
  IUpdateSentimentArticleResponse,
  IUpdateSentimentArticlePayload,
  IUpdateLabelArticleResponse,
  IUpdateLabelArticlePayload,
  IGetChronologicalSentimentResponse
} from 'src/models/offlineMedia';
import {
  ADD_CLIPPING,
  DELETE_CLIPPING,
  EDIT_CLIPPING,
  MANAGE_ARTICLES_ON_CLIPPING,
  MANAGE_CLIPPING_ON_ARTICLE,
  MANAGE_LABEL_ON_ARTICLE,
  MANAGE_SENTIMENT_OFFLINE_MEDIA,
  MANAGE_SENTIMENT_ON_ARTICLE,
  MANAGE_VISIBILITY_OFFLINE_MEDIA
} from './graphql/offlineMedia/mutation';
import {
  GET_ARTICLE_OFFLINE_MEDIA,
  GET_CHRONOLOGICAL_OFFLINE_ARTICLE,
  GET_CHRONOLOGICAL_OFFLINE_MEDIA,
  GET_CHRONOLOGICAL_SENTIMENT_OFFLINE_MEDIA,
  GET_CLIPPING_BY_ID,
  GET_CLIPPING_LIST,
  GET_SENTIMENT_ANALYSIS_OFFLINE_MEDIA,
  GET_TOP_LOCATION_OFFLINE_MEDIA,
  GET_TOP_PEOPLE_OFFLINE_MEDIA,
  GET_TOTAL_ARTICLE_BY_OFFLINE_MEDIA,
  GET_TOTAL_OFFLINE_ARTICLE,
  GET_TOTAL_OFFLINE_MEDIA,
  GET_TOTAL_PR_VALUES_OFFLINE_MEDIA,
  GET_WORD_CLOUD_OFFLINE_NEWS
} from './graphql/offlineMedia/query';
import { IClipping } from 'src/models/onlineMedia';
import { useAlert } from 'src/hooks/useAlert';

const useOfflineMedia = () => {
  const dispatch = useAppDispatch();
  const { handleClickAlert } = useAlert();

  const [getArticleOfflineMedia, resGetArticleOfflineMedia] = useLazyQuery<
    IArticleOfflineMediaResponse,
    IArticleOfflineMediaPayload
  >(GET_ARTICLE_OFFLINE_MEDIA, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      dispatch(
        reducerSetOfflineMediaArticleList(
          res.offlineMedia_getArticleOfflineMedia
        )
      );
    }
  });

  const [getTotalArtilceByOfflineMedia, resGetTotalArtilceByOfflineMedia] =
    useLazyQuery<ITotalArticleByOfflineMediaResponse>(
      GET_TOTAL_ARTICLE_BY_OFFLINE_MEDIA,
      {
        fetchPolicy: 'network-only'
      }
    );

  const [getClippingOfflineMedia, resGetClippingOfflineMedia] = useLazyQuery<
    IClippingListResponse,
    IClippingListPayload
  >(GET_CLIPPING_LIST, {
    fetchPolicy: 'network-only'
  });

  const [getClippingOfflineMediaById, resGetClippingOfflineMediaById] =
    useLazyQuery<IGetClippingByIdResponse, IGetClippingByIdPayload>(
      GET_CLIPPING_BY_ID,
      { fetchPolicy: 'network-only' }
    );

  const [getTotalArticle, resTotalArticle] = useLazyQuery(
    GET_TOTAL_OFFLINE_ARTICLE
  );
  const [getSentimentAnalysis, resSentimentAnalysist] = useLazyQuery(
    GET_SENTIMENT_ANALYSIS_OFFLINE_MEDIA
  );
  const [getTotalMedia, resTotalMedia] = useLazyQuery(GET_TOTAL_OFFLINE_MEDIA);
  const [getTotalPrValue, resTotalPrValue] = useLazyQuery(
    GET_TOTAL_PR_VALUES_OFFLINE_MEDIA
  );
  const [getTopPeople, resTopPeople] = useLazyQuery(
    GET_TOP_PEOPLE_OFFLINE_MEDIA
  );
  const [getTopLocation, resTopLocation] = useLazyQuery(
    GET_TOP_LOCATION_OFFLINE_MEDIA
  );
  const [getMediaArticle, resMediaArticle] = useLazyQuery(
    GET_TOTAL_ARTICLE_BY_OFFLINE_MEDIA
  );
  const [getChronologicalMedia, resChronologicalMedia] = useLazyQuery(
    GET_CHRONOLOGICAL_OFFLINE_MEDIA
  );
  const [getChronologicalArticle, resChronologicalArticle] = useLazyQuery(
    GET_CHRONOLOGICAL_OFFLINE_ARTICLE
  );

  const [getWordCloud, resWordCloud] = useLazyQuery(
    GET_WORD_CLOUD_OFFLINE_NEWS
  );

  const [getChronologicalSentiment, resChronologicalSentiment] =
    useLazyQuery<IGetChronologicalSentimentResponse>(
      GET_CHRONOLOGICAL_SENTIMENT_OFFLINE_MEDIA
    );

  const [updateSentimentOfflineMedia, resUpdateSentimentOfflineMedia] =
    useMutation<IManageSentimentOfflineMediaResponse>(
      MANAGE_SENTIMENT_OFFLINE_MEDIA,
      {
        onCompleted: (res) => {
          dispatch(reducerSentimentLoadingOfflineMedia([]));
          dispatch(
            reducerUpdateOfflineMediaArticles(
              res.offlineMedia_manageSentimentOnArticle.attachArticles
            )
          );
        }
      }
    );

  const [updateVisibilityOfflineMedia, resUpdateVisibilityOfflineMedia] =
    useMutation<IManageVisibilityOfflineMediaResponse>(
      MANAGE_VISIBILITY_OFFLINE_MEDIA,
      {
        onCompleted: (res) => {
          dispatch(reducerRemoveLoadingOfflineMedia([]));
          dispatch(
            reducerUpdateRemoveOfflineMedia(
              res.offlineMedia_manageVisibilityOnArticle.attachArticles
            )
          );
        }
      }
    );

  const [addClippingOfflineMedia, resAddClippingOfflineMedia] = useMutation<
    IAddClippingResponse,
    IAddClippingPayload
  >(ADD_CLIPPING);

  const [editClippingOfflineMedia, resEditClippingOfflineMedia] = useMutation<
    IEditClippingResponse,
    IEditClippingPayload
  >(EDIT_CLIPPING, {
    refetchQueries: [GET_CLIPPING_LIST, 'article_getClippingArticle']
  });

  const [deleteClippingOfflineMedia, resDeleteClippingOfflineMedia] =
    useMutation<IClipping, { clippingId: string }>(DELETE_CLIPPING, {
      onError: (error) => {
        handleClickAlert({
          vertical: 'top',
          horizontal: 'center',
          message: "Couldn't delete clipping, please try again",
          severity: 'error'
        });
        console.log(error);
      }
    });

  const [deleteClippingArticles, resDeleteClippingArticles] = useMutation<
    IDeleteClippingArticlesResponse,
    IDeleteClippingArticlesPayload
  >(MANAGE_ARTICLES_ON_CLIPPING, {
    onError: (error) => {
      handleClickAlert({
        vertical: 'top',
        horizontal: 'center',
        message: "Couldn't delete articles, please try again",
        severity: 'error'
      });
      console.log(error);
    }
  });

  const [addArticlesToClippings, resAddArticlesToClippings] = useMutation<
    IAddArticlesToClippingsResponse,
    IAddArticlesToClippingsPayload
  >(MANAGE_CLIPPING_ON_ARTICLE, {
    onError: (error) => {
      handleClickAlert({
        vertical: 'top',
        horizontal: 'center',
        message: "Couldn't add clippings, please try again",
        severity: 'error'
      });
      console.log(error);
    }
  });

  const [updateSentimentArticle, resUpdateSentimentArticle] = useMutation<
    IUpdateSentimentArticleResponse,
    IUpdateSentimentArticlePayload
  >(MANAGE_SENTIMENT_ON_ARTICLE, {
    onError: (error) => {
      handleClickAlert({
        vertical: 'top',
        horizontal: 'center',
        message: "Couldn't update sentiment, please try again",
        severity: 'error'
      });
      console.log(error);
    }
  });

  const [updateLabelArticle, resUpdateLabelArticle] = useMutation<
    IUpdateLabelArticleResponse,
    IUpdateLabelArticlePayload
  >(MANAGE_LABEL_ON_ARTICLE, {
    onError: (error) => {
      handleClickAlert({
        vertical: 'top',
        horizontal: 'center',
        message: "Couldn't update label, please try again",
        severity: 'error'
      });
      console.log(error);
    }
  });

  return {
    getArticleOfflineMedia,
    resGetArticleOfflineMedia,
    getTotalArtilceByOfflineMedia,
    resGetTotalArtilceByOfflineMedia,
    updateSentimentOfflineMedia,
    resUpdateSentimentOfflineMedia,
    updateVisibilityOfflineMedia,
    resUpdateVisibilityOfflineMedia,
    getClippingOfflineMedia,
    resGetClippingOfflineMedia,
    addClippingOfflineMedia,
    resAddClippingOfflineMedia,
    deleteClippingOfflineMedia,
    resDeleteClippingOfflineMedia,
    editClippingOfflineMedia,
    resEditClippingOfflineMedia,
    getClippingOfflineMediaById,
    resGetClippingOfflineMediaById,
    deleteClippingArticles,
    resDeleteClippingArticles,
    addArticlesToClippings,
    resAddArticlesToClippings,
    updateSentimentArticle,
    resUpdateSentimentArticle,
    updateLabelArticle,
    resUpdateLabelArticle,
    getTotalArticle,
    resTotalArticle,
    getSentimentAnalysis,
    resSentimentAnalysist,
    getTotalMedia,
    resTotalMedia,
    getTotalPrValue,
    resTotalPrValue,
    getTopPeople,
    resTopPeople,
    getTopLocation,
    resTopLocation,
    getMediaArticle,
    resMediaArticle,
    getChronologicalMedia,
    resChronologicalMedia,
    getChronologicalArticle,
    resChronologicalArticle,
    getWordCloud,
    resWordCloud,
    getChronologicalSentiment,
    resChronologicalSentiment
  };
};

export default useOfflineMedia;
