import gql from 'graphql-tag';

export const GET_USER_PLAN = gql`
  query billing_getUserPlan($projectId: Id!) {
    billing_getUserPlan(projectId: $projectId) {
      id
      name
      email
      basicObjectQuota
      advanceObjectQuota
      period
      periodStart
      periodEnd
      status
      currency
      nextBasicObjectQuota
      nextBasicObjectPrice
      nextAdvanceObjectQuota
      nextTotal
      packagePlan {
        id
        name
        displayName
        advanceObjectQuota
        basicObjectQuota
        onlineMediaObjectQuota
      }
      total
      streamQuota
      currentUsageStreamQuota
      nextPeriodStart
      nextPeriodEnd
      isApply
      nextInvoiceId
      nextAdvanceObjectPrice
      nextBasicObjectPrice
      currentUsageStreamQuota
      countryId
      __typename
    }
  }
`;

export const GET_USER_INVOICE = gql`
  query billing_getUserInvoice($projectId: Id!) {
    billing_getUserInvoice(projectId: $projectId) {
      id
      invoiceDate
      dueDate
      invoiceNumber
      status
      periodStart
      periodEnd
      plan {
        account
        currency
        keyword
        period
        promoCode
        packagePlanName
        __typename
      }
      planCalculation {
        discount
        extensionFees
        isPromoValid
        isUsedPromo
        promoCode
        subTotal
        total
        totalNotes
        upgradeFees
        __typename
      }
      project {
        companyName
        name
        userEmail
        userName
        __typename
      }
      payments {
        groupName
        groupCode
        groupIcon
        channels {
          id
          name
          logo
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const CALCULATE_PLAN = gql`
  query billing_calculatePlan(
    $userPlanId: Id!
    $advanceObject: Int!
    $basicObject: Int!
    $period: Int!
  ) {
    billing_calculatePlan(
      userPlanId: $userPlanId
      advanceObject: $advanceObject
      basicObject: $basicObject
      period: $period
    ) {
      total
      upgradeFees
      extensionFees
      changeReason
      removedBasicObject
      removedAdvanceObject
      isApply
    }
  }
`;

export const GET_USER_INVOICE_BY_ID = gql`
  query billing_getUserInvoiceById($userInvoiceId: Id!) {
    billing_getUserInvoiceById(userInvoiceId: $userInvoiceId) {
      id
      merchantName
      merchantDetail {
        url
        description
        merchantUserId
        merchantExternalId
        merchantResponse
        paymentType
        paymentChannel
        xenditInvoicesId
      }
      invoiceNumber
      invoiceDate
      dueDate
      status
      payments {
        channels {
          id
          name
          logo
        }
        groupName
        groupCode
        groupIcon
      }
      project {
        name
        companyName
        userName
        userEmail
      }
      plan {
        account
        keyword
        period
        currency
        promoCode
        packagePlanName
      }
      planCalculation {
        subTotal
        discount
        total
        totalNotes
        isUsedPromo
        isPromoValid
        promoCode
        upgradeFees
        extensionFees
        registrasionFees
      }
    }
  }
`;

export const BILLING_GET_COUNTRIES = gql`
  query billing_getCountry {
    billing_getCountry {
      id
      name
      countryCode
      currency
      __typename
    }
  }
`;

export const BILLING_GET_ACCOUNT_PERIODE = gql`
  query billing_getAccountKeywordSubcription {
    billing_getAccountKeywordSubcription {
      basicObject
      advanceObject
      subscription
    }
  }
`;

export const BILLING_GET_PAKAGE_PLAN_STREAM = gql`
  query billingAppSumo_getPackagePlanStream {
    billingAppSumo_getPackagePlanStream {
      name
      price
      packagePlanId
      quota
      __typename
    }
  }
`;

export const BILLING_APPSUMO_GET_PLAN_STREAM_SIMULATION = gql`
  query billingAppSumo_getPlanStreamSimulation(
    $packagePlanId: Id!
    $promoCode: String
  ) {
    billingAppSumo_getPlanStreamSimulation(
      packagePlanId: $packagePlanId
      promoCode: $promoCode
    ) {
      name
      packagePlanId
      quota
      total
      subTotal
      discount
      message
      currency
      symbolBySelectedCountry
      __typename
    }
  }
`;

export const BILLING_APPSUMO_GET_USER_INVOICE_BY_ID = gql`
  query billingAppSumo_getUserInvoiceById($userInvoiceId: Id!) {
    billingAppSumo_getUserInvoiceById(userInvoiceId: $userInvoiceId) {
      id
      merchantName
      merchantDetail {
        url
      }
      status
      periodStart
      periodEnd
      plan {
        account
        stream
        currency
        keyword
        period
        promoCode
        packagePlanName
        __typename
      }
      planCalculation {
        discount
        extensionFees
        isPromoValid
        isUsedPromo
        promoCode
        subTotal
        total
        totalNotes
        upgradeFees
        __typename
      }
      project {
        companyName
        name
        userEmail
        userName
        __typename
      }
      payments {
        groupName
        groupCode
        groupIcon
        channels {
          id
          name
          logo
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const BILLING_APPSUMO_GET_USER_INVOICE = gql`
  query billingAppSumo_getUserInvoice($projectId: Id!) {
    billingAppSumo_getUserInvoice(projectId: $projectId) {
      id
      merchantName
      merchantDetail {
        url
      }
      status
      periodStart
      periodEnd
      plan {
        account
        stream
        currency
        keyword
        period
        promoCode
        packagePlanName
        __typename
      }
      planCalculation {
        discount
        extensionFees
        isPromoValid
        isUsedPromo
        promoCode
        subTotal
        total
        totalNotes
        upgradeFees
        __typename
      }
      project {
        companyName
        name
        userEmail
        userName
        __typename
      }
      payments {
        groupName
        groupCode
        groupIcon
        channels {
          id
          name
          logo
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const BILLING_GET_USER_PLAN = gql`
  query billing_getUserPlan($projectId: Id!) {
    billing_getUserPlan(projectId: $projectId) {
      id
      name
      email
      basicObjectQuota
      advanceObjectQuota
      periodStart
      periodEnd
      status
      currency
      nextBasicObjectQuota
      nextBasicObjectPrice
      nextAdvanceObjectQuota
      nextTotal
      packagePlan
      total
      streamQuota
      currentUsageStreamQuota
      nextPeriodStart
      nextPeriodEnd
      isApply
      nextInvoiceId
      streamQuota
      currentUsageStreamQuota
      __typename
    }
  }
`;

export const BILLING_CURRENCY_CONVERTER = gql`
  query user_currencyConverter($countryId: Id!) {
    user_currencyConverter(countryId: $countryId) {
      value
    }
  }
`;
