import {
  Grid,
  Paper,
  Box,
  useTheme,
  useMediaQuery,
  Button
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import FormAccount from './FormAccount';
import PricingCalculator from './PricingCalculator';
import { useMemo, useState } from 'react';
import useRegister from 'src/services/useRegister';
import getCoverImage from 'src/utils/getCoverImage';

const Account = () => {
  const { responseUserRegister } = useRegister();
  const [periodes, setPeriodes] = useState([
    {
      id: 'monthly',
      isActive: true,
      label: 'Monthly',
      value: 1,
      period: 'month'
    },
    {
      id: 'quarterly',
      isActive: false,
      label: 'Quarterly',
      value: 3,
      period: '3 month'
    },
    {
      id: 'annually',
      isActive: false,
      label: 'Annually',
      value: 12,
      period: 'year'
    }
  ]);

  const period = useMemo(() => {
    return periodes.find((period) => period.isActive);
  }, [periodes]);

  const theme = useTheme();
  const isSmallOrLess = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <CssBaseline />
      <Grid
        container
        component="main"
        sx={{
          height: '100vh',
          overflow: 'auto',
          position: 'relative'
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          // component={Paper}
          // elevation={6}
          // square
        >
          <Box
            sx={{
              pt: 4,
              pr: 10,
              [theme.breakpoints.down('sm')]: {
                px: 2
              },
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <FormAccount period={period} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          sx={
            isSmallOrLess
              ? {}
              : {
                  backgroundImage: `url(${getCoverImage()})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundColor: (t) =>
                    t.palette.mode === 'light'
                      ? t.palette.grey[50]
                      : t.palette.grey[900],
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }
          }
        >
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
              pt: 4,
              pl: 10,
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              [theme.breakpoints.down('sm')]: {
                px: 2,
                pt: 0
              }
            }}
          >
            <PricingCalculator periodes={periodes} setPeriodes={setPeriodes} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{ [theme.breakpoints.up('sm')]: { display: 'none' } }}
        >
          <Box
            sx={{
              [theme.breakpoints.down('sm')]: {
                px: 2,
                pt: 0
              }
            }}
          >
            <Button
              fullWidth
              form={'accountForm'}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              sx={
                !isSmallOrLess
                  ? {
                      display: 'none'
                    }
                  : {
                      my: 4,
                      px: 8,
                      mx: 'auto'
                    }
              }
              disabled={responseUserRegister.loading}
            >
              Continue
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Account;
