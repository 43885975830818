import {
  BorderStyle,
  Document,
  ExternalHyperlink,
  HeadingLevel,
  ITableBordersOptions,
  ITableWidthProperties,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType
} from 'docx';
import { IOfflineMedia } from 'src/models/offlineMedia';
import moment from 'moment';

export function generateOfmDoc(stream: IOfflineMedia) {
  const {
    title,
    sourceName,
    writer,
    datePublished,
    prValues,
    sentiment,
    content,
    attachments
  } = stream;

  const generateTableDetail = () => {
    const borderOptions: ITableBordersOptions = {
      top: { size: 2, style: BorderStyle.SINGLE, color: '000000' },
      bottom: { size: 2, style: BorderStyle.SINGLE, color: '000000' },
      left: { style: BorderStyle.NIL },
      right: { style: BorderStyle.NIL }
    };

    const createTableCell = (
      text: string,
      borderOptions: ITableBordersOptions,
      width: ITableWidthProperties
    ) => {
      return new TableCell({
        borders: borderOptions,
        width: width,
        children: [new Paragraph(text)]
      });
    };

    return new Table({
      borders: {
        insideHorizontal: { style: BorderStyle.NIL },
        insideVertical: { style: BorderStyle.NIL }
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE
      },
      rows: [
        new TableRow({
          children: [
            createTableCell('Judul', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(title, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Nama Media', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(sourceName, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Penulis', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(writer[0], borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Tanggal', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(
              moment(datePublished).format('llll'),
              borderOptions,
              {
                size: 72,
                type: WidthType.PERCENTAGE
              }
            )
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Sentiment', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(sentiment, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        }),
        new TableRow({
          children: [
            createTableCell('Ad Value', borderOptions, {
              size: 28,
              type: WidthType.PERCENTAGE
            }),
            createTableCell(`Rp ${prValues.toLocaleString()}`, borderOptions, {
              size: 72,
              type: WidthType.PERCENTAGE
            })
          ]
        })
      ]
    });
  };

  const generateParagraphs = (content: string) => {
    const paragraphStrings = content.split('\n');
    const paragraphs = paragraphStrings.map((text) => {
      return new Paragraph(text);
    });
    return paragraphs;
  };

  const generateAttachmentLink = (attachments: string[]) => {
    if (attachments.length <= 0) return new Paragraph('');
    return new Paragraph({
      children: [
        new TextRun({
          text: '',
          break: 1
        }),
        new ExternalHyperlink({
          children: [
            new TextRun({
              text: attachments[0],
              style: 'Hyperlink'
            })
          ],
          link: attachments[0]
        })
      ]
    });
  };

  const tableDetail = generateTableDetail();
  const paragraphs = generateParagraphs(content);
  const attachmentLink = generateAttachmentLink(attachments);

  const doc = new Document({
    styles: {
      default: {
        document: {
          run: { size: '11pt', font: 'Calibri' },
          paragraph: { spacing: { after: 60, before: 60 } }
        }
      }
    },
    sections: [
      {
        children: [
          tableDetail,
          new Paragraph({
            children: [
              new TextRun({
                text: '',
                break: 2
              })
            ]
          }),
          new Paragraph({ text: title, heading: HeadingLevel.HEADING_1 }),
          ...paragraphs,
          attachmentLink
        ]
      }
    ]
  });

  return doc;
}
