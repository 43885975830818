const sortingDayPeakTime = function (dataPeakTime) {
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const sorted = days
    .reverse()
    .map((day: string) => dataPeakTime.find((time: any) => time.name === day));
  return sorted;
};

export default sortingDayPeakTime;
