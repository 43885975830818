import moment from 'moment';
import { IDateRange, IGranularity } from 'src/models/general';

// get granularity based on timeframe
export const getGranularityForChronological = ({
  start,
  end
}: IDateRange): IGranularity => {
  const differentDays = moment(end).diff(start, 'day');
  const differentHours = moment(end).diff(start, 'hour');
  // hours
  if (differentDays <= 1 || differentHours <= 24) {
    const hourly: IGranularity = { unit: 'hour', value: 1 };
    return hourly;
  }
  // days
  if (differentDays > 1 && differentDays < 14) {
    const daily: IGranularity = { unit: 'day', value: 1 };
    return daily;
  }
  // weeks
  if (differentDays >= 14 && differentDays < 60) {
    const weekly: IGranularity = { unit: 'day', value: 7 };
    return weekly;
  }
  const monthly: IGranularity = { unit: 'month', value: 1 };
  return monthly;
};
