import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { IAgeRange, IPeople, TAgeRange } from 'src/models/people';

type Props = {
  ageRange: TAgeRange;
  handleChange: (event: SelectChangeEvent, person: IPeople) => void;
};

export const ageRangeList: IAgeRange[] = [
  {
    id: 'youngerthan13',
    value: 'youngerthan13',
    label: '<13'
  },
  {
    id: 'between13and17',
    value: 'between13and17',
    label: '13 - 17'
  },
  {
    id: 'between18and24',
    value: 'between18and24',
    label: '18 - 24'
  },
  {
    id: 'between24and34',
    value: 'between24and34',
    label: '24 - 34'
  },
  {
    id: 'between35and44',
    value: 'between35and44',
    label: '35 - 44'
  },
  {
    id: 'between45and54',
    value: 'between45and54',
    label: '45 - 54'
  },
  {
    id: 'between55and64',
    value: 'between55and64',
    label: '55 - 64'
  },
  {
    id: 'olderthan64',
    value: 'olderthan64',
    label: '>64'
  }
];

const SelectAgeRange = (props: Props) => {
  return (
    <Select
      labelId="age-select-label"
      id="age-select"
      name="age-select"
      value={props.ageRange ?? 'none'}
      onChange={props.handleChange}
    >
      {ageRangeList.map(({ id, value, label }) => (
        <MenuItem key={id} id={id} value={value}>
          {label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectAgeRange;
