import { gql } from '@apollo/client';
import { slideLayoutDetails } from './query';

export const ADD_REPORT = gql`
  mutation customReportV2_addCustomReport(
    $projectId: Id!
    $displayName: String!
  ) {
    customReportV2_addCustomReport(
      projectId: $projectId
      displayName: $displayName
    ) {
      id
      displayName
    }
  }
`;
export const EDIT_REPORT = gql`
  mutation customReportV2_editCustomReport(
    $projectId: Id!
    $customReportId: Id!
    $displayName: String!
  ) {
    customReportV2_editCustomReport(
      projectId: $projectId
      customReportId: $customReportId
      displayName: $displayName
    ) {
      id
      displayName
      createdAt
    }
  }
`;
export const DELETE_REPORT = gql`
  mutation customReportV2_deleteCustomReport($customReportId: Id!) {
    customReportV2_deleteCustomReport(customReportId: $customReportId) {
      id
      displayName
      createdAt
    }
  }
`;

//// create slide
export const CREATE_NEW_SLIDE = gql`
  mutation customReportV2_createNewSlide(
    $projectId: Id!
    $customReportId: Id!
    $slideType: SlideTypeV2!
  ) {
    customReportV2_createNewSlide(
      projectId: $projectId
      customReportId: $customReportId
      slideType: $slideType
    ) {
      id
      slideType
      details {
        ${slideLayoutDetails}
      }
    }
  }
`;

// update title slide
export const UPDATE_TITLE_SLIDE = gql`
  mutation customReportV2_updateTitleSlide(
    $projectId: Id!
    $slideId: Id!
    $title: String
    $subTitle: String
  ) {
    customReportV2_updateTitleSlide(
      projectId: $projectId
      slideId: $slideId
      title: $title
      subTitle: $subTitle
    ) {
      id
      slideType
      pagePosition
      details {
        titleSlide {
          title
          subTitle
        }
      }
    }
  }
`;

// update multiple content
export const UPDATE_MULTIPLE_CONTENT = gql`
  mutation customReportV2_updateMultipleContent(
    $projectId: Id!
    $slideId: Id!
    $contents: [CustomReportWidgetArgument!]!
    $title: String
  ) {
    customReportV2_updateMultipleContent(
      projectId: $projectId
      slideId: $slideId
      contents: $contents
      title: $title
    ) {
      id
      slideType
      pagePosition
      details {
        ${slideLayoutDetails}
      }
    }
  }
`;

// download report
export const DOWNLOAD_CUSTOM_REPORT = gql`
  mutation customReportV2_downloadReportAsPpt(
    $projectId: Id!
    $startDate: Timestamp!
    $endDate: Timestamp!
    $timezone: Int!
    $customReportId: Id!
  ) {
    customReportV2_downloadReportAsPpt(
      projectId: $projectId
      startDate: $startDate
      endDate: $endDate
      timezone: $timezone
      customReportId: $customReportId
    ) {
      projectId
      startDate
      endDate
      timezone
      status
      fileName
      filePath
    }
  }
`;

export const DELETE_SLIDE = gql`
  mutation customReportV2_deleteSlide($slideId: Id!) {
    customReportV2_deleteSlide(slideId: $slideId) {
      slideType
      pagePosition
    }
  }
`;

export const REORDER_SLIDE = gql`
  mutation customReportV2_changePosition(
    $projectId: Id!
    $slideId: Id!
    $fromIndex: Int!
    $toIndex: Int!
  ) {
    customReportV2_changePosition(
      projectId: $projectId
      slideId: $slideId
      fromIndex: $fromIndex
      toIndex: $toIndex
    ) {
      id
      slideType
      pagePosition
    }
  }
`;
