import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'src/app/store';

interface DownloadState {
  isShow: boolean;
  btnShow: boolean;
}

const initialState: DownloadState = {
  isShow: false,
  btnShow: true
};

export const download = createSlice({
  name: 'downloadState',
  initialState,
  reducers: {
    downloadState: (state: DownloadState, action: any) => {
      state.isShow = action.payload;
    },
    downloadBtnState: (state: DownloadState, action: any) => {
      state.btnShow = action.payload;
    }
  }
});

export const { downloadState, downloadBtnState } = download.actions;

export const stateDownload = (state: RootState) => state.downloadState.isShow;

export default download.reducer;
