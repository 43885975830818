import { ApolloQueryResult } from '@apollo/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INamespaceOnm } from 'src/models/comparison';
import {
  IChronologicalOutput,
  IClusteringConfig,
  IDataWorldCloud,
  IGranularity,
  ILanguage,
  INumericOutput,
  ITableWithPagination
} from 'src/models/general';
import {
  IArticleByMedia,
  IClipping,
  IOnlineMediaStreamOutputEntity,
  IOnlineMediaStreamOutputEntityWithPagination,
  ITopLocation,
  ITopPeople
} from 'src/models/onlineMedia';

const initWordCloud: IDataWorldCloud = {
  data: [],
  min: 0,
  max: 1
};

export const initClusterConfig: IClusteringConfig = {
  desiredClusterCount: 5,
  minimumLabelLength: 2,
  stopWords: []
};

interface IOnlineMediaStream {
  articleList: ApolloQueryResult<IOnlineMediaStreamOutputEntityWithPagination>;
  mediaList: ApolloQueryResult<Array<IArticleByMedia>>;
  clippingList: ApolloQueryResult<ITableWithPagination<IClipping>>;
  totalArticle: ApolloQueryResult<INumericOutput>;
  totalMedia: ApolloQueryResult<INumericOutput>;
  totalPRValue: ApolloQueryResult<INumericOutput>;
  chronologicalArticle: ApolloQueryResult<IChronologicalOutput>;
  chronologicalMedia: ApolloQueryResult<IChronologicalOutput>;
  chronologicalSentiment: ApolloQueryResult<Array<IChronologicalOutput>>;
  wordClouds: ApolloQueryResult<IDataWorldCloud>;
  talkBySentiment: ApolloQueryResult<Array<any>>;
  topPeoples: ApolloQueryResult<Array<ITopPeople>>;
  topLocations: ApolloQueryResult<Array<ITopLocation>>;
  streamSelected: Array<IOnlineMediaStreamOutputEntity>;
  isSelectedAllPrimary: boolean;
  articleSelected: Array<IOnlineMediaStreamOutputEntity>;
  streamUpdatingSentiments: Array<IOnlineMediaStreamOutputEntity>;
  streamUpdatingLabels: Array<IOnlineMediaStreamOutputEntity>;
  streamUpdatingRemoves: Array<IOnlineMediaStreamOutputEntity>;
  streamUpdatingClippings: Array<IOnlineMediaStreamOutputEntity>;
  mediaSelected: string;
  languages: Array<ILanguage>;
  languageSelected: string;
  comparisonList: ApolloQueryResult<Array<INamespaceOnm>>;
  includeKeywords: Array<string>;
  clusteringConfig: IClusteringConfig;
  clippingListSearch: string;
  statisticGranularity: IGranularity;
}

const initialState: IOnlineMediaStream = {
  articleList: {
    data: {
      result: [],
      limit: 20,
      page: 1,
      totalRows: 0,
      totalPages: 0
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  mediaList: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  clippingList: {
    data: {
      result: [],
      limit: 10,
      page: 1,
      totalRows: 0,
      totalPages: 0
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  totalArticle: {
    data: {
      name: '',
      value: null
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  totalMedia: {
    data: {
      name: '',
      value: null
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  totalPRValue: {
    data: {
      name: '',
      value: null
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  chronologicalArticle: {
    data: {
      contentType: '',
      values: []
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  chronologicalMedia: {
    data: {
      contentType: '',
      values: []
    },
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  chronologicalSentiment: {
    data: [],
    loading: false,
    errors: [],
    networkStatus: 1,
    partial: false
  },
  wordClouds: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: initWordCloud
  },
  talkBySentiment: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  topPeoples: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  topLocations: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  streamSelected: [],
  isSelectedAllPrimary: false,
  articleSelected: [],
  streamUpdatingSentiments: [],
  streamUpdatingLabels: [],
  streamUpdatingRemoves: [],
  streamUpdatingClippings: [],
  mediaSelected: 'all-media',
  languages: [],
  languageSelected: 'all-language',
  comparisonList: {
    errors: undefined,
    loading: false,
    networkStatus: 1,
    partial: false,
    data: []
  },
  includeKeywords: [],
  clusteringConfig: initClusterConfig,
  clippingListSearch: '',
  statisticGranularity: { unit: 'day', value: 1 }
};

export const onlineMediaStream = createSlice({
  name: 'onlineMediaStream',
  initialState,
  reducers: {
    reducerUpdateOnlineMediaMediaListLoading: (
      state: IOnlineMediaStream,
      action: PayloadAction<boolean>
    ) => {
      state.mediaList.loading = action.payload;
    },
    reducerUpdateOnlineMediaArticleListLoading: (
      state: IOnlineMediaStream,
      action: PayloadAction<boolean>
    ) => {
      state.articleList.loading = action.payload;
    },
    reducerUpdateOnlineMediaStreamAricles: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<Array<IArticleByMedia>>>
    ) => {
      state.mediaList = action.payload;
    },
    reducerUpdateOnlineMediaArticleList: (
      state: IOnlineMediaStream,
      action: PayloadAction<
        ApolloQueryResult<IOnlineMediaStreamOutputEntityWithPagination>
      >
    ) => {
      state.articleList = action.payload;
    },
    reducerUpdateOnlineMediaArticleListEffect: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<IOnlineMediaStreamOutputEntity>>
    ) => {
      const updateArticle = state.articleList.data.result.map((article) => {
        const articleUpdate = action.payload.find(
          (re) => re.originalId === article.originalId
        );
        if (articleUpdate) {
          return {
            ...article,
            sentiment: articleUpdate.sentiment,
            clippingList: articleUpdate.clippingList,
            labelArticleList: articleUpdate.labelArticleList,
            isEdited: true
          };
        }
        return article;
      });
      state.articleList.data.result = updateArticle;
    },
    reducerRemovesOnlineMediaArticle: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<IOnlineMediaStreamOutputEntity>>
    ) => {
      const updateArticle = state.articleList.data.result.filter((article) => {
        return !action.payload.find(
          (re) => re.originalId === article.originalId
        );
      });
      state.articleList.data.result = updateArticle;
    },
    reducerUpdateOnlineMediaClippingList: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<ITableWithPagination<IClipping>>>
    ) => {
      state.clippingList = action.payload;
    },
    reducerUpdateOnlineMediaTotalArticle: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<INumericOutput>>
    ) => {
      state.totalArticle = action.payload;
    },
    reducerUpdateOnlineMediaTotalMedia: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<INumericOutput>>
    ) => {
      state.totalMedia = action.payload;
    },
    reducerUpdateOnlineMediaTotalPRValue: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<INumericOutput>>
    ) => {
      state.totalPRValue = action.payload;
    },
    reducerUpdateOnlineMediaChronologicalArticle: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<IChronologicalOutput>>
    ) => {
      state.chronologicalArticle = action.payload;
    },
    reducerUpdateOnlineMediaChronologicalArticleLoading: (
      state: IOnlineMediaStream,
      action: PayloadAction<boolean>
    ) => {
      state.chronologicalArticle.loading = action.payload;
    },
    reducerUpdateOnlineMediaChronologicalMedia: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<IChronologicalOutput>>
    ) => {
      state.chronologicalMedia = action.payload;
    },
    reducerUpdateOnlineMediaChronologicalMediaLoading: (
      state: IOnlineMediaStream,
      action: PayloadAction<boolean>
    ) => {
      state.chronologicalMedia.loading = action.payload;
    },
    reducerUpdateOnlineMediaChronologicalSentiment: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.chronologicalSentiment = action.payload;
    },
    reducerUpdateOnlineMediaWordClouds: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<IDataWorldCloud>>
    ) => {
      state.wordClouds = action.payload;
    },
    reducerUpdateOnlineTalkBySentiment: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<Array<any>>>
    ) => {
      state.talkBySentiment = action.payload;
    },
    reducerUpdateOnlineTopPeoples: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<Array<ITopPeople>>>
    ) => {
      state.topPeoples = action.payload;
    },
    reducerUpdateOnlineTopLocations: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<Array<ITopLocation>>>
    ) => {
      state.topLocations = action.payload;
    },
    reducerUpdateArticleSelected: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<IOnlineMediaStreamOutputEntity>>
    ) => {
      state.articleSelected = action.payload;
    },
    reducerUpdateOnlineMediaStatisticLoading: (
      state: IOnlineMediaStream,
      action: PayloadAction<boolean>
    ) => {
      state.articleList.loading = action.payload;
      state.mediaList.loading = action.payload;
      state.totalArticle.loading = action.payload;
      state.totalMedia.loading = action.payload;
      state.totalPRValue.loading = action.payload;
      state.chronologicalArticle.loading = action.payload;
      state.chronologicalMedia.loading = action.payload;
      state.chronologicalSentiment.loading = action.payload;
      state.talkBySentiment.loading = action.payload;
      state.wordClouds.loading = action.payload;
      state.topPeoples.loading = action.payload;
      state.topLocations.loading = action.payload;
    },
    reducerUpdateStreamOnlineMediaSelected: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<IOnlineMediaStreamOutputEntity>>
    ) => {
      state.streamSelected = action.payload;
    },
    reducerUpdateIsSelectedAllPrimaryOnlineMedia: (
      state: IOnlineMediaStream,
      action: PayloadAction<boolean>
    ) => {
      state.isSelectedAllPrimary = action.payload;
    },
    reducerUpdateStreamOnlineMediaSentiment: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<IOnlineMediaStreamOutputEntity>>
    ) => {
      state.streamUpdatingSentiments = action.payload;
    },
    reducerFinishUpdateStreamOnlineMediaSentiment: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<IOnlineMediaStreamOutputEntity>>
    ) => {
      const updateSentiment = state.streamUpdatingSentiments.filter(
        (streamPrev) =>
          action.payload.find(
            (streamUpdate) => streamUpdate.originalId !== streamPrev.originalId
          )
      );
      state.streamUpdatingSentiments = updateSentiment;
    },
    reducerUpdateStreamOnlineMediaLabels: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<IOnlineMediaStreamOutputEntity>>
    ) => {
      state.streamUpdatingLabels = action.payload;
    },
    reducerUpdateStreamOnlineMediaRemoves: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<IOnlineMediaStreamOutputEntity>>
    ) => {
      state.streamUpdatingRemoves = action.payload;
    },
    reducerUpdateStreamOnlineMediaClipping: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<IOnlineMediaStreamOutputEntity>>
    ) => {
      state.streamUpdatingClippings = action.payload;
    },
    reducerFinishStreamOnlineMediaClipping: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<IOnlineMediaStreamOutputEntity>>
    ) => {
      const update = state.streamUpdatingClippings.filter(
        (streamPrev) =>
          !action.payload.find(
            (streamUpdate) => streamUpdate.originalId === streamPrev.originalId
          )
      );
      state.streamUpdatingClippings = update;
    },
    reducerUpdateMediaSelected: (
      state: IOnlineMediaStream,
      action: PayloadAction<string>
    ) => {
      state.mediaSelected = action.payload;
    },
    reducerUpdateLanguages: (
      state: IOnlineMediaStream,
      action: PayloadAction<Array<ILanguage>>
    ) => {
      state.languages = action.payload;
    },
    reducerUpdateLanguageSelected: (
      state: IOnlineMediaStream,
      action: PayloadAction<string>
    ) => {
      state.languageSelected = action.payload;
    },
    reducerUpdateComparisonOnmList: (
      state: IOnlineMediaStream,
      action: PayloadAction<ApolloQueryResult<Array<INamespaceOnm>>>
    ) => {
      state.comparisonList = action.payload;
    },
    reducerUpdateIncludeKeywords: (
      state: IOnlineMediaStream,
      action: PayloadAction<string>
    ) => {
      state.includeKeywords = [action.payload];
    },
    reducerUpdateOnlineMediaClippingListLoading: (
      state: IOnlineMediaStream,
      action: PayloadAction<boolean>
    ) => {
      state.clippingList.loading = action.payload;
    },
    reducerUpdateClusterConfig: (
      state: IOnlineMediaStream,
      action: PayloadAction<IClusteringConfig>
    ) => {
      state.clusteringConfig = action.payload;
    },
    reducerUpdateClippingListSearch: (
      state: IOnlineMediaStream,
      action: PayloadAction<string>
    ) => {
      state.clippingListSearch = action.payload;
    },
    reducerUpdateStatisticGranularity: (
      state: IOnlineMediaStream,
      action: PayloadAction<IGranularity>
    ) => {
      state.statisticGranularity = action.payload;
    }
  }
});

export const {
  reducerUpdateOnlineMediaMediaListLoading,
  reducerUpdateOnlineMediaArticleListLoading,
  reducerUpdateOnlineMediaStreamAricles,
  reducerUpdateOnlineMediaArticleList,
  reducerUpdateOnlineMediaClippingList,
  reducerUpdateOnlineMediaTotalArticle,
  reducerUpdateOnlineMediaTotalMedia,
  reducerUpdateOnlineMediaTotalPRValue,
  reducerUpdateOnlineMediaChronologicalArticle,
  reducerUpdateOnlineMediaChronologicalArticleLoading,
  reducerUpdateOnlineMediaChronologicalMedia,
  reducerUpdateOnlineMediaChronologicalMediaLoading,
  reducerUpdateOnlineMediaChronologicalSentiment,
  reducerUpdateOnlineMediaWordClouds,
  reducerUpdateOnlineTalkBySentiment,
  reducerUpdateOnlineTopPeoples,
  reducerUpdateOnlineTopLocations,
  reducerUpdateOnlineMediaStatisticLoading,
  reducerUpdateOnlineMediaArticleListEffect,
  reducerUpdateStreamOnlineMediaSelected,
  reducerUpdateIsSelectedAllPrimaryOnlineMedia,
  reducerUpdateStreamOnlineMediaSentiment,
  reducerFinishUpdateStreamOnlineMediaSentiment,
  reducerUpdateStreamOnlineMediaLabels,
  reducerUpdateStreamOnlineMediaRemoves,
  reducerUpdateStreamOnlineMediaClipping,
  reducerFinishStreamOnlineMediaClipping,
  reducerUpdateMediaSelected,
  reducerUpdateLanguages,
  reducerUpdateLanguageSelected,
  reducerUpdateComparisonOnmList,
  reducerRemovesOnlineMediaArticle,
  reducerUpdateIncludeKeywords,
  reducerUpdateOnlineMediaClippingListLoading,
  reducerUpdateClusterConfig,
  reducerUpdateClippingListSearch,
  reducerUpdateStatisticGranularity
} = onlineMediaStream.actions;

export default onlineMediaStream.reducer;
