import gql from 'graphql-tag';

export const GET_LABEL_FROM_PROJECT = gql`
  query labellingSystem_getLabelFromProjects($projectId: Id!) {
    labellingSystem_getLabelFromProjects(projectId: $projectId) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
    }
  }
`;

export const GET_AUTO_LABEL_LIST = gql`
  query autoLabel_getAutoLabelList($projectId: Id!) {
    autoLabel_getAutoLabelList(projectId: $projectId) {
      id
      projectId
      name
      description
      start
      postOwnerships
      keywords
      isActive
      createdAt
    }
  }
`;

export const GET_AUTO_LABEL_BY_ID = gql`
  query autoLabel_getAutoLabelById($projectId: Id!, $autoLabelId: Id!) {
    autoLabel_getAutoLabelById(
      projectId: $projectId
      autoLabelId: $autoLabelId
    ) {
      id
      projectId
      objectList {
        id
        socialMedia
        streamType
        content
        extraContent
        objectName
      }
      name
      description
      start
      labelList {
        id
        name
        displayName
        colorType
        isActive
        createdAt
      }
      postOwnerships
      keywords
      isActive
      createdAt
    }
  }
`;

export const GET_LABEL_ARTICLE = gql`
  query labellingArticle_getLabelArticle($projectId: Id!, $clippingId: Id!) {
    labellingArticle_getLabelArticle(
      projectId: $projectId
      clippingId: $clippingId
    ) {
      id
      name
      displayName
      colorType
      isActive
      createdAt
      clippingId
    }
  }
`;

export const GET_LABEL_ARTICLE_V2 = gql`
  query labellingArticle_getLabelArticleV2($projectId: Id!) {
    labellingArticle_getLabelArticleV2(projectId: $projectId) {
      displayName
      name
      colorType
      displayName
      id
      __typename
      createdAt
    }
  }
`;

export const GET_LABEL_PEOPLE = gql`
  query peoplePanel_getLabelList($projectId: Id!) {
    peoplePanel_getLabelList(projectId: $projectId) {
      displayName
      name
      color
      isProjectDefault
      id
      __typename
      createdAt
    }
  }
`;

export const GET_AUTO_LABEL_ONM = gql`
  query autoLabelArticle_getAutoLabel(
    $projectId: Id!
    $limit: Int!
    $page: Int!
    $keyword: String
  ) {
    autoLabelArticle_getAutoLabel(
      projectId: $projectId
      limit: $limit
      page: $page
      keyword: $keyword
    ) {
      page
      limit
      totalRows
      totalPages
      result {
        clippingList {
          id
          name
          createdAt
          phrases
          startDate
        }
        createdAt
        description
        id
        isActive
        labelList {
          id
          name
          displayName
          colorType
          isActive
          createdAt
          clippingId
        }
        name
        phrasesList
        projectId
        start
      }
    }
  }
`;
