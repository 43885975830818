import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    margin: '2px 8px 2px 0px',
    padding: theme.spacing(0, 2, 0, 1),
    borderRadius: '24px 4px 4px 24px',
    borderColor: 'transparent',
    maxWidth: 'fit-content',
    whiteSpace: 'nowrap'
  },
  dot: {
    height: theme.spacing(1),
    width: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: theme.spacing(1)
  },
  textHover: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 14,
    fontWeight: 0.1,
    letterSpacing: 0.2,
    lineHeight: 1.4
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '-webkit-fill-available'
  }
}));

export default useStyles;
