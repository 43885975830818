import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILabel, TValidation } from 'src/models/general';
import {
  IAgeRange,
  IGender,
  TPeopleLocation
} from 'src/models/people';

export interface IPeopleAdvanceSearchSlice {
  interestList: Array<ILabel>;
  interestValidationList: Array<TValidation>;
  labelList: Array<ILabel>;
  labelValidationList: Array<TValidation>;
  ageList: Array<IAgeRange>;
  ageRangeValidationList: Array<TValidation>;
  locationList: Array<TPeopleLocation>;
  locationValidationList: Array<TValidation>;
  genderList: Array<IGender>;
  genderValidationList: Array<TValidation>;
  includeUsername: Array<string>;
}
export const initialState: IPeopleAdvanceSearchSlice = {
  interestList: [],
  interestValidationList: [],
  labelList: [],
  labelValidationList: [],
  ageList: [],
  ageRangeValidationList: [],
  locationList: [],
  locationValidationList: [],
  genderList: [],
  genderValidationList: [],
  includeUsername: []
};

export const advanceSearchPeoplePanel = createSlice({
  name: 'peopleAdvanceSearch',
  initialState: initialState,
  reducers: {
    reducerUpdateIncludeUsername: (
      state: IPeopleAdvanceSearchSlice,
      action: PayloadAction<Array<string>>
    ) => {
      state.includeUsername = action.payload;
    },
    reducerUpdatePeopleAdvanceSearch: (
      state: IPeopleAdvanceSearchSlice,
      action: PayloadAction<IPeopleAdvanceSearchSlice>
    ) => {
      state.includeUsername = action.payload.includeUsername;
      state.locationList = action.payload.locationList;
      state.ageList = action.payload.ageList;
      state.ageRangeValidationList = action.payload.ageRangeValidationList;
      state.genderList = action.payload.genderList;
      state.genderValidationList = action.payload.genderValidationList;
      state.interestList = action.payload.interestList;
      state.interestValidationList = action.payload.interestValidationList;
      state.labelList = action.payload.labelList;
      state.labelValidationList = action.payload.labelValidationList;
    }
  }
});

export const {
  reducerUpdatePeopleAdvanceSearch,
  reducerUpdateIncludeUsername
} = advanceSearchPeoplePanel.actions;

export default advanceSearchPeoplePanel.reducer;
